import { Components } from "@mui/material";

const MENU_THEME: Components["MuiMenu"] = {
  defaultProps: {
    // Elevation will be handled by the boxShadow on paper below
    elevation: 0,
  },
  styleOverrides: {
    paper: {
      boxShadow:
        "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 16px 32px rgba(0, 0, 0, 0.1)",
    },
  },
};

const MENU_ITEM_THEME: Components["MuiMenuItem"] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      // Override anchor styles if used as a menu item
      "a&&": {
        textDecoration: "none",
        color: "inherit",
      },
      // Override button styles if used as a menu item
      "button&&&": {
        textDecoration: "none",
      },
      "button&&&:hover": {
        // Match default MenuItem hover style
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        textDecoration: "none",
      },
      "button&&&:focus-visible": {
        // Match default MenuItem focus-visible style
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        textDecoration: "none",
      },
    },
  },
};

export { MENU_THEME, MENU_ITEM_THEME };
