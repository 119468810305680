import { gql } from "@outschool/ui-apollo";

import { LearnerFragment } from "./Learner";

export const RecordingRequestFragment = gql`
  fragment RecordingRequestFragment on RecordingRequest {
    requestedBy {
      ...LearnerFragment
    }
    requestedAt
    message
  }
  ${LearnerFragment}
`;
