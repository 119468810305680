import { AnalyticsPluginRestProxyConfig, RestProxyClient } from "./node";

class RestProxyBrowserClient extends RestProxyClient {
  constructor(config: AnalyticsPluginRestProxyConfig) {
    super(config);
    this.contextLibraryName = "kafka-browser";
  }

  _applyCommonEventProperties(_properties: any) {
    // do not do anything for now, this is just used by learner tracking and it
    // does not handle well additional values until we fully transition to this pipeline
  }
}

export { RestProxyBrowserClient };
