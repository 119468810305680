import { styled } from "@outschool/backpack";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  sx?: SystemStyleObject;
  dataTestId?: string;
};

const InputBase = styled("input")({
  padding: "6px 10px",
  borderRadius: "4px",
  boxShadow: "none",
  fontSize: "1.6rem",
  fontStyle: "initial",
  "&:focus": {
    outline: "none",
    borderColor: "#4b01d4",
  },
  "&&::placeholder": { color: "grayText", opacity: 1 },
  height: "38px",
  border: "1px solid #EBEBEB",
  webkitAutofill: {
    webkitBoxShadow: "0 0 0px 1000px #f1f1f1 inset !important",
  },
  webkitAppearance: "none",
  mozAppearance: "none",
  appearance: "none",
});

const GenericInput = styled("input")({});

function Input({ sx, dataTestId, ...props }: InputProps, ref: any) {
  return props.type === "radio" ? (
    <GenericInput
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      ref={ref}
      data-test-id={dataTestId}
      {...props}
    />
  ) : (
    <InputBase
      sx={[
        {
          minWidth: props.type === "date" ? "60px" : undefined,
          lineHeight: props.type === "checkbox" ? "1.5" : "1.6",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      ref={ref}
      data-test-id={dataTestId}
      {...props}
    />
  );
}

export default React.forwardRef(Input);
