// import * as Sentry from "../../shared/client/Sentry";

import { City as BaseCity } from "@maxmind/geoip2-node";

export type City = BaseCity;

export async function getIPLookup({
  iplookupApiBaseUrl,
  iplookupApiKey,
  ip,
  fetchFn,
}: {
  iplookupApiBaseUrl: string;
  iplookupApiKey: string;
  ip?: string;
  fetchFn: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
}): Promise<City | null> {
  try {
    let url = `https://${iplookupApiBaseUrl}/lookup?token=${iplookupApiKey}`;
    if (ip) {
      url += `&ip=${ip}`;
    }
    const response = await fetchFn(url);
    return await response.json();
  } catch (err) {
    // Sentry.logException(err, { tags: { isIPLookup: true } });
    throw err;
  }
}
