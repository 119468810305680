/** @jsx jsxPragma */

import { makeEnterKeyPressHandler } from "@outschool/ui-utils";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import jsxPragma from "./jsxPragma";

export interface BoxButtonProps {
  sx?: SystemStyleObject;
  as?: React.ElementType;
  onClick?: (e: React.SyntheticEvent<Element, Event>) => void;
  [prop: string]: any;
}

const BoxButton: React.ForwardRefExoticComponent<BoxButtonProps> =
  React.forwardRef<unknown, BoxButtonProps>(
    ({ as: Component = "div", sx, onClick, ...props }, ref) => {
      return (
        <Component
          ref={ref}
          // @ts-ignore
          sx={sx}
          onClick={onClick}
          tabIndex={onClick && 0}
          onKeyPress={onClick && makeEnterKeyPressHandler(onClick)}
          {...props}
        />
      );
    }
  );

export default BoxButton;
