import {
  Components,
  Palette,
  Theme,
  alpha,
  buttonClasses,
} from "@mui/material";

import { SHARED_BUTTON_VARIANTS } from "../shared/buttonVariants";
import { PaletteColorOptionKey } from "../shared/types";

const createLinkVariant = (colorName: keyof Palette) => ({
  props: { variant: "link", color: colorName },
  style: ({ theme }: { theme: Theme }) => ({
    color: theme.palette[colorName][700 as PaletteColorOptionKey],
    backgroundColor: "transparent",
    // Match default outlined color border + alpha
    // This only matters in ButtonGroup
    // @ts-ignore
    borderColor: alpha(
      theme.palette[colorName][500 as PaletteColorOptionKey],
      0.5
    ),
  }),
});

const BUTTON_THEME: Components<Theme>["MuiButton"] = {
  // Do not specify default props for variant, color, and size.
  // Instead, specifty them on the component itself.
  // This is because Button "variant" has a custom value, and it is not
  // playing well with "defaultProps" and "variants" theming.
  defaultProps: {
    disableRipple: true,
    disableElevation: true,
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      borderRadius: "999px",
      "&:hover:not(:disabled), &:focus:not(:disabled)": {
        textDecoration: "none",
      },
      ":disabled": {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[600],
      },
      [`& > .${buttonClasses.startIcon}`]: {
        // Remove the negative margin that makes alignment hard
        marginLeft: 0,
      },
      [`& > .${buttonClasses.endIcon}`]: {
        // Remove the negative margin that makes alignment hard
        marginRight: 0,
      },
    }),
  },
  variants: [
    /**
     * sizes
     */
    {
      props: { size: "inherit" },
      style: {
        fontSize: "inherit",
      },
    },
    // small
    {
      props: { size: "small" },
      style: ({ theme }: { theme: Theme }) => ({
        // matches IconButton small size
        padding: theme.spacing(8, 18),
        fontSize: theme.typography.pxToRem(14),
      }),
    },
    {
      props: { size: "small", edge: "start" },
      style: ({ theme }: { theme: Theme }) => ({
        marginLeft: theme.spacing(-18),
      }),
    },
    {
      props: { size: "small", edge: "end" },
      style: ({ theme }: { theme: Theme }) => ({
        marginRight: theme.spacing(-18),
      }),
    },
    // medium
    {
      props: { size: "medium" },
      style: ({ theme }: { theme: Theme }) => ({
        // matches IconButton medium size
        padding: theme.spacing(12, 28),
        fontSize: theme.typography.pxToRem(16),
      }),
    },
    {
      props: { size: "medium", edge: "start" },
      style: ({ theme }: { theme: Theme }) => ({
        marginLeft: theme.spacing(-28),
      }),
    },
    {
      props: { size: "medium", edge: "end" },
      style: ({ theme }: { theme: Theme }) => ({
        marginRight: theme.spacing(-28),
      }),
    },
    // large
    {
      props: { size: "large" },
      style: ({ theme }: { theme: Theme }) => ({
        // matches IconButton large size
        padding: theme.spacing(16, 36),
        fontSize: theme.typography.pxToRem(18),
      }),
    },
    {
      props: { size: "large", edge: "start" },
      style: ({ theme }: { theme: Theme }) => ({
        marginLeft: theme.spacing(-36),
      }),
    },
    {
      props: { size: "large", edge: "end" },
      style: ({ theme }: { theme: Theme }) => ({
        marginRight: theme.spacing(-36),
      }),
    },
    ...SHARED_BUTTON_VARIANTS,
    /**
     * variant "link"
     */
    {
      props: { variant: "link" },
      style: {
        // override root styles
        lineHeight: 1,
        fontWeight: 500,
        border: "none",
        borderRadius: 0,
        cursor: "pointer",
        "&:hover:not(:disabled), &:focus:not(:disabled)": {
          textDecoration: "underline",
          backgroundColor: "transparent",
        },
        "&:disabled": {
          backgroundColor: "transparent",
        },
        margin: 0,
        padding: 0,
      },
    },
    createLinkVariant("primary"),
    createLinkVariant("secondary"),
    createLinkVariant("success"),
    createLinkVariant("error"),
    createLinkVariant("info"),
    createLinkVariant("warning"),
    createLinkVariant("neutral"),
  ],
};

export default BUTTON_THEME;
