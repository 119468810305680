import { dataIdFromObject } from "./data-id-from-object";
import { InMemoryCache } from "@outschool/ui-apollo";

export function createMemoryCache() {
  return new InMemoryCache({
    dataIdFromObject,
    possibleTypes,
  });
}

const possibleTypes: Record<string, string[]> = {
  IMessage: ["Message", "PrivateClassMessage"],
  IMessageThread: ["MessageThread", "PrivateClassThread"],
  BaseEnrollment: ["Enrollment", "GiftEnrollment"],
  INotification: [
    "ClassPostNotification",
    "ClubNotification",
    "PrivateClassMessageNotification",
    "AssignmentNotification",
  ],
};
