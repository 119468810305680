import { gql } from "@outschool/ui-apollo";

export default gql`
  query ParentSearchSuggestions(
    $query: String!
    $ignore: [String!]!
    $size: Int
  ) {
    searchSuggestions(search: { query: $query }, ignore: $ignore, size: $size) {
      popularTermSuggestions {
        uid
        category
        subCategory
      }
      topicSuggestions {
        uid
        label
        category
      }
      teacherSuggestions {
        uid
        name
        photo
        leader_link
      }
    }
  }
`;
