import {
  InputBaseProps,
  TextField as MuiTextField,
  OutlinedInputProps,
  OutlinedTextFieldProps,
} from "@mui/material";
import React from "react";

export {
  InputAdornment,
  inputAdornmentClasses,
  outlinedInputClasses as textFieldClasses,
} from "@mui/material";

export const TextFieldSizes = ["small", "medium"] as const;
export type TextFieldSize = (typeof TextFieldSizes)[number];

export const TextFieldColors = [
  "primary",
  "secondary",
  "success",
  "error",
  "info",
  "warning",
] as const;
export type TextFieldColor = (typeof TextFieldColors)[number];

export type TextFieldProps = Omit<
  OutlinedTextFieldProps,
  "variant" | "InputProps"
> & {
  // the original OutlinedTextFieldProps InputProps isn't working properly, so redefine here
  /**
   * Props applied to the Input element.
   * It will be a [`FilledInput`](/material-ui/api/filled-input/),
   * [`OutlinedInput`](/material-ui/api/outlined-input/) or [`Input`](/material-ui/api/input/)
   * component depending on the `variant` prop value.
   */
  InputProps?: Partial<InputBaseProps & OutlinedInputProps>;
};

// wrapping so that TextField has the correct props
export const TextField = (props: TextFieldProps) => {
  return <MuiTextField {...props} />;
};
