import { awinAttributionFrom } from "./Awin";
import { queryStringToObject, findQueryString } from "./lib/UrlUtils";

export enum UtmParamKey {
  utm_campaign = "utm_campaign",
  utm_source = "utm_source",
  utm_medium = "utm_medium",
  utm_term = "utm_term",
  utm_content = "utm_content",
}

export enum UtmKey {
  campaign = "campaign",
  source = "source",
  medium = "medium",
  term = "term",
  content = "content",
}

export type UtmParams = Record<UtmParamKey, string | null>;

function isUtmParamKey(key: any): key is UtmParamKey {
  return Object.values(UtmParamKey).includes(key);
}

function extractUtmValues(
  utmParamKey: UtmParamKey,
  utmParamsObj: Record<string, string>
) {
  const keys = Object.keys(utmParamsObj)
    .filter(paramKey => paramKey.startsWith(utmParamKey))
    .sort();
  const values: string[] = [];
  for (const key of keys) {
    values.push(utmParamsObj[key]);
  }
  return values;
}

export function getUtmParams(queryString: string): UtmParams {
  const params = queryStringToObject(queryString);
  const mappedParams: UtmParams = Object.values(UtmParamKey).reduce(
    (obj, utmParamKey) => {
      const utmValues = extractUtmValues(utmParamKey, params);
      obj[utmParamKey] = utmValues.length > 0 ? utmValues.join(",") : null;
      return obj;
    },
    {} as UtmParams
  );
  return mappedParams;
}

export function getUtmParamsForSegment(search: string) {
  const utmParams = getUtmParams(search);
  const utmParamsTransformed = Object.keys(utmParams).reduce(
    (obj, utmParamKey) => {
      const transformedKey = utmParamKey
        .replace("campaign", "name")
        .replace("utm_", "");
      if (isUtmParamKey(utmParamKey)) {
        obj[transformedKey] = utmParams[utmParamKey];
      }
      return obj;
    },
    {} as Record<string, string | null>
  );
  return utmParamsTransformed;
}

export default class Utm {
  public campaign: string | null;
  public source: string | null;
  public medium: string | null;
  public term: string | null;
  public content: string | null;
  public awc: string | null | undefined; //from AWIN
  public a8: string | null | undefined; //from A8

  constructor(href: string) {
    const search = findQueryString(href);
    const utmParams = getUtmParams(search);

    this.campaign = utmParams.utm_campaign;
    this.source = utmParams.utm_source;
    this.medium = utmParams.utm_medium;
    this.term = utmParams.utm_term;
    this.content = utmParams.utm_content;
    this.awc = awinAttributionFrom(search)?.awc;
  }

  isValid() {
    return Boolean(
      this.campaign || this.source || this.medium || this.term || this.content
    );
  }
}

type Attribution = {
  campaign?: string;
  source?: string;
  medium?: string;
  term?: string;
  content?: string;
  referer?: string;
  landingPage?: string;
  usid?: string;
  ruid?: string;
};

export function summarizeAttribution(attribution?: Attribution) {
  if (!attribution) {
    return "";
  } else if (
    attribution.medium?.length ||
    attribution.source?.length ||
    attribution.campaign?.length
  ) {
    return [
      "referred by:",
      attribution.medium,
      attribution.source,
      attribution.campaign,
      attribution.usid || attribution.ruid,
    ]
      .filter(item => !!item)
      .join(" ");
  } else if (attribution.referer) {
    return `referred from: ${attribution.referer}`;
  } else if (attribution.landingPage) {
    return `landed on: ${attribution.landingPage}`;
  } else {
    return "";
  }
}
