/** @jsx jsxPragma */

import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import jsxPragma from "./jsxPragma";

interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  sx?: SystemStyleObject;
}

function Form({ sx, autoComplete = "off", ...props }: FormProps, ref: any) {
  // @ts-ignore
  // eslint-disable-next-line
  return <form ref={ref} sx={sx} autoComplete={autoComplete} {...props} />;
}

export default React.forwardRef<any, FormProps>(Form);
