export function plural(
  singularWord: string,
  count?: number,
  pluralWord?: string
): string {
  return count === undefined || count === 1
    ? singularWord
    : pluralWord || singularWord + "s";
}

export function joinCommasAnd(items: string[]): string | null {
  if (!items || !items.length) {
    return null;
  }
  if (items.length === 2) {
    return items.join(" and ");
  }

  if (items.length === 1) {
    return items[0];
  }
  //  names.length > 2
  const lastItem = items.pop();
  const penultimateItem = items.pop();
  // Oxford comma
  items.push([penultimateItem, lastItem].join(", and "));
  return items.join(", ");
}
