import {
  faBackpack,
  faBook,
  faCalculatorAlt,
  faCode,
  faComments,
  faEarthAmericas,
  faFlask,
  faGraduationCap,
  faHandshake,
  faLanguage,
  faMusic,
  faPaintbrushAlt,
  faPuzzlePiece,
  faSalad,
  faSnowman,
} from "@outschool/icons";
import type { IconDefinition } from "@outschool/icons";

export type TopicTaxonomyCategoryObject = Record<string, IconDefinition>;

export const TOPIC_TAXONOMY_CATEGORY_ICONS: TopicTaxonomyCategoryObject = {
  "English Language Arts": faBook,
  Math: faCalculatorAlt,
  Arts: faPaintbrushAlt,
  Science: faFlask,
  "Social Studies": faEarthAmericas,
  "Games & Hobbies": faPuzzlePiece,
  "Standardized Test Prep": faGraduationCap,
  "Health & Wellness": faSalad,
  "Life Skills": faHandshake,
  "Full Curriculum": faBackpack,
  Holidays: faSnowman,
  Languages: faLanguage,
  Music: faMusic,
  Technology: faCode,
  "Social Clubs": faComments,
};
