import React from "react";
import { Box, Icon } from "@outschool/backpack";
import { faEnvelope } from "@outschool/icons";
import { TrackedButton } from "@outschool/ui-components-shared";
import { useTranslation } from "@outschool/localization";
import { LinkProps } from "@outschool/ui-utils";

interface EmailSignupButtonProps {
  linkComponent: React.ComponentType<LinkProps>;
  onClick: () => void;
  trackingUniqueId: string;
  to: string;
}

export default function EmailSignupButton({
  linkComponent,
  onClick,
  trackingUniqueId,
  to,
}: EmailSignupButtonProps) {
  const { t } = useTranslation("ui-components-website\\SignupForm");

  return (
    <TrackedButton
      component={linkComponent}
      onClick={onClick}
      data-test-id="signup-flow-link"
      trackingName="signup-with-email"
      trackingUniqueId={trackingUniqueId}
      to={to}
      sx={{
        padding: "0 !important",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          fontSize: "1.5em",
          padding: "10px",
          width: "44px",
        }}
      >
        <Icon
          icon={faEnvelope}
          sx={{
            display: "block",
          }}
        />
      </Box>
      <Box
        flex
        sx={{
          flex: 1,
          padding: "12.5px",
          minWidth: "215px",
          justifyContent: "center",
        }}
      >
        {t`Sign Up with Email`}
      </Box>
    </TrackedButton>
  );
}
