import React from "react";

import { addLoadDurationEntry } from "../../providers/AnalyticsContext";

export enum FallbackSettingsMode {
  Limited = "Limited",
  Off = "Off",
  Unknown = "Unknown",
}

const LOAD_TIME_LIMIT = 5000;

let globalLoadTimerId: number | null = null;
let startLoadTime: number;

export default function useFallbackSettings() {
  const [fallbackSettingsLoaded, setFallbackSettingsLoaded] =
    React.useState<boolean>(false);
  const [fallbackSettingsMode, setFallbackSettingsMode] =
    React.useState<FallbackSettingsMode>(FallbackSettingsMode.Unknown);
  const [loadTimerId, setLoadTimerId] = React.useState(globalLoadTimerId);

  const onFallbackTimeout = React.useCallback(() => {
    setFallbackSettingsLoaded(true);
    setFallbackSettingsMode(FallbackSettingsMode.Limited);
    addLoadDurationEntry("modeResolution", startLoadTime);
  }, [setFallbackSettingsLoaded, setFallbackSettingsMode]);

  const cancelFallbackTimer = React.useCallback(() => {
    if (fallbackSettingsLoaded) {
      return;
    }

    setFallbackSettingsLoaded(true);
    setFallbackSettingsMode(FallbackSettingsMode.Off);
    window.clearTimeout(Number(globalLoadTimerId));
    addLoadDurationEntry("modeResolution", startLoadTime);
  }, [
    fallbackSettingsLoaded,
    setFallbackSettingsLoaded,
    setFallbackSettingsMode,
  ]);

  React.useEffect(() => {
    if (typeof loadTimerId === "number" || fallbackSettingsLoaded) {
      return;
    }

    startLoadTime = Date.now();
    globalLoadTimerId = window.setTimeout(onFallbackTimeout, LOAD_TIME_LIMIT);
    setLoadTimerId(globalLoadTimerId);
  }, [
    fallbackSettingsMode,
    fallbackSettingsLoaded,
    loadTimerId,
    onFallbackTimeout,
  ]);

  return {
    fallbackSettingsLoaded,
    fallbackSettingsMode,
    cancelFallbackTimer,
  };
}
