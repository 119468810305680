import React from "react";

export type Id = string;

export type TrackingContextValue = {
  pageImpressionId?: string;
  page_name?: string;
  page_section_name?: string;
  page_subsection_name?: string;
  page?: string;
  order?: string;
  filters?: string[];
  activity_uid?: Id;
  section_uid?: Id;
  leader_uid?: Id;
  parent_uid?: Id;
  enrollment_uid?: Id;
  feedback_uid?: Id;
  first_enrollment_purchased_at?: Date;
};

export const TrackingContext = React.createContext<TrackingContextValue>({});

export function useTrackingContext() {
  return React.useContext<TrackingContextValue>(TrackingContext);
}

export function withTrackingContext<R, P>(Component: React.FC<P>) {
  return React.forwardRef<R, P>((props, ref) => {
    return (
      <TrackingContext.Consumer>
        {currentTrackingContext => (
          <Component
            currentTrackingContext={currentTrackingContext}
            {...props}
            ref={ref}
          />
        )}
      </TrackingContext.Consumer>
    );
  });
}
