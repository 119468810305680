import { useScript } from "@outschool/ui-utils";
import React from "react";

type NotDisplayedReason =
  | "browser_not_supported"
  | "invalid_client"
  | "missing_client_id"
  | "opt_out_or_no_session"
  | "secure_http_required"
  | "suppressed_by_user"
  | "unregistered_origin"
  | "unknown_reason";

type SkippedReason =
  | "auto_cancel"
  | "user_cancel"
  | "tap_outside"
  | "issuing_failed";

type DismissedReason =
  | "credential_returned"
  | "cancel_called"
  | "flow_restarted";

type MomentType = "display" | "skipped" | "dismissed";

/**
 * @see https://developers.google.com/identity/gsi/web/reference/js-reference#PromptMomentNotification
 */
interface PromptMomentNotification {
  /**
   * 	Is this notification for a display moment?
   */
  isDisplayMoment: () => boolean;
  /**
   * Is this notification for a display moment, and the UI is displayed?
   */
  isDisplayed: () => boolean;
  /**
   * Is this notification for a display moment, and the UI isn't displayed?
   */
  isNotDisplayed: () => boolean;
  /**
   * The detailed reason why the UI isn't displayed.
   */
  getNotDisplayedReason: () => NotDisplayedReason;
  /**
   * Is this notification for a skipped moment?
   */
  isSkippedMoment: () => boolean;
  /**
   * The detailed reason for the skipped moment.
   */
  getSkippedReason: () => SkippedReason;
  /**
   * Is this notification for a dismissed moment?
   */
  isDismissedMoment: () => boolean;
  /**
   * The detailed reason for the dismissal.
   */
  getDismissedReason: () => DismissedReason;
  /**
   * Return a string for the moment type.
   */
  getMomentType: () => MomentType;
}

/**
 * @see https://developers.google.com/identity/gsi/web/reference/js-reference#GsiButtonConfiguration
 */
interface GsiButtonConfiguration {
  type?: "icon" | "standard";
  theme?: "outline" | "filled_blue" | "filled_black";
  size?: "small" | "medium" | "large";
  text?: "signin_with" | "signup_with" | "continue_with";
  shape?: "rectangular" | "pill" | "circle" | "square";
  logo_alignment?: "left" | "center";
  width?: number;
  locale?: string;
}

/**
 * @see https://developers.google.com/identity/gsi/web/reference/js-reference#select_by
 */
type SelectBy =
  | "auto"
  | "user"
  | "user_1tap"
  | "user_2tap"
  | "btn"
  | "btn_confirm"
  | "btn_add_session"
  | "btn_confirm_add_session";

/**
 * @see https://developers.google.com/identity/gsi/web/reference/js-reference#CredentialResponse
 */
export interface CredentialResponse {
  credential: string;
  select_by: SelectBy;
}

/**
 * @see https://developers.google.com/identity/gsi/web/reference/js-reference#IdConfiguration
 */
interface IdConfiguration {
  client_id: string;
  callback: (response: CredentialResponse) => void;
  cancel_on_tap_outside: boolean;
  context: string;
  prompt_parent_id?: string;
  itp_support: boolean;
}

interface GoogleIdSDK {
  initialize: (config: IdConfiguration) => void;
  prompt: (
    momentListener?: (notification: PromptMomentNotification) => void
  ) => void;
  renderButton: (parent: HTMLElement, options: GsiButtonConfiguration) => void;
}

declare global {
  interface Window {
    onGoogleLibraryLoad: () => void;
    google: {
      accounts: {
        id: GoogleIdSDK;
      };
    };
  }
}

export default function ContinueWithGoogleOneTap({
  clientId,
  loginWithGoogle,
  onPrompt,
  onLoad,
  shouldPrompt = true,
}: {
  clientId: string;
  loginWithGoogle: (token: string) => Promise<void>;
  onPrompt: (notification: PromptMomentNotification) => void;
  onLoad: () => void;
  shouldPrompt?: boolean;
}) {
  const google: Window["google"] | undefined = useScript({
    src: "https://accounts.google.com/gsi/client",
    variableName: "google",
  });

  /*
   * Avoid re-displaying the google prompt on every route change.
   */
  const [triedToShow, setTriedToShow] = React.useState(false);

  React.useEffect(() => {
    if (google !== undefined && !triedToShow) {
      onLoad();
      google.accounts.id.initialize({
        client_id: clientId,
        callback: async token => {
          loginWithGoogle(token.credential);
        },
        cancel_on_tap_outside: false,
        itp_support: true,
        context: "use",
      });

      if (shouldPrompt) {
        google.accounts.id.prompt((notification: PromptMomentNotification) => {
          setTriedToShow(true);
          onPrompt(notification);
        });
      }
    }
  }, [
    google,
    loginWithGoogle,
    clientId,
    triedToShow,
    onPrompt,
    onLoad,
    shouldPrompt,
  ]);

  return null;
}
