import { useMemo } from "react";

import useWindowReference from "./useWindowReference";

const testKey = "outschool.storageTest";

const useLocalStorageReference = () => {
  const { window } = useWindowReference();

  const canUseLocalStorage = useMemo(() => {
    if (!window) {
      return false;
    }
    try {
      window.localStorage.setItem(testKey, testKey);
      window.localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }, [window]);

  return {
    canUseLocalStorage,
    localStorage: canUseLocalStorage ? window!.localStorage : undefined,
  };
};

export default useLocalStorageReference;
