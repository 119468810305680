import { CurrencyCode } from "@outschool/gql-backend-generated";
import { useSessionStorageState } from "@outschool/session-storage";
import { useEffect, useState } from "react";

const LATEST_CURRENCY_CODE = "latestCurrencyCode";
const EXCHANGE_RATE = "latestExchangeRate";

export function useExchangeRate(currencyCode: CurrencyCode) {
  const [loading, setLoading] = useState<boolean | null>(null);
  const [error, setError] = useState(null);
  const [exchangeRate, setExchangeRate] = useSessionStorageState(
    EXCHANGE_RATE,
    1
  );
  const [latestCurrencyCode, setLatestCurrencyCode] =
    useSessionStorageState<CurrencyCode | null>(LATEST_CURRENCY_CODE, null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!currencyCode || currencyCode === latestCurrencyCode) {
          return;
        }
        setLatestCurrencyCode(currencyCode);

        let newExchangeRate = 1;
        // prevent api call for usd since exchange rate will always return as 1
        if (currencyCode !== CurrencyCode.Usd) {
          setLoading(true);
          const response = (await fetchGraphql(currencyCode)) as any;
          newExchangeRate = response?.data?.exchangeRate?.exchange_rate;
        }
        setExchangeRate(newExchangeRate);
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    fetchData();
  }, [
    currencyCode,
    latestCurrencyCode,
    setExchangeRate,
    setLatestCurrencyCode,
  ]);

  return {
    loading,
    error,
    exchangeRate,
  };
}

async function fetchGraphql(currencyCode: CurrencyCode) {
  const response = await fetch(`https://outschool.com/graphql/ExchangeRate`, {
    method: "POST",
    body: JSON.stringify({
      operationName: "ExchangeRate",
      query: exchangeRateQuery,
      variables: { currencyCode },
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json;
}

export const exchangeRateQuery = `
  query ExchangeRate($currencyCode: CurrencyCode!) {
    exchangeRate(currencyCode: $currencyCode) {
      uid
      currency_code
      exchange_rate
    }
  }
`;
