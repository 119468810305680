import {
  OUTSCHOOL_TIMEZONE,
  guessBrowserTimeZone,
  zoneIsValid,
} from "@outschool/time";
import React from "react";

import { cloneEventProperties } from "../lib/util";
import {
  Integration,
  enqueueEvent,
  getMergedEventOptions,
  isOff,
  isReady,
  useAnalyticsContext,
} from "../providers/AnalyticsContext";
import useTrackEvent from "./useTrackEvent";

import type {
  EventOptions,
  EventProperties,
  SendIdentify,
  Traits,
} from "../types";
import type { IntegrationCategory } from "../providers/AnalyticsContext";

export default function useIdentify(
  category?: IntegrationCategory
): SendIdentify {
  const { status } = useAnalyticsContext();
  const trackEvent = useTrackEvent();
  const identifyCallback = React.useCallback(identify, [
    category,
    status,
    trackEvent,
  ]);

  function identify(
    userId?: string,
    properties?: EventProperties,
    options?: EventOptions,
    callback = () => {}
  ) {
    if (isOff(status)) {
      callback();
      return;
    }

    const clonedProperties = cloneEventProperties(properties);
    const traits = mapPropertiesToTraits(clonedProperties);

    /*
     * GoogleTagManager needs access to identify properties, but identify
     * events aren't supported by the integration. This custom event is used
     * as a workaround.
     */
    trackEvent("gtm_identify", traits, {
      integrations: {
        [Integration.GoogleTagManager]: true,
      },
    });

    const identifyOptions = getIdentifyOptions(clonedProperties, options);

    let args: [string?, Traits?] | [Traits?];

    if (!!userId) {
      args = [userId, traits || {}];
    } else {
      args = [traits || {}];
    }

    if (isReady(status)) {
      const eventOptions = getMergedEventOptions(identifyOptions, category);
      window.analytics.identify(...args, eventOptions, callback);
      return;
    }

    enqueueEvent(["identify", ...args, identifyOptions, callback], category);
  }

  return identifyCallback;
}

function mapPropertiesToTraits(properties: EventProperties): Traits {
  const {
    attribution,
    name,
    email,
    is_admin,
    leader_link,
    leader_subscribed_at,
    location,
    phone,
    sellerOrg,
    uid,
  } = properties;
  const traits: Traits = {};

  if (!!name) {
    const { firstName, lastName } = splitFirstAndLastName(name);

    traits.name = name;
    traits.first_name = firstName || undefined;
    traits.last_name = lastName || undefined;
  }

  traits.attribution = attribution;
  traits.email = email;
  traits.isAdmin = is_admin;
  traits.phone = phone;
  traits.hasAccount = !!uid || undefined;
  traits.leader_subscribed_at = leader_subscribed_at;
  traits.leader_link = leader_link;
  traits.timezone = getTimeZone(location?.timeZone);
  traits.isSellerOrg = sellerOrg?.currentUserIsOwner;
  traits.isSellerOrgTeacher = sellerOrg?.currentUserIsTeacher;
  traits.sellerOrgUserUid = sellerOrg?.uid;

  return traits;
}

function splitFirstAndLastName(name: string) {
  let firstName: string | null = null;
  let lastName: string | null = null;

  if (name) {
    const names = name.split(" ");
    firstName = names[0];
    if (names.length > 1) {
      lastName = names.slice(1, names.length).join(" ");
    }
  }

  return { firstName, lastName };
}

function getIdentifyOptions(
  properties: EventProperties,
  options?: EventOptions
) {
  const integrations: EventOptions["integrations"] = {};

  if (!!properties.intercomUserHash) {
    integrations[Integration.Intercom] = {
      user_hash: properties?.intercomUserHash,
    };
  }

  if (!options) {
    return { integrations };
  }

  options.integrations = {
    ...integrations,
    ...options.integrations,
  };

  return options;
}

function getTimeZone(timeZone?: string) {
  if (!!timeZone && zoneIsValid(timeZone)) {
    return timeZone;
  }

  return guessBrowserTimeZone() || OUTSCHOOL_TIMEZONE;
}
