import { isLocalStorageSupported } from "@outschool/local-storage";
import { validate as isUuid, v4 as uuid } from "uuid";

const DEVICE_UID_KEY = "outschool_device_uid";

export default function getDeviceUid(): undefined | string {
  const localStorage = isLocalStorageSupported();

  if (!localStorage) {
    return undefined;
  }

  const existingDeviceUid = localStorage.getItem(DEVICE_UID_KEY);
  if (!!existingDeviceUid && isUuid(existingDeviceUid)) {
    return existingDeviceUid;
  }
  const newDeviceUid = uuid();
  localStorage.setItem(DEVICE_UID_KEY, newDeviceUid);
  return newDeviceUid;
}
