import { decodeToken, hasExpired } from "@outschool/auth-shared";
import { TokenRefreshSource } from "@outschool/gql-frontend-generated";
import * as Time from "@outschool/time";
import { useApolloClient } from "@outschool/ui-apollo";
import React from "react";

import { useTokenContext } from "../providers/TokenProvider";
import { sessionRefresh } from "../graphql/SessionRefreshMutation";

export default function useRefreshSessionToken() {
  const apolloClient = useApolloClient();
  const { getTokens, setTokens, logout } = useTokenContext();
  const sessionToken = getTokens().sessionToken;
  const refreshToken = getTokens().refreshToken;

  return React.useCallback(async () => {
    if (
      hasExpired(sessionToken) &&
      (!refreshToken || hasExpired(refreshToken))
    ) {
      // When both tokens are 'expired', we _know_ that the user session has expired
      //Date.now() returns ms since the epoch and jwt returns seconds since the epoch
      const decodedSessionToken = decodeToken(sessionToken);
      const sessionExp = decodedSessionToken?.exp;
      const sessionExpiredSecs =
        sessionExp != null ? Date.now() / 1000 - sessionExp : null;

      const refreshExp = decodeToken(refreshToken)?.exp;
      const refreshExpiredSecs =
        refreshExp != null ? Date.now() / 1000 - refreshExp : null;

      let loginProviders;
      if (decodedSessionToken && "providers" in decodedSessionToken) {
        loginProviders = decodedSessionToken.providers;
      }
      logout({
        reason: "_refreshSessionToken: Both tokens have expired",
        sessionExpiredSecs,
        refreshExpiredSecs,
        loginProviders,
      });
      return null;
    }

    if (!getTokens().refreshToken) {
      return null;
    }

    const { data } = await sessionRefresh(apolloClient, {
      refreshToken: getTokens().refreshToken,
      browserTimeZone: Time.guessBrowserTimeZone(),
      refreshSource: TokenRefreshSource.Client,
    });

    setTokens(
      data?.refreshV2?.sessionToken ?? null,
      data?.refreshV2?.refreshToken ?? null
    );
    return null;
  }, [sessionToken, refreshToken, getTokens, setTokens, logout, apolloClient]);
}
