import { simpleRandomId, useOnScreen } from "@outschool/ui-utils";
import React from "react";

import { useTrackingContext } from "./TrackingContext";
import { TrackingProperties } from "./TrackingProperties";

type TrackingOptions = {
  integrations: { [key: string]: boolean };
};

type ExternalTrackFn = (
  name: string,
  properties: { [propName: string]: any },
  options?: TrackingOptions
) => void;

export const ComponentTrackingContext = React.createContext<ExternalTrackFn>(
  () => {}
);

export function useComponentTrackingContext() {
  return React.useContext(ComponentTrackingContext);
}

export function ComponentTrackingProvider({
  children,
  externalTrackFn = () => {},
}: {
  children: React.ReactElement;
  externalTrackFn?: ExternalTrackFn;
}): React.ReactElement {
  return (
    <ComponentTrackingContext.Provider value={externalTrackFn}>
      {children}
    </ComponentTrackingContext.Provider>
  );
}

type ImpressionTrackingInput = {
  node?: Element | null;
  uniqueId: string;
  trackingLabel: string;
  sharedProperties?: TrackingProperties;
  trackingEventName?: string;
  impressionEventName?: string;
  trackViews?: boolean;
  alreadyTracked?: boolean;
};

type ImpressionTrackingOutput = {
  trackTouch: (properties?: TrackingProperties) => void;
  impressionId: string;
};

export function useImpressionTracking({
  node,
  uniqueId,
  trackingLabel,
  sharedProperties = {},
  trackingEventName = `${trackingLabel}_touch`,
  impressionEventName = `${trackingLabel}_view`,
  trackViews = false,
  // TODO: Remove this when legacy TrackedLink_Deprecated gets removed or refactored
  alreadyTracked = false,
}: ImpressionTrackingInput): ImpressionTrackingOutput {
  const [impressionId, setImpressionId] = React.useState<string>(() =>
    simpleRandomId()
  );
  const impressionHasBeenTracked = React.useRef(false);
  const onScreen = useOnScreen(node);
  const externalTrackFn = useComponentTrackingContext();
  const contextProperties = useTrackingContext();

  const track = (name: string, properties: TrackingProperties = {}) => {
    if (!trackingLabel || alreadyTracked) {
      return;
    }

    externalTrackFn(
      name,
      {
        impressionId,
        uniqueId,
        ...contextProperties,
        ...sharedProperties,
        ...properties,
      },
      {
        integrations: {
          All: false,
          "Amplitude (Actions)": true,
          "Actions Amplitude": true,
        },
      }
    );
  };

  const trackTouch = (properties: TrackingProperties) => {
    track(trackingEventName, properties);
  };

  // Note (Amedee): This has been here for a while, but it feels like an antipattern to change the uniqueId of a
  // tracked component after it has been rendered and/or its impression has been tracked. This behavior
  // (supporting changes to the uniqueId) should be investigated for removal.
  const previousUniqueId = React.useRef(uniqueId);
  if (uniqueId !== previousUniqueId.current) {
    previousUniqueId.current = uniqueId;
    impressionHasBeenTracked.current = false;
    // This will trigger a re-render of the component with the new impressionId right after this render is
    // done. impressionHasBeenTracked will be false so it can be tracked again
    setImpressionId(simpleRandomId());
    // paranoid edge case CYA to put this in the else block, in the case the uniqueID has just changed we don't want to
    // track() with the old impressionId
  } else if (trackViews && onScreen && !impressionHasBeenTracked.current) {
    track(impressionEventName);
    impressionHasBeenTracked.current = true;
  }

  return { trackTouch, impressionId };
}

type ImpressionTrackingProps = ImpressionTrackingInput & {
  children: (props: {
    setNode: React.Dispatch<HTMLElement>;
    trackTouch: (properties?: TrackingProperties) => void;
    impressionId: string;
  }) => React.ReactElement;
};

export function ImpressionTracking({
  children,
  uniqueId,
  trackingLabel,
  sharedProperties = {},
  trackingEventName = `${trackingLabel}_touch`,
  impressionEventName = `${trackingLabel}_view`,
  alreadyTracked = false,
  trackViews = false,
}: ImpressionTrackingProps) {
  const [node, setNode] = React.useState<HTMLElement>();
  const { trackTouch, impressionId } = useImpressionTracking({
    node,
    uniqueId,
    trackingLabel,
    sharedProperties,
    trackingEventName,
    impressionEventName,
    alreadyTracked,
    trackViews,
  });
  return children({ setNode, trackTouch, impressionId });
}
