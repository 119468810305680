export function isDesktopSafari() {
  const uA = navigator.userAgent;
  const vendor = navigator.vendor;
  return Boolean(
    /Safari/i.test(uA) &&
      /Apple Computer/.test(vendor) &&
      !/Mobi|Android/i.test(uA)
  );
}

/**
 * Returns true if is a iPhone or iPad not running iPadOS
 */
export function isMobileSafari() {
  return Boolean(
    navigator?.userAgent && /iP(ad|hone|od)/.test(navigator.userAgent)
  );
}

/**
 * Returns true if device is running iPadOS.
 * Due to https://developer.apple.com/forums/thread/119186
 * We must check for the presence of maxTouchPoints if
 */
export function isIPadOS() {
  return Boolean(
    navigator?.userAgent &&
      /Macintosh; Intel Mac OS X 10/.test(navigator.userAgent) &&
      // If it claims to be a Mac, but it has more than 1 touch point, assume it's an iPad
      navigator.maxTouchPoints > 1
  );
}

export function isIOSVersion(version: string) {
  return Boolean(
    navigator?.userAgent &&
      new RegExp(`OS ${version} like Mac OS X`).test(navigator.userAgent)
  );
}

export function isIPhone() {
  return Boolean(navigator?.userAgent && /iPhone/.test(navigator.userAgent));
}

export function isIPad() {
  return (
    Boolean(navigator?.userAgent && /iPad/.test(navigator.userAgent)) ||
    isIPadOS()
  );
}
