// TODO: Make these developer-only codes and generate localized error messages on the frontend

export enum RedeemGiftCardError {
  CodeDidNotWork = "Sorry, that code did not work. Please reference your email for the correct gift code.",
  CodeAlreadyRedeemed = "Sorry, that code has already been redeemed.",
  BalanceExceedsMaxValue = "Sorry, your gift card could not be redeemed because your credit balance may not exceed $2000Usd. Please use your existing Outschool credits before redeeming this gift card to ensure the new balance is beneath the limit.",
  ProcessingProblem = "There was a problem processing your payment, please try again.",
  RedemptionIssue = "Sorry, there was an issue redeeming this gift card.",
}

export enum SettlementError {
  SectionSoldOut = "This section is sold out.",
  SectionAlmostSoldOut = "This section is almost sold out.",
  SectionCanceled = "This section has been canceled.",
  SectionUnavailable = "This section is not open for enrollment.",
}

export const StoredCreditSharedPaymentMethodError =
  "This credit (promo code) cannot be used at this time. Please contact support@outschool.com if you believe this to be an error.";

// Add any new error code enums here
export type ErrorCode = RedeemGiftCardError | SettlementError;
