import {
  Box,
  Divider,
  Drawer,
  Icon,
  IconButton,
  SxProps,
  Theme,
} from "@outschool/backpack";
import {
  faBook,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { onlineClassesPath } from "@outschool/routes";
import { TrackedButton } from "@outschool/ui-components-shared";
import { Carousel } from "@outschool/ui-legacy-component-library";
import { useIsBot, useLinkComponent } from "@outschool/ui-utils";
import React, { useCallback, useState } from "react";

import { CategoryPartial } from "./navbarCategories";

export default function MobileCategoryNavbar({
  categories,
  sx,
  onClickLink,
}: {
  categories: CategoryPartial[];
  onClickLink?: () => void;
  sx?: SxProps;
}) {
  const { t } = useTranslation(
    "ui-components-website\\CategoryNavbar\\MobileCategoryNavbar"
  );
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const closeDrawer = useCallback(() => setDrawerOpen(false), [setDrawerOpen]);
  const CategoryDrawerMemo = React.useMemo(() => {
    return (
      <CategoryDrawer
        open={drawerOpen}
        categories={categories}
        onClose={closeDrawer}
      />
    );
  }, [categories, closeDrawer, drawerOpen]);
  const isBot = useIsBot();

  return (
    <Box
      sx={[
        {
          display: "flex",
          height: "56px",
          overflowX: "hidden",
          overflowY: "hidden",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <TrackedButton
        variant="link"
        sx={{
          paddingX: 16,
        }}
        onClick={() => setDrawerOpen(true)}
        trackingName="category-navbar-mobile-browse"
        trackViews
        size="small"
        startIcon={<Icon icon={faBook} />}
        endIcon={<Icon icon={faChevronDown} />}
      >
        {t("Browse")}
      </TrackedButton>
      <Box
        sx={{
          flexGrow: 1,
          flexBasis: 0,
          width: "0px",
        }}
      >
        <Carousel>
          {categories
            .filter(c => c.can_link)
            .map((category, index) => (
              <React.Fragment key={index}>
                <CarouselLink
                  category={category}
                  sharedProperties={{ index }}
                  onClickLink={onClickLink ? onClickLink : undefined}
                />
                {/* for prerender only, expand all subcategories into the carousel */}
                {isBot &&
                  category.subcategories?.map(subcategory => (
                    <React.Fragment key={subcategory.slug}>
                      <CarouselLink category={subcategory} />
                      {subcategory.subcategories?.map(nestedSubcategory => (
                        <CarouselLink category={nestedSubcategory} />
                      ))}
                    </React.Fragment>
                  ))}
              </React.Fragment>
            ))}
        </Carousel>
      </Box>
      {CategoryDrawerMemo}
    </Box>
  );
}

function CarouselLink({
  category,
  sharedProperties,
  onClickLink,
}: {
  category: CategoryPartial;
  sharedProperties?: { [key: string]: number | string };
  onClickLink?: () => void;
}) {
  const Link = useLinkComponent();

  return (
    <TrackedButton
      component={Link}
      to={
        category.path
          ? category.path
          : onlineClassesPath(category.slug, category.basePath)
      }
      trackingName="category-navbar-mobile-pill"
      trackingUniqueId={category.slug}
      trackViews
      sharedProperties={sharedProperties}
      reloadDocument={category.reloadDocument}
      onClick={onClickLink ? onClickLink : undefined}
      sx={(theme: Theme) => ({
        height: 40,
        lineHeight: "unset",
        backgroundColor: "grey.100",
        borderStyle: "none",
        fontSize: 16,
        color: "text.primary",
        fontWeight: "fontWeightRegular",
        padding: theme.spacing(8, 16),
        margin: theme.spacing(8, 4),
      })}
    >
      {category.nameTitleCased}
    </TrackedButton>
  );
}

function CategoryDrawer({
  open,
  categories,
  onClose,
}: {
  open: boolean;
  categories: CategoryPartial[];
  onClose: () => void;
}) {
  const { t } = useTranslation(
    "ui-components-website\\CategoryNavbar\\MobileCategoryNavbar"
  );
  const [selectedCategory, setSelectedCategory] = useState<CategoryPartial>();

  const closeAll = useCallback(() => {
    setSelectedCategory(undefined);
    onClose();
  }, [onClose, setSelectedCategory]);

  return (
    <Drawer
      // eslint-disable-next-line i18next/no-literal-string
      PaperProps={{ sx: { width: "295px", minWidth: "295px" } }}
      open={open}
      onClose={closeAll}
      // eslint-disable-next-line i18next/no-literal-string
      anchor="right"
    >
      <Box
        data-test-id="category-mobile-drawer"
        sx={{
          padding: "24px",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "fontWeightBold",
            marginBottom: "16px",
            color: "primary.900",
          }}
        >
          {t("Browse categories")}
        </Box>
        {categories.map(category => (
          <CategoryButton
            key={category.slug}
            category={category}
            onClick={() => setSelectedCategory(category)}
          />
        ))}
      </Box>
      {open && (
        <SubcategoryDrawerContents
          category={selectedCategory}
          onClose={closeAll}
          onBack={() => setSelectedCategory(undefined)}
        />
      )}
    </Drawer>
  );
}

function CategoryButton({
  category,
  onClick,
}: {
  category: CategoryPartial;
  onClick: () => void;
}) {
  return (
    <Box>
      <TrackedButton
        variant="link"
        sx={{
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "fontWeightRegular",
          color: "primary.900",
          marginBottom: "16px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        trackingName="category-navbar-mobile-drawer-category"
        trackingUniqueId={category.slug}
        trackViews={true}
        onClick={onClick}
      >
        <Box
          sx={{
            flexGrow: 1,
            textAlign: "start",
            overflowX: "hidden",
            whiteSpace: "normal",
          }}
        >
          {category.nameTitleCased}
        </Box>
        <Icon
          sx={{
            fontSize: "1em",
            color: "primary.500",
          }}
          icon={faChevronRight}
        />
      </TrackedButton>
    </Box>
  );
}

function SubcategoryDrawerContents({
  category,
  onBack,
  onClose,
}: {
  category?: CategoryPartial;
  onBack: () => void;
  onClose: () => void;
}) {
  const { t } = useTranslation(
    "ui-components-website\\CategoryNavbar\\MobileCategoryNavbar"
  );

  return (
    <Drawer
      open={!!category}
      onClose={onClose}
      // eslint-disable-next-line i18next/no-literal-string
      PaperProps={{ sx: { width: "295px", minWidth: "295px" } }}
      // eslint-disable-next-line i18next/no-literal-string
      anchor="right"
    >
      <Box>
        <IconButton
          aria-label={t("Back")}
          icon={faChevronLeft}
          onClick={onBack}
          component={TrackedButton}
          trackingName="category-navbar-mobile-drawer-back"
          variant="text"
          color="neutral"
          size="large"
        />
      </Box>
      <Divider />
      {category && (
        <Box
          data-test-id="subcategory-mobile-drawer"
          sx={{
            padding: "24px",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              marginBottom: "16px",
            }}
          >
            <Box
              sx={{
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "fontWeightBold",
                marginBottom: "4px",
                color: "primary.900",
              }}
            >
              {category.nameTitleCased}
            </Box>
            {category.can_link && (
              <SubcategoryLink
                category={category}
                subcategory={{
                  nameTitleCased: t("View all"),
                  slug: category.slug,
                }}
                sx={{ color: "grey.400" }}
                onClick={onClose}
              />
            )}
          </Box>
          {category.subcategories?.map(subcategory => (
            <React.Fragment key={subcategory.slug}>
              <SubcategoryLink
                category={category}
                subcategory={subcategory}
                sx={{
                  color: "primary.900",
                  fontWeight: "500",
                  fontSize: "1em",
                }}
                onClick={onClose}
              />
              {subcategory.subcategories?.map(nestedSubcategory => (
                <SubcategoryLink
                  key={nestedSubcategory.slug}
                  category={category}
                  subcategory={nestedSubcategory}
                  sx={{ color: "primary.900" }}
                  onClick={onClose}
                />
              ))}
            </React.Fragment>
          ))}
        </Box>
      )}
    </Drawer>
  );
}
function SubcategoryLink({
  subcategory,
  category,
  onClick,
  sx,
}: {
  subcategory: CategoryPartial;
  category: CategoryPartial;
  onClick: () => void;
  sx: SxProps;
}) {
  const Link = useLinkComponent();
  return (
    <Link
      to={
        subcategory.path
          ? subcategory.path
          : onlineClassesPath(subcategory.slug, subcategory.basePath)
      }
      trackingName="category-navbar-mobile-drawer-subcategory"
      trackingUniqueId={`${category.slug}/${subcategory.slug}`}
      trackViews={true}
      onClick={onClick}
    >
      <Box
        sx={[
          {
            marginBottom: "16px",
            fontSize: "16px",
            fontWeight: "fontWeightRegular",
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {subcategory.nameTitleCased}
      </Box>
    </Link>
  );
}
