import { Box, SxProps } from "@outschool/backpack";
import { onlineClassesPath } from "@outschool/routes";
import { TrackedButton } from "@outschool/ui-components-shared";
import {
  Container,
  LegacyPopover,
} from "@outschool/ui-legacy-component-library";
import {
  useClickOutsideRefEventListener,
  useEventListener,
  useNavigation,
} from "@outschool/ui-utils";
import lodashDebounce from "lodash/debounce";
import React, { useCallback, useRef, useState } from "react";
import { CategoryPartial } from "./navbarCategories";
import { DesktopDropdown } from "./DesktopDropdown";

export default function DesktopCategoryNavbar({
  categories,
  sx = {},
}: {
  categories: CategoryPartial[];
  sx?: SxProps;
}) {
  const navbarRef = useRef<HTMLDivElement>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const document = (
    typeof window !== "undefined" ? window.document : null
  ) as Document | null;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const select = useCallback(lodashDebounce(setSelectedCategory, 250), [
    setSelectedCategory,
  ]);
  const deselect = useCallback(() => select(undefined), [select]);

  useClickOutsideRefEventListener(document, navbarRef, deselect);

  const onEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        deselect();
      }
    },
    [deselect]
  );
  useEventListener(document, "keyup", onEscape);

  const mouseListener = useCallback(
    (event: MouseEvent) => {
      const isOutside = [navbarRef].every(
        ref => !ref.current?.contains(event.target as HTMLElement)
      );
      if (isOutside) {
        deselect();
      }
    },
    [navbarRef, deselect]
  );
  useEventListener(document, "mouseover", mouseListener);
  const renderSubcategoryCallback = React.useCallback(
    (category: CategoryPartial) => {
      return () => <DesktopDropdown category={category} closeMenu={deselect} />;
    },
    [deselect]
  );

  return (
    <Box sx={sx}>
      <Container>
        <Box
          flex
          sx={{
            position: "relative",
            height: "48px",
            columnGap: "20px",
            justifyContent: "space-between",
            alignItems: "stretch",
          }}
          ref={navbarRef}
        >
          {categories.map((category: CategoryPartial, index: number) => (
            <NavbarItem
              key={`${category.slug}:${
                category?.subcategories?.length || 0
              }:${index}`}
              parentRef={navbarRef}
              uniqueId={category.slug}
              label={category.nameTitleCased}
              renderPopupContents={renderSubcategoryCallback(category)}
              isSelected={selectedCategory === category.slug}
              select={() => select(category.slug)}
              link={
                category.can_link
                  ? category.path
                    ? category.path
                    : onlineClassesPath(category.slug, category.basePath)
                  : undefined
              }
              reloadDocument={!!category.reloadDocument}
            />
          ))}
        </Box>
      </Container>
    </Box>
  );
}

function NavbarItem({
  uniqueId,
  label,
  renderPopupContents,
  parentRef,
  select,
  isSelected,
  link,
  reloadDocument,
}: {
  uniqueId: string;
  label: string | React.ReactElement;
  renderPopupContents: (isActive: boolean) => React.ReactNode;
  parentRef: React.RefObject<HTMLElement>;
  select: () => void;
  isSelected: boolean;
  link?: string;
  reloadDocument: boolean;
}) {
  const popoverRef = useRef<HTMLElement>();
  const buttonRef = useRef<HTMLElement>();

  const navigate = useNavigation();
  const goToLink = useCallback(() => {
    if (link) {
      navigate(link, { hardNav: reloadDocument });
    }
  }, [link, navigate, reloadDocument]);

  const handleClick = () => {
    select();
  };

  return (
    <LegacyPopover
      ref={popoverRef as React.Ref<HTMLElement>}
      placement="hide"
      slideIn={false}
      isHidden={!isSelected}
      animationDuration="0.2s"
      mainPlacement="bottom"
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: parentRef.current ?? undefined,
          priority: ["left", "right"],
        },
      }}
      renderButton={(ref: React.Ref<HTMLElement>) => (
        <NavbarButton
          isActive={isSelected}
          ref={ref}
          onClick={handleClick}
          onMouseUp={goToLink}
          onMouseEnter={select}
          buttonRef={buttonRef}
          label={label}
          uniqueId={uniqueId}
        />
      )}
    >
      {renderPopupContents(isSelected)}
    </LegacyPopover>
  );
}

type NavbarButtonProps = {
  buttonRef: any;
  label: string | React.ReactNode;
  uniqueId: string;
  onClick: () => void;
  onMouseEnter: (event: MouseEvent) => void;
  onMouseUp: (event: MouseEvent) => void;
  isActive: boolean;
};

const NavbarButton = React.forwardRef<HTMLElement, NavbarButtonProps>(
  (
    { buttonRef, label, uniqueId, onClick, onMouseEnter, onMouseUp, isActive },
    ref
  ) => {
    return (
      <Box
        ref={ref}
        onMouseEnter={onMouseEnter}
        sx={{
          height: "48px",
          lineHeight: "48px",
          paddingTop: "2px",
          borderBottom: "2px solid rgba(0,0,0,0)",

          "& > button": {
            color: isActive ? "primary.700" : "grey.900",
            fontWeight: "fontWeightRegular",
          },

          borderColor: isActive ? "primary.700" : undefined,

          "&:hover": {
            borderColor: "primary.700",

            "& > button:not(:disabled)": {
              color: "primary.700",
              textDecoration: "none",
            },
          },

          "&:focus-within": {
            "& > button:not(:disabled)": {
              color: "primary.700",
              textDecoration: "none",
            },
          },
        }}
      >
        <TrackedButton
          variant="link"
          ref={buttonRef}
          trackingName="category-navbar-desktop-item"
          trackingUniqueId={uniqueId}
          trackViews={true}
          onClick={onClick}
          onMouseUp={onMouseUp}
          sx={{
            whitSpace: "nowrap",
            height: "100%",
          }}
        >
          {label}
        </TrackedButton>
      </Box>
    );
  }
);
