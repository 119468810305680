import { createManagedChallengeViewInDom } from "./managed-challenge-view";

export function isManagedChallengeResponse(resp: Response) {
  // Cloudflare now has a way for us to identify that a response is a challenge
  // https://developers.cloudflare.com/fundamentals/get-started/concepts/cloudflare-challenges/#detecting-a-challenge-page-response
  return (
    resp.headers.get("server") === "cloudflare" &&
    resp.headers.get("cf-mitigated") === "challenge" &&
    resp.headers.get("content-type")?.includes("text/html")
  );
}

let managedChallengeInProgress: Promise<void> | undefined;
export function handleManagedChallenge() {
  if (managedChallengeInProgress) {
    return managedChallengeInProgress;
  }
  managedChallengeInProgress = new Promise<void>((resolve, reject) => {
    (async () => {
      const managedChallengeElement = createManagedChallengeViewInDom();
      document.body.appendChild(managedChallengeElement);

      // Set up listeners to handle managed challenge response from cloudflare
      const f = document.getElementById("managed-challenge-iframe-content");

      f?.addEventListener("load", () => {
        const fContentDocument = isIFrame(f) ? f.contentDocument : undefined;
        // Hack: remove cloudflare's unnecessary spacing
        const mainContent: HTMLElement | undefined | null =
          fContentDocument?.querySelector(".main-content") ||
          fContentDocument?.querySelector(".cf-main-content");
        if (mainContent) {
          mainContent.style.marginTop = "0px";
        }

        // Hack: remove cloudflare's dark mode
        if (
          window.matchMedia("(prefers-color-scheme: dark)").matches &&
          fContentDocument
        ) {
          fContentDocument.body.style.backgroundColor = "#ffffff";
          fContentDocument.body.style.color = "#000000";
        }

        // Once results are in, respond accordingly and remove iframe
        if (fContentDocument?.querySelector("#os_cf_verified")) {
          document.body.removeChild(managedChallengeElement);
          resolve();
        } else if (fContentDocument?.querySelector(".cf-error-title")) {
          document.body.removeChild(managedChallengeElement);
          reject(new Error("Blocked"));
        }

        managedChallengeInProgress = undefined;
      });
    })().catch(error => {
      OsPlatform.captureError(
        "Error attempting to load managed challenge from Cloudflare",
        {
          tags: { package: "ui-gql" },
          extra: {
            errorMessage: error.message,
          },
        }
      );
      reject(error);
    });
  });
  return managedChallengeInProgress;
}

const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
  input !== null && input.tagName === "IFRAME";
