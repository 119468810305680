import React from "react";

export default function VideoPlaySvg() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="62"
        height="62"
        rx="31"
        fill="#474E57"
        fillOpacity="0.8"
      />
      <g filter="url(#filter0_d)">
        <path d="M48 32L24 45.8564L24 18.1436L48 32Z" fill="white" />
      </g>
      <rect
        x="1"
        y="1"
        width="62"
        height="62"
        rx="31"
        stroke="white"
        strokeWidth="2"
      />
      <defs>
        <filter
          id="filter0_d"
          x="14"
          y="10.1436"
          width="44"
          height="47.7128"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
