import { implementation } from "./lib/implementation";
import { OsCaptureContext } from "./captures";
import { OsSpanAttributes } from "./spans";
import { expandAlarmSettings } from "./lib/expand-alarm-settings";

export type OsContextBaggage = {
  spanAttributes?: OsSpanAttributes;
  captureContext?: OsCaptureContext;
};

/**
 * This gets the current ContextBaggage for the current scope. It may be undefined if no context
 * baggage has been defined yet.
 */
export function getContextBaggage(): OsContextBaggage | undefined {
  return implementation.getContextBaggage();
}

/**
 * "Baggage" is an open-telemetry concept for passing details transparently and automagically across
 * boundaries. This ContextBaggage is used to allow you to add span and capture details to everything
 * that happens within the function, even sub functions and from promises launched within the function.
 *
 * The given updates will be applied additively to previous ContextBaggages.
 *
 * This is a great function to use if you want to ensure all {@link captureError} calls within a set of code
 * have a specific attribute for debugging purposes.
 */
export function runWithContextBaggage<T>(
  updates: OsContextBaggage,
  action: () => T
): T {
  return implementation.runWithContextBaggage(updateBaggage(updates), action);
}

/**
 * "Baggage" is an open-telemetry concept for passing details transparently and automagically across
 * boundaries. This ContextBaggage is used to allow you to add span and capture details to everything
 * that happens beyond this function, even functions called after the current function has returned.
 *
 * The given updates will be applied additively to previous ContextBaggages.
 *
 * This is a great function to use if you need to apply details from a request to the context within
 * an event or express middleware.
 *
 * This is _not_ a standard otel feature and comes with some warts because of it. First, we are taking
 * advantage of the node.js specific implementation for how this is stored. Second, this is akin to
 * modifying a global variable. Modifications to the current context can leak outside of the current
 * scope and very much depends on how node.js is tracking async events under the hood. Please prefer
 * {@link runWithContextBaggage} where possible.
 */
export function updateContextBaggage(updates: OsContextBaggage): void {
  return implementation.updateContextBaggage(updateBaggage(updates));
}

function updateBaggage(updates: OsContextBaggage): OsContextBaggage {
  const prev = implementation.getContextBaggage();
  expandAlarmSettings(updates.captureContext);

  return {
    spanAttributes: mergeSpanAttributes(
      prev?.spanAttributes,
      updates.spanAttributes
    ),
    captureContext: mergeCaptureContext(
      prev?.captureContext,
      updates.captureContext
    ),
  };
}

function mergeSpanAttributes(
  prev: OsSpanAttributes | undefined,
  updates: OsSpanAttributes | undefined
) {
  if (!updates) {
    return prev;
  }
  if (!prev) {
    return updates;
  }
  return { ...prev, ...updates };
}

function mergeCaptureContext(
  prev: OsCaptureContext | undefined,
  updates: OsCaptureContext | undefined
): OsCaptureContext | undefined {
  if (!updates) {
    return prev;
  }
  if (!prev) {
    return updates;
  }

  const result = { ...prev };
  if ("priority" in updates) {
    result.priority = updates.priority;
  }
  if ("component" in updates) {
    result.component = updates.component;
  }
  if ("tags" in updates) {
    result.tags = { ...result.tags, ...updates.tags };
  }
  if ("extra" in updates) {
    result.extra = { ...result.extra, ...updates.extra };
  }
  if ("user" in updates) {
    result.user = { ...result.user, ...updates.user };
  }
  return result;
}
