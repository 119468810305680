import React from "react";

const IsBotContext = React.createContext(false);

export function IsBotProvider({
  children,
  value,
}: {
  children: React.ReactNode;
  value: boolean;
}) {
  return (
    <IsBotContext.Provider value={value}>{children}</IsBotContext.Provider>
  );
}

export function useIsBot() {
  const isBot = React.useContext(IsBotContext);
  return isBot;
}
