import MuiTypography, {
  TypographyProps as MUITypographyProps,
} from "@mui/material/Typography";
import React from "react";
import { Theme } from "../../theme";

// Add emphasized to Typography theme
declare module "@mui/material/styles/createTypography" {
  interface AdditionalFontStyle {
    emphasized?: React.CSSProperties;
  }
  interface FontStyle extends AdditionalFontStyle {}
}

export const TypographyVariants = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "display1",
  "display2",
  "display3",
  "subtitle1",
  "subtitle2",
  "body1",
  "body2",
  "caption",
  "overline",
  "inherit",
] as const;
export type TypographyVariant = (typeof TypographyVariants)[number];

export type TypographyProps<
  D extends React.ElementType<any> = "span",
  P = {}
> = {
  /**
   * Changes the font to something that's more... aggressive.
   */
  emphasized?: boolean;
} & MUITypographyProps<D, P>;

const Typography = (
  { emphasized, sx, ...props }: TypographyProps<React.ElementType>,
  ref: React.Ref<any>
) => (
  <MuiTypography
    ref={ref}
    {...props}
    sx={[
      ...(sx ? (Array.isArray(sx) ? sx : [sx]) : []),
      (theme: Theme) => ({
        ...(emphasized ? theme.typography.emphasized : {}),
      }),
    ]}
  />
);

export default React.forwardRef(Typography);
