import { Button, ButtonProps } from "@outschool/backpack";
import { useImpressionTracking } from "@outschool/ui-legacy-component-library";
import React from "react";

export {
  ButtonSize as TrackedButtonSize,
  ButtonVariant as TrackedButtonVariant,
  ButtonColor as TrackedButtonColor,
} from "@outschool/backpack";

export type TrackedButtonProps<C extends React.ElementType = "button"> =
  ButtonProps<C> & {
    trackingName?: string;
    trackingViewEventName?: string;
    trackingTouchEventName?: string;
    trackingUniqueId?: string;
    trackingSharedProperties?: Record<string | number, any>;
    immediateAction?(e: MouseEvent): void;
    trackViews?: boolean;
  };

/**
 * A tracked implementation of Backpack Button
 */
const _TrackedButton = <C extends React.ElementType = "button">(
  {
    trackingName,
    trackingViewEventName,
    trackingTouchEventName,
    trackingUniqueId,
    trackingSharedProperties,
    immediateAction,
    trackViews,
    onClick,
    ...props
  }: TrackedButtonProps<C> & { component?: C },
  ref: React.Ref<HTMLElement | null>
) => {
  const impressionNodeRef = React.useRef<HTMLElement | null>(null);
  React.useImperativeHandle(ref, () => impressionNodeRef.current);

  const { trackTouch } = useImpressionTracking({
    node: impressionNodeRef.current,
    trackingLabel: trackingName!,
    uniqueId: trackingUniqueId!,
    trackingEventName: trackingTouchEventName,
    impressionEventName: trackingViewEventName,
    sharedProperties: trackingSharedProperties,
    trackViews,
  });

  return (
    <Button
      ref={impressionNodeRef as any}
      onClick={(e: any) => {
        if (trackingName) {
          trackTouch();
        }
        immediateAction?.(e);
        onClick?.(e);
      }}
      {...props}
    />
  );
};
_TrackedButton.displayName = "TrackedButton";

export const TrackedButton = React.forwardRef(_TrackedButton);
