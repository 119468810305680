import _ from "lodash";
import { SubscriptionStatus } from "@outschool/gql-frontend-generated";

/**
 * Tag set on activities that are "valid" content for subscriptions.
 * Used as a hack to quickly deliniate a difference among activities that
 * work for subscriptions and regular enrollments.
 *
 * The curation process is manual, done by an outschool admin.
 *
 * The plan is to replace this mechanism with a proper upgrade to
 * Outschool's data model.
 *
 */
export const SubscriptionActivityAdminTag = "Subscription";

/**
 * Key that we use to store stripe subscription status in the user details
 * column.
 *
 */
export const UserDetailsStripeSubscriptionStatusKey =
  "stripeSubscriptionStatus";
export const UserDetailsStripeSubscriptionId = "stripeSubscriptionId";
export const UserDetailsStripeCheckoutId = "stripeCheckoutId";

export function getUserHasActiveSubscription<T extends { details: object }>(
  user: T
): boolean {
  return isActiveSubscription(getUserSubscriptionStatus(user));
}

export function getUserSubscriptionStatus<T extends { details: object }>(
  user: T
): SubscriptionStatus | null {
  const value = _.get(user.details, UserDetailsStripeSubscriptionStatusKey);
  if (!_.isString(value)) {
    return null;
  }

  switch (value) {
    case "active":
      return SubscriptionStatus.Active;
    case "canceled":
      return SubscriptionStatus.Canceled;
    /**
     * More status to be supported
     */
    default:
      return null;
  }
}

export function getUserSubscriptionId<T extends { details: object }>(
  user: T
): string | null {
  const value = _.get(user.details, UserDetailsStripeSubscriptionId);

  if (!_.isString(value)) {
    return null;
  }

  return value;
}

export function getUserStripeCheckoutSessionId<T extends { details: object }>(
  user: T
): string | null {
  const value = _.get(user.details, UserDetailsStripeCheckoutId);

  if (!_.isString(value)) {
    return null;
  }

  return value;
}

export function isActiveSubscription(status: SubscriptionStatus | null) {
  if (!status) {
    return false;
  }

  return status === SubscriptionStatus.Active;
}

export const FULL_PRICE_PER_MONTH_IN_CENTS = 4_000;
export const DISCOUNTED_PRICE_PER_MONTH_IN_CENTS = 2_999;
export const FIFTY_OFF_DISCOUNTED_PRICE_PER_MONTH_IN_CENTS = 2_000;

export * from "./email";
