export default [
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Dakar",
  "Africa/Douala",
  "Africa/Djibouti",
  "Africa/Johannesburg",
  "Africa/Khartoum",
  "Africa/Lagos",
  "Africa/Mogadishu",
  "Africa/Nairobi",
  "Africa/Tripoli",
  "America/Adak",
  "America/Anchorage",
  "America/Antigua",
  "America/Aruba",
  "America/Barbados",
  "America/Belize",
  "America/Bogota",
  "America/Buenos_Aires",
  "America/Cancun",
  "America/Caracas",
  "America/Cayman",
  "America/Chicago",
  "America/Cordoba",
  "America/Denver",
  "America/Detroit",
  "America/Edmonton",
  "America/El_Salvador",
  "America/Guadeloupe",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Indianapolis",
  "America/Jamaica",
  "America/Juneau",
  "America/Lima",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Martinique",
  "America/Mexico_City",
  "America/Montevideo",
  "America/New_York",
  "America/Panama",
  "America/Phoenix",
  "America/Puerto_Rico",
  "America/Regina",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/St_Johns",
  "America/Tegucigalpa",
  "America/Toronto",
  "America/Vancouver",
  "America/Winnipeg",
  "America/Yellowknife",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Bangkok",
  "Asia/Beirut",
  "Asia/Brunei",
  "Asia/Calcutta",
  "Asia/Colombo",
  "Asia/Dhaka",
  "Asia/Dubai",
  "Asia/Gaza",
  "Asia/Hong_Kong",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Katmandu",
  "Asia/Kuala_Lumpur",
  "Asia/Kuwait",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Manila",
  "Asia/Omsk",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Saigon",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Taipei",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Tokyo",
  "Asia/Ulaanbaatar",
  "Asia/Vientiane",
  "Asia/Yakutsk",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Melbourne",
  "Australia/Perth",
  "Australia/Sydney",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Athens",
  "Europe/Berlin",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Helsinki",
  "Europe/Istanbul",
  "Europe/Kiev",
  "Europe/Lisbon",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Prague",
  "Europe/Rome",
  "Europe/San_Marino",
  "Europe/Stockholm",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Warsaw",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "Pacific/Easter",
  "Pacific/Fiji",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Kiritimati",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Norfolk",
  "Pacific/Tahiti",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa",
];
