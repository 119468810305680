import { Components } from "@mui/material";

export const RADIO_THEME: Components["MuiRadio"] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      "&.Mui-focusVisible": {
        outline: "2px solid",
        outlineOffset: -8,
        outlineColor: "primary.main",
      },
    },
  },
};
