import React from "react";
import {
  TextField,
  TextFieldColor,
  TextFieldColors,
  TextFieldProps,
  TextFieldSize,
  TextFieldSizes,
} from "../TextField/TextField";
import { BaseSelectProps } from "@mui/material";

export { SelectChangeEvent, selectClasses } from "@mui/material";

export const SelectSizes = TextFieldSizes;
export type SelectSize = TextFieldSize;

export const SelectColors = TextFieldColors;
export type SelectColor = TextFieldColor;

export type SelectProps<Value = unknown> = Omit<
  TextFieldProps,
  "select" | "InputProps" | "value" | "onChange" | "SelectProps"
> &
  Omit<BaseSelectProps<Value>, "sx>"> & {
    selectSx?: BaseSelectProps<Value>["sx"];
  };

// Using TextField isntead of Select so that we can use label and helperText, and have consistent styling.
export const Select = <Value = unknown,>({
  /* extract _most_ Select specific props (see BaseSelectProps) */
  autoWidth,
  defaultOpen,
  displayEmpty,
  IconComponent,
  MenuProps,
  multiple,
  native,
  onChange,
  onClose,
  onOpen,
  open,
  renderValue,
  SelectDisplayProps,
  value,
  /* end of Select specific props */
  selectSx,
  ...props
}: SelectProps<Value>) => (
  <TextField
    {...props}
    select
    SelectProps={{
      autoWidth,
      defaultOpen,
      displayEmpty,
      IconComponent,
      MenuProps,
      multiple,
      native,
      onChange,
      onClose,
      onOpen,
      open,
      renderValue,
      SelectDisplayProps,
      value,
      sx: selectSx,
    }}
  />
);
