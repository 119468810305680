// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { EsaSessionData } from "@outschool/auth-shared";
import { CurrentUserQueryQuery } from "@outschool/gql-frontend-generated";
import * as Time from "@outschool/time";
import React from "react";
import { EsaSessionType } from "./TokenProvider";

function timeZone(
  user?: CurrentUserQueryQuery["currentUser"] | null,
  defaultTimeZone = Time.OUTSCHOOL_TIMEZONE
) {
  const locationZone = user?.location?.timeZone;
  if (locationZone && Time.zoneIsValid(locationZone)) {
    return locationZone;
  }
  const browserZone = Time.guessBrowserTimeZone();
  if (Time.zoneIsValid(browserZone)) {
    return browserZone;
  }
  return defaultTimeZone;
}

export type CurrentUser = CurrentUserQueryQuery["currentUser"] | null;

export type SessionContext = {
  currentUser: CurrentUser;
  currentUserIsLoading: boolean;
  currentUserHasLoaded: boolean;
  isAdmin: boolean;
  isLoggedIn: boolean;
  isEsaSession: boolean;
  esaSessionType: EsaSessionType | null;
  esaSessionData: EsaSessionData | null;
  destroyEsaSession: () => void;
  isLeader: boolean;
  sessionToken: string | null;
  hasApple: boolean;
  hasFacebook: boolean;
  hasGoogle: boolean;
  hasLine: boolean;
  hasKakao: boolean;
  hasPassword: boolean;
  hasAccount: boolean;
  /** @deprecated Access the time zone via `useTimeZone` from `TimeZoneProvider` instead. */
  userTimeZone: string | null;
  roles: string[];
};

const DEFAULT_CONTEXT = {
  currentUser: null,
  currentUserIsLoading: false,
  currentUserHasLoaded: true,
  isAdmin: false,
  isLoggedIn: false,
  isEsaSession: false,
  esaSessionType: null,
  esaSessionData: null,
  destroyEsaSession: () => {},
  isLeader: false,
  sessionToken: null,
  hasApple: false,
  hasLine: false,
  hasKakao: false,
  hasFacebook: false,
  hasGoogle: false,
  hasPassword: false,
  hasAccount: false,
  userTimeZone: null,
  roles: [],
};

const SessionContext = React.createContext<SessionContext>(DEFAULT_CONTEXT);

export function SessionProvider({
  children,
  ...context
}: Partial<Omit<SessionContext, "userTimeZone">> & {
  children: React.ReactNode;
}) {
  const [userTimeZone, setUserTimeZone] = React.useState(
    timeZone(context?.currentUser)
  );
  React.useEffect(() => {
    setUserTimeZone(timeZone(context?.currentUser));
  }, [context.currentUser]);

  const value = React.useMemo(
    () => ({
      ...DEFAULT_CONTEXT,
      ...context,
      userTimeZone,
    }),
    [context, userTimeZone]
  );

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
}

export function withSession<R, P>(Component: React.FC<P>) {
  return React.forwardRef<R, P>((props, ref) => {
    const { currentUser, ...rest } = useSession();
    return (
      <Component currentUser={currentUser} {...rest} {...props} ref={ref} />
    );
  });
}

export function useSession() {
  return React.useContext(SessionContext);
}
