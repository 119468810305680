import { Operation, RetryLink } from "@outschool/ui-apollo";

export function createRetryLink() {
  const MAX_RETRY_COUNT = 5;
  // Using defaults (300 ms timeout, max 5 attempts)
  // See https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
  return new RetryLink({
    attempts(count, operation, error) {
      const context = operation.getContext();
      if (context.doNotRetry) {
        return false;
      }
      if (
        error?.statusCode === 403 &&
        error.response?.statusText === "Cloudflare Denied Request"
      ) {
        OsPlatform.captureMessage("Cloudflare blocked GraphQL request", {
          tags: {
            package: "ui-gql",
            gqlOperationName: operation.operationName,
          },
        });
        return false;
      }
      if (
        error?.statusCode === 401 &&
        error?.response?.headers?.has("x-os-invalid-signature")
      ) {
        return false;
      }
      if (count >= MAX_RETRY_COUNT) {
        OsPlatform.captureMessage("Max retry attempt reached", {
          tags: {
            package: "ui-gql",
            gqlOperationName: operation.operationName,
            retryLinkCount: count,
          },
          extra: { retryLinkError: error },
        });

        return false;
      }
      if (isMutationOperation(operation) && !context.retryMutationOnError) {
        return false;
      }
      if (!error) {
        return false;
      }

      return true;
    },
  });
}

function isMutationOperation(operation: Operation) {
  return !!operation.query?.definitions?.some(
    (op: any) =>
      op.kind === "OperationDefinition" && op.operation === "mutation"
  );
}
