import React, { PropsWithChildren } from "react";

import { City, getIPLookup } from "./IPLookup";
// England, Switzerland, Iceland, Liechtenstein, and Norway
export const NON_EU_COUNTRIES_WITH_GDPR = ["GB", "CH", "IS", "LI", "NO"];

export const EUROZONE_COUNTRIES = [
  // European Union countries
  "AT", // Austria
  "BE", // Belgium
  "CY", // Cyprus
  "EE", // Estonia
  "FI", // Finland
  "FR", // France
  "DE", // Germany
  "GR", // Greece
  "IE", // Ireland
  "IT", // Italy
  "LV", // Latvia
  "LT", // Lithuania
  "LU", // Luxemmbourgh
  "MT", // Malta
  "NL", // Netherlands
  "PT", // Portugal
  "SK", // Slovakia
  "SI", // Slovenia
  "ES", // Spain

  // Non-EU countries
  "AD", // Andorra
  "MC", // Monaco
  "SM", // San Marino
  "VA", // Vatican City
];

export type IPContext = {
  ipInfo: City | null;
  ipInfoLoaded: boolean;
  isInCA: boolean;
  isInGDPR: boolean;
  isInEurozone: boolean;
};

export const LookupIPContext = React.createContext<IPContext>({
  ipInfo: null,
  ipInfoLoaded: false,
  isInCA: false,
  isInGDPR: false,
  isInEurozone: false,
});

export default function LookupIPProvider({
  children,
  iplookupApiBaseUrl,
  iplookupApiKey,
  skip = false,
  bakedLocation,
}: PropsWithChildren<{
  iplookupApiBaseUrl: string;
  iplookupApiKey: string;
  skip?: boolean;
  bakedLocation?: City;
}>) {
  const [ipInfo, setIpInfo] = React.useState<City | null>(
    bakedLocation ?? null
  );
  const bakedLocationGiven = !!bakedLocation;
  const [ipInfoLoaded, setIpInfoLoaded] = React.useState(bakedLocationGiven);

  React.useEffect(() => {
    if (!iplookupApiBaseUrl || skip || bakedLocationGiven) {
      return;
    }
    getIPLookup({ iplookupApiBaseUrl, iplookupApiKey, fetchFn: fetch })
      .then(resp => {
        // @ts-ignore these are the same types, but clashing on private fields
        setIpInfo(resp);
      })
      .catch(() => {
        setIpInfo(null);
      })
      .finally(() => {
        setIpInfoLoaded(true);
      });
  }, [iplookupApiBaseUrl, iplookupApiKey, skip, bakedLocationGiven]);

  return (
    <LookupIPContext.Provider
      value={{
        ipInfo,
        ipInfoLoaded,
        isInCA: Boolean(
          ipInfo?.country?.isoCode === "US" &&
            !!(ipInfo?.subdivisions || []).find(
              subdivision => subdivision.isoCode === "CA"
            )
        ),
        isInGDPR: Boolean(
          ipInfo?.country?.isInEuropeanUnion ||
            NON_EU_COUNTRIES_WITH_GDPR.includes(ipInfo?.country?.isoCode || "")
        ),
        isInEurozone: Boolean(
          EUROZONE_COUNTRIES.includes(ipInfo?.country?.isoCode || "")
        ),
      }}
    >
      {children}
    </LookupIPContext.Provider>
  );
}
