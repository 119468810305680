import React, { createContext, useContext, useState } from "react";

type MobileSearchBarContext = {
  setIsHeaderOnScreen: (isHeaderOnScreen: boolean) => void;
  setIsHeaderMounted: (isHeaderMounted: boolean) => void;
  setIsSearchFieldOnScreen: (isSearchFieldOnScreen: boolean) => void;
  showMobileSearchBar: boolean;
  verticalOffset: number;
  setVerticalOffset: (verticalOffset: number) => void;
};

const MobileSearchBar = createContext({} as MobileSearchBarContext);

type Props = {
  children: React.ReactNode;
};

export function MobileSearchBarProvider({ children }: Props) {
  const [isHeaderOnScreen, setIsHeaderOnScreen] = useState(false);
  const [isHeaderMounted, setIsHeaderMounted] = useState(false);
  const [isSearchFieldOnScreen, setIsSearchFieldOnScreen] = useState(false);
  const [verticalOffset, setVerticalOffset] = useState(0);

  const showMobileSearchBar = !isHeaderOnScreen && !isSearchFieldOnScreen;

  return (
    <MobileSearchBar.Provider
      value={{
        verticalOffset: showMobileSearchBar ? verticalOffset : 0,
        setVerticalOffset,
        setIsHeaderOnScreen,
        setIsSearchFieldOnScreen,
        setIsHeaderMounted,
        showMobileSearchBar:
          isHeaderMounted && !isHeaderOnScreen && !isSearchFieldOnScreen,
      }}
    >
      {children}
    </MobileSearchBar.Provider>
  );
}

export function useMobileSearchBar() {
  return useContext(MobileSearchBar);
}
