import { useEffect, useState } from "react";

import { DeviceType, useDeviceTypeContext } from "./DeviceTypeContext";
import { useIsBot } from "./IsBotContext";

const MOBILE_MIN_HEIGHT = 575;
const SMALL_MAX_WIDTH = 574.95;
const MOBILE_MAX_WIDTH = 767.95;
const TABLET_MAX_WIDTH = 923.95;
const LARGE_MIN_WIDTH = 1000;
const XLARGE_MIN_WIDTH = 1440;

function getInitialMediaQueryValue(
  query: string,
  targetDeviceType: DeviceType | undefined,
  currentDeviceType: DeviceType
) {
  if (typeof window !== "undefined") {
    return window.matchMedia(query).matches;
  }
  return targetDeviceType === currentDeviceType;
}

export function getBottomOffset(
  element: HTMLElement,
  otherElement: HTMLElement
) {
  if (!element || !otherElement) {
    return undefined;
  }

  let otherRect = otherElement.getBoundingClientRect();
  let elemRect = element.getBoundingClientRect();
  return elemRect.bottom - otherRect.bottom;
}

export function getScrollParent(
  node: Pick<
    HTMLElement,
    "parentNode" | "scrollHeight" | "clientHeight" | "scrollTop"
  >
): Pick<
  HTMLElement,
  "parentNode" | "scrollHeight" | "clientHeight" | "scrollTop"
> | null {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode as HTMLElement);
  }
}

export function scrollToTop(
  { behavior }: { behavior: "smooth" | "auto" } = { behavior: "smooth" }
) {
  window.scrollTo({ top: 0, behavior });
}

export const scrollToElement = (element: HTMLElement | null) => {
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

export const scrollToElementId = (id: string | null) => {
  if (!id) {
    return;
  }
  const element = document.getElementById(id);
  scrollToElement(element);
};

export function elementTopIsAboveViewport(element: HTMLElement) {
  return element.getBoundingClientRect().top < 0;
}

export function elementBottomIsAboveParentBottom(element: HTMLElement) {
  const offset = getBottomOffset(element, element.parentNode as HTMLElement);
  return !offset || (!!offset && offset < 0);
}

export function useIsSmall() {
  const isPrerenderRequest = useIsBot();
  const isMobile = useMediaQuery(
    `(max-width: ${SMALL_MAX_WIDTH}px)`,
    DeviceType.Mobile
  );
  return isPrerenderRequest || isMobile;
}

export function useIsMobile() {
  const isPrerenderRequest = useIsBot();
  const isMobile = useMediaQuery(
    `only screen and (max-width: ${MOBILE_MAX_WIDTH}px)`,
    DeviceType.Mobile
  );

  return isPrerenderRequest || isMobile;
}

export function useIsTablet() {
  const isPrerenderRequest = useIsBot();
  const isTablet = useMediaQuery(
    `only screen and (max-width: ${TABLET_MAX_WIDTH}px)`,
    DeviceType.Tablet
  );
  return isPrerenderRequest || isTablet;
}

export function useIsLarge() {
  const isPrerenderRequest = useIsBot();
  const isLarge = useMediaQuery(
    `(min-width: ${LARGE_MIN_WIDTH}px)`,
    DeviceType.Desktop
  );
  return !isPrerenderRequest && isLarge;
}

export function useIsXLarge() {
  const isPrerenderRequest = useIsBot();
  const isXLarge = useMediaQuery(
    `(min-width: ${XLARGE_MIN_WIDTH}px)`,
    DeviceType.Desktop
  );
  return !isPrerenderRequest && isXLarge;
}

export function useHasEnoughSpaceForStickyHeader() {
  const isPrerenderRequest = useIsBot();
  const isMobile = useMediaQuery(
    `(min-height: ${MOBILE_MIN_HEIGHT}px)`,
    DeviceType.Mobile
  );
  return isPrerenderRequest || isMobile;
}

function componentFromHook<T>(hook: () => T) {
  return ({ children: render }: { children: (value: T) => JSX.Element }) =>
    render(hook());
}

export const MediaIsMobile = componentFromHook(useIsMobile);

export const MediaHasEnoughSpaceForStickyHeader = componentFromHook(
  useHasEnoughSpaceForStickyHeader
);

export function useMediaQuery(query: string, defaultDeviceType?: DeviceType) {
  const { deviceType } = useDeviceTypeContext();
  const initialValue = getInitialMediaQueryValue(
    query,
    defaultDeviceType,
    deviceType
  );
  const [match, setMatch] = useState(initialValue);

  useEffect(() => {
    const matchQuery = window.matchMedia(query);
    const callback = (e: MediaQueryListEvent) => {
      setMatch(e.matches);
    };

    matchQuery.addListener(callback);
    setMatch(matchQuery.matches);

    return () => matchQuery.removeListener(callback);
  }, [query, setMatch]);

  return match;
}

export function useDevicePixelRatio(deviceType?: DeviceType) {
  const [pixelRatio, setPixelRatio] = useState<number | undefined>(undefined);
  useEffect(() => {
    // useEffect only executes on client even in SSR
    setPixelRatio(window.devicePixelRatio);
  }, []);
  if (!!deviceType) {
    return deviceType === DeviceType.Desktop ? 1 : 2;
  }
  if (!pixelRatio) {
    return 1;
  }
  return pixelRatio < 1.5 ? 1 : 2;
}

export function useResolutionMultiplier() {
  const hasHighPixelRatio = useMediaQuery(
    "(-webkit-min-device-pixel-ratio: 1.5)",
    DeviceType.Mobile
  );
  return hasHighPixelRatio ? 2 : 1;
}

function isHTMLElement(
  elementOrRect: HTMLElement | DOMRect
): elementOrRect is HTMLElement {
  return typeof (elementOrRect as any).getBoundingClientRect === "function";
}

// From Stack Overflow:
// http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433
export function isElementInViewport(elementOrRect: HTMLElement | DOMRect) {
  if (isHTMLElement(elementOrRect)) {
    var rect = elementOrRect.getBoundingClientRect();
  } else {
    rect = elementOrRect;
  }

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= window.innerHeight &&
    rect.right <= window.innerWidth
  );
}

export function isElementInOrAboveViewport(
  elementOrRect: HTMLElement | DOMRect
) {
  if (isHTMLElement(elementOrRect)) {
    var rect = elementOrRect.getBoundingClientRect();
  } else {
    rect = elementOrRect;
  }

  return rect.top < window.innerHeight;
}

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  return size;
}
