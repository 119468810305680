import { Global, css } from "@emotion/react";
import { useTheme } from "@outschool/backpack";
import { UserAgent, responsive } from "@outschool/ui-utils";
import React from "react";
import ReactDOM from "react-dom";
import { v4 as uuid } from "uuid";

import LegacyBox from "./LegacyBox";
import LegacyFlex from "./LegacyFlex";

interface ModalHelperProps {
  children?: React.ReactNode;
  onClose?: () => void;
  closeViaMaskClick?: boolean;
  closeViaEscButton?: boolean;
  fullBleed?: boolean;
  maskBackgroundColor?: string;
  ariaLabel?: string;
}

export default function ModalHelper({
  children,
  onClose = () => {},
  closeViaMaskClick = false,
  closeViaEscButton = false,
  fullBleed = true,
  maskBackgroundColor = "rgba(0, 0, 0, 0.5)",
  ariaLabel = undefined,
}: ModalHelperProps): React.ReactPortal {
  const [initialScrollTop] = React.useState(window.scrollY);
  const modalIdRef = React.useRef(`modal-${uuid()}`);
  const lastActiveElement = React.useRef(document.activeElement);

  React.useEffect(() => {
    const lastActiveElementValue = lastActiveElement.current;
    return () => {
      // After closing the modal, focus back to the last active element outside of the modal
      if (lastActiveElementValue) {
        (lastActiveElementValue as HTMLElement).focus();
      }
      if (UserAgent.isMobileSafari()) {
        setTimeout(() => window.scrollTo(0, initialScrollTop), 0);
      }
    };
  }, [initialScrollTop]);

  const getFocusableModalElements = () => {
    const modal = document.getElementById(modalIdRef.current);
    if (!modal) {
      return [];
    }
    return Array.from(
      modal.querySelectorAll(
        'a[href], button:not([disabled]), textarea, input, select, [tabindex]:not([tabindex^="-"])'
      )
    ) as HTMLElement[];
  };

  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (closeViaEscButton && event && event.keyCode === 27) {
        onClose();
      }
      if (event.key !== "Tab") {
        return;
      }

      const focusableModalElements = getFocusableModalElements();
      if (focusableModalElements.length === 0) {
        return;
      }

      const firstFocusableModalElement = focusableModalElements[0];
      const lastFocusableModalElement =
        focusableModalElements[focusableModalElements.length - 1];
      /**
       * - going forward by pressing tab AND
       * - the active element is not in the modal OR the active element is the last focusable element
       *   in the modal
       */
      if (
        !event.shiftKey &&
        (!focusableModalElements.find(e => e === document.activeElement) ||
          document.activeElement === lastFocusableModalElement)
      ) {
        (firstFocusableModalElement as HTMLElement).focus();
        return event.preventDefault();
      }
      /**
       * - going backward by pressing shift + tab AND
       * - the active element is not in the modal OR the active element is the first focusable element
       *   in the modal
       */
      if (
        event.shiftKey &&
        (!focusableModalElements.find(e => e === document.activeElement) ||
          document.activeElement === firstFocusableModalElement)
      ) {
        (lastFocusableModalElement as HTMLElement).focus();
        event.preventDefault();
      }
    },
    [closeViaEscButton, onClose]
  );

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);

  const theme = useTheme();

  return ReactDOM.createPortal(
    <LegacyBox
      id={modalIdRef.current}
      role="dialog"
      aria-label={ariaLabel}
      aria-modal="true"
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        // zIndex is undefined during tests, so optional chain for safety
        zIndex: theme.zIndex?.modal,
      }}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <LegacyFlex
        sx={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          flexDirection: "column",
          alignItems: "center",
          overflow: "auto",
          padding: responsive({
            default: fullBleed ? "none" : "small",
            medium: "large",
          }),
          backgroundColor: maskBackgroundColor,
        }}
      >
        {/* We have to use flex spacers to vertically center here, justifyContent pushes the children out of the container padding */}
        <LegacyBox sx={{ flexGrow: 1 }} />
        {/* Placing the click handler outside of the modal to avoid modal closing when click didn't initiate outside of the content */}
        <LegacyBox
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
          onClick={(e: React.MouseEvent) => {
            if (
              closeViaMaskClick &&
              (e.target as Element)?.isSameNode(e.currentTarget)
            ) {
              onClose();
            }
          }}
        />
        {children}
        <LegacyBox sx={{ flexGrow: 1 }} />
      </LegacyFlex>
      <Global
        styles={
          UserAgent.isMobileSafari()
            ? css`
                body {
                  position: fixed;
                  width: 100%;
                }
              `
            : css`
                body {
                  overflow: hidden;
                  height: 100%;
                }
              `
        }
      />
    </LegacyBox>,
    document.body
  );
}
