import { useMemo } from "react";

const useWindowReference = () => {
  const canUseWindow: boolean = useMemo(
    () => !!(typeof window !== "undefined" && window.document?.createElement),
    []
  );

  return {
    canUseWindow,
    window: canUseWindow ? window : undefined,
  };
};

export default useWindowReference;
