import { I18nLocale } from "@outschool/localization";
import { PageContext } from "@outschool/ui-analytics";

import { DocumentNode, ApolloClient, ApolloLink } from "@outschool/ui-apollo";

import { createHttpLink } from "./create-http-link";
import { createMemoryCache } from "./create-memory-cache";
import { RateLimitConfig, createRateLimitLink } from "./create-rate-limit-link";
import { createTrackingHeadersLink } from "./create-tracking-headers-link";
import { createUpdateClientVersionLink } from "./create-update-client-version-link";
import {
  createTokenRefreshLink,
  TokenRefreshControl,
} from "./create-token-refresh-link";
import { createRetryLink } from "./create-retry-link";
import { createErrorLoggerLink } from "./create-error-logger-link";

export type OsApolloClientOptions = {
  /** client name, used in logging */
  name: string;
  /** Force client actions on stale client versions */
  clientVersion: number;

  /** [Defaults to /graphql] Uri used for requests */
  uri?: string;

  /** Builds a link to catch notification service related queries */
  notificationServiceUrl?: string;

  /** ƒn to handle stale client version. Defaults to ignored */
  updateExpectedClientVersion?: (newClientVersion: number) => void;

  /** Clients locale for tracking */
  locale?: I18nLocale;

  /** Appended to every request */
  extraHeaders?: Record<string, string>;

  /** Information of the page for OS tracking purposes */
  pageContextRef?: { current: PageContext };

  /** Allow individual queries to be rate limited */
  rateLimits?: RateLimitConfig;

  /** Set this handle token refreshing */
  tokenRefreshControl?: TokenRefreshControl;

  /** Provide debugging info for apollo dev tools. May be empty. */
  typeDefs?: DocumentNode | DocumentNode[];

  /** controls aspects of how apollo handles the request */
  ssrMode?: boolean;
};

export function createApolloClient(opts: OsApolloClientOptions) {
  let links: ApolloLink[] = [];
  if (opts.rateLimits) {
    links.push(createRateLimitLink(opts.rateLimits));
  }
  if (opts.tokenRefreshControl) {
    links.push(
      createTokenRefreshLink(opts.name, opts.tokenRefreshControl, () => client)
    );
  }
  if (opts.updateExpectedClientVersion) {
    links.push(createUpdateClientVersionLink(opts.updateExpectedClientVersion));
  }
  links.push(
    createErrorLoggerLink(opts.name),
    createRetryLink(),
    createTrackingHeadersLink(
      opts.clientVersion,
      opts.locale,
      opts.pageContextRef
    ),
    createHttpLink({
      uri: opts.uri ?? "/graphql",
      notificationServiceUrl: opts.notificationServiceUrl,
      extraHeaders: opts.extraHeaders,
    })
  );

  const client = new ApolloClient({
    link: ApolloLink.from(links),
    // dev tool detection causes tests to hang open and jest to whine
    connectToDevTools: !OsPlatform.isTest,
    name: opts.name,
    assumeImmutableResults: true,
    version: opts.clientVersion.toString(),
    cache: createMemoryCache(),
    ssrMode: opts.ssrMode,
    typeDefs: opts.typeDefs,
  });

  return client;
}
