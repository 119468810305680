import { ErrorMessages } from "@outschool/errors";
import { CreateOrLoginError } from "@outschool/gql-frontend-generated";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

import AccountLockedError from "../components/AccountLockedError";
import LearnerSocialAccountLinkFailed from "../components/LearnerSocialAccountLinkFailed";

function exhausiveCheck(x: never): never {
  throw new Error(`${x} not handled`);
}

export default function useAuthError(provider?: string) {
  return React.useCallback(
    (error: CreateOrLoginError) => {
      switch (error) {
        case CreateOrLoginError.AccountSuspended:
          return ErrorMessages.USER_ACCOUNT_SUSPENDED;
        case CreateOrLoginError.AccountLocked:
          return <AccountLockedError />;
        case CreateOrLoginError.InvalidPermissions:
          return "You must grant permission to access your email to create an account with Facebook";
        case CreateOrLoginError.AccountAlreadyLinked:
          return "This account is already linked to another Outschool.com account";
        case CreateOrLoginError.UnknownError:
          return "Sorry that didn't work, try again";
        case CreateOrLoginError.LearnerAccountLinkFailed:
          return <LearnerSocialAccountLinkFailed provider={provider} />;
        case CreateOrLoginError.AccountAlreadyExists:
        case CreateOrLoginError.InvalidEmail:
        case CreateOrLoginError.InvalidName:
        case CreateOrLoginError.AccountNotFound:
        case CreateOrLoginError.AccountCreationNotAllowed:
        case CreateOrLoginError.IncorrectApp:
        case CreateOrLoginError.SocialLoginNotAllowed:
          return undefined;
        default:
          exhausiveCheck(error);
      }
    },
    [provider]
  );
}
