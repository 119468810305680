/** @jsx jsxPragma */

import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import jsxPragma from "./jsxPragma";

interface LabelProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: string;
  textColor?: string;
  sx?: SystemStyleObject;
}

function Label(
  { color = "gray3", textColor = "white", sx, ...props }: LabelProps,
  ref: React.Ref<HTMLDivElement> | undefined
) {
  return (
    <div
      ref={ref}
      // @ts-ignore
      // eslint-disable-next-line
      sx={{
        display: "inline-block",
        backgroundColor: color,
        color: textColor,
        borderRadius: 4,
        textAlign: "center",
        paddingY: "tiny",
        paddingX: "small",
        fontSize: "0.8em",
        ...sx,
      }}
      {...props}
    />
  );
}

export default React.forwardRef(Label);
