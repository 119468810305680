import { OwnershipAreas } from "@outschool/ownership-areas";
import { OsCaptureContext, OsSentryContext } from "../captures";

export function expandAlarmSettings(ctx: OsCaptureContext): OsSentryContext;
export function expandAlarmSettings(
  ctx: OsCaptureContext | undefined
): OsSentryContext | undefined;
export function expandAlarmSettings(
  ctx: OsCaptureContext | undefined
): OsSentryContext | undefined {
  if (!ctx) {
    return ctx;
  }

  if (ctx.component) {
    ctx.tags ??= {};
    ctx.tags["component"] = ctx.component;
    ctx.tags["owner"] = OwnershipAreas[ctx.component];
  }

  if (ctx.priority) {
    ctx.tags ??= {};
    ctx.tags["priority"] = ctx.priority;
  }

  return ctx;
}
