import { I18nLocale, useLocale, useTranslation } from "@outschool/localization";
import { KakaoButton } from "@outschool/ui-components-shared";
import React from "react";

import { useOidcOnClick } from "./useOidcOnClick";

interface ContinueWithKakaoProps {
  isLearnerApp: boolean;
  setAuthStrategy?: (strategy: "kakao") => void;
  trackingName: string;
  getAnonymousId?: () => Promise<string | undefined>;
  disableWhileLoading?: boolean;
  onClick?: () => void;
}

export default function ContinueWithKakao({
  isLearnerApp,
  trackingName,
  setAuthStrategy = () => undefined,
  getAnonymousId = () => Promise.resolve(undefined),
  disableWhileLoading = false,
  onClick: onClickProp = () => {},
}: ContinueWithKakaoProps) {
  const { t } = useTranslation("ui-auth\\src\\ContinueWithKakao");
  const oidcOnClick = useOidcOnClick({
    getAnonymousId,
    isLearnerApp,
    setAuthStrategy,
    provider: "kakao",
  });

  return (
    <KakaoButton
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        onClickProp();
        oidcOnClick(event);
      }}
      trackingName={trackingName}
      disabled={disableWhileLoading}
      sx={{
        "&:disabled": {
          pointerEvents: "none",
          opacity: 0.5,
          cursor: "not-allowed",
        },
      }}
    >
      {t("Continue with Kakao")}
    </KakaoButton>
  );
}

export function useShouldShowKakao() {
  const locale = useLocale();
  return locale === I18nLocale.Ko;
}
