import { Components, Theme } from "@mui/material";

export const ARROW_SWITCHER_THEME: Components<Theme>["MuiPickersArrowSwitcher"] =
  {
    styleOverrides: {
      // Increase space between arrows in calendar view -- they're cramped
      spacer: ({ theme }: { theme: Theme }) => ({
        width: theme.spacing(16),
      }),
    },
  };
