import { Components, Theme, mobileStepperClasses } from "@mui/material";

export const STEPPER_THEME: Components<Theme>["MuiMobileStepper"] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      [`& .${mobileStepperClasses.dots}`]: {
        gap: "1.6rem",
        [`& .${mobileStepperClasses.dot}`]: {
          backgroundColor: theme.palette.primary[100],
          [`&.${mobileStepperClasses.dotActive}`]: {
            backgroundColor: theme.palette.primary.main,
          },
          [`&:not(.${mobileStepperClasses.dotActive} ~ .${mobileStepperClasses.dot})`]:
            {
              backgroundColor: theme.palette.primary.main,
            },
        },
      },
    }),
  },
};
