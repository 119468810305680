import { renderCurrencyString } from "@outschool/localization";
import {
  DAY_END_HOUR,
  DAY_START_HOUR,
  briefDurationHoursString,
  formatAbsoluteTime,
  formatDayWithFullMonth,
} from "@outschool/time";

export enum RelativeDateFilter {
  /** Classes starting on or after today
   *  Two strings to differentiate between new or old relative choices
   */
  ThisWeek = "ThisWeek",
  AllUpcoming = "AllUpcoming",
  /** Classes starting on or after next Monday */
  NextWeek = "NextWeek",
  /** Classes starting on or after the first day of the next month */
  NextMonth = "NextMonth",
  /** Classes starting today */
  Today = "Today",
  /** Classes starting in the next 7 days */
  SevenDays = "SevenDays",
  /** Classes starting in the next 14 days */
  FourteenDays = "FourteenDays",
  /** Classes starting today or June 1 and ending August 31 */
  Summer = "Summer",
  /** Classes starting today or September 1 and ending November 30 */
  Fall = "Fall",
  /** Classes starting in January */
  January = "January",
}

export function relativeDateFilterToString(relativeDateFilter: any) {
  return relativeDateFilter === RelativeDateFilter.ThisWeek
    ? "Starting this week or later"
    : relativeDateFilter === RelativeDateFilter.NextWeek
    ? "Starting next week or later"
    : relativeDateFilter === RelativeDateFilter.NextMonth
    ? "Starting next month or later"
    : relativeDateFilter === RelativeDateFilter.Today
    ? "Today"
    : relativeDateFilter === RelativeDateFilter.SevenDays
    ? "Next 7 days"
    : relativeDateFilter === RelativeDateFilter.FourteenDays
    ? "Next 14 days"
    : relativeDateFilter === RelativeDateFilter.AllUpcoming
    ? "All upcoming dates"
    : "";
}

export function displayTimeFromCardinalHour(cardinalHour: any) {
  const hour = Math.floor(cardinalHour);
  const minutes = Math.round((cardinalHour - Math.floor(cardinalHour)) * 60);
  return formatAbsoluteTime(hour, minutes);
}

export function timeRangeToString(startAfterTime: any, endByTime: any) {
  if (!startAfterTime && !endByTime) {
    return null;
  }

  const startTime = startAfterTime || DAY_START_HOUR;
  const endTime = endByTime || DAY_END_HOUR;

  return briefDurationHoursString(startTime, endTime);
}

export const SEARCH_FILTER_KEY_TO_DISPLAY_NAME = {
  age: "Age",
  capacityMax: "Max Class size",
  capacityMin: "Min Class size",
  delivery: "Class type",
  dow: "Days",
  endBy: "End by",
  endByTime: "Ending",
  format: "Class format",
  gradeLevel: "Grade",
  pricePerMeetingMax: "Max Price",
  pricePerMeetingMin: "Min Price",
  q: "Query",
  startAfter: "Start after",
  startAfterTime: "Starting",
  startBefore: "Start before",
  theme: "Subject",
  time: "Time",
  timeOfDay: "Time of day",
  timeZone: "Time zone",
  order: "Order",
};

export function getFilterDisplayString({
  filterName,
  filterValue,
}: {
  filterName: string;
  filterValue: string;
}): string | null {
  const filterDisplayString = getFilterDisplayStringHelper({
    filterName,
    filterValue,
  });

  if (!filterDisplayString) {
    return filterDisplayString;
  }

  return filterDisplayString.split(",").join(", ");
}

function getFilterDisplayStringHelper({
  filterName,
  filterValue,
}: {
  filterName: string;
  filterValue: string;
}): string | null {
  switch (filterName) {
    case "startAfterTime":
    case "endByTime":
      return `${
        SEARCH_FILTER_KEY_TO_DISPLAY_NAME[filterName]
      } ${displayTimeFromCardinalHour(filterValue)}`;
    case "pricePerMeetingMax":
    case "pricePerMeetingMin":
      return `${
        SEARCH_FILTER_KEY_TO_DISPLAY_NAME[filterName]
      } ${renderCurrencyString({ priceInCents: Number(filterValue) * 100 })}`;
    case "endBy":
    case "startAfter":
    case "startBefore":
      const relDateFilterString = relativeDateFilterToString(filterValue);

      if (relDateFilterString) {
        return `Starting ${relDateFilterString}`;
      }

      return `${
        SEARCH_FILTER_KEY_TO_DISPLAY_NAME[filterName]
      } ${formatDayWithFullMonth(filterValue)}`;
    case "gradeLevel":
      const splitGradeLevel = filterValue.split(",");
      const sortedGradeLevel = sortByGradeLevel(splitGradeLevel);
      const gradeLevelString = sortedGradeLevel.join(",");

      return gradeLevelString;
    case "capacityMin":
      if (parseInt(filterValue) === 2) {
        return "Group Class";
      }

      return null;
    case "capacityMax":
      if (parseInt(filterValue) === 1) {
        return "1-on-1 Tutoring";
      }

      return null;
    case "age":
      const splitAges = filterValue.split(",");
      splitAges.sort();
      const ageString = splitAges.join(",");
      return `Age ${ageString}`;
    case "theme":
    case "dow":
    case "delivery":
    case "time":
    case "format":
    case "type":
      return filterValue;
    default:
      if (filterName in SEARCH_FILTER_KEY_TO_DISPLAY_NAME) {
        // @ts-ignore
        return `${SEARCH_FILTER_KEY_TO_DISPLAY_NAME[filterName]} ${filterValue}`;
      }
      return null;
  }
}

function sortByGradeLevel(gradeLevelList: string[]) {
  const sortedGradeLevel = gradeLevelList.sort((a, b) => {
    const gradeLevelNumberA = parseInt(a);
    const gradeLevelNumberB = parseInt(b);

    if (isNaN(gradeLevelNumberA)) {
      return -1;
    } else if (isNaN(gradeLevelNumberB)) {
      return 1;
    } else if (gradeLevelNumberA < gradeLevelNumberB) {
      return -1;
    } else if (gradeLevelNumberA > gradeLevelNumberB) {
      return 1;
    } else {
      return 0;
    }
  });

  return sortedGradeLevel;
}
