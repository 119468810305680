import dayjs from "./dayjsExtended";

/**
 * Add some number of days to a Date while keeping the hour constant in regards to DST
 * For example 11/4 @ 10 AM EDT + 1 day = 11/5 10 AM EST.
 *
 * Note: this function is NOT consistent about the duration of the day being added due to DST.
 * The above example would result in a duration of 25 hours between the two dates.
 */
export function addDaysWithConsistentHour(
  date: dayjs.ConfigType,
  timeZone: string,
  days: number
) {
  const dateAsDayjs = dayjs.tz(date, timeZone);
  return (
    dateAsDayjs
      // when .day is larger than 7 it rolls over to the next week
      // same is true in reverse for negative numbers
      .day(dateAsDayjs.day() + days)
      // Use tz method to "reset" TZ (date method doesn't update the offset value)
      .tz(timeZone, true)
  );
}
