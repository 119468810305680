import { gql } from "@outschool/ui-apollo";

import {
  LoginErrorOrResultFragment,
  LoginErrorOrResultFragmentV2,
} from "./LoginErrorOrResultFragment";

export default gql`
  mutation LoginWithGoogleOneTap(
    $idToken: String!
    $createOptions: CreateAccountWithProviderInput
    $osRef: OsRefInput
  ) {
    loginOrCreateAccountWithGoogle(
      canCreateAccount: true
      idToken: $idToken
      createOptions: $createOptions
      osRef: $osRef
    ) {
      ...LoginErrorOrResultFragment
    }
  }
  ${LoginErrorOrResultFragment}
`;

export const loginOrCreateAccountWithGoogleOneTapMutationV2 = gql`
  mutation LoginWithGoogleOneTapV2(
    $idToken: String!
    $createOptions: CreateAccountWithProviderInput
    $osRef: OsRefInput
  ) {
    loginOrCreateAccountWithGoogleV2(
      canCreateAccount: true
      idToken: $idToken
      createOptions: $createOptions
      osRef: $osRef
    ) {
      ...LoginErrorOrResultFragmentV2
    }
  }
  ${LoginErrorOrResultFragmentV2}
`;
