import { Global, css } from "@emotion/react";
import React from "react";

export default function GlobalStyle() {
  return (
    <Global
      styles={css`
        * {
          box-sizing: border-box;
        }

        html {
          font-size: 62.5%;
        }
        body {
          font-size: 1.6rem;
          line-height: 1.5;
          font-weight: normal;
          color: #262626;
          font-family: "Ginto Normal", Verdana, sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        html,
        body {
          margin: 0;
          padding: 0;
          height: 100%;
        }

        p {
          margin: 0;
        }

        a {
          text-decoration: none;
          cursor: pointer;
          color: #4b01d4;
          transition: all ease-in-out 0.05s;

          &:hover:not(:disabled),
          &:focus:not(:disabled) {
            color: #380596;
            text-decoration: underline;
          }

          &:active:not(:disabled) {
            color: #380596;
          }
          &:disabled {
            cursor: default;
          }
        }

        form {
          margin: 0;
        }

        label,
        legend {
          display: block;
          margin-bottom: 0.5rem;
        }

        th,
        td {
          padding: 1rem;
          text-align: left;
        }
        th:first-of-type,
        td:first-of-type {
          padding-left: 0;
        }
        th:last-child,
        td:last-child {
          padding-right: 0;
        }

        hr {
          margin-top: 3rem;
          margin-bottom: 3.5rem;
          border-width: 0;
          border-top: 1px solid #e1e1e1;
        }

        ul {
          padding: 0;
        }

        pre {
          line-height: 1.5;
          font-family: inherit;
          white-space: pre-wrap;
          white-space: -moz-pre-wrap;
          white-space: -pre-wrap;
          white-space: -o-pre-wrap;
          word-wrap: break-word;
          overflow: visible;
        }

        #app-main {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          min-height: 100vh;
          overflow-x: clip;
        }
      `}
    />
  );
}
