import React from "react";

import pkg from "../../lib/pkg";
import { AnalyticsError } from "../../lib/util";
import { IntegrationCategory } from "../../providers/AnalyticsContext";
import useTrackEvent from "../useTrackEvent";

const EVENT_NAME = "Payment Info Entered";

interface PaymentInfoEnteredProperties {
  checkout_id?: string;
  order_id: string;
  payment_method?: string;
  shipping_method?: string;
  step: number;
}

interface TrackPaymentInfoEnteredProperties {
  orderSlugId?: string;
  orderPaymentUid?: string;
  step?: number;
}

export default function usePaymentInfoEntered() {
  const trackAdEvent = useTrackEvent(IntegrationCategory.Advertising);
  const [tracked, setTracked] = React.useState<Array<string>>([]);

  function track({ orderSlugId, step = 0 }: TrackPaymentInfoEnteredProperties) {
    try {
      if (!orderSlugId || tracked.includes(orderSlugId)) {
        return;
      }

      const properties: PaymentInfoEnteredProperties = {
        order_id: orderSlugId,
        step,
      };

      trackAdEvent(EVENT_NAME, properties);
      setTracked([...tracked, orderSlugId]);
    } catch (error) {
      pkg.onError(new AnalyticsError(EVENT_NAME, error));
    }
  }

  return React.useCallback(track, [trackAdEvent, tracked]);
}
