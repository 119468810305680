import { captureError, captureMessage, addErrorContext } from "../captures";
import {
  getContextBaggage,
  updateContextBaggage,
  runWithContextBaggage,
} from "../context";
import { onPlatformInit, onPlatformExit } from "../hooks";
import { runSpan, addSpanAttributes } from "../spans";
import { implementation } from "./implementation";
import * as Env from "../env";

declare global {
  // must be a 'var'
  var OsPlatform: GlobalPlatform;

  // used for namespacing the platform types so they don't have to be imported directly.
  // Not all types need to be listed here, only the ones most likely to be used.
  namespace OsPlatform {
    type OsEnvType = Env.OsEnvType;
    type OsWorkerType = Env.OsWorkerType;
  }
}

export type GlobalPlatform = {
  // shorthand for reading from environment
  readonly envType: Env.OsEnvType;
  readonly isProduction: boolean;
  readonly isStaging: boolean;
  readonly isReview: boolean;
  readonly isTest: boolean;
  readonly isDev: boolean;

  // pass throughs
  readonly osEnvironment: Env.OsEnvironment;
  readonly captureError: typeof captureError;
  readonly captureMessage: typeof captureMessage;
  readonly addErrorContext: typeof addErrorContext;
  readonly getContextBaggage: typeof getContextBaggage;
  readonly updateContextBaggage: typeof updateContextBaggage;
  readonly runWithContextBaggage: typeof runWithContextBaggage;
  readonly onPlatformInit: typeof onPlatformInit;
  readonly onPlatformExit: typeof onPlatformExit;
  readonly runSpan: typeof runSpan;
  readonly addSpanAttributes: typeof addSpanAttributes;
};

export function buildGlobalImpl(): GlobalPlatform {
  return {
    // use getters to accommodate implementation changes
    // in tests
    get osEnvironment() {
      return implementation.environment;
    },
    get envType() {
      return implementation.environment.envType;
    },
    get isProduction() {
      return implementation.environment.envType === "prod";
    },
    get isStaging() {
      return implementation.environment.envType === "stage";
    },
    get isReview() {
      return implementation.environment.envType === "review";
    },
    get isTest() {
      return implementation.environment.envType === "test";
    },
    get isDev() {
      return implementation.environment.envType === "dev";
    },
    captureError,
    captureMessage,
    addErrorContext,
    getContextBaggage,
    updateContextBaggage,
    runWithContextBaggage,
    onPlatformInit,
    onPlatformExit,
    runSpan,
    addSpanAttributes,
  };
}
