import { checkboxClasses, Components, Theme } from "@mui/material";

const CHECKBOX_THEME: Components<Theme>["MuiCheckbox"] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      [`&.${checkboxClasses.checked}`]: {
        color: theme.palette.primary[800],
      },
    }),
  },
};

export default CHECKBOX_THEME;
