import { Box, SxProps } from "@outschool/backpack";
import { loginPath } from "@outschool/routes";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  duplicateEmailInviteMessage,
  duplicateEmailSignupMessage,
} from "@outschool/text";
import {
  UserFacingError,
  cleanErrorMessage,
  isApolloError,
  useLinkComponent,
} from "@outschool/ui-utils";
import React, { PropsWithChildren } from "react";

type Props = Omit<React.ComponentProps<typeof Box>, "value"> & {
  value: any;
  showLogin?: boolean;
  expandedText?: boolean;
  sx?: SxProps;
  showStatusPageLink?: boolean;
};

const ErrorMessage = ({
  value,
  sx,
  showLogin = true,
  expandedText = false,
  showStatusPageLink = false,
  ...props
}: Props) => {
  if (!value) {
    return null;
  }
  let message: any;
  if (isApolloError(value)) {
    message = new UserFacingError(value).toString();
  } else if (React.isValidElement(value)) {
    message = value;
  } else {
    message = value.toString ? cleanErrorMessage(value.toString()) : value;
  }

  const isDuplicateEmailMessage =
    typeof message === "string" &&
    (message.endsWith(duplicateEmailInviteMessage) ||
      message.endsWith(duplicateEmailSignupMessage));
  if (showLogin && isDuplicateEmailMessage) {
    message = <ErrorLink>{message}</ErrorLink>;
  }

  return (
    <Box
      sx={[
        {
          backgroundColor: "#faf2c7",
          color: "#5f4f2a",
          padding: "0.5em 1em",
          border: "1px solid #f6e798",
          marginBottom: "0.5em",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      {expandedText && (
        <Box
          sx={{
            marginBottom: "20px",
          }}
        >
          Sorry! Something went wrong. Please contact support for help.
        </Box>
      )}
      {message}
      {showStatusPageLink && (
        <>
          {" "}
          For more info, see our{" "}
          <a href="https://status.outschool.com/">status page</a>.
        </>
      )}
    </Box>
  );
};

const ErrorLink: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const Link = useLinkComponent();
  return (
    <div>
      {children}. &nbsp;
      <Link to={loginPath()}>Login</Link>
    </div>
  );
};

export default ErrorMessage;
