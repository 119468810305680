import { Avatar } from "@outschool/ui-legacy-component-library";
import React from "react";

import FilestackImage, { FilestackImageProps } from "./FilestackImage";

import type { SystemStyleObject } from "@styled-system/css";

const DEFAULT_PROFILE_IMAGE =
  "https://cdn.filestackcontent.com/26Xc9cgQFmpVTv8h268V";

interface UserHeadshotImageProps
  extends Omit<
    FilestackImageProps<typeof Avatar>,
    "src" | "imageWidth" | "imageHeight"
  > {
  user?: { photo?: string | null } | null;
  size?: number;
  sx?: SystemStyleObject;
}

export default function UserHeadshotImage({
  user,
  size = 125,
  sx,
  ...props
}: UserHeadshotImageProps) {
  const src = user && user.photo ? user.photo : DEFAULT_PROFILE_IMAGE;

  return (
    <FilestackImage
      as={Avatar}
      src={src}
      imageWidth={size}
      imageHeight={size}
      size={size}
      sx={{
        flexShrink: 0,
        ...sx,
      }}
      {...props}
    />
  );
}
