import { I18nLocale, SUPPORTED_LOCALES } from "@outschool/localization";
import isNil from "lodash/isNil";
import omit from "lodash/omit";
import slugify from "slugify";

export interface UrlParams {
  [key: string]: string | undefined | boolean | null | number;
}

export interface ActivitySlugProps {
  slug_id?: string | null;
  title?: string | null;
  is_club?: boolean;
  isClub?: boolean;
  uid: string;
}

export function activitySlug(activity: ActivitySlugProps, noTitle = false) {
  if (activity.slug_id) {
    if (noTitle) {
      return activity.slug_id;
    }
    const title = activity.title ? activity.title.toLowerCase() : "";
    const cleanTitle = slugify(title.replace(/\([^)]*\)/g, ""))
      .replace(/[!'",:.]/g, "")
      .replace(/--/g, "-")
      .replace(/-$/g, "");
    return `${cleanTitle}-${activity.slug_id}`;
  }
  return activity.uid || activity;
}

/**
 *  used for example to add utm params to a url generated by Routes file
 */
export function addParamsToUrl(url: string, params: UrlParams) {
  const urlParams = queryStringToObject(hrefToQueryString(url));
  const newParams = { ...urlParams, ...params };
  const hasParams =
    Object.keys(newParams).filter(k => Boolean(newParams[k])).length > 0;
  if (!hasParams) {
    return url.split("?")[0];
  }
  return url.split("?")[0] + "?" + objectToQueryString(newParams);
}

export interface UtmParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign: string;
}

export function addMissingUtmParams(
  url: string,
  {
    utm_source = "transactional",
    utm_medium = "email",
    utm_campaign,
  }: UtmParams
) {
  const existingUrlParams = queryStringToObject(hrefToQueryString(url));
  const updatedParams = {
    utm_source,
    utm_medium,
    utm_campaign,
    ...existingUrlParams, // let url params to override utm_ params, if any
  };
  return url.split("?")[0] + "?" + objectToQueryString(updatedParams);
}

export function hrefToQueryString(s: string) {
  return (s && s.split("?")[1]) || "";
}

export function objectToQueryString(obj: UrlParams, questionMark?: boolean) {
  return (
    (questionMark ? "?" : "") +
    Object.keys(obj)
      .filter(k => !isNil(obj[k]))
      .map(function (k) {
        var keyValue = encodeURIComponent(k);
        const value = obj[k];
        if (value !== undefined && value !== null) {
          keyValue = keyValue + "=" + encodeURIComponent(value);
        }
        return keyValue;
      })
      .join("&")
  );
}

export function queryStringToObject(qs: string, parseValueType = false) {
  const params: Record<string, any> = {};
  const withoutQuestionMark = qs.startsWith("?") ? qs.slice(1) : qs;
  // filter is used to remove the empty string
  const paramsArray = withoutQuestionMark.split("&").filter(s => s);

  paramsArray.forEach(function (e) {
    const kv = e.split("=");
    const key = decodeURIComponent(kv[0]);
    const value = decodeURIComponent(kv[1]);

    if (parseValueType) {
      let parsedValue;
      if (!isNaN(Number(value))) {
        parsedValue = Number(value);
      } else if (value === "true" || value === "false") {
        parsedValue = value === "true";
      } else {
        parsedValue = value;
      }
      params[key] = parsedValue;
    } else {
      params[key] = value;
    }
  });

  return params;
}

export function fuseQueryParams(
  query1: string | UrlParams | null | undefined,
  query2: string | UrlParams | null | undefined,
  questionMark?: boolean
) {
  const obj1 = typeof query1 == "string" ? queryStringToObject(query1) : query1;
  const obj2 = typeof query2 == "string" ? queryStringToObject(query2) : query2;
  return objectToQueryString({ ...obj1, ...obj2 }, questionMark);
}

/**
 * The shared config object to get the baseUrl for WebsiteRoutes and LearnerRoutes
 */
export interface RouteConfigOptions {
  /**
   * The hostName of the review app. Only required if you want the link to be correct when running in review apps
   */
  appHostName?: string;
  locale?: string;
}

export function getWebsiteHostForEnv(appHostName: string | undefined): string {
  if (OsPlatform.isProduction) {
    return "https://outschool.com";
  } else if (OsPlatform.isStaging) {
    return "https://staging.outschool.dev";
  } else if (appHostName) {
    return `https://${appHostName}`;
  } else {
    return "http://localhost:3000";
  }
}

export function getLearnerHostForEnv(appHostName: string | undefined): string {
  if (OsPlatform.isProduction) {
    return "https://learner.outschool.com";
  } else if (OsPlatform.isStaging) {
    return "https://learner-staging.outschool.dev";
  } else if (appHostName) {
    return `https://${appHostName}`;
  } else {
    return "http://localhost:3001";
  }
}

// See http://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
const UUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export function getIdFromSlug(slug: string) {
  if (UUID_REGEX.test(slug)) {
    return slug;
  } else {
    const tokens = slug.split("-");
    if (tokens.length) {
      return tokens[tokens.length - 1];
    }
    return slug;
  }
}

export interface PathFromLocationArg {
  pathname: string;
  search: string;
}

export function pathFromLocation(location: PathFromLocationArg) {
  return !!location && location.pathname + location.search;
}

export function removeParamsFromUrl(url: string, params: string[]) {
  const urlParams = queryStringToObject(hrefToQueryString(url));
  const filteredParams = omit(urlParams, params);
  const hasParams =
    Object.keys(filteredParams).filter((k: keyof typeof filteredParams) =>
      Boolean(filteredParams[k])
    ).length > 0;
  if (!hasParams) {
    return url.split("?")[0];
  }
  return url.split("?")[0] + "?" + objectToQueryString(filteredParams);
}

export interface UpdateQueryParamLocation {
  pathname: string;
  query: Record<string, string | string[]>;
}
export function updateQueryParam(
  location: UpdateQueryParamLocation,
  key: string,
  value: string
) {
  return {
    pathname: location.pathname,
    query: Object.assign({}, location.query, {
      [key]: value,
    }),
  };
}

export function slugifyTitle(title: string) {
  if (title) {
    return title
      .toLowerCase()
      .replace(/\([^)]*\)/g, "")
      .replace(/[!'",:.&]/g, "")
      .replace(/[\s]/g, "-")
      .replace(/[-]+/g, "-")
      .replace(/-$/g, "");
  } else {
    return null;
  }
}

export function makeLocalePrefix(locale?: I18nLocale | string | null) {
  return locale && locale !== "en" ? `/${locale}` : "";
}

export function pathnameWithoutLocale(locationPathname: string): string {
  for (const locale of SUPPORTED_LOCALES) {
    if (
      locationPathname.startsWith(`/${locale}/`) ||
      locationPathname === `/${locale}`
    ) {
      return locationPathname.replace(`/${locale}`, "");
    }
  }
  return locationPathname;
}

export function urlSearchParamsToObject(
  searchParamString: string
): Record<string, string> {
  const entries = new URLSearchParams(searchParamString).entries();
  const result: Record<string, string> = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}
