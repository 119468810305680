import { SystemStyleObject } from "@styled-system/css";
import { useCallback } from "react";

import useMediaQuery from "./useMediaQuery";

function usePrefersReducedMotion(): boolean {
  // If the browser or OS supports the setting and it is not checked the value
  // will be "no-preference". Otherwise, honor the preference for reduced motion
  // or default to reduced motion for browsers or OSes that don't support the
  // setting.
  const canUseMotion = useMediaQuery("(prefers-reduced-motion: no-preference)");
  return !canUseMotion;
}

type MotionSxFn = (motionSx: SystemStyleObject) => SystemStyleObject;
export function useMotionSx(): MotionSxFn;
export function useMotionSx(inputStyles: SystemStyleObject): SystemStyleObject;
export function useMotionSx(
  sx?: SystemStyleObject
): MotionSxFn | SystemStyleObject {
  const prefersReducedMotion = usePrefersReducedMotion();
  const motionSxFn = useCallback<MotionSxFn>(
    motionSx => (prefersReducedMotion ? {} : motionSx),
    [prefersReducedMotion]
  );

  return sx ? motionSxFn(sx) : motionSxFn;
}

export default usePrefersReducedMotion;
