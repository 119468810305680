// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

import {
  UrlParams,
  fuseQueryParams,
  queryStringToObject,
} from "@outschool/routes";
import { useNavigation } from "@outschool/ui-utils";
import { Location, LocationDescriptor } from "history";
import lodashOmit from "lodash/omit";
import lodashPick from "lodash/pick";
import React from "react";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";

import { useImpressionTracking } from "./tracking";

type LocationState = Record<string, any>;
type LS = LocationState;
type LinkDestination = NavLinkProps<LS>["to"];

export const PERSISTENT_QUERY_PARAMS = ["locale"];

export function addStateToLocation(
  location: LocationDescriptor<LS>,
  newState?: Record<string, any>,
  newQueryParams?: UrlParams
): LocationDescriptor<LS>;
export function addStateToLocation(
  location: (loc: Location<LS>) => LocationDescriptor<LS>,
  newState?: Record<string, any>,
  newQueryParams?: UrlParams
): (loc: Location<LS>) => LocationDescriptor<LS>;
export function addStateToLocation(
  location: LinkDestination,
  newState?: Record<string, any>,
  newQueryParams?: UrlParams
): LinkDestination {
  if (!location) {
    return location;
  }
  if (typeof location === "string") {
    const [pathname, queryParams] = location.split("?");
    const search = newQueryParams
      ? fuseQueryParams(queryParams, newQueryParams)
      : queryParams;
    return {
      pathname,
      state: newState || {},
      ...(search ? { search } : {}),
    };
  }
  if (typeof location === "object") {
    const search = newQueryParams
      ? fuseQueryParams(location.search, newQueryParams)
      : location.search;
    return {
      ...location,
      state: { ...(location.state || {}), ...newState },
      ...(search ? { search } : {}),
    };
  }
  if (typeof location === "function") {
    const locationFn = location as (
      loc: Location<LS>
    ) => LocationDescriptor<LS>;
    const newLocationFunction: (loc: Location<LS>) => LocationDescriptor<LS> = (
      oldLocation: Location<LS>
    ) => {
      return addStateToLocation(
        locationFn(oldLocation),
        newState,
        newQueryParams
      );
    };
    return newLocationFunction;
  }
  return location;
}

function Link(
  {
    children,
    disabled = false,
    linkAlreadyTracked,
    onClick,
    reloadDocument = false,
    sharedProperties = {},
    style,
    target,
    to,
    trackingName,
    trackingTouchEventName,
    trackingUniqueId,
    trackingViewEventName,
    useHardNav = false,
    useLinkTracking = false,
    trackViews,
    ...props
  }: any,
  ref: React.ForwardedRef<unknown>
) {
  // fixes TypeError: Cannot add property openInNewTab, object is not extensible
  sharedProperties = Object.assign({}, sharedProperties);
  if (target && target === "_blank") {
    sharedProperties.openInNewTab = true;
  } else {
    sharedProperties.openInNewTab = false;
  }
  const navigate = useNavigation();
  const { search } = useLocation();
  const queryParams = queryStringToObject(search);

  const impressionNodeRef = React.useRef<HTMLAnchorElement | null>(null);
  React.useImperativeHandle(ref, () => impressionNodeRef.current);

  const { trackTouch, impressionId } = useImpressionTracking({
    node: impressionNodeRef.current,
    trackingLabel: trackingName,
    alreadyTracked: linkAlreadyTracked,
    uniqueId: trackingUniqueId,
    trackingEventName: trackingTouchEventName,
    impressionEventName: trackingViewEventName,
    sharedProperties,
    trackViews,
  });

  const builtTo = React.useMemo(() => {
    const newState =
      trackingName && useLinkTracking
        ? {
            linkTracking: {
              linkType: trackingName,
              linkImpressionId: impressionId,
            },
          }
        : {};
    const newQueryParams = lodashPick(queryParams, PERSISTENT_QUERY_PARAMS);
    return addStateToLocation(to, newState, newQueryParams);
  }, [to, trackingName, impressionId, useLinkTracking, queryParams]);

  return (
    <NavLink
      ref={impressionNodeRef}
      to={builtTo}
      target={target}
      style={style}
      onClick={e => {
        if (useHardNav) {
          e.preventDefault();
          return navigate(to, { hardNav: true });
        } else if (disabled) {
          e.preventDefault();
        } else {
          if (trackingName) {
            trackTouch();
          }
          if (typeof onClick === "function") {
            onClick(e);
          }
          if (reloadDocument) {
            e.metaKey = true;
            return false;
          }
        }
        return true;
      }}
      {...lodashOmit(props, [
        "trackingEventName",
        "impressionEventName",
        "data-testid",
        "ownerState",
      ])}
    >
      {children}
    </NavLink>
  );
}

export default React.forwardRef(Link);
