// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

type Context = {
  topNotice: React.ReactNode;
  timedTopNotice: React.ReactNode;
  setTopNotice(node: React.ReactNode): void;
  setTimedTopNotice(node: React.ReactNode): void;
};

const TopNoticeContext = React.createContext<Context>({
  topNotice: null,
  timedTopNotice: null,
  setTopNotice: () => null,
  setTimedTopNotice: () => null,
});

export const TopNoticeProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [topNotice, setTopNotice] = React.useState<
    React.ReactNode | string | null
  >(null);
  const [timedTopNotice, setTimedTopNotice] = React.useState<
    React.ReactNode | string | null
  >(null);

  return (
    <TopNoticeContext.Provider
      value={{
        topNotice,
        timedTopNotice,
        setTopNotice,
        setTimedTopNotice,
      }}
    >
      {children}
    </TopNoticeContext.Provider>
  );
};

export const useTopNoticeContext = () => {
  return React.useContext(TopNoticeContext);
};

export function withTopNoticeContext(DecoratedComponent: React.ComponentClass) {
  const displayName = DecoratedComponent.displayName || "Component";

  return class TopNoticeContextWrapper extends React.Component {
    static displayName = `TopNoticeContextWrapper(${displayName})`;

    render() {
      return (
        <TopNoticeContext.Consumer>
          {context => <DecoratedComponent {...context} {...this.props} />}
        </TopNoticeContext.Consumer>
      );
    }
  };
}

/**
 * Apollo is throwing this weird exception, fix it.
 */
export function cleanExceptionMessage(err: { message?: string }): string {
  return err.message?.replace("GraphQL error: ", "") || "";
}
