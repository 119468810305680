import React, { useContext } from "react";

export interface LinkProps
  extends React.PropsWithChildren<{}>,
    Record<string, any> {
  to: string;
  target?: string;
  rel?: string;
  isActive?: () => boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  role?: string;
  style?: object;
  trackingName?: string;
  trackingUniqueId?: string;
  sharedProperties?: { [key: string]: string | number };
  navigateQueryParams?: { [key: string]: string };
  exact?: boolean;
  trackViews?: boolean;
  reloadDocument?: boolean;
  trackingViewEventName?: string;
  trackingTouchEventName?: string;
}

const MinimalLink = React.forwardRef(
  (
    {
      to,
      rel,
      target,
      onClick,
      role,
      style,
      children,
      trackViews: _trackViews,
      trackingUniqueId: _trackingUniqueId,
      trackingName: _trackingName,
      isActive: _isActive,
      reloadDocument: _reloadDocument,
      sharedProperties: _sharedProperties,
      trackingViewEventName: _trackingViewEventName,
      trackingTouchEventName: _trackingTouchEventName,
      ...props
    }: LinkProps,
    ref: React.Ref<any>
  ) => {
    return (
      <a
        ref={ref}
        href={to}
        rel={rel}
        target={target}
        onClick={onClick}
        role={role}
        style={style}
        // eslint-disable-next-line i18next/no-literal-string
        {...props}
      >
        {children}
      </a>
    );
  }
);

export type NavigateOptions = {
  hardNav?: boolean;
  newTab?: boolean;
  replace?: boolean;
};

export type NavigateFunction = (
  linkTo: string,
  options?: NavigateOptions
) => void;

type NavigationContextType = {
  linkComponent: React.ComponentType<LinkProps>;
  useNavigation: () => NavigateFunction;
};

const minimalNavigate: NavigateFunction = (to, { newTab, replace } = {}) =>
  /* eslint-disable no-restricted-syntax */
  newTab
    ? window.open(to, "_blank")
    : window.location[replace ? "replace" : "assign"](to);

const NavigationContext = React.createContext<NavigationContextType>({
  linkComponent: MinimalLink,
  useNavigation: () => minimalNavigate,
});

type NavigationProviderProps = React.PropsWithChildren<NavigationContextType>;

export const NavigationProvider = React.memo(
  ({ children, linkComponent, useNavigation }: NavigationProviderProps) => (
    <NavigationContext.Provider value={{ linkComponent, useNavigation }}>
      {children}
    </NavigationContext.Provider>
  )
);

export const useLinkComponent = () =>
  useContext(NavigationContext).linkComponent;

export const useNavigation = () =>
  useContext(NavigationContext).useNavigation();
