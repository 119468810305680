import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import arraySupport from "dayjs/plugin/arraySupport";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayOfYear from "dayjs/plugin/dayOfYear";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isToday from "dayjs/plugin/isToday";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import minMax from "dayjs/plugin/minMax";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import custom from "./dayjsCustomPlugin";
import customTimezonePlugin from "./timezone";

dayjs.locale("en");

dayjs.extend(advancedFormat);
dayjs.extend(arraySupport);
dayjs.extend(customParseFormat);
dayjs.extend(dayOfYear);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isToday);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(minMax);
dayjs.extend(relativeTime);
dayjs.extend(customTimezonePlugin);
dayjs.extend(utc);
dayjs.extend(custom);

export default dayjs;

// Required for type stuff for some reason
export {
  advancedFormat,
  arraySupport,
  customParseFormat,
  dayOfYear,
  duration,
  isBetween,
  isoWeek,
  isSameOrAfter,
  isSameOrBefore,
  isToday,
  localeData,
  localizedFormat,
  minMax,
  relativeTime,
  timezone,
  utc,
  custom,
};
