import { Components, Theme } from "@mui/material";
import { alertClasses } from "@mui/material/Alert";

export const ALERT_THEME: Components<Theme>["MuiAlert"] = {
  styleOverrides: {
    standard: ({ theme }: { theme: Theme }) => ({
      flex: 1,
      fontSize: theme.typography.pxToRem(16),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(14),
      paddingRight: theme.spacing(14),
      borderStyle: "solid",
      borderWidth: "2px",
      borderRadius: theme.spacing(8),
      color: theme.palette.text.primary,
      boxSizing: "border-box",
      [`& .${alertClasses.icon}`]: {
        marginRight: theme.spacing(12),
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
      },
      [`& .${alertClasses.action}`]: {
        flexShrink: 0,
        paddingTop: 0,
      },
      [`& .${alertClasses.message}`]: {
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        overflow: "visible",
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
      },
    }),
    standardError: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.error[700],
      backgroundColor: theme.palette.error[100],
      borderColor: theme.palette.error[500],
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.error[500],
      },
    }),
    standardWarning: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.warning[700],
      backgroundColor: theme.palette.warning[100],
      borderColor: theme.palette.warning[500],
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.warning[500],
      },
    }),
    standardInfo: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.info[700],
      backgroundColor: theme.palette.info[100],
      borderColor: theme.palette.info[500],
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.info[500],
      },
    }),
    standardSuccess: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.success[700],
      backgroundColor: theme.palette.success[100],
      borderColor: theme.palette.success[500],
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.success[500],
      },
    }),
  },
};

export const ALERT_TITLE_THEME: Components<Theme>["MuiAlertTitle"] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      fontSize: theme.typography.pxToRem(18),
    }),
  },
};
