import { SearchFilters } from "@outschool/gql-backend-generated";
import {
  DeleteSavedSearchMutation,
  SaveSearchMutationMutation,
  SavedSearchesQueryQuery,
  SavedSearchesQueryQueryVariables,
} from "@outschool/gql-frontend-generated";
import { ApolloCache, DataProxy, gql } from "@outschool/ui-apollo";
import { validOptimisticResponse } from "@outschool/ui-utils";

const SavedSearchFragment = gql`
  fragment SavedSearchFragment on SavedSearch {
    uid
    createdAt
    filters {
      adminTags
      age
      capacityMax
      capacityMin
      curriculums
      delivery
      dow
      enabledBooleanFilters
      endBy
      endByTime
      englishProficiencyLevel
      format
      fundingPrograms
      gradeLevel
      hasFilledOutUniqueLearningNeeds
      includeInProgressFixedLengthLiveFormat
      languageOfInstruction
      multiTermQuery
      order
      pricePerMeetingMax
      pricePerMeetingMin
      q
      standards
      startAfter
      startAfterTime
      startBefore
      theme
      time
      timeOfDay
      timeZone
      uniqueLearningNeeds
      userName
      userUid
    }
  }
`;

export const SavedSearchesQuery = gql`
  query SavedSearchesQuery {
    currentUser {
      uid
      savedSearches {
        ...SavedSearchFragment
      }
    }
  }
  ${SavedSearchFragment}
`;

export const deleteSavedSearchMutation = gql`
  mutation deleteSavedSearch($uid: ID!) {
    deleteSavedSearch(uid: $uid) {
      deletedSavedSearchUid
    }
  }
`;

export function deleteSavedSearchUpdate(
  store: DataProxy,
  { data: { deleteSavedSearch } }: { data: DeleteSavedSearchMutation }
) {
  const data = store.readQuery<SavedSearchesQueryQuery>({
    query: SavedSearchesQuery,
  });
  if (!data?.currentUser) {
    return;
  }

  const savedSearches = data.currentUser.savedSearches.filter(
    ss => ss.uid !== deleteSavedSearch.deletedSavedSearchUid
  );
  store.writeQuery({
    query: SavedSearchesQuery,
    data: {
      ...data,
      currentUser: { ...data.currentUser, savedSearches },
    },
  });
}
export function deleteSavedSearchOptimisticResponse(savedSearch: {
  uid: string;
}): DeleteSavedSearchMutation {
  return validOptimisticResponse({
    __typename: "Mutation",
    deleteSavedSearch: {
      __typename: "DeleteSavedSearchPayload",
      deletedSavedSearchUid: savedSearch.uid,
    },
  });
}

export const saveSearchMutation = gql`
  mutation SaveSearchMutation($filters: SearchFilters!) {
    saveSearch(filters: $filters) {
      ...SavedSearchFragment
    }
  }
  ${SavedSearchFragment}
`;

export function saveSearchUpdate(
  store: ApolloCache<any>,
  res: { data: SaveSearchMutationMutation }
) {
  const saveSearch = res?.data?.saveSearch;
  const data = store.readQuery<SavedSearchesQueryQuery>({
    query: SavedSearchesQuery,
  });
  if (!data?.currentUser) {
    return;
  }
  const newData: SavedSearchesQueryQuery = {
    ...data,
    currentUser: {
      ...data.currentUser,
      savedSearches: [...(data.currentUser.savedSearches ?? []), saveSearch],
    },
  };
  store.writeQuery<SavedSearchesQueryQuery, SavedSearchesQueryQueryVariables>({
    query: SavedSearchesQuery,
    data: newData,
  });
}

export function saveSearchOptimisticResponse(filters?: SearchFilters) {
  return validOptimisticResponse({
    __typename: "Mutation",
    saveSearch: {
      __typename: "SavedSearch",
      uid: new Date(),
      createdAt: new Date(),
      filters: {
        adminTags: filters?.adminTags || null,
        age: filters?.age || null,
        delivery: filters?.delivery || null,
        dow: filters?.dow || null,
        enabledBooleanFilters: filters?.enabledBooleanFilters || null,
        endBy: filters?.endBy || null,
        endByTime: filters?.endByTime || null,
        englishProficiencyLevel: filters?.englishProficiencyLevel || null,
        format: filters?.format || null,
        fundingPrograms: filters?.fundingPrograms || null,
        gradeLevel: filters?.gradeLevel || null,
        languageOfInstruction: filters?.languageOfInstruction || null,
        multiTermQuery: filters?.multiTermQuery || null,
        order: filters?.order || null,
        pricePerMeetingMax: filters?.pricePerMeetingMax || null,
        pricePerMeetingMin: filters?.pricePerMeetingMin || null,
        q: filters?.q || null,
        startAfter: filters?.startAfter || null,
        startAfterTime: filters?.startAfterTime || null,
        startBefore: filters?.startBefore || null,
        theme: filters?.theme || null,
        time: filters?.time || null,
        timeOfDay: filters?.timeOfDay || null,
        timeZone: filters?.timeZone || null,
        capacityMax: filters?.capacityMax || null,
        capacityMin: filters?.capacityMin || null,
        userUid: filters?.userUid || null,
        userName: filters?.userName || null,
        __typename: "SavedSearchFilters",
      },
    },
  });
}
