const DEFAULT_PROFILE_IMAGE =
  "https://cdn.filestackcontent.com/26Xc9cgQFmpVTv8h268V";
const DEFAULT_IMAGE = "https://www.filepicker.io/api/file/6pKABdZpStOVb1tcTGgA";

type ResizeOptions = {
  height?: number;
  h?: number;
  width?: number;
  w?: number;
  fit?: string;
};

function filestackResizeString(options: ResizeOptions = {}) {
  const unroundedHeight = options.height ?? options.h;
  const unroundedWidth = options.width ?? options.w;
  const height = unroundedHeight ? Math.round(unroundedHeight) : false;
  const width = unroundedWidth ? Math.round(unroundedWidth) : false;

  const defaultResizeOptions = {
    fit: "crop",
  };

  const { w: _w, h: _h, ...optionsWithoutDimensions } = options;
  return Object.entries({
    ...defaultResizeOptions,
    ...optionsWithoutDimensions,
    width,
    height,
  })
    .map(([key, val]) => val && `${key}:${val}`)
    .filter(x => x)
    .sort()
    .join(",");
}

type FilestackImageFormats = "jpg" | "pjpg" | "png" | "svg" | "webp";

export function imageUrl(
  url?: string | null,
  options?: ResizeOptions,
  format: FilestackImageFormats = "jpg"
) {
  let fileStackUrl =
    "https://process.filepicker.io/APHE465sSbqvbOIStdwTyz/rotate=deg:exif";

  if (!url) {
    // This is the default "missing photo" image we show
    url = DEFAULT_IMAGE;
    format = "png";
  }
  if (options && (options.width || options.height || options.w || options.h)) {
    const resize = filestackResizeString(options);
    fileStackUrl += `/resize=${resize}`;
  }

  // strip:true removes metadata information from the photo
  fileStackUrl += `/output=quality:80,compress:true,strip:true,format:${format}/cache=expiry:max/${url}`;

  return fileStackUrl;
}

export function videoThumbnailUrl(
  url: string,
  options: ResizeOptions,
  format: FilestackImageFormats = "jpg"
) {
  const resize = filestackResizeString(options);
  return `https://process.filepicker.io/APHE465sSbqvbOIStdwTyz/rotate=deg:exif/resize=${resize}/modulate=brightness:60/watermark=file:z90DIZ8PQmmN0DTt0LQG,size:60/output=quality:80,compress:true,strip:true,format:${format}/cache=expiry:max/${url}`;
}

// Currently using: 125 x 125 full size, 60x60 in featured cards and header (shown as 30x30)
export function headshotImageUrl(
  url: string | undefined | null,
  size: number = 125
) {
  return url
    ? imageUrl(url, { w: size, h: size, fit: "crop" })
    : imageUrl(DEFAULT_PROFILE_IMAGE, { w: size, h: size, fit: "crop" }, "png");
}

export function filestackPreviewUrl(fileUrl: string) {
  return `https://process.filestackapi.com/APHE465sSbqvbOIStdwTyz/output=format:pdf/${fileUrl}`;
}

export const HOMEPAGE_PREVIEW_IMAGE =
  "https://cdn.filestackcontent.com/gTGLqGaURguESvO9kgxe";
