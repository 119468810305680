export function sortByGradeLevel(gradeLevelList: string[]) {
  const sortedGradeLevel = gradeLevelList.sort((a, b) => {
    const gradeLevelNumberA = parseInt(a);
    const gradeLevelNumberB = parseInt(b);

    if (isNaN(gradeLevelNumberA)) {
      return -1;
    } else if (isNaN(gradeLevelNumberB)) {
      return 1;
    } else if (gradeLevelNumberA < gradeLevelNumberB) {
      return -1;
    } else if (gradeLevelNumberA > gradeLevelNumberB) {
      return 1;
    } else {
      return 0;
    }
  });

  return sortedGradeLevel;
}
