import { useLookupIP } from "@outschool/iplookup-client";

import pkg from "../../lib/pkg";

import type { IPContext } from "@outschool/iplookup-client";

export default function useIpLocation(): IPContext {
  const ipContext = useLookupIP();

  if (!pkg.devMode) {
    return ipContext;
  }

  const context: IPContext = {
    ipInfo: null,
    ipInfoLoaded: false,
    isInCA: pkg.devMode === "ca",
    isInEurozone: pkg.devMode === "eurozone",
    isInGDPR: pkg.devMode === "gdpr",
  };

  if (pkg.devMode === "timeout") {
    return context;
  }

  context.ipInfoLoaded = true;

  if (pkg.devMode === "kr") {
    context.ipInfo = {
      country: {
        isoCode: "KR",
      },
    } as IPContext["ipInfo"];
  }

  return context;
}
