import React from "react";

import Video, { VideoProps } from "./Video";

export type HlsType = typeof import("hls.js");

export type HlsVideoProps = VideoProps & {
  Hls: HlsType;
};

/** Extends `Video` to support streaming via HLS */
export default function HlsVideo({
  src,
  autoPlay,
  muted,
  loop,
  onVideoCanPlay,
  videoRef,
  Hls,
  ...videoProps
}: HlsVideoProps) {
  const internalRef = React.useRef<HTMLVideoElement>(null);
  const myVideoRef = videoRef || internalRef;

  React.useEffect(() => {
    if (!src || !myVideoRef.current) {
      return () => {};
    }

    const video = myVideoRef.current;

    const autoPlayFn = async () => {
      if (autoPlay) {
        try {
          video && (await video.play());
        } catch (error) {
          if (error.name !== "NotAllowedError" && error.name !== "AbortError") {
            throw error;
          }
        }
      }
    };

    // no need for hls.js
    if (
      !src.includes(".m3u8") ||
      video.canPlayType("application/vnd.apple.mpegurl") === "probably" ||
      !Hls.isSupported()
    ) {
      video.src = src;
      video.addEventListener("loadedmetadata", autoPlayFn);
      return () => {
        video.removeEventListener("loadedmetadata", autoPlayFn);
      };
    }
    // HLS.js-specific setup code
    else {
      const hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, autoPlayFn);
      return () => {
        hls.destroy();
      };
    }
  }, [autoPlay, myVideoRef, src, Hls]);

  return (
    <Video
      videoRef={myVideoRef}
      muted={muted}
      playsInline={true}
      loop={loop}
      /* eslint-disable-next-line i18next/no-literal-string */
      preload="auto"
      onVideoCanPlay={onVideoCanPlay}
      {...videoProps}
    />
  );
}
