/**
 * Returns localStorage if the current browser supports localStorage. Returns null otherwise.
 */
export default function isLocalStorageSupported() {
  let key = "outschool.storageTest";
  if (
    typeof window === "undefined" ||
    typeof window.localStorage === "undefined"
  ) {
    return null;
  }
  try {
    if (!window.localStorage) {
      return null;
    }
  } catch (e) {
    // Some browsers, like Mobile Safari, may throw SecurityError exception
    return null;
  }

  try {
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    return window.localStorage;
  } catch (e) {
    return null;
  }
}
