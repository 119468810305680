import { implementation } from "./lib/implementation";

export type AttributeValue =
  | string
  | number
  | boolean
  | undefined
  | Array<string>
  | Array<number>
  | Array<boolean>;
export type OsSpanAttributes = Record<string, AttributeValue>;

/**
 * Adds the given attributes to the current running span. If there is no running span, this
 * function does nothing.
 */
export function addSpanAttributes(opts: OsSpanAttributes): void {
  implementation.addSpanAttributes(opts);
}

/**
 * Starts a span, runs the given action and then ends it when the promise is finished.
 * Errors thrown from the action will be added to the span and rethrown.
 */
export function runSpan<T>(name: string, action: () => Promise<T>): Promise<T>;
/**
 * Starts a span, runs the given action and then ends it when the promise is finished.
 * Errors thrown from the action will be added to the span and rethrown.
 */
export function runSpan<T>(
  name: string,
  opts: OsSpanAttributes,
  action: () => Promise<T>
): Promise<T>;
export function runSpan<T>(name: string, arg1: any, arg2?: any): Promise<T> {
  if (typeof arg1 === "function") {
    return implementation.runSpan(name, undefined, arg1);
  } else {
    return implementation.runSpan(name, arg1, arg2);
  }
}
