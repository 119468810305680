/**
 * The name of a cookie that contains the url to redirect to upon success
 */
export const OAUTH2_ON_SUCCESS = "OAuth2.onSuccess";

/**
 * The name of a cookie that contains the url to redirect to upon error
 */
export const OAUTH2_ON_ERROR = "OAuth2.onError";

/**
 * The name of a cookie that contains the machine-readable login error
 */
export const OAUTH2_ERROR = "OAuth2.error";

/**
 * The name of a cookie that contains whether or not the login can create a user
 */
export const OAUTH2_CAN_CREATE_USER = "OAuth2.canCreateUser";

/**
 * The name of a cookie that contains the options to pass to AuthTasks.CreateAccount
 */
export const OAUTH2_CREATE_OPTIONS = "OAuth2.createOptions";

/**
 * The name of a cookie that contains the session token for login
 */
export const OAUTH2_SESSION_TOKEN = "OAuth2.sessionToken";

/**
 * The name of a cookie that contains a refresh token to get a new session token when it expires
 */
export const OAUTH2_REFRESH_TOKEN = "OAuth2.refreshToken";

/**
 * The name of a cookie that contains whether or not the login created a user
 */
export const OAUTH2_IS_NEW_USER = "OAuth2.isNewUser";

/**
 * The name of the cookie that contains the session token when a user is being impersonated.
 */
export const SWITCH_USER_TOKEN = "switchUserToken";

/**
 * The name of the cookie that contains the refresh token when a user is being impersonated.
 */
export const SWITCH_USER_REFRESH_TOKEN = "switchUserRefreshToken";
