import fetch from "cross-fetch";

import { BASE_LOCALE } from "../constants";
import {
  APP_HOSTNAME,
  CF_ACCESS_CLIENT_ID,
  CF_ACCESS_CLIENT_SECRET,
  isProduction,
  TRANSLATIONS_ENDPOINT,
} from "../Env";

/**
 * Initialize a function for sending text through the Google Translate API.
 * @param locale The locale to use for translating.
 * @param shouldTranslate If falsy, skip sending the request.
 * @returns The translation function
 */
export function getMachineTranslation(
  locale: string | null,
  shouldTranslate: boolean = true
) {
  const sourceLocale = locale || BASE_LOCALE;
  return async (baseText: string | null | undefined) => {
    const text = baseText || "";
    if (!(baseText && shouldTranslate) || sourceLocale === BASE_LOCALE) {
      return text;
    }
    return (
      await googleTranslateLines(text.split("\n"), sourceLocale, BASE_LOCALE)
    ).join("\n");
  };
}

/**
 * Initialize a function for sending text through the Google Translate API.
 * This V2 function requires a source locale
 * @param sourceLocale The source locale to use for translating.
 * @param targetLocale The target locale to use for translating.
 * @param shouldTranslate If falsy, skip sending the request.
 * @returns The translation function
 */
export function getMachineTranslationV2(
  sourceLocale: string,
  targetLocale: string,
  shouldTranslate: boolean = true
) {
  return async (baseText: string | null | undefined) => {
    const text = baseText || "";
    if (!(baseText && shouldTranslate) || sourceLocale === targetLocale) {
      return text;
    }
    return (
      await googleTranslateLines(text.split("\n"), targetLocale, sourceLocale)
    ).join("\n");
  };
}

async function googleTranslateLines(
  text: string[],
  targetLocale: string,
  sourceLocale: string
): Promise<string[]> {
  // The following account for the majority of translation calls, these should be made static or stored alongside source.
  if (text.length === 1) {
    if (text[0] === "English") {
      switch (targetLocale) {
        case "ja":
          return ["英語"];
        case "ko":
          return ["영어"];
        case "zh-TW":
          return ["英語"];
        case "es":
          return ["Inglés"];
      }
    } else if (
      text[0] ===
      "Live online classes that make kids excited to learn. Try academic courses, virtual tutoring, fun clubs, and more. As low as $10 per class."
    ) {
      switch (targetLocale) {
        case "ja":
          return [
            "子どもたちが学習に熱中できるライブ オンライン クラス。アカデミック コース、バーチャル チューター、楽しいクラブなどをお試しください。1 クラスあたりわずか 10 ドルです。",
          ];
        case "ko":
          return [
            "아이들이 학습에 흥미를 갖게 만드는 실시간 온라인 수업입니다. 학업 과정, 가상 개인교습, 재미있는 클럽 등을 이용해 보세요. 수업당 최저 $10.",
          ];
        case "zh-TW":
          return [
            "線上直播課程讓孩子們樂於學習。嘗試學術課程、虛擬輔導、有趣的俱樂部等等。每節課低至 10 美元。",
          ];
        case "es":
          return [
            "Clases en vivo en línea que entusiasman a los niños por aprender. Pruebe cursos académicos, tutorías virtuales, clubes divertidos y más. Tan solo $10 por clase.",
          ];
      }
    }
  }

  const response = await fetch(TRANSLATIONS_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": `application/json; charset="utf-8"`,
      Referer: APP_HOSTNAME
        ? APP_HOSTNAME
        : isProduction
        ? "https://www.outschool.com"
        : "https://staging.outschool.dev",
      ...(CF_ACCESS_CLIENT_ID
        ? { "CF-Access-Client-Id": CF_ACCESS_CLIENT_ID }
        : {}),
      ...(CF_ACCESS_CLIENT_SECRET
        ? { "CF-Access-Client-Secret": CF_ACCESS_CLIENT_SECRET }
        : {}),
    },
    body: JSON.stringify({
      q: text,
      target: targetLocale,
      source: sourceLocale,
    }),
  });
  if (response.status >= 400) {
    const err = new Error(
      `GTranslate CF worker error code: ${response.status}`
    );
    // include roughly the first KB of the response body
    (err as any).body = (await response.text()).slice(0, 1024);
    throw err;
  }
  const { data } = (await response.json()) as any;
  const translatedText = data.translations.map(
    (translation: { translatedText: string }) =>
      cleanUpTranslation(translation.translatedText)
  );
  return translatedText;
}

function cleanUpTranslation(str: string) {
  return str
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, ",")
    .replace(/&amp;/g, "&");
}
