import React from "react";

import { PageAlertProps } from "./PageAlertTypes";

export type PageAlertArgs = PageAlertProps;

type PageAlerts = {
  [id: PageAlertProps["id"]]: PageAlertProps;
};

type PageAlertsContext = {
  pageAlerts: PageAlerts;
  /**
   * Add a PageAlert, which will be scrolled to / animated in.
   * Uniqueness, and thus deduplication and dismissal, is determined by the `id` of the alert.
   * @param alert
   */
  addPageAlert(alert: PageAlertArgs): void;
  /**
   * Completely remove a PageAlert. Will immediately disappear with no animation.
   * @param id
   */
  removePageAlert(id: string): void;
};

const PageAlertsContext = React.createContext<PageAlertsContext>({
  pageAlerts: {},
  addPageAlert: () => null,
  removePageAlert: () => null,
});

const getUniqueId = (id: string) => `os-page-alert-id-${id}`;

const PageAlertsProvider = (props: React.PropsWithChildren<{}>) => {
  const [pageAlerts, setPageAlerts] = React.useState<PageAlerts>({});

  // keep memoized for use in useEffect elsewhere
  const addPageAlert = React.useCallback((alert: PageAlertArgs) => {
    const { id } = alert;
    const uniqueId = getUniqueId(id);
    setPageAlerts(prevAlerts => {
      return {
        ...prevAlerts,
        [uniqueId]: alert,
      };
    });
  }, []);

  // keep memoized for use in useEffect elsewhere
  const removePageAlert = React.useCallback((id: string) => {
    const uniqueId = getUniqueId(id);
    setPageAlerts(prevAlerts => {
      const { [uniqueId]: _alertToRemove, ...rest } = prevAlerts;
      return rest;
    });
  }, []);

  return (
    <PageAlertsContext.Provider
      value={{
        pageAlerts,
        addPageAlert,
        removePageAlert,
      }}
      {...props}
    />
  );
};

export default React.memo(PageAlertsProvider);

export const usePageAlerts = () => React.useContext(PageAlertsContext);
