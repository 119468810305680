import { Theme } from "@mui/material";
import { PickersProComponents } from "@mui/x-date-pickers-pro/themeAugmentation";

export const DATE_RANGE_PICKER_DAY_THEME: PickersProComponents<Theme>["MuiDateRangePickerDay"] =
  {
    styleOverrides: {
      rangeIntervalDayHighlight: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme.palette.primary[50],
      }),
      day: ({ theme }: { theme: Theme }) => ({
        "&.Mui-selected": {
          backgroundColor: theme.palette.primary[700],
          color: "#fff",
        },
        "&.Mui-selected:focus": {
          backgroundColor: theme.palette.primary[700],
          color: "#fff",
        },
        "&:hover": {
          backgroundColor: theme.palette.primary[700],
          color: "#fff",
        },
        "&:focus": {
          backgroundColor: theme.palette.primary[700],
          color: "#fff",
        },
      }),
    },
  };
