import { useEffect, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";

type Dimensions = {
  bottom: null | number;
  height: null | number;
  left: null | number;
  right: null | number;
  top: null | number;
  width: null | number;
  x: null | number;
  y: null | number;
};

export default function useDimensions(node?: HTMLElement | null) {
  const [dimensions, setDimensions] = useState<Dimensions>({
    bottom: null,
    height: null,
    left: null,
    right: null,
    top: null,
    width: null,
    x: null,
    y: null,
  });
  useEffect(() => {
    if (!node) {
      return undefined;
    }

    const observer = new ResizeObserver(() => {
      setDimensions(node.getBoundingClientRect());
    });

    observer.observe(node);

    return () => observer.disconnect();
  }, [node]);

  return dimensions;
}
