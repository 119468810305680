export const gray = {
  0: "#FFFFFF",
  50: "#F7F7F7",
  100: "#EBEBEB",
  200: "#E0E0E0",
  300: "#CCCCCC",
  400: "#A3A3A3",
  500: "#767676",
  600: "#5C5C5C",
  700: "#424242",
  800: "#2E2E2E",
  900: "#1A1a1a",
  950: "#000000",
};

export const peacock = {
  50: "#f0f2ff",
  100: "#E0E5FF",
  200: "#ccd4ff",
  300: "#b4befe",
  400: "#9ea7fc",
  500: "#8188f8",
  600: "#6161f2",
  700: "#4b01d4",
  800: "#380596",
  900: "#23055b",
};

export const waterslide = {
  50: "#E4F0FB",
  100: "#CFE6FB",
  200: "#B4DAFB",
  300: "#95CEFC",
  400: "#70C2FD",
  500: "#19AFFA",
  600: "#007abb",
  700: "#004a77",
  800: "#003457",
  900: "#001f36",
};

export const bubblegum = {
  50: "#FFE8F5",
  100: "#FFDBEF",
  200: "#FFBFE3",
  300: "#FFA6D8",
  400: "#ff80ca",
  500: "#ff43bd",
  600: "#d124a5",
  700: "#7b2169",
  800: "#521c4b",
  900: "#2f122c",
};

export const redhot = {
  50: "#FFF2EE",
  100: "#ffe5dd",
  200: "#ffcab9",
  300: "#ffae95",
  400: "#ff8e72",
  500: "#FF5C33",
  600: "#D32F2F",
  700: "#8f1104",
  800: "#6b0108",
  900: "#430009",
};

export const sunshine = {
  50: "#FFF8E3",
  100: "#FFF2CC",
  200: "#ffecb3",
  300: "#ffe082",
  400: "#ffd54f",
  500: "#FFC619",
  600: "#d07021",
  700: "#a15014",
  800: "#6e320a",
  900: "#3D1C06",
};

export const apple = {
  50: "#eef7ee",
  100: "#ddf0dc",
  200: "#bbe0ba",
  300: "#98d097",
  400: "#74C075",
  500: "#4caf50",
  600: "#368139",
  700: "#225624",
  800: "#18411a",
  900: "#0f2e10",
};
