/**
 * Queried for the most common unsupported time zones and manually mapped them to
 * supported counterparts where necessary. Ordered by most common usage.
 * SELECT location->>'timeZone'
 * FROM users
 * WHERE location->>'timeZone' NOT IN supportedTimeZones
 * GROUP BY location->>'timeZone'
 * ORDER BY count(location->>'timeZone') DESC;
 */
export default {
  "America/Indiana/Indianapolis": "America/Indianapolis",
  "America/Boise": "America/Denver",
  "Asia/Kolkata": "Asia/Calcutta",
  "Africa/Casablanca": "Africa/Algiers",
  "Europe/Belgrade": "Europe/Budapest",
  "Asia/Jerusalem": "Asia/Beirut",
  "America/Costa_Rica": "America/Mexico_City",
  "Canada/Eastern": "America/Toronto",
  "America/Guayaquil": "America/Bogota",
  "America/Port_of_Spain": "America/Antigua",
  "Africa/Harare": "Africa/Johannesburg",
  "Asia/Amman": "Asia/Beirut",
  "Canada/Pacific": "America/Vancouver",
  "Asia/Muscat": "Asia/Dubai",
  "America/St_Thomas": "America/Antigua",
  "Africa/Gaborone": "Africa/Johannesburg",
  "Africa/Tunis": "Africa/Algiers",
  "Australia/Hobart": "Australia/Melbourne",
  "Africa/Kampala": "Africa/Addis_Ababa",
  "Africa/Lusaka": "Africa/Johannesburg",
  "Asia/Phnom_Penh": "Asia/Bangkok",
  "America/Guatemala": "America/Mexico_City",
  "Canada/Mountain": "America/Edmonton",
  "US/Michigan": "US/East-Indiana",
  "Europe/Sofia": "Europe/Athens",
  "Asia/Makassar": "Asia/Brunei",
  "Europe/Tirane": "Europe/Amsterdam",
  "Africa/Dar_es_Salaam": "Africa/Addis_Ababa",
  "Europe/Sarajevo": "Europe/Budapest",
  "Asia/Nicosia": "Asia/Beirut",
  "Asia/Yerevan": "Asia/Dubai",
  "Europe/Skopje": "Europe/Amsterdam",
  "Africa/Windhoek": "Africa/Cairo",
  "America/Tijuana": "America/Los_Angeles",
  EST: "US/Eastern",
  "America/Nassau": "America/Bogota",
  "Europe/Zagreb": "Europe/Amsterdam",
  "America/Monterrey": "America/Mexico_City",
  "America/Managua": "America/Mexico_City",
  "America/Matamoros": "America/Mexico_City",
  "America/Indiana/Vincennes": "America/Indianapolis",
  "America/Dawson_Creek": "America/Denver",
  "Europe/Podgorica": "Europe/Amsterdam",
  "America/Mazatlan": "America/Denver",
  "Europe/Bratislava": "Europe/Amsterdam",
  "America/Fortaleza": "America/Buenos_Aires",
  "Europe/Tallinn": "Europe/Athens",
  "Europe/Vilnius": "Europe/Athens",
  "Europe/Ljubljana": "Europe/Amsterdam",
  "Asia/Baku": "Asia/Dubai",
  "America/La_Paz": "America/Antigua",
  "America/St_Lucia": "America/Antigua",
  CST: "US/Central",
  "Asia/Aden": "Asia/Baghdad",
  "Africa/Maseru": "Africa/Cairo",
  "Canada/Central": "America/Winnipeg",
  "Canada/Atlantic": "America/Halifax",
  "America/Argentina/Salta": "America/Buenos_Aires",
  Hongkong: "Asia/Hong_Kong",
  "Africa/Kigali": "Africa/Cairo",
  "America/Montreal": "America/Toronto",
  "Asia/Almaty": "Asia/Dhaka",
  "Africa/Mbabane": "Africa/Johannesburg",
  "America/Campo_Grande": "America/Antigua",
  "Asia/Krasnoyarsk": "Asia/Bangkok",
  "Europe/Riga": "Europe/Athens",
  GMT: "UTC",
  "Africa/Maputo": "Africa/Cairo",
  "America/Bahia": "America/Buenos_Aires",
  "Europe/Isle_of_Man": "Europe/Dublin",
  Japan: "Asia/Tokyo",
  "Asia/Bishkek": "Asia/Dhaka",
  "America/Indiana/Knox": "America/Indianapolis",
  "Asia/Kuching": "Asia/Brunei",
  "Europe/Guernsey": "Europe/Dublin",
  "Asia/Ho_Chi_Minh": "Asia/Bangkok",
  GB: "Europe/London",
  "America/Moncton": "America/Antigua",
  "Europe/Jersey": "Europe/Dublin",
  "America/Whitehorse": "America/Denver",
  "Africa/Blantyre": "Africa/Cairo",
  "America/Hermosillo": "America/Denver",
  PST8PDT: "America/Los_Angeles",
  "Australia/NSW": "Australia/Melbourne",
  "America/Asuncion": "America/Buenos_Aires",
  "America/Dominica": "America/Antigua",
  "America/Recife": "America/Buenos_Aires",
  "Asia/Thimphu": "Asia/Dhaka",
  "Africa/Abidjan": "Africa/Dakar",
  "Indian/Antananarivo": "Indian/Comoro",
  MST: "America/Denver",
  "America/Grenada": "America/Antigua",
  "America/Grand_Turk": "America/Bogota",
  "America/Curacao": "America/Antigua",
  "Africa/Banjul": "Africa/Dakar",
  "Pacific/Saipan": "Pacific/Guam",
  "America/Tortola": "America/Antigua",
  "America/Port-au-Prince": "America/Bogota",
  MST7MDT: "America/Denver",
  "Africa/Freetown": "Africa/Dakar",
  "America/Anguilla": "America/Antigua",
  "America/St_Vincent": "America/Antigua",
  "Canada/Saskatchewan": "America/Winnipeg",
  "Canada/Newfoundland": "America/Winnipeg",
  "Pacific/Port_Moresby": "Pacific/Guam",
  "Asia/Yekaterinburg": "Asia/Karachi",
  "Mexico/General": "America/Mexico_City",
  "Asia/Damascus": "Asia/Beirut",
  "Africa/Bamako": "Africa/Dakar",
  "Asia/Famagusta": "Asia/Beirut",
  "America/North_Dakota/Center": "America/Mexico_City",
  EST5EDT: "US/Eastern",
  "America/Kentucky/Louisville": "US/Eastern",
  "America/Belem": "America/Buenos_Aires",
  CST6CDT: "US/Central",
  Singapore: "Asia/Brunei",
  "America/St_Kitts": "America/Antigua",
  "Asia/Vladivostok": "Australia/Brisbane",
  NZ: "Pacific/Auckland",
  Turkey: "Europe/Istanbul",
  "America/Kentucky/Monticello": "US/Eastern",
  "America/Indiana/Winamac": "America/Indianapolis",
  "America/Indiana/Marengo": "America/Indianapolis",
  UTC: "UTC",
} as Record<string, string>;
