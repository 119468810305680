import { Box, Icon, SxProps } from "@outschool/backpack";
import { IconDefinition, faCheck } from "@outschool/icons";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import FormFieldError from "./FormFieldError";
import jsxPragma from "./jsxPragma";
import LegacyFlex from "./LegacyFlex";

const InputWithSx = ({ checkboxRef, ...props }: any) =>
  jsxPragma((props: any) => <input {...props} ref={checkboxRef} />, props);
const LabelWithSx = (props: any) => jsxPragma("label", props);

type CheckboxProps = {
  label?: JSX.Element | string;
  name?: string;
  id?: string;
  errorMessage?: string;
  checked?: boolean;
  value?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: React.MouseEventHandler<HTMLElement>; // This will override onChange
  sx?: SystemStyleObject;
  iconSx?: SxProps;
  disabled?: boolean;
  icon?: IconDefinition;
  checkboxRef?: React.Ref<HTMLInputElement>;
  /**
   * font-size of the icon, with units
   */
  iconSize?: string;
  iconHidden?: boolean;
};

const Checkbox = React.forwardRef(
  (
    {
      label = "",
      name = "checkbox",
      id = "",
      checked = false,
      errorMessage,
      value = "",
      required = false,
      onChange = () => {},
      onBlur = () => {},
      onClick = () => {},
      sx = {},
      iconSx = {},
      disabled = false,
      icon = faCheck,
      iconSize = "1.333em",
      iconHidden = false,
      checkboxRef,
    }: CheckboxProps,
    ref: React.Ref<unknown>
  ) => {
    return (
      <LegacyFlex
        sx={{ flexFlow: "column", ...sx }}
        onClick={onClick as any}
        ref={ref}
      >
        <LegacyFlex sx={{ flexFlow: "row" }}>
          <InputWithSx
            id={id || name}
            name={name}
            type="checkbox"
            value={value}
            required={required}
            onChange={onChange}
            onBlur={onBlur}
            checked={checked}
            disabled={disabled}
            checkboxRef={checkboxRef}
            // @ts-ignore
            sx={{
              opacity: 0,
              width: 0,
              "&:focus-visible + label svg": {
                borderColor: "primary",
                outline: "2px solid",
                outlineOffset: "2px",
                outlineColor: "peacock700",
              },
            }}
          />

          <LabelWithSx
            htmlFor={id || name}
            // @ts-ignore
            sx={{
              position: "relative",
              display: "inline-flex",
              flexDirection: "row",
              fontWeight: "normal",
              margin: 0,
              userSelect: "none",
              alignItems: "center",
              cursor: disabled ? "not-allowed" : "pointer",
            }}
          >
            {!iconHidden && (
              <Icon
                icon={icon}
                sx={{
                  fontSize: iconSize,
                  flexShrink: 0,
                  display: "inline-block",
                  padding: "0.25rem",
                  marginRight: "0.75rem",
                  backgroundColor: "common.white",
                  color: "common.white",
                  border: "1px solid",
                  borderColor: "grey.500",
                  borderRadius: 4,
                  textAlign: "center",
                  ...(checked && {
                    backgroundColor: "primary.main",
                    borderColor: "primary.main",
                    ...(disabled && {
                      backgroundColor: "grey.400",
                      borderColor: "grey.300",
                    }),
                  }),
                  ...iconSx,
                }}
              />
            )}
            <Box>{label}</Box>
          </LabelWithSx>
        </LegacyFlex>
        <FormFieldError>{errorMessage}</FormFieldError>
      </LegacyFlex>
    );
  }
);

export type FormCheckboxProps = {
  label?: JSX.Element | string;
  id?: string;
  name?: string;
  errorMessage?: string;
  value?: boolean;
  required?: boolean;
  onChange?: (k: boolean) => void;
  onBlur?: (e: any) => void;
  disabled?: boolean;
  checkboxRef?: React.Ref<HTMLInputElement>;
};

export const FormCheckbox = React.forwardRef(
  (
    { value, onChange = () => {}, ...props }: FormCheckboxProps,
    ref: React.Ref<unknown>
  ) => (
    <Checkbox
      ref={ref}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange(e.currentTarget.checked)
      }
      checked={value}
      {...props}
    />
  )
);

export default Checkbox;
