/**
 * Followed implementation:
 *
 * https://partner.affiliates.one/advertisers/mkt_sites/gtm_doc?mkt_site_id=2778
 *
 */

import { injectScript } from "../lib/util";
import {
  Integration,
  IntegrationCategory,
} from "../providers/AnalyticsContext";
import { AnalyticsPlugin, AnalyticsPluginType } from ".";

import type { Context } from "../types";
import pkg from "../lib/pkg";

declare global {
  interface Window {
    VA?: {
      remoteLoad: (obj: any) => void;
    };
  }
}

const whiteLabel = { id: 8, siteId: "2778", domain: "t.adotone.com" };

export default class AdotOnePlugin implements AnalyticsPlugin {
  name = Integration.AdotOne;
  category = IntegrationCategory.Advertising;
  type = AnalyticsPluginType.destination;
  version = "0.1.0";

  scriptId = "adotone";
  scriptSrc = "https://cdn.adotone.com/javascripts/va.js";

  isLoadable() {
    return pkg.isProduction;
  }

  async load(): Promise<void> {
    await injectScript(this.scriptId, this.scriptSrc);
    window.VA?.remoteLoad({
      whiteLabel,
      locale: "en-US",
      mkt: true,
    });
  }

  async unload(): Promise<void> {
    const script = document.getElementById(this.scriptId);
    script?.remove();
  }

  isLoaded() {
    if (typeof window === "undefined") {
      return false;
    }
    return !!document.getElementById(this.scriptId) && !!window.VA;
  }

  async track(context: Context) {
    if (context.event.event !== "Order Completed") {
      return context;
    }

    const total = context.event?.properties?.total;
    const orderId = context.event?.properties?.order_id;
    const firstTimeBuyer = context.event?.properties?.is_first_purchase;

    if (!firstTimeBuyer) {
      return context;
    }

    window.VA?.remoteLoad({
      whiteLabel,
      conversion: true,
      conversionData: {
        step: "sale",
        order: orderId,
        orderTotal: total,
      },
      locale: "en-US",
      mkt: true,
    });

    return context;
  }
}
