import { OsEnvironment } from "../env";
import { expandAlarmSettings } from "../lib/expand-alarm-settings";

/**
 * Returns a base set of properties for initializing sentry.
 */
export function getSentryInitBase(env: OsEnvironment) {
  return {
    environment: env.appEnv,
    release: env.gitCommit,
    initialScope: expandAlarmSettings({
      tags: {
        appName: env.appName,
        appWorker: env.appWorker,
        workerType: env.workerType,
      },
      component: env.alarmComponent,
    }),
  };
}
