import { Box, Icon, Typography } from "@outschool/backpack";
import { fasExclamationTriangle } from "@outschool/icons";
import { UserFacingError, isApolloError } from "@outschool/ui-utils";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

type FieldErrorMessageProps = {
  value: string | null;
};

export default function FieldErrorMessage({ value }: FieldErrorMessageProps) {
  if (!value) {
    return null;
  }
  let message;
  if (isApolloError(value)) {
    message = new UserFacingError(value).toString();
  } else if (React.isValidElement(value)) {
    message = value;
  } else {
    message = value.toString ? value.toString() : value;
  }

  return (
    <Box
      sx={{
        marginTop: "0.25em",
        fontSize: "0.75em",
        lineHeight: "14px",
        height: "14px",
        color: "error.500",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Icon icon={fasExclamationTriangle} />
      <Typography
        variant="inherit"
        role="alert"
        sx={{
          marginLeft: "0.5em",
        }}
      >
        {message}
      </Typography>
    </Box>
  );
}
