import { Components } from "@mui/material";

const ICON_THEME: Components["MuiSvgIcon"] = {
  defaultProps: {
    fontSize: "inherit",
  },
  styleOverrides: {
    root: {
      // Mimic FontAwesome vertical alignment
      verticalAlign: "-0.125em",
    },
  },
};

export default ICON_THEME;
