/**
 * This represents different priority levels for OpsGenie
 *
 * Based on {@link https://support.atlassian.com/opsgenie/docs/what-are-incident-priority-levels/ OpsGenie's documentation}
 */
export enum Priority {
  /**
   * An important system is unavailable.  The highest level of priority.
   */
  P1 = "P1",
  /**
   * An important system is severely degraded. The second highest level of priority.
   */
  P2 = "P2",
  /**
   * A system has a possible issue.  The default priority level of priority.
   */
  P3 = "P3",
  /**
   * A system has an unimportant issue.  The second lowest level of priority.
   */
  P4 = "P4",
  /**
   * Informational issue.  The lowest level of priority.
   */
  P5 = "P5",
}
