import { Components, Theme } from "@mui/material";

export const MULTI_SECTION_DIGITAL_CLOCK_SECTION_THEME: Components<Theme>["MuiMultiSectionDigitalClockSection"] =
  {
    styleOverrides: {
      item: ({ theme }: { theme: Theme }) => ({
        "&.Mui-selected": {
          color: "inherit",
          backgroundColor: theme.palette.primary[100],
          "&:hover": {
            backgroundColor: theme.palette.primary[200],
          },
          "&:focus": {
            backgroundColor: theme.palette.primary[300],
          },
        },
      }),
    },
  };
