export type ExperimentsConfig = {
  GIT_COMMIT_SHA: string;
  OUTSCHOOL_ENV: string;
  KAFKA_REST_PROXY: string;
  CLIENT_VERSION?: number;
  SEGMENT_WRITE_KEY?: string;
};

type InternalPackageState = ExperimentsConfig & {
  initialized: boolean;
  isDev?: boolean;
};

const pkg: InternalPackageState = {
  initialized: false,
  GIT_COMMIT_SHA: "",
  OUTSCHOOL_ENV: "",
  KAFKA_REST_PROXY: "",
};

export function setPackageState(config: ExperimentsConfig) {
  pkg.GIT_COMMIT_SHA = config.GIT_COMMIT_SHA;
  pkg.OUTSCHOOL_ENV = config.OUTSCHOOL_ENV;
  pkg.KAFKA_REST_PROXY = config.KAFKA_REST_PROXY;
  pkg.isDev = pkg.OUTSCHOOL_ENV !== "production";

  pkg.initialized = true;

  if (pkg.isDev) {
    console.log("State:", pkg);
  }
}

export default pkg;
