import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  styled,
} from "@mui/material";
import { IconDefinition } from "@outschool/icons";
import React from "react";

import Icon from "../Icon/Icon";

export { iconButtonClasses } from "@mui/material";

export const IconButtonSizes = ["small", "medium", "large"] as const;
export type IconButtonSize = (typeof IconButtonSizes)[number];

export const IconButtonColors = [
  "primary",
  "secondary",
  "success",
  "error",
  "info",
  "warning",
  "neutral",
] as const;
export type IconButtonColor = (typeof IconButtonColors)[number];

export const IconButtonVariants = ["outlined", "contained", "text"] as const;
export type IconButtonVariant = (typeof IconButtonVariants)[number];

export const IconButtonEdges = ["start", "end", false] as const;
export type IconButtonEdge = (typeof IconButtonEdges)[number];

// Add "neutral", disable "inherit" and "default" colors
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    neutral: true;
    inherit: false;
    default: false;
  }
}

export type IconButtonProps<C extends React.ElementType = "button"> =
  MuiIconButtonProps<C, { component?: C }> & {
    /**
     * Style of the button.
     * @default "outlined"
     */
    variant?: IconButtonVariant;
    /**
     * Icon to use as the button content.
     */
    icon: IconDefinition;
  };

const StyledIconButton = styled<IconButtonProps>(MuiIconButton, {
  shouldForwardProp: (prop: string) => prop !== "variant",
  name: "MuiIconButton",
})({});

const _IconButton = <C extends React.ElementType>(
  // Specify default props governed by IconButton theme "variants".
  // Custom props don't play well with variants.
  {
    variant = "outlined",
    color = "primary",
    size = "medium",
    icon,
    ...props
  }: IconButtonProps<C>,
  ref: React.ForwardedRef<Element>
) => {
  return (
    <StyledIconButton
      {...props}
      variant={variant}
      color={color}
      size={size}
      ref={ref}
      aria-label={props["aria-label"]}
    >
      <Icon icon={icon} color="inherit" size="inherit" />
    </StyledIconButton>
  );
};

export const IconButton = React.forwardRef(_IconButton);
