import React from "react";

const ClientVersionContext = React.createContext<
  React.MutableRefObject<number | undefined>
>({ current: undefined });

interface ClientVersionProviderProps {
  initialClientVersion: number;
}

export const ClientVersionProvider = ({
  initialClientVersion,
  ...props
}: React.PropsWithChildren<ClientVersionProviderProps>) => (
  <ClientVersionContext.Provider
    value={React.useRef(initialClientVersion)}
    {...props}
  />
);

/**
 * @returns {React.MutableRefObject<number | undefined>} Current expected client version in a ref
 */
export const useClientVersion = () => {
  const context = React.useContext(ClientVersionContext);
  if (!context) {
    throw new Error(
      "useClientVersion must be used within a ClientVersionProvider"
    );
  }
  return context;
};
