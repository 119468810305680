import type { EventOptions, EventProperties, SendPageEvent } from "../types";
import type { IntegrationCategory } from "../providers/AnalyticsContext";

import React from "react";

import { cloneEventProperties } from "../lib/util";
import {
  enqueueEvent,
  getMergedEventOptions,
  isOff,
  isReady,
  useAnalyticsContext,
} from "../providers/AnalyticsContext";

export default function usePageEvent(
  category?: IntegrationCategory
): SendPageEvent {
  const { status } = useAnalyticsContext();

  function page(
    pageCategory?: string,
    pageName?: string,
    properties?: EventProperties,
    options?: EventOptions,
    callback = () => {}
  ) {
    if (isOff(status)) {
      callback();
      return;
    }

    const args: [string?, string?] = [];

    if (!!pageCategory) {
      args.push(pageCategory);
    }

    if (!!pageName) {
      args.push(pageName);
    }

    const clonedProperties = cloneEventProperties(properties);

    if (isReady(status)) {
      const eventOptions = getMergedEventOptions(options, category);
      window.analytics.page(...args, clonedProperties, eventOptions, callback);
      return;
    }

    clonedProperties.enqueuedAt = new Date().toISOString();

    enqueueEvent(
      ["page", ...args, clonedProperties, options, callback],
      category
    );
  }

  return React.useCallback(page, [category, status]);
}
