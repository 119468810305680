import { CookieKeys } from "@outschool/data-schemas";
import Cookies from "js-cookie";
import React from "react";
import { v4 as uuid } from "uuid";

export type PageContext = {
  ready: boolean;
  pageName?: string;
  pageCategory?: string;
  pageImpressionId: string;
  pageLoadId?: string;
  pagePath: string;
};

interface PageInfo {
  name?: string;
  category?: string;
  path?: string;
}

const Context = React.createContext<PageContext | undefined>(undefined);

export function PageContextProvider({
  children,
  pageInfo = {},
}: {
  children: React.ReactElement;
  pageInfo?: PageInfo;
}) {
  const [currentPath, setCurrentPath] = React.useState<string>();
  const [currentPageName, setCurrentPageName] = React.useState<string>();
  const [pageImpressionId, setPageImpressionId] = React.useState<string>("");
  const pageLoadId = React.useRef(Cookies.get(CookieKeys.OsPageLoadId));
  const { category, name, path } = pageInfo;

  React.useEffect(() => {
    if (!path) {
      return;
    }

    if (!!currentPageName && !!name && currentPageName === name) {
      return;
    }

    if (currentPath !== path) {
      setPageImpressionId(uuid());
      setCurrentPath(path);
      setCurrentPageName(name);
    }
  }, [
    currentPath,
    pageImpressionId,
    path,
    currentPageName,
    name,
    setCurrentPageName,
  ]);

  const value: PageContext = {
    ready: !!path && !!pageImpressionId,
    pageCategory: category,
    pageImpressionId,
    pageLoadId: pageLoadId.current,
    pageName: name,
    pagePath: path || "",
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function usePageContext(): PageContext {
  const context = React.useContext(Context);

  if (context === undefined) {
    throw new Error("usePageContext called outside of a PageContextProvider");
  }

  return context;
}
