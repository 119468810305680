export const makeUrl = (loc: Location) => loc.pathname + loc.search + loc.hash;

export function hrefToQueryString(s: string): string {
  return (s && s.split("?")[1]) || "";
}

export function queryStringToObject(qs: string): Record<string, string> {
  const params: Record<string, string> = {};
  // filter is used to remove the empty string
  const paramsArray = qs.split("&").filter(s => s);
  paramsArray.forEach(function (e) {
    const kv = e.split("=");
    params[decodeURIComponent(kv[0])] = decodeURIComponent(kv[1]);
  });

  return params;
}

enum QUERY_STRING_REDIRECT_KEYS {
  postLoginPath = "postLoginPath",
  learnerAppUrl = "learnerAppUrl",
}

export function findQueryString(href: string): string {
  let search: string;

  try {
    const url = new URL(href);
    search = url.search;
  } catch {
    search = href || "";
  }

  const queryString = hrefToQueryString(search);

  if (!queryString) {
    return "";
  }

  const params = new URLSearchParams(queryString);
  const redirectParams = findEncodedRedirectParams(params);

  if (!redirectParams) {
    return params.toString();
  }

  return mergeParams(params, redirectParams).toString();
}

export function findEncodedRedirectParams(
  params: URLSearchParams
): URLSearchParams | null {
  let queryString = "";

  if (params.has(QUERY_STRING_REDIRECT_KEYS.postLoginPath)) {
    queryString = findQueryString(
      params.get(QUERY_STRING_REDIRECT_KEYS.postLoginPath) || ""
    );
  } else if (params.has(QUERY_STRING_REDIRECT_KEYS.learnerAppUrl)) {
    queryString = findQueryString(
      params.get(QUERY_STRING_REDIRECT_KEYS.learnerAppUrl) || ""
    );
  }

  if (!queryString) {
    return null;
  }

  return new URLSearchParams(queryString);
}

export function mergeParams(
  params: URLSearchParams,
  fallback: URLSearchParams | null
): URLSearchParams {
  if (!fallback) {
    return params;
  }

  for (const [key, value] of fallback.entries()) {
    if (!params.has(key)) {
      params.set(key, value);
    }
  }

  return params;
}
