import {
  SavedSearchesQueryQuery,
  SavedSearchesQueryQueryVariables,
} from "@outschool/gql-frontend-generated";
import { useQueryWithPreviousData } from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import React from "react";

import { SavedSearchesQuery } from "./graphql";

export function useSavedSearches() {
  const { isLoggedIn } = useSession();
  const { loading, error, data, refetch } = useQueryWithPreviousData<
    SavedSearchesQueryQuery,
    SavedSearchesQueryQueryVariables
  >(SavedSearchesQuery, {
    skip: !isLoggedIn,
  });

  const savedSearches = React.useMemo(() => {
    return data?.currentUser?.savedSearches || [];
  }, [data?.currentUser?.savedSearches]);

  return {
    loading,
    error,
    savedSearches,
    refetch,
  };
}

export function useSavedSearchesCount() {
  const { savedSearches } = useSavedSearches();
  const uidHash = savedSearches.map(sa => sa.uid).join(" ");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => savedSearches.length, [uidHash]);
}
