import React from "react";

import { Theme, useTheme } from "../../theme";
import { BaseImage } from "./BaseImage";
import { BaseImageProps } from "./types";

const Image = <AddtlProps = {},>(props: BaseImageProps<AddtlProps>) => {
  const { components } = useTheme() || ({} as Theme);
  const { MuiImage } = components || ({} as NonNullable<Theme["components"]>);
  const { BaseComponent = BaseImage } =
    MuiImage ||
    ({} as NonNullable<NonNullable<Theme["components"]>["MuiImage"]>);

  return <BaseComponent {...props} />;
};

export type ImageProps = BaseImageProps<{}>;

// Purposefully does not use forwardRef
// NextImage on SSR does not support ref, so make it consistent
export default Image;
