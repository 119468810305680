import { useMemo } from "react";

import useWindowReference from "./useWindowReference";

const testKey = "outschool.sessionStorageTest";

const useSessionStorageReference = () => {
  const { window } = useWindowReference();

  const canUseSessionStorage = useMemo(() => {
    if (!window) {
      return false;
    }
    try {
      window.sessionStorage.setItem(testKey, testKey);
      window.sessionStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }, [window]);

  return {
    canUseSessionStorage,
    sessionStorage: canUseSessionStorage ? window!.sessionStorage : undefined,
  };
};

export default useSessionStorageReference;
