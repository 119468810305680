import React from "react";
import { I18nextProvider, UseTranslationOptions } from "react-i18next";

import { BASE_LOCALE } from "../constants";
import { isTest } from "../Env";
import { I18nType, TFunction } from "./types";
import { UseMachineTranslationType } from "./useMachineTranslationFactory";
import { createUseTranslationHook } from "./useTranslationFactory";

type UseTranslationFunction = ReturnType<typeof createUseTranslationHook>;
type UseTranslationParameters = Parameters<UseTranslationFunction>;

const UseTranslationContext = React.createContext<UseTranslationFunction>(
  (...args: UseTranslationParameters) => {
    if (!isTest) {
      throw new Error(
        "TranslationsProvider not found. Please ensure your component calling useTranslation is inside TranslationsProvider."
      );
    }

    // Return default useTranslation hook which should only get called in tests.
    return createUseTranslationHook(() => false)(...args);
  }
);

const UseMachineTranslationContext =
  React.createContext<UseMachineTranslationType>(
    (..._args: Parameters<UseMachineTranslationType>) => {
      if (!isTest) {
        throw new Error(
          "TranslationsProvider not found. Please ensure your component calling useTranslation is inside TranslationsProvider."
        );
      }

      // Return default useTranslation hook which should only get called in tests.
      return {
        translate: (baseText: string, _lookupName: any) => baseText,
        locale: BASE_LOCALE,
      };
    }
  );

export const TranslationsProvider = React.memo(
  ({
    i18n,
    useTranslation,
    useMachineTranslation,
    children,
  }: React.PropsWithChildren<{
    i18n: I18nType;
    useTranslation: UseTranslationFunction;
    useMachineTranslation: UseMachineTranslationType;
  }>) => {
    return (
      <I18nextProvider i18n={i18n}>
        <UseTranslationContext.Provider value={useTranslation}>
          <UseMachineTranslationContext.Provider value={useMachineTranslation}>
            {children}
          </UseMachineTranslationContext.Provider>
        </UseTranslationContext.Provider>
      </I18nextProvider>
    );
  }
);

TranslationsProvider.displayName = "TranslationsProvider";

export const useTranslation = (...args: UseTranslationParameters) =>
  React.useContext(UseTranslationContext)(...args);

export const useMachineTranslation = (
  ...args: Parameters<UseMachineTranslationType>
) => React.useContext(UseMachineTranslationContext)(...args);

interface WithTranslationProps {
  i18n: I18nType;
  translationReady: boolean;
  t: TFunction;
}

export function withTranslation<T>(
  namespace: string,
  options?: UseTranslationOptions
) {
  return (WrappedComponent: React.ComponentType<T & WithTranslationProps>) =>
    (props: T) => {
      const {
        t,
        i18n,
        ready: translationReady,
        // eslint-disable-next-line local-rules/i18next-use-single-namespace-in-file, local-rules/i18next-use-appropriate-namespace
      } = useTranslation(namespace, options);

      return (
        <WrappedComponent
          t={t}
          i18n={i18n}
          translationReady={translationReady}
          {...(props as T)}
        />
      );
    };
}
