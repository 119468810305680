import { Icon, useTheme } from "@outschool/backpack";
import { faChevronLeft, faChevronRight } from "@outschool/icons";
import { useDimensions, useOnScreen } from "@outschool/ui-utils";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import { LegacyBox } from "./LegacyBox";
import LegacyButton from "./LegacyButton/LegacyButton";
import LegacyFlex from "./LegacyFlex";

const SCROLL_ARROW_VARIANT_STYLES = {
  left: {
    left: "-1px",
    maskImage: "linear-gradient(to left, transparent 0%, black 30%);",
  },
  right: {
    right: "-1px",
    maskImage: "linear-gradient(to right, transparent 0%, black 30%);",
  },
};

function ScrollArrow({
  variant,
  onClick = () => {},
  sx = {},
  arrowClickOnBox = false,
  useSmallArrow = false,
}: {
  variant: "left" | "right";
  onClick: (e: React.SyntheticEvent) => void;
  sx?: SystemStyleObject;
  arrowClickOnBox?: boolean;
  useSmallArrow?: boolean;
}) {
  return (
    <LegacyFlex
      sx={{
        position: "absolute",
        top: 0,
        zIndex: 1,
        width: useSmallArrow ? 50 : 68,
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        ...SCROLL_ARROW_VARIANT_STYLES[variant],
        ...sx,
      }}
      role="button"
      onClick={arrowClickOnBox ? onClick : () => {}}
    >
      <LegacyButton
        color="secondary"
        round
        onClick={arrowClickOnBox ? () => {} : onClick}
        size={useSmallArrow ? "small" : "medium"}
      >
        <Icon icon={variant === "left" ? faChevronLeft : faChevronRight} />
      </LegacyButton>
    </LegacyFlex>
  );
}

export type CarouselProps = {
  children: JSX.Element[] | JSX.Element;
  innerSx?: SystemStyleObject;
  sx?: SystemStyleObject;
  arrowSx?: SystemStyleObject;
  arrowClickOnBox?: boolean;
  scrollOnMobile?: boolean;
  leftAlign?: boolean;
  useSmallArrow?: boolean;
};

function Carousel(
  {
    children,
    innerSx,
    sx,
    arrowClickOnBox,
    arrowSx,
    scrollOnMobile = false,
    leftAlign = false,
    useSmallArrow = false,
  }: CarouselProps,
  ref: React.Ref<HTMLDivElement>
) {
  const theme = useTheme();

  const [containerNode, setContainerNode] = React.useState<HTMLElement | null>(
    null
  );
  const [cardContainerNode, setCardContainerNode] =
    React.useState<HTMLElement | null>(null);
  const [leftScrollNode, setLeftScrollNode] =
    React.useState<HTMLElement | null>(null);
  const [rightScrollNode, setRightScrollNode] =
    React.useState<HTMLElement | null>(null);

  const { width: containerWidth } = useDimensions(containerNode);
  const isCompletelyScrolledLeft = useOnScreen(leftScrollNode);
  const isCompletelyScrolledRight = useOnScreen(rightScrollNode);

  arrowSx = {
    // @ts-ignore
    color: sx?.color ?? "gray4",
    // @ts-ignore
    backgroundColor: sx?.backgroundColor ?? "white",
    ...arrowSx,
  };

  let slideTimer: NodeJS.Timeout | null = null;

  const scrollTimer = (
    element: HTMLElement,
    distance: number | null,
    direction: "left" | "right"
  ) => {
    const slideDist = distance ? (Math.floor(distance / 250) - 1) * 250 : null;
    let scrollAmount = 0;
    if (slideTimer) {
      clearInterval(slideTimer);
    }
    slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= 10;
      } else {
        element.scrollLeft += 10;
      }
      scrollAmount += 10;
      if (slideDist == null || scrollAmount >= slideDist) {
        clearInterval(slideTimer!);
        slideTimer = null;
      }
    }, 7);
  };

  return (
    <LegacyBox ref={ref} sx={sx}>
      <LegacyBox ref={setContainerNode} sx={{ position: "relative" }}>
        {!isCompletelyScrolledLeft && (
          <ScrollArrow
            variant="left"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              scrollTimer(cardContainerNode!, containerWidth, "left");
            }}
            sx={{
              [theme.breakpoints.down("md")]: {
                display: scrollOnMobile ? "" : "none",
              },
              ...arrowSx,
            }}
            arrowClickOnBox={arrowClickOnBox}
            useSmallArrow={useSmallArrow}
          />
        )}
        <LegacyFlex
          ref={setCardContainerNode}
          sx={{
            justifyContent: "flex-start",
            overflowX: "auto",
            scrollbarWidth: "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <LegacyBox
            ref={setLeftScrollNode}
            sx={{ width: "1px", flexShrink: 0 }}
          />
          <LegacyFlex
            sx={{
              flexGrow: 1,
              flexShrink: 0,
              flexBasis: 0,
              justifyContent: leftAlign ? "start" : "center",
              ...innerSx,
            }}
          >
            {children}
          </LegacyFlex>
          <LegacyBox
            ref={setRightScrollNode}
            sx={{ width: "1px", flexShrink: 0 }}
          />
        </LegacyFlex>
        {!isCompletelyScrolledRight && (
          <ScrollArrow
            variant="right"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              scrollTimer(cardContainerNode!, containerWidth, "right");
            }}
            arrowClickOnBox={arrowClickOnBox}
            sx={{
              [theme.breakpoints.down("md")]: {
                display: scrollOnMobile ? "" : "none",
              },
              ...arrowSx,
            }}
            useSmallArrow={useSmallArrow}
          />
        )}
      </LegacyBox>
    </LegacyBox>
  );
}

export default React.forwardRef(Carousel);
