/** @jsx jsxPragma */

import {
  jsxPragma,
  useImpressionTracking,
  useTrackingContext,
} from "@outschool/ui-legacy-component-library";
import { SystemStyleObject } from "@styled-system/css";
import React, { PropsWithChildren } from "react";

export interface ExternalLinkProps {
  url: string;
  style?: object;
  onClick?: (e: React.MouseEvent) => void;
  trackingName?: string;
  trackingUniqueId?: string;
  trackingTouchEventName?: string;
  trackingViewEventName?: string;
  trackingSharedProperties?: object;
  title?: string;
  sx?: SystemStyleObject;
  className?: string;
}

function ExternalLink(
  {
    url,
    style,
    children,
    onClick = () => {},
    trackingName,
    trackingUniqueId,
    trackingTouchEventName,
    trackingViewEventName,
    trackingSharedProperties,
    title,
    sx,
    className,
  }: PropsWithChildren<ExternalLinkProps>,
  ref: React.Ref<HTMLAnchorElement>
) {
  const currentContext = useTrackingContext();
  const [impressionNode, setImpressionNode] =
    React.useState<HTMLAnchorElement>();
  const { trackTouch } = useImpressionTracking({
    node: impressionNode,
    trackingLabel: trackingName!,
    sharedProperties: {
      ...currentContext,
      ...trackingSharedProperties,
    },
    uniqueId: trackingUniqueId!,
    trackingEventName: trackingTouchEventName,
    impressionEventName: trackingViewEventName,
  });
  return (
    <a
      ref={node => {
        if (node && node !== impressionNode) {
          setImpressionNode(node);
          if (typeof ref === "function") {
            ref(node);
          } else if (ref && ref.current !== node) {
            // Ignoring because ref.current is supposed to be readonly
            // @ts-ignore
            ref.current = node;
          }
        }
      }}
      href={url}
      style={style}
      onClick={e => {
        if (trackingName) {
          trackTouch();
        }
        onClick(e);
        e.stopPropagation();
        return true;
      }}
      target="_blank"
      rel="noopener noreferrer"
      title={title}
      /* eslint-disable react/no-unknown-property */
      // @ts-ignore
      sx={sx}
      className={className}
    >
      {children}
    </a>
  );
}

export default React.forwardRef(ExternalLink);
