import React from "react";
import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect";

const defaultOptions: Partial<AddEventListenerOptions> = {
  passive: true,
  capture: true,
};

export function useEventListener(
  target: EventTarget | null,
  type: string,
  handler: EventListener,
  options?: boolean | AddEventListenerOptions
) {
  // Wrap in ref to avoid calling useEffect on each render
  const listenerRef = React.useRef(handler);

  useIsomorphicLayoutEffect(() => {
    listenerRef.current = handler;
  }, [handler]);

  React.useEffect(() => {
    if (!(target && target.addEventListener)) {
      return undefined;
    }

    const listener = (event: Event) => listenerRef.current(event);

    target.addEventListener(type, listener, options ?? defaultOptions);

    return () => {
      target.removeEventListener(type, listener, options ?? defaultOptions);
    };
  }, [target, type, options]);
}

export function useClickOutsideEventListener(
  target: EventTarget | null,
  outsideOfRef: React.RefObject<HTMLElement>,
  listener: EventListener
) {
  useClickOutsideRefEventListener(target, outsideOfRef, listener);
}

export function useClickOutsideRefEventListener<
  T extends HTMLElement,
  E extends Event
>(
  target: EventTarget | null,
  outsideOfRef: React.RefObject<T>[] | React.RefObject<T> | null,
  listener: EventListener
) {
  const handleClickOutside = (event: E) => {
    if (!outsideOfRef) {
      return;
    }

    const refs = Array.isArray(outsideOfRef) ? outsideOfRef : [outsideOfRef];

    const eventIsOutsideRef = refs.every(
      ref =>
        ref.current &&
        !(event.target instanceof Node && ref.current.contains(event.target))
    );

    if (eventIsOutsideRef) {
      return listener(event);
    }
  };

  useEventListener(target, "mouseup", handleClickOutside);
}
