// Keep in sync with lang-js/apps/website/shared/OwnershipAreas.ts
// Derived from: https://www.notion.so/outschool/0f57b8ae7ea3459b9963e0b9ae63b049
//          and: https://www.notion.so/outschool/b4d911c386d04200889f83c77b988dee
export enum Component {
  // N.B. that there is no "unknown" or "misc" component.
  // You can add such a catch-all to this enum,
  // but we would encourage you to consider adding
  // a new product area instead of such a concept.
  Analytics = "Analytics",
  BackpackStorybook = "Backpack Storybook",
  BuyerOrgs = "Buyer Orgs",
  CategoryPages = "Category Pages",
  // TODO: Add to https://www.notion.so/outschool/0f57b8ae7ea3459b9963e0b9ae63b049
  //         and: https://www.notion.so/outschool/b4d911c386d04200889f83c77b988dee
  ChatGPTPlugin = "ChatGPT Plugin Service",
  Classroom = "Classroom",
  ComponentLibrary = "Component Library",
  ContentfulLandingPages = "Contentful Landing Pages",
  ContentModeration = "Content Moderation",
  DataDeletion = "Data Deletion",
  EmailNotifications = "Email Notifications",
  EnrollmentsParent = "Enrollments for Parents",
  EnrollmentsTeacher = "Enrollments for Teachers",
  ExperimentationFramework = "Experimentation Framework",
  GoogleSearchConsole = "Google Search Console",
  Infra = "Infra",
  International = "International",
  IpLookupService = "IP Lookup Service",
  LearnerDataPipeline = "Learner Data Pipeline",
  LearnerMode = "Learner Mode / Learner UX (Learner privacy, learner accounts)",
  LearnerApp = "Learner App",
  LearningPlanner = "Learning Planner",
  Marketing = "Marketing",
  Messaging = "Messaging",
  Notifications = "Notifications",
  Orders = "Orders",
  ParentAdminTools = "Parent CX and Admin Tools",
  ParentEmailNotifications = "Parent Email Notifications",
  Payouts = "Payouts",
  Recommendations = "Recommendations",
  Recordings = "Recordings",
  ReferralProgram = "Referral Program",
  UserAdmin = "User Admin",
  SEOTaxonomy = "SEO Taxonomy",
  Search = "Search",
  Subscriptions = "Subscriptions",
  SearchRanking = "SearchRanking",
  ServerRenderedWebsite = "Server Rendered Website",
  Sitemap = "Sitemap",
  TeacherAdminTools = "Teacher CX and Admin Tools",
  TeacherTools = "Teacher Tools",
  Tracking = "Tracking",
  TrustMonitoring = "Trust and Safety Monitoring",
  TrustOs = "Trust OS",
  Tutoring = "Tutoring",
  UserAccountManagement = "User Account Management",
  Website = "Website",
  Zoom = "Zoom",
  ZoomChats = "Zoom Chats"
}

export enum EngineeringTeams {
  AllTeams = "AllTeams",
  Infra = "Infra",
  TrustAndSafety = "TrustAndSafety",
  Product = "Product",
  Analytics = "Analytics"
}

export const OwnershipAreas: Record<Component, EngineeringTeams> = {
  [Component.Website]: EngineeringTeams.AllTeams,
  [Component.Analytics]: EngineeringTeams.Analytics,
  [Component.BackpackStorybook]: EngineeringTeams.AllTeams,
  [Component.BuyerOrgs]: EngineeringTeams.Product,
  [Component.CategoryPages]: EngineeringTeams.Product,
  [Component.ChatGPTPlugin]: EngineeringTeams.Infra,
  [Component.Classroom]: EngineeringTeams.Product,
  [Component.ComponentLibrary]: EngineeringTeams.Product,
  [Component.ContentfulLandingPages]: EngineeringTeams.Product,
  [Component.ContentModeration]: EngineeringTeams.TrustAndSafety,
  [Component.DataDeletion]: EngineeringTeams.Infra,
  [Component.EmailNotifications]: EngineeringTeams.Product,
  [Component.EnrollmentsParent]: EngineeringTeams.Product,
  [Component.EnrollmentsTeacher]: EngineeringTeams.Product,
  [Component.ExperimentationFramework]: EngineeringTeams.AllTeams,
  [Component.GoogleSearchConsole]: EngineeringTeams.Product,
  [Component.Infra]: EngineeringTeams.Infra,
  [Component.International]: EngineeringTeams.Product,
  [Component.IpLookupService]: EngineeringTeams.Product,
  [Component.LearnerDataPipeline]: EngineeringTeams.Infra,
  [Component.LearnerMode]: EngineeringTeams.Product,
  [Component.LearnerApp]: EngineeringTeams.Product,
  [Component.LearningPlanner]: EngineeringTeams.Product,
  [Component.Marketing]: EngineeringTeams.Product,
  [Component.Messaging]: EngineeringTeams.Product,
  [Component.Notifications]: EngineeringTeams.Product,
  [Component.Orders]: EngineeringTeams.Product,
  [Component.ParentAdminTools]: EngineeringTeams.Product,
  [Component.ParentEmailNotifications]: EngineeringTeams.Product,
  [Component.Payouts]: EngineeringTeams.Product,
  [Component.Recommendations]: EngineeringTeams.Product,
  [Component.Recordings]: EngineeringTeams.Product,
  [Component.ReferralProgram]: EngineeringTeams.Product,
  [Component.UserAdmin]: EngineeringTeams.TrustAndSafety,
  [Component.SEOTaxonomy]: EngineeringTeams.Product,
  [Component.SearchRanking]: EngineeringTeams.Product,
  [Component.Search]: EngineeringTeams.Product,
  [Component.Subscriptions]: EngineeringTeams.Product,
  [Component.ServerRenderedWebsite]: EngineeringTeams.Product,
  [Component.Sitemap]: EngineeringTeams.Product,
  [Component.TeacherAdminTools]: EngineeringTeams.Product,
  [Component.TeacherTools]: EngineeringTeams.Product,
  [Component.Tracking]: EngineeringTeams.AllTeams,
  [Component.TrustMonitoring]: EngineeringTeams.TrustAndSafety,
  [Component.TrustOs]: EngineeringTeams.TrustAndSafety,
  [Component.Tutoring]: EngineeringTeams.Product,
  [Component.UserAccountManagement]: EngineeringTeams.Product,
  [Component.Zoom]: EngineeringTeams.Product,
  [Component.ZoomChats]: EngineeringTeams.TrustAndSafety
};
