import React from "react";
import { Box } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { addParamsToUrl, loginPath, teachPath } from "@outschool/routes";
import { useLinkComponent } from "@outschool/ui-utils";

interface TeacherRegistrationLinkExpComponentProps {
  onRedirect?: (e: React.MouseEvent) => void;
  loginParams?: { [key: string]: string | boolean | number };
  postLoginPath: string | undefined | null;
  trackingUniqueId?: string;
}

const TeacherRegistrationLinkExpComponent = ({
  onRedirect,
  loginParams,
  postLoginPath,
  trackingUniqueId,
}: TeacherRegistrationLinkExpComponentProps) => {
  const Link = useLinkComponent();

  const { t } = useTranslation(
    "ui-components-website\\TeacherRegistrationLinkExpComponent"
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Link
        data-test-id="login-link"
        onClick={onRedirect}
        to={addParamsToUrl(loginPath(), {
          ...loginParams,
          postLoginPath: loginParams?.redirect ?? postLoginPath,
        })}
        trackingName="login"
        trackingUniqueId={trackingUniqueId}
      >
        {t("Log in")}
      </Link>
      <Link
        data-test-id="teacher-join-link"
        onClick={onRedirect}
        to={teachPath()}
        trackingName="join-as-teacher"
        trackingUniqueId={trackingUniqueId}
      >
        {t("Join as Teacher")}
      </Link>
    </Box>
  );
};

export default TeacherRegistrationLinkExpComponent;
