import { Box, List, ListItem, useTheme, styled } from "@outschool/backpack";
import { onlineClassesPath } from "@outschool/routes";
import { useLinkComponent } from "@outschool/ui-utils";
import React, { useMemo } from "react";
import { CategoryPartial } from "./navbarCategories";

export function DesktopDropdown({
  category: parentCategory,
  closeMenu,
}: {
  category: CategoryPartial;
  closeMenu: () => void;
}) {
  const Link = useLinkComponent();
  const theme = useTheme();

  const CategoryLink = styled(Link)`
    color: ${theme.colors.gray900};
    font-weight: 500;
    font-size: 1em;
    text-decoration: none;
    &:hover,
    &:focus-within,
    &:focus,
    &:focus-visible {
      color: ${theme.palette.primary.main};
      text-decoration: underline;
    }
  `;

  const SubcategoryLink = styled(Link)`
    color: ${theme.colors.gray900};
    font-size: 0.875em;
    text-decoration: none;
    &:hover,
    &:focus-within,
    &:focus,
    &:focus-visible {
      color: ${theme.palette.primary.main};
      text-decoration: underline;
    }
  `;

  const columnCount = useMemo(
    () => countColumns(parentCategory.subcategories ?? []),
    [parentCategory.subcategories]
  );

  const subcategoriesMemo = useMemo(() => {
    return (
      <>
        {parentCategory.subcategories?.map((category, index) => (
          <List
            key={`${parentCategory.slug}:${category.slug}:${index}`}
            sx={{
              paddingTop: 0,
              paddingBottom: 18,
              // Prevent wrapping of lists on larger screens
              WebkitColumnBreakInside: "avoid",
              pageBreakInside: "avoid",
              breakInside: "avoid",
              [theme.breakpoints.down("lg")]: {
                WebkitColumnBreakInside: "auto",
                pageBreakInside: "auto",
                breakInside: "auto",
              },
            }}
          >
            <CategoryLink
              to={category.path || onlineClassesPath(category.slug)}
              trackingName="category-navbar-desktop-popup-item"
              trackingUniqueId={`${parentCategory.slug}/${category.slug}`}
              trackViews={false}
              onClick={closeMenu}
            >
              <ListItem dense disableGutters>
                {category.nameTitleCased}
              </ListItem>
            </CategoryLink>
            {category.subcategories?.map(subcategory => (
              <SubcategoryLink
                key={subcategory.slug}
                to={subcategory.path || onlineClassesPath(subcategory.slug)}
                trackingName="category-navbar-desktop-popup-item"
                trackingUniqueId={`${category.slug}/${subcategory.slug}`}
                trackViews={false}
                onClick={closeMenu}
              >
                <ListItem dense disableGutters>
                  {subcategory.nameTitleCased}
                </ListItem>
              </SubcategoryLink>
            ))}
          </List>
        ))}
      </>
    );
  }, [
    parentCategory,
    CategoryLink,
    SubcategoryLink,
    closeMenu,
    theme.breakpoints,
  ]);

  if (
    !parentCategory.subcategories ||
    parentCategory.subcategories?.length === 0
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        columnCount: columnCount,
        columnWidth: 170,
        columnGap: "0.8em",
        padding: "1em",
      }}
    >
      {subcategoriesMemo}
    </Box>
  );
}

function countColumns(categories: CategoryPartial[]): number {
  const heights: number[] = categories.map(
    cat => (cat.subcategories?.length ?? 0) + 1
  );
  const maxHeight = Math.max(...heights); // Find the height of the tallest category

  let columnCount = 0;
  let i = 0; // Left pointer

  // Loop through the categories using two pointers
  while (i < categories.length) {
    let currentHeight = heights[i];
    let j = i + 1;
    // Combine shorter categories into one column if they fit within max height
    while (j < categories.length && currentHeight + heights[j] <= maxHeight) {
      currentHeight += heights[j];
      j++;
    }
    columnCount++;
    i = j;
  }

  return columnCount;
}
