import { getSessionId } from "@outschool/attribution";
import get from "lodash/get";
import { Integration } from "../providers/AnalyticsContext";
import { MiddlewareParams } from "../types";
import { JSONObject } from "@segment/analytics-next";
import { AMPLITUDE_SESSION_REPLAY_ACTIVE_PATH } from "../plugins/AmplitudeSessionReplay";

export const isJSONObject = (value: any): value is JSONObject => {
  return value !== true && typeof value === "object" && value !== null;
};

export const AmplitudeSessionReplayMiddleware = ({
  payload,
  next,
}: MiddlewareParams) => {
  const pageUrl = get(payload, "obj.context.page.url");
  if (!pageUrl) {
    next(payload);
  } else {
    const amplitudeIntegration = get(
      payload,
      `obj.integrations.${[Integration.ActionsAmplitude]}`
    );
    if (
      payload.obj.integrations &&
      amplitudeIntegration &&
      amplitudeIntegration.session_id &&
      !!window?.sessionReplay
    ) {
      const storedSessionId = getSessionId() || 0;

      const nextSessionId = amplitudeIntegration.session_id;

      const url = new URL(pageUrl);

      if (AMPLITUDE_SESSION_REPLAY_ACTIVE_PATH.includes(url.pathname)) {
        if (storedSessionId < nextSessionId) {
          window.sessionReplay.setSessionId(nextSessionId);
        }
      }
    }
    next(payload);
  }
};
