import { Alert, AlertProps, Collapse, SxProps } from "@outschool/backpack";
import { useLocalStorageState } from "@outschool/local-storage";
import { usePreviousValue } from "@outschool/ui-utils";
import React from "react";

const DEFAULT_DURATION_SECONDS = 8;
const COLLAPSE_ANIMATION_MS = 500;

export interface DismissibleAlertProps
  extends Pick<AlertProps, "severity" | "title" | "action" | "icon"> {
  /**
   * Unique identifier for the DismissibleAlert.
   * Will not add an additional DismissibleAlert if one with the same id already exists.
   */
  id: string;
  /**
   * Content of the DismissibleAlert.
   */
  children: React.ReactNode;
  /**
   * Allow DismissibleAlert to be closed (render a close button).
   * @default false
   */
  dismissible?: boolean;
  /**
   * DismissibleAlert will stay dismissed once closed, even if it's rendered again.
   * This state will live in localStorage.
   * @default false
   */
  dismissWillMute?: boolean;
  /**
   * DismissibleAlert will stay dismissed once closed this many times (across renders).
   * This state will live in localStorage.
   * @default 1
   */
  muteAfterXDismisses?: number;
  /**
   * DismissibleAlert will stay open. If false, DismissibleAlert will close after `duration` seconds.
   * @default true
   */
  persistant?: boolean;
  /**
   * Duration (seconds) to hold DismissibleAlert if `persistant` is false.
   * @default 8 seconds
   */
  duration?: number;
  /**
   * Callback when DismissibleAlert is closed.
   * @default undefined
   */
  onClose?: (event: React.SyntheticEvent) => void;
  /**
   * Callback when DismissibleAlert's Collapse animation is finished.
   * @default undefined
   */
  onCollapseEnd?: () => void;
  /**
   * sx to apply to Alert container (Collapse, which is a div)
   */
  containerSx?: SxProps;
  /**
   * sx to apply to Alert
   */
  sx?: SxProps;
  /**
   * Disable the Collapse animation on open.
   * @default false
   */
  disableOpenAnimation?: boolean;
  /**
   * Disable the Collapse animation on close.
   * @default false
   */
  disableCloseAnimation?: boolean;
}

/**
 * A Backpack Alert with built in dismiss functionality.
 * Maintains dismissed state in localStorage, and by the provided unique `id`.
 */
export const DismissibleAlert = ({
  id,
  children,
  dismissWillMute = false,
  muteAfterXDismisses = 1,
  dismissible = false,
  persistant = true,
  duration = DEFAULT_DURATION_SECONDS,
  onClose,
  onCollapseEnd,
  containerSx,
  sx,
  disableOpenAnimation,
  disableCloseAnimation,
  ...props
}: DismissibleAlertProps) => {
  // Track all muted alerts so they stay muted between page loads
  const [numTimesMuted, setNumTimesMuted] = useLocalStorageState(
    `os-alert-id-${id}`,
    0
  );
  const alertIsMuted =
    dismissWillMute &&
    typeof numTimesMuted === "number" &&
    numTimesMuted >= muteAfterXDismisses;

  const [open, setOpen] = React.useState(false);

  const handleClose = dismissible
    ? (e: React.SyntheticEvent) => {
        setOpen(false);
        onClose?.(e);
        if (dismissWillMute) {
          setNumTimesMuted(numTimesMuted + 1);
        }
      }
    : // Make undefined, otherwise Alert will render a close button
      undefined;

  React.useEffect(() => {
    if (!persistant) {
      const timeoutId = setTimeout(() => {
        setOpen(false);
      }, duration * 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      return () => {};
    }
  }, [persistant, duration]);

  React.useEffect(() => {
    // This will set initial open state, as well as close self if it gets muted.
    // We open in an effect so it happens after initial render,
    // allowing the Collapse to animate in.
    setOpen(!alertIsMuted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const wasOpen = usePreviousValue(open);
  React.useEffect(() => {
    if (wasOpen && !open && onCollapseEnd) {
      // Do this in an effect, with a delay, so it happens after the Collapse animation.
      setTimeout(() => {
        onCollapseEnd();
      }, COLLAPSE_ANIMATION_MS);
    }
  }, [wasOpen, open, onCollapseEnd]);

  if (alertIsMuted && !wasOpen) {
    // Don't render anything if the alert is muted and was never open.
    // This happens if the alert was muted previously and this is a new render.
    return null;
  }

  return (
    <Collapse
      in={open}
      sx={containerSx}
      timeout={COLLAPSE_ANIMATION_MS}
      enter={!disableOpenAnimation}
      exit={!disableCloseAnimation}
    >
      <Alert sx={sx} onClose={handleClose} {...props}>
        {children}
      </Alert>
    </Collapse>
  );
};
