import { useCallback, useState } from "react";

export default function useBooleanState(
  initialValue?: boolean
): [boolean, () => void, () => void] {
  const [state, setState] = useState(!!initialValue);

  const set = useCallback(() => setState(true), []);
  const clear = useCallback(() => setState(false), []);

  return [state, set, clear];
}
