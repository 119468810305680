import { imageUrl } from "@outschool/filestack-urls";
import { LegacyImage } from "@outschool/ui-legacy-component-library";
import { useDevicePixelRatio, useDeviceTypeContext } from "@outschool/ui-utils";
import React from "react";

import type { SystemStyleObject } from "@styled-system/css";

export type FilestackImageProps<
  T extends React.ElementType = typeof LegacyImage
> = {
  src?: string;
  imageWidth?: number;
  imageHeight?: number;
  as?: T;
  sx?: SystemStyleObject;
} & React.ComponentProps<T>;

export default function FilestackImage<
  T extends React.ElementType = typeof LegacyImage
>({
  src,
  imageWidth,
  imageHeight,
  as: Component = LegacyImage,
  ...props
}: FilestackImageProps<T>) {
  if (!imageWidth && !imageHeight) {
    throw new Error("Must specify at least one dimension for a FilestackImage");
  }
  const { deviceType } = useDeviceTypeContext();
  const pixelRatio = useDevicePixelRatio(deviceType);

  let dimensions: { [key: string]: number } = {};
  if (imageWidth) {
    dimensions.width = imageWidth * pixelRatio;
  }
  if (imageHeight) {
    dimensions.height = imageHeight * pixelRatio;
  }

  return (
    <Component
      src={imageUrl(src, dimensions)}
      width={imageWidth}
      height={imageHeight}
      {...props}
    />
  );
}
