import { Trans, useTranslation } from "@outschool/localization";
import { Banner } from "@outschool/ui-legacy-component-library";
import React from "react";

export default function ReadOnlyBanner() {
  const { t } = useTranslation(
    "ui-components-website\\Banners\\ReadOnlyBanner"
  );
  return (
    <Banner data-test-id="read-only-mode-banner">
      {
        <Trans t={t}>
          Outschool is undergoing scheduled maintenance. You can still browse
          and attend classes, but some other actions may be temporarily
          unavailable. See our{" "}
          <a href="https://status.outschool.com/">status page</a> for more info.
        </Trans>
      }
    </Banner>
  );
}
