/**
 * The original, unlocalized versions of functions that now use
 * Intl.DateTimeFormat.prototype.formatRange. When the browser does
 * not support formatRange, we'll fall back to these functions.
 */
import dayjs from "./dayjsExtended";
import { OUTSCHOOL_TIMEZONE } from "./index";

function formatTime(
  time: dayjs.ConfigType,
  timeZone?: string,
  showTimeZone = true
): string {
  const timeDate = dayjs.tz(time, timeZone || OUTSCHOOL_TIMEZONE);
  let formatString = "h";
  if (timeDate.minutes()) {
    formatString += ":mm";
  }
  formatString += "a";
  if (showTimeZone) {
    formatString += " zz";
  }
  return timeDate.format(formatString);
}

export function fullDurationStringWithoutTimes(
  startTime: dayjs.ConfigType | undefined | null,
  endTime: dayjs.ConfigType | undefined | null,
  timeZone?: string
): string {
  if (!startTime || !endTime) {
    return "Not yet scheduled";
  }
  const startDate = dayjs.tz(startTime, timeZone || OUTSCHOOL_TIMEZONE);
  const endDate = dayjs.tz(endTime, timeZone || OUTSCHOOL_TIMEZONE);
  const nowDate = dayjs().tz(timeZone || OUTSCHOOL_TIMEZONE);
  const showYear = !(
    nowDate.isSame(startDate, "year") && nowDate.isSame(endDate, "year")
  );
  const startDowString = startDate.format("ddd, ");
  const startDateString = startDate.format(`MMM D${showYear ? ", YYYY" : ""}`);
  const endDateString = endDate.format(`MMM D${showYear ? ", YYYY" : ""}`);
  if (startDateString !== endDateString) {
    return startDowString + startDateString + " - " + endDateString;
  } else {
    return startDowString + startDateString;
  }
}

export function fullDurationStringWithStartTime(
  startTime: dayjs.ConfigType | undefined | null,
  endTime: dayjs.ConfigType | undefined | null,
  timeZone?: string
): string {
  if (!startTime || !endTime) {
    return "Not yet scheduled";
  }
  const startDate = dayjs.tz(startTime, timeZone || OUTSCHOOL_TIMEZONE);
  const endDate = dayjs.tz(endTime, timeZone || OUTSCHOOL_TIMEZONE);
  const moreThanDay = dayjs(startDate).add(12, "hour").isBefore(endDate);
  const nowDate = dayjs().tz(timeZone || OUTSCHOOL_TIMEZONE);
  const optionalYear =
    nowDate.isSame(startDate, "year") && nowDate.isSame(endDate, "year")
      ? ""
      : ", YYYY";
  if (moreThanDay) {
    return (
      startDate.format(`ddd MMM D${optionalYear}`) +
      " - " +
      endDate.format(`MMM D${optionalYear}`) +
      " at " +
      formatTime(startTime, timeZone)
    );
  } else {
    return (
      startDate.format(`ddd MMM D${optionalYear}`) +
      ", " +
      formatTime(startTime, timeZone, false) +
      " - " +
      formatTime(endTime, timeZone)
    );
  }
}

/**
 * eg: Mon, Sep 5 1pm - Mon, Dec 12 2:15pm
 * or: Mon, Sep 5 1pm - 2:15pm
 */
export function fullDurationStringWithTimes(
  startTime: dayjs.ConfigType | undefined | null,
  endTime: dayjs.ConfigType | undefined | null,
  timeZone: string,
  hideTimeZone = false
): string {
  if (!startTime || !endTime) {
    return "Not yet scheduled";
  }
  const startDate = dayjs.tz(startTime, timeZone || OUTSCHOOL_TIMEZONE);
  const endDate = dayjs.tz(endTime, timeZone || OUTSCHOOL_TIMEZONE);
  const moreThanDay = dayjs(startDate).add(12, "hour").isBefore(endDate);
  const nowDate = dayjs().tz(timeZone || OUTSCHOOL_TIMEZONE);
  const optionalYear =
    nowDate.isSame(startDate, "year") && nowDate.isSame(endDate, "year")
      ? ""
      : ", YYYY";
  if (moreThanDay) {
    return `${startDate.format(`ddd MMM D${optionalYear}`)} ${formatTime(
      startTime,
      timeZone,
      false
    )} - ${endDate.format(`ddd MMM D${optionalYear}`)} ${formatTime(
      endTime,
      timeZone,
      !hideTimeZone
    )}`;
  } else {
    return (
      startDate.format(`ddd MMM D${optionalYear}`) +
      ", " +
      formatTime(startTime, timeZone, false) +
      " - " +
      formatTime(endTime, timeZone, !hideTimeZone)
    );
  }
}

/**
 * Returns a 'month day, year' representation of start end end times.
 * It produces the shortest representation possible:
 * Same day:
 *   Nov 7, 2017
 * Same month:
 *   Nov 7 - 10, 2017
 * Same year:
 *   Nov 7 - Dec 5, 2017
 * Otherwise:
 *   Nov 7, 2017 - Jan 5, 2018
 * @param startTime
 * @param endTime
 * @param timeZone
 * @returns {string}
 */
export function pastDurationString(
  startTime: dayjs.ConfigType,
  endTime: dayjs.ConfigType,
  timeZone: string
) {
  let start = dayjs(startTime).tz(timeZone || OUTSCHOOL_TIMEZONE);
  let end = dayjs(endTime).tz(timeZone || OUTSCHOOL_TIMEZONE);
  if (start.isSame(end, "day")) {
    return `${start.format("MMM D, YYYY")}`;
  } else if (start.isSame(end, "month")) {
    return `${start.format("MMM D")} - ${end.format("D, YYYY")} `;
  } else if (start.isSame(end, "year")) {
    return `${start.format("MMM D")} - ${end.format("MMM D, YYYY")} `;
  } else {
    return `${start.format("MMM D, YYYY")} - ${end.format("MMM D, YYYY")} `;
  }
}

export function pastDurationStringWithTimes(
  startTime: dayjs.ConfigType,
  endTime: dayjs.ConfigType,
  timeZone: string
) {
  let start = dayjs(startTime).tz(timeZone || OUTSCHOOL_TIMEZONE);
  let end = dayjs(endTime).tz(timeZone || OUTSCHOOL_TIMEZONE);
  const withoutTimes = pastDurationString(start, end, timeZone);
  if (start.isSame(end, "day")) {
    return `${withoutTimes}, ${formatTime(
      start,
      timeZone,
      false
    )} - ${formatTime(end, timeZone, true)}`;
  } else {
    return `${withoutTimes}, ${formatTime(start, timeZone, true)}`;
  }
}

/** 9am - 10am Central */
export function briefDurationTimesString(
  startTime: dayjs.ConfigType | undefined | null,
  endTime: dayjs.ConfigType | undefined | null,
  timeZone: string,
  showTimeZone = true
) {
  if (!startTime || !endTime) {
    return "Not yet scheduled";
  }
  return `${formatTime(startTime, timeZone, false)} - ${formatTime(
    endTime,
    timeZone,
    showTimeZone
  )}`;
}

/**  eg. Wed, Feb 1 - Mar 1, intentionally doesn't add weekday for the second date */
export function briefDateDurationWithWeekday(
  startTime: dayjs.ConfigType,
  endTime: dayjs.ConfigType,
  timeZone: string
) {
  const briefDateDuration = briefDurationStringWithoutTimes(
    startTime,
    endTime,
    timeZone
  );
  const startMoment = dayjs.tz(startTime, timeZone || OUTSCHOOL_TIMEZONE);
  return startMoment.format("ddd, ") + briefDateDuration;
}

/** eg. Feb 1 - Mar 1 */
export function briefDurationStringWithoutTimes(
  startTime: dayjs.ConfigType,
  endTime: dayjs.ConfigType,
  timeZone: string
) {
  if (!startTime || !endTime) {
    return "Not yet scheduled";
  }
  const startMoment = dayjs.tz(startTime, timeZone || OUTSCHOOL_TIMEZONE);
  const endMoment = dayjs.tz(endTime, timeZone || OUTSCHOOL_TIMEZONE);
  const nowMoment = dayjs().tz(timeZone || OUTSCHOOL_TIMEZONE);
  const optionalYear =
    nowMoment.isSame(startMoment, "year") && nowMoment.isSame(endMoment, "year")
      ? ""
      : ", YYYY";
  const startDate = dayjs
    .tz(startTime, timeZone || OUTSCHOOL_TIMEZONE)
    .format(`MMM D${optionalYear}`);
  const endDate = dayjs
    .tz(endTime, timeZone || OUTSCHOOL_TIMEZONE)
    .format(`MMM D${optionalYear}`);
  return startDate === endDate ? startDate : `${startDate} - ${endDate}`;
}

/**
 * eg: December 18 - 25
 * or: December 30 - January 6
 */
export function shortDurationString(
  startTime: dayjs.ConfigType,
  endTime: dayjs.ConfigType,
  timeZone: string
) {
  const startDay = dayjs.tz(startTime, timeZone || OUTSCHOOL_TIMEZONE);
  const endDay = dayjs.tz(endTime, timeZone || OUTSCHOOL_TIMEZONE);
  if (startDay.isSame(endDay, "month")) {
    return `${startDay.format("MMMM D")} - ${endDay.format("D")}`;
  } else {
    return `${startDay.format("MMMM D")} - ${endDay.format("MMMM D")}`;
  }
}

/**
 * eg: 09:00 - 10:00am Pacific
 * or: 11:00am - 12:00pm Pacific
 */
export function startEndTime(
  startTime: dayjs.ConfigType,
  endTime: dayjs.ConfigType,
  timeZone = OUTSCHOOL_TIMEZONE,
  showTimeZone = true
) {
  const startMoment = dayjs.tz(startTime, timeZone);
  const endMoment = dayjs.tz(endTime, timeZone);
  const endFormat = showTimeZone ? "h:mma zz" : "h:mma";
  if (startMoment.format("a") === endMoment.format("a")) {
    return `${startMoment.format("h:mm")} - ${endMoment.format(endFormat)}`;
  }
  return `${startMoment.format("h:mma")} - ${endMoment.format(endFormat)}`;
}

/**
 * eg: 21 - 25
 */
export function startEndDay(
  startDate: dayjs.ConfigType,
  endDate: dayjs.ConfigType,
  timeZone = OUTSCHOOL_TIMEZONE
) {
  const start = dayjs.tz(startDate, timeZone);
  const end = dayjs.tz(endDate, timeZone);
  return `${start.format("D")} - ${end.format("D")}`;
}
