import React from "react";

type ErrorBoundaryProps = {
  fallback?: (error: Error | null) => JSX.Element | null;
  children?: React.ReactNode;
};

type ErrorBoundaryState = {
  errorBoundaryError: Error | null;
};

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static defaultProps = {
    fallback: null,
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      errorBoundaryError: null,
    };
  }

  componentDidCatch(error: Error | null, errorInfo: object) {
    console.error("ErrorBoundary.componentDidCatch", error, errorInfo);
    this.setState({ errorBoundaryError: error });
    this.forceUpdate(); // If the error happens while navigating setState was not enough for some reason.
  }

  render() {
    const { fallback, children } = this.props;
    const { errorBoundaryError } = this.state;
    return errorBoundaryError
      ? typeof fallback === "function"
        ? fallback(errorBoundaryError)
        : fallback
      : children;
  }
}
