import { Icon, Theme } from "@outschool/backpack";
import { faTimes } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import React, { useState } from "react";

import jsxPragma from "./jsxPragma";

import type { SystemStyleObject } from "@styled-system/css";

const DivWithSx = (props: any) => jsxPragma("div", props);

export type Variant =
  | "warning"
  | "information"
  | "confirmation"
  | "promo"
  | "referral";

export interface BannerProps {
  variant?: Variant;
  sticky?: boolean;
  round?: boolean;
  closable?: boolean;
  leftAlign?: boolean;
  onClose?: () => void;
  sx?: SystemStyleObject;
  children?: React.ReactNode;
}

const variants: Record<Variant, SystemStyleObject> = {
  warning: {
    backgroundColor: "yellow5",
    color: "yellow0",
    "& a": {
      color: "yellow0",
      textDecoration: "underline",
      "&:hover": { color: "yellow0" },
    },
  },
  information: {
    backgroundColor: "primary",
    color: "white",
    "& a": {
      color: "white",
      textDecoration: "underline",
      "&:hover": { color: "white" },
    },
  },
  confirmation: {
    backgroundColor: "peacock100",
    color: "gray900",
    "& a": {
      color: "gray900",
      textDecoration: "underline",
      "&:hover": { color: "gray900" },
    },
  },
  promo: {
    background: theme =>
      `linear-gradient(180deg, rgba(5, 56, 115, 0.5) 0%, rgba(5, 56, 115, 0) 50.52%), ${theme.colors.blue2}`,
    color: "white",
    border: 0,
  },
  referral: {
    backgroundColor: "blue7",
    color: "black",
    fontSize: "medium",
  },
};

const Banner = React.forwardRef<any, BannerProps>(
  (
    {
      variant = "warning",
      sticky = false,
      round = false,
      closable = false,
      leftAlign = false,
      onClose,
      sx,
      children,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation("ui-legacy-component-library\\Banner");
    const [show, setShow] = useState(true);

    const close = () => {
      setShow(false);
      if (onClose) {
        onClose();
      }
    };

    if (!show) {
      return null;
    }

    return (
      <DivWithSx
        ref={ref}
        // @ts-ignore
        sx={{
          position: "relative",
          padding: "medium",
          paddingRight:
            leftAlign || closable ? ["2.75rem", "3.25rem"] : "medium",
          textAlign: leftAlign || closable ? "left" : "center",
          fontSize: "small",
          marginTop: "-1px",
          "@media print": {
            display: "none",
          },
          opacity: show ? 1 : 0,
          transition: "opacity 500ms",
          ...variants[variant],
          ...(round && { borderWidth: "1px", borderRadius: "medium" }),
          ...(sticky && { position: "sticky", top: 0, zIndex: 1 }),
          ...sx,
        }}
        {...rest}
      >
        {children}
        {closable && (
          <Icon
            aria-label={t("Close")}
            icon={faTimes}
            sx={(theme: Theme) => ({
              fontSize: "1.333em",
              opacity: 0.8,
              position: "absolute",
              right: "1.25rem",
              top: "1.25rem",
              [theme.breakpoints.up("md")]: {
                top: "calc(50% - 1rem)",
              },
              cursor: "pointer",
            })}
            onClick={close}
          />
        )}
      </DivWithSx>
    );
  }
);

export default Banner;
