import { Subject } from "../types";

export const DEFAULT_SUBJECTS_LIST_VERSION = "v1";

export const DEFAULT_SUBJECTS: {
  v1: Subject[];
} = Object.freeze({
  v1: [
    {
      slug: "arts",
      name: "Arts",
      topics: [
        {
          slug: "drawing",
          name: "Drawing",
        },
        {
          slug: "photography",
          name: "Photography",
        },
        {
          slug: "dance",
          name: "Dance",
        },
        {
          slug: "theater",
          name: "Theater",
        },
        {
          slug: "film",
          name: "Film",
        },
        {
          slug: "sewing",
          name: "Sewing",
        },
      ],
    },
    {
      slug: "english",
      name: "English",
      topics: [
        {
          slug: "creative-writing",
          name: "Creative Writing",
        },
        {
          slug: "grammar",
          name: "Grammar",
        },
        {
          slug: "spelling",
          name: "Spelling",
        },
        {
          slug: "book-club",
          name: "Book Club",
        },
        {
          slug: "essay-writing",
          name: "Essay Writing",
        },
        {
          slug: "poetry",
          name: "Poetry",
        },
        {
          slug: "literature",
          name: "Literature",
        },
      ],
    },
    {
      slug: "life-skills",
      name: "Life Skills",
      topics: [
        {
          slug: "cooking",
          name: "Cooking",
        },
        {
          slug: "financial-skills",
          name: "Financial Skills",
        },
        {
          slug: "study-skills",
          name: "Study Skills",
        },
        {
          slug: "social-skills",
          name: "Social Skills",
        },
        {
          slug: "critical-thinking",
          name: "Critical Thinking",
        },
      ],
    },
    {
      slug: "music",
      name: "Music",
      topics: [
        {
          slug: "guitar",
          name: "Guitar",
        },
        {
          slug: "piano",
          name: "Piano",
        },
        {
          slug: "singing",
          name: "Singing",
        },
        {
          slug: "composers",
          name: "Composers",
        },
        {
          slug: "music-theory",
          name: "Music Theory",
        },
      ],
    },
    {
      slug: "social-studies",
      name: "Social Studies",
      topics: [
        {
          slug: "geography",
          name: "Geography",
        },
        {
          slug: "world-history",
          name: "World History",
        },
        {
          slug: "american-history",
          name: "American History",
        },
        {
          slug: "anthropology",
          name: "Anthropology",
        },
        {
          slug: "economics",
          name: "Economics",
        },
        {
          slug: "politics",
          name: "Politics",
        },
      ],
    },
    {
      slug: "coding-and-tech",
      name: "Coding & Tech",
      topics: [
        {
          slug: "coding",
          name: "Coding",
        },
        {
          slug: "video-game-design",
          name: "Video Game Design",
        },
        {
          slug: "robotics",
          name: "Robotics",
        },
        {
          slug: "engineering",
          name: "Engineering",
        },
        {
          slug: "internet-safety",
          name: "Internet Safety",
        },
        {
          slug: "animation",
          name: "Animation",
        },
      ],
    },
    {
      slug: "health-and-wellness",
      name: "Health & Wellness",
      topics: [
        {
          slug: "hygiene",
          name: "Hygiene",
        },
        {
          slug: "emotions",
          name: "Emotions",
        },
        {
          slug: "exercise",
          name: "Exercise",
        },
        {
          slug: "mindfulness",
          name: "Mindfulness",
        },
        {
          slug: "nutrition",
          name: "Nutrition",
        },
      ],
    },
    {
      slug: "math",
      name: "Math",
      topics: [
        {
          slug: "algebra",
          name: "Algebra",
        },
        {
          slug: "numbers",
          name: "Numbers",
        },
        {
          slug: "geometry",
          name: "Geometry",
        },
        {
          slug: "fractions",
          name: "Fractions",
        },
        {
          slug: "calculus",
          name: "Calculus",
        },
        {
          slug: "statistics",
          name: "Statistics",
        },
        {
          slug: "probability",
          name: "Probability",
        },
      ],
    },
    {
      slug: "science-and-nature",
      name: "Science & Nature",
      topics: [
        {
          slug: "chemistry",
          name: "Chemistry",
        },
        {
          slug: "biology",
          name: "Biology",
        },
        {
          slug: "zoology",
          name: "Zoology",
        },
        {
          slug: "physics",
          name: "Physics",
        },
        {
          slug: "astronomy",
          name: "Astronomy",
        },
        {
          slug: "anatomy",
          name: "Anatomy",
        },
        {
          slug: "marine-biology",
          name: "Marine Biology",
        },
        {
          slug: "psychology",
          name: "Psychology",
        },
      ],
    },
    {
      slug: "world-languages",
      name: "World Languages",
      topics: [
        {
          slug: "spanish",
          name: "Spanish",
        },
        {
          slug: "american-sign-language",
          name: "American Sign Language",
        },
        {
          slug: "french",
          name: "French",
        },
        {
          slug: "japanese",
          name: "Japanese",
        },
        {
          slug: "latin",
          name: "Latin",
        },
        {
          slug: "german",
          name: "German",
        },
        {
          slug: "chinese",
          name: "Chinese",
        },
        {
          slug: "greek",
          name: "Greek",
        },
        {
          slug: "italian",
          name: "Italian",
        },
        {
          slug: "mandarin",
          name: "Mandarin",
        },
      ],
    },
    {
      slug: "One-on-one",
      name: "One on One",
      topics: [],
    },
    {
      slug: "games-and-hobbies",
      name: "Games & Hobbies",
      topics: [],
    },
    {
      slug: "early-education",
      name: "Early Education",
      topics: [],
    },
    {
      slug: "test-prep",
      name: "Test Prep",
      topics: [],
    },
  ],
});
