import { Components, Theme } from "@mui/material";

const POPPER_THEME: Components<Theme>["MuiPopper"] = {
  styleOverrides: {
    root: () => ({
      zIndex: 9999,
    }),
  },
};

export default POPPER_THEME;
