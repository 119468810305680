import pkg from "../lib/pkg";
import { injectScript } from "../lib/util";
import {
  Integration,
  IntegrationCategory,
} from "../providers/AnalyticsContext";
import { AnalyticsPlugin, AnalyticsPluginType } from ".";

import type { Context } from "../types";

declare global {
  interface Window {
    wcs?: any;
    wcs_do?: any;
    _nasa?: any;
    wcs_add?: {
      [key: string]: unknown;
    };
  }
}

const EVENT_ORDER_COMPLETED = 1;
const EVENT_USER_CREATED = 2;
export default class NaverPlugin implements AnalyticsPlugin {
  name = Integration.Naver;
  category = IntegrationCategory.Advertising;
  type = AnalyticsPluginType.destination;
  version = "0.1.0";

  scriptId = "os-naver";
  scriptSrc = "https://wcs.naver.net/wcslog.js";
  key = "s_5118681a88f7";

  constructor() {}

  isLoadable() {
    return pkg.isProduction;
  }

  async load(): Promise<void> {
    await injectScript(this.scriptId, this.scriptSrc);

    if (!window?.wcs_add) {
      window.wcs_add = {};
    }

    window.wcs_add["wa"] = this.key;

    if (!window?._nasa) {
      window._nasa = {};
    }

    window?.wcs.inflow();
    window?.wcs_do(window?._nasa);
  }

  async unload(): Promise<void> {
    const script = document.getElementById(this.scriptId);
    script?.remove();
    window.wcs_add = undefined;
    window.wcs_do = undefined;
    window.wcs = undefined;
  }

  isLoaded() {
    if (typeof window === "undefined") {
      return false;
    }

    return !!window?.wcs;
  }

  async track(context: Context) {
    const { event, properties } = context.event;
    const wcs = window?.wcs;
    if (!wcs || !properties) {
      return context;
    }

    if (event === "user-created") {
      this.sendRegistration(wcs);
    } else if (event === "Order Completed") {
      this.sendConversion(properties, wcs);
    }

    return context;
  }

  sendRegistration(wcs: any) {
    window._nasa["cnv"] = wcs.cnv(EVENT_USER_CREATED, 0);
    window.wcs_do(window._nasa);
  }

  sendConversion(eventProperties: Context["event"]["properties"], wcs: any) {
    const total = eventProperties?.total;
    window._nasa["cnv"] = wcs.cnv(EVENT_ORDER_COMPLETED, total);
    window.wcs_do(window._nasa);
  }
}
