import dayjs from "dayjs";

// Only import constants here, to avoid circular logic.
import {
  DAYS,
  DAYS_SHORT,
  Day,
  DayShort,
  TIMEZONES,
  TIMEZONE_SPECIAL_NAMES,
  timeZoneRegex,
} from "..";

// Custom plugin
// https://day.js.org/docs/en/plugin/plugin#customize
const custom: dayjs.PluginFunc<any> = function (
  options,
  { prototype },
  constructor
) {
  // Override some behavior of dayjs.tz
  const oldTz = constructor.tz;
  const oldTzProps = { ...constructor.tz };
  // @ts-ignore
  constructor.tz = function (arg1, arg2, arg3) {
    // Allow timezone as first argument to dayjs.tz
    if (timeZoneRegex.test(arg1)) {
      return oldTz.bind(this)(undefined, arg1);
    }
    // Allow invalid inputs to dayjs.tz (return invalid dayjs rather than error)
    const withoutTz = arg3 ? constructor(arg1, arg2) : constructor(arg1);
    if (!withoutTz.isValid()) {
      return withoutTz;
    }
    // Fix a bug with dayjs.tz and ISO string inputs. If the
    // input is an ISO string, convert to a Date before processing.
    // https://github.com/iamkun/dayjs/issues/1265
    const date = new Date(arg1);
    const isValidDate = !isNaN(date.valueOf());
    const inputIsISOString = isValidDate && date.toISOString() == arg1;
    if (inputIsISOString) {
      return oldTz.bind(this)(date, arg2, arg3);
    }
    // Default return
    return oldTz.bind(this)(arg1, arg2, arg3);
  };
  Object.assign(constructor.tz, oldTzProps);

  // Add moment.tz methods missing from dayjs.tz
  constructor.tz.names = function () {
    return TIMEZONES;
  };
  constructor.tz.isValid = function (name: string) {
    return timeZoneRegex.test(name);
  };

  // Fix DST bug with adding in days:
  const oldAdd = prototype.add;
  // @ts-ignore
  prototype.add = function (input, units) {
    // If this is a dayjs with timezone information, use this workaround
    // for adding days or weeks (TODO months, hours?)
    // https://github.com/iamkun/dayjs/issues/1271#issuecomment-772542205
    if (
      units &&
      ["day", "days", "week", "weeks"].includes(units) &&
      this?.$x?.$timezone
    ) {
      const days = ["weeks", "week"].includes(units) ? input * 7 : input;
      const clone = dayjs(this);
      // @ts-ignore
      const tz = clone.$x.$timezone;
      return clone.date(clone.date() + days).tz(tz, true);
    }
    return oldAdd.bind(this)(input, units);
  };

  // Support string inputs to `.day()`
  const oldDay = prototype.day;
  // @ts-ignore
  prototype.day = function (
    input: number | Day | DayShort
  ): dayjs.Dayjs | number {
    input = DAYS.includes(input as Day)
      ? DAYS.indexOf(input as Day)
      : DAYS_SHORT.includes(input as DayShort)
      ? DAYS_SHORT.indexOf(input as DayShort)
      : (input as number);
    return oldDay.bind(this)(input);
  };

  // Support "zz" time zone formatting
  // Reference: https://github.com/iamkun/dayjs/blob/dev/src/plugin/advancedFormat/index.js
  const oldFormat = prototype.format;
  prototype.format = function (formatStr) {
    if (!this.isValid()) {
      return oldFormat.bind(this)(formatStr);
    }
    // Fallback to the default dayjs format string:
    // https://github.com/iamkun/dayjs/blob/3d316117f04362d31f4e8bd349620b8414ce5d0c/src/constant.js#L24
    const str = formatStr || "YYYY-MM-DDTHH:mm:ssZ";
    // We are replicating how custom formatting logic works in other dayjs plugins.
    // Replace "zz" in the format string with our custom formatted time zone
    // (wrap "zz" with [^z] ("not z") characers since "z" and "zzz" are existing formats)
    const result = str.replace(/([^z]|^)zz([^z]|$)/g, (match, $1, $2) => {
      let replacement = match;
      // dayjs objects have some "private" properties that aren't in the type
      // definitions: $x.$timezone contains the IANA timezone name, or undefined.
      const timeZone: string = this.$x.$timezone || constructor.tz.guess();
      const locale: string = this.$L;
      const specialTzNamesForLocale = TIMEZONE_SPECIAL_NAMES[locale] ?? {};
      // If this is a TZ with a common name not supported by Intl formatting,
      // override with a hand-picked name for the given locale
      if (timeZone in specialTzNamesForLocale) {
        replacement = specialTzNamesForLocale[timeZone];
      } else {
        // Otherwise, format it using `Intl` since dayjs doesn't localize tz names
        replacement =
          // $L is the locale of the current dayjs object
          new Intl.DateTimeFormat(this.$L, {
            timeZone,
            timeZoneName: "long",
          })
            .formatToParts(this.toDate())
            .find(part => part.type == "timeZoneName")?.value ??
          this.offsetName("long");
      }
      // Wrap the formatted portion of the string in brackets so it is escaped.
      // The rest will be passed on to the original format function.
      return `${$1 ?? ""}[${replacement}]${$2 ?? ""}`;
    });
    return oldFormat.bind(this)(result);
  };

  // Add moment-compliant aliases
  prototype.days = prototype.day;
  prototype.hours = prototype.hour;
  prototype.minutes = prototype.minute;
  prototype.seconds = prototype.second;

  // Helper to access "private" properties
  prototype.getTz = function () {
    return this.$x?.$timezone;
  };
};

// Types
declare module "dayjs" {
  interface DayjsTimezone {
    (timezone: string): dayjs.Dayjs;
    /** @deprecated Use `import { TIMEZONES } from "@outschool/time"` instead */
    names(): string[];
    isValid(name: string): boolean;
  }

  interface Dayjs {
    day(value: number | Day): dayjs.Dayjs;
    isBefore(date?: dayjs.Dayjs, unit?: dayjs.OpUnitType): boolean;
    isAfter(date?: dayjs.Dayjs, unit?: dayjs.OpUnitType): boolean;
    days: typeof dayjs.prototype.day;
    hours: typeof dayjs.prototype.hour;
    minutes: typeof dayjs.prototype.minute;
    seconds: typeof dayjs.prototype.second;
    getTz(): string | undefined;
  }
}

export default custom;
