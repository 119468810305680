const CHANNEL_PARAM_NAME = "utm_source";

enum AwinField {
  awc = "awc",
  source = "source",
}

export type AwinAttribution = {
  [key in AwinField]?: string | null;
};

/**
 * Extracts relevant query params for Awin tracking from URL.
 *
 * @param queryString - A query string taken from a path or URL.
 */
export function awinAttributionFrom(queryString: string): AwinAttribution {
  const attribution: { [key in AwinField]?: string | null } = {};

  if (!queryString) {
    return attribution;
  }

  const params = new URLSearchParams(queryString);

  if (params.has(AwinField.awc)) {
    attribution[AwinField.awc] = params.get(AwinField.awc);
  }

  if (params.has(CHANNEL_PARAM_NAME)) {
    attribution[AwinField.source] = params.get(CHANNEL_PARAM_NAME);
  }

  return attribution;
}
