import React from "react";

import { useNavigation } from "./NavigationProvider";

interface RedirectProps {
  to: string;
}

/**
 * react-router v5 doesn't work well with React 18. This is a workaround which uses our built-in navigation hooks.
 * But also works with hookrouter, which is neat.
 * @param props RedirectProps
 * @returns null
 */
export const Redirect = (props: RedirectProps) => {
  const navigate = useNavigation();

  React.useEffect(() => {
    navigate(props.to, {
      replace: true,
    });
  }, [navigate, props.to]);
  return null;
};
