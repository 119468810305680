import pkg from "./pkg";
import { AnalyticsError } from "./util";

import type { PageContext } from "../providers/PageContext";
import type { ExtendedPerformanceEntry, SendTrackEvent } from "../types";

export type GlobalObserver = {
  entry?: ExtendedPerformanceEntry;
  error?: Error;
  event?: string;
  instance?: PerformanceObserver;
  properties?: object;
  type: string;
};

export interface PerformanceWindow extends Window {
  PerformanceObserver: typeof PerformanceObserver;
  ospm?: GlobalObserver;
}

declare const window: PerformanceWindow;

type Observer = {
  type: string;
  label?: string;
  event?: string;
  properties?: object;
  pages?: Array<string>;
  instance?: PerformanceObserver;
};

type PageLoadState = {
  initialized: boolean;
  queue: Array<[observer: Observer, entry: ExtendedPerformanceEntry]>;
  observers: Array<Observer>;
  page: string | undefined;
  pathname: string | undefined;
  track: SendTrackEvent | null;
};

export let state: PageLoadState = createPageLoadState();

export function createPageLoadState(): PageLoadState {
  return {
    initialized: false,
    queue: [],
    observers: [],
    page: undefined,
    pathname: undefined,
    track: null,
  };
}

/**
 * Full initialization is a two-step process. The performance observers must
 * be created as early as possible to avoid event loss, so they're created
 * here in this function without yet having all the dependencies required to
 * send the events. See setPerformanceMonitoringState for the second step in
 * the initialization process.
 *
 * Alternatively, some observers (currently "largest-contentful-paint") are
 * created globally to further mitigate event loss. If a global observer
 * exists and matches with performance configuration, then the event data is
 * sent (if available) and the observer is disconnected.
 *
 * Note: This function includes conditions to protect against unwanted
 * re-initialization of observers during re-renders.
 */
export function initPerformanceMonitoring() {
  try {
    if (!isSupported() || !pkg.performance || state.initialized) {
      return;
    }

    state.observers =
      JSON.parse(JSON.stringify(pkg.performance.observers)) || [];

    state.observers
      .filter(observer => {
        if (!isEntryTypeSupported(observer.type)) {
          return false;
        }

        const globalEventTracked = processGlobalEvents(observer);

        return !globalEventTracked;
      })
      .forEach(observer => {
        observer.event = getEventName(observer);

        observer.instance = new window.PerformanceObserver(entryList => {
          trackEntryList(observer, entryList);
        });

        observer.instance.observe({ type: observer.type });
      });

    state.initialized = true;
    processQueuedEvents();
  } catch (error) {
    pkg.onError(
      new AnalyticsError("Failed to initialize performance monitoring", error)
    );
  }
}

/*
 * This function is step two of two in the performance monitoring
 * initialization process. The dependencies required to send events are
 * collected here and set on state when they're available.
 *
 * Page info is used to cleanup observers on pages that aren't configured to
 * send/receive measure events (e.g. os-page-load). Page info is also used to
 * invalidate events if the page is loaded under one path and receives an event
 * under a different path.
 *
 * The track function here is the same function exported by the useTrackEvent
 * hook.
 */
export function setPerformanceMonitoringState(
  track: SendTrackEvent,
  page: PageContext
) {
  if (!pkg.performance || !!state.track || !!state.pathname) {
    return;
  }

  state.page = page.pageName;
  state.pathname = page.pagePath;
  state.track = track;

  state.observers.forEach(observer => {
    if (!observer.pages) {
      return;
    }

    if (!!state.page && observer.pages.includes(state.page)) {
      return;
    }

    cleanupObserver(observer);
  });

  processQueuedEvents();
}

/*
 * Custom `measure` events don't have dedicated observers by default. This is
 * unlike the behavior of other events (e.g. largest-contentful-paint,
 * navigation).
 *
 * Non-measure events can be tracked immediately. `measure` events must get
 * paired up with the correct observer by its label so that it can be tracked
 * and cleaned-up independently of other `measure` events.
 */
export function trackEntryList(
  observer: Observer,
  entryList: PerformanceObserverEntryList
) {
  try {
    entryList.getEntries().forEach(entry => {
      if (entry.entryType === "measure" && entry.name !== observer.label) {
        return;
      }

      trackEntry(observer, entry);
    });
  } catch (error) {
    console.warn("failed to track performance event", error);
  }
}

/*
 * If an entry is received while we're still waiting for full initialization,
 * then the entry is added to a queue with all its original timing and
 * properties preserved.
 *
 * Invalid entries (due to path mismatch, etc) are discarded and the observer
 * is cleaned up.
 *
 * Valid entries are sent immediately and the observer is cleaned up
 * afterwards.
 */
export function trackEntry(
  observer: Observer,
  entry: ExtendedPerformanceEntry
) {
  if (!isReady()) {
    enqueueEvent(observer, entry);
    return;
  }

  if (!isValidEntry(observer, entry)) {
    cleanupObserver(observer);
    return;
  }

  if (observer.event) {
    sendEvent(observer.event, getPayload(observer, entry));
  }

  cleanupObserver(observer);
}

export function measurePerformance(label: string, properties?: object) {
  try {
    if (!isSupported()) {
      return;
    }

    const index = state.observers.findIndex(
      observer => observer.type === "measure" && observer.label === label
    );

    const observer = state.observers[index];

    if (!observer) {
      return;
    }

    observer.properties = !!properties ? properties : {};

    window.performance.measure(observer.label || "");
  } catch (error) {
    pkg.onError(new AnalyticsError("Failed to measure page load", error));
  }
}

export function getEventName(observer: Observer) {
  const event = `performance_${observer.type.replace(/-/g, "_")}`;

  if (!observer.label) {
    return event;
  }

  return `${event}_${observer.label.replace(/-/g, "_")}`;
}

export function getPayload(
  observer: Observer,
  entry: ExtendedPerformanceEntry
): object {
  const type = observer.type;
  let json = entry.toJSON();

  if (type === "first-input" || type === "largest-contentful-paint") {
    for (let key in json) {
      if (!/^(element|target)$/.test(key)) {
        continue;
      }

      delete json[key];
    }

    if (!!entry?.element) {
      json.elementTagName = entry.element?.tagName;
      json.elementClassName = entry.element?.className;
    }
  }

  if (!!observer.properties) {
    json.os = {
      ...observer.properties,
    };
  }

  return json;
}

export function sendEvent(event: string, payload: object) {
  state?.track?.(event, payload);
}

export function enqueueEvent(
  observer: Observer,
  entry: ExtendedPerformanceEntry
) {
  if (isEntryQueued(observer, entry)) {
    return;
  }

  disconnectObserver(observer);

  if (!observer.properties) {
    observer.properties = {};
  }

  observer.properties = {
    ...observer.properties,
    queued: true,
  };

  state.queue.push([observer, entry]);
}

export function processQueuedEvents() {
  if (!isReady() || !state.queue.length) {
    return;
  }

  state.queue.forEach(([observer, entry]) => {
    trackEntry(observer, entry);
  });

  state.queue = [];
}

export function disconnectObserver(observer: Observer) {
  if (!observer.instance) {
    return;
  }

  observer.instance.disconnect();
  observer.instance = undefined;
}

export function cleanupObserver(observer: Observer) {
  disconnectObserver(observer);
  observer.properties = undefined;
}

export function resetPerformanceMonitoringState() {
  try {
    if (!isSupported() || !state.initialized) {
      return;
    }

    state = createPageLoadState();
  } catch (error) {
    pkg.onError(new AnalyticsError("Failed to reset performance state", error));
  }
}

/*
 * Global observers can be created and injected into the head of the document
 * (see snippet.ts) when timing is critically important. If a global observer
 * is found that matches performance configuration, it will be returned here.
 *
 * ospm:  "outschool performance monitoring"
 */
export function findGlobalObserver(
  observer: Observer
): GlobalObserver | undefined {
  const ospm = window?.ospm;

  if (!ospm || !(ospm.instance instanceof window.PerformanceObserver)) {
    return;
  }

  if (ospm.type !== observer.type) {
    return;
  }

  return ospm;
}

/*
 * If a global observer is found and it hasn't received any entries, it's
 * disconnected in favor of a new local observer. Otherwise, if the global
 * observer does have an entry, it's processed (and cleaned up) just like a
 * local observer that receives an entry.
 *
 * The property `global` is set on event data so we can identify this case.
 */
export function processGlobalEvents(observer: Observer): boolean {
  const globalObserver = findGlobalObserver(observer);

  if (!globalObserver) {
    return false;
  }

  if (!!globalObserver.error) {
    pkg.onError(
      new AnalyticsError("Failed global observer", globalObserver.error)
    );
  }

  if (!globalObserver.entry) {
    globalObserver.instance?.disconnect();
    return false;
  }

  globalObserver.event = getEventName(globalObserver);
  globalObserver.properties = { global: true };
  trackEntry(globalObserver, globalObserver.entry);

  return true;
}

export function isEntryQueued(
  observer: Observer,
  entry: ExtendedPerformanceEntry
) {
  return state.queue.some(([queuedObserver]) => {
    if (observer.type !== "measure") {
      return queuedObserver.type === observer.type;
    }

    return (
      queuedObserver.type === "measure" && queuedObserver.label === entry.name
    );
  });
}

export function isValidEntry(
  observer: Observer,
  entry: ExtendedPerformanceEntry
): boolean {
  if (state.pathname !== getCurrentPathname()) {
    return false;
  }

  return observer.type !== "measure" || observer.label === entry.name;
}

function isReady() {
  return state.initialized && !!state.track && !!state.pathname;
}

export function getCurrentPathname(): string {
  return window?.location?.pathname;
}

export function isSupported(): boolean {
  return (
    !!window?.performance?.measure &&
    !!window?.PerformanceObserver?.supportedEntryTypes
  );
}

export function isEntryTypeSupported(type: string | undefined): boolean {
  if (!type) {
    return false;
  }

  return window?.PerformanceObserver?.supportedEntryTypes?.includes(type);
}
