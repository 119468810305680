import * as EmailValidator from "email-validator";
import lodashCompact from "lodash/compact";
import lodashTrim from "lodash/trim";
export * from "./categories";
export {
  mentionsMarkupToPlainText,
  hasMentionsMarkup,
  TEACHER_MENTION_ID,
  mentionsRegex,
} from "./mentions";

export function normalizeString(str: string | undefined): string | undefined {
  return str && lodashTrim(str).toLowerCase();
}

export function conjugateToBe(isPlural: boolean, isPresent: boolean): string {
  return isPresent ? (isPlural ? "are" : "is") : isPlural ? "were" : "was";
}

export function namePossessive(name: string, isPlural: boolean): string | null {
  if (isNullOrEmpty(name)) {
    return null;
  }
  if (isPlural) {
    return `${name}'`;
  } else {
    return `${name}'s`;
  }
}

export function splitEmailInputs(emailInputs: string): string[] {
  return lodashCompact(
    (!!emailInputs && !!emailInputs.split && emailInputs.split(/[,;\s]+/)) || []
  );
}

export function isNullOrEmpty(val: string | null | undefined): boolean {
  return val === null || val === undefined || val.trim() === "";
}

export function isInteger(val: string): boolean {
  return /^\d+$/.test(val);
}

export function plural(
  singularWord: string,
  count?: number,
  pluralWord?: string
): string {
  return count === undefined || count === 1
    ? singularWord
    : pluralWord || singularWord + "s";
}

export function joinCommasAnd(items: string[]): string | null {
  if (!items || !items.length) {
    return null;
  }
  if (items.length === 2) {
    return items.join(" and ");
  }

  if (items.length === 1) {
    return items[0];
  }
  //  names.length > 2
  const lastItem = items.pop();
  const penultimateItem = items.pop();
  // Oxford comma
  items.push([penultimateItem, lastItem].join(", and "));
  return items.join(", ");
}

// capitalize if: after start of line; after whitespace; or after '(', ')', '.' ''', '"', '-', '/', or '\'
// This is intended to handle most-to-all word boundaries for English proper nouns with full unicode support
export function startCaseProperNoun(str: string): string {
  return str
    ? str
        .trim()
        .toLocaleLowerCase()
        .replace(
          // we need to use a capturing group for boundary group b/c replace will replace the whole span of the regex match
          /((?:^|[\s().'"-\/\\])+(?![\s().'"-\/\\]))(.)/gm,
          (match, whitespace, firstLetter) =>
            whitespace + firstLetter.toLocaleUpperCase()
        )
    : str;
}

export function ordinalNumber(i: number): string {
  const j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

// See http://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
const UUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export function isUuid(testString: string) {
  return !!UUID_REGEX.exec(testString);
}

export function isValidEmail(str: string): boolean {
  return EmailValidator.validate(str);
}

export const duplicateEmailInviteMessage = "already has an Outschool account";
export const duplicateEmailSignupMessage =
  "is already connected to an Outschool account";

export const duplicateUsernameMessage = "is not available";
export const usernameFeedbackMessage =
  "Username must be 6-64 characters long and can only include numbers and letters.";
const usernameRegex = /^[a-zA-Z0-9]*$/;
export function isValidUsername(str: string): boolean {
  return str.length >= 6 && str.length <= 64 && usernameRegex.test(str);
}

export function isValidEmailOrUsername(str: string): boolean {
  return isValidUsername(str) || isValidEmail(str);
}

export const passwordStrengthFeedbackMessage =
  "Password must be at least 8 characters long and include a number, a letter, and a special character";
export const passwordTooLongFeedbackMessage =
  "Password must be 8-100 characters long and include a number, a letter, and a special character";

const specialCharacters = " !\"#$%&'()*+,-./:;<=>?@[]^_`{|}~";
const mustContainLettersAndNumbersRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
export function isStrongPassword(str: string): boolean {
  return (
    str.length >= 8 &&
    mustContainLettersAndNumbersRegex.test(str) &&
    str.split("").some(c => specialCharacters.includes(c))
  );
}

export function isTooShortPassword(str: string): boolean {
  return str.length < 8;
}

export function isTooLongPassword(str: string): boolean {
  return str.length > 100;
}

export function isValidPassword(str: string): boolean {
  return (
    !isTooShortPassword(str) && !isTooLongPassword(str) && isStrongPassword(str)
  );
}
