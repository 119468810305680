import { Box, Icon } from "@outschool/backpack";
import { fasExclamationTriangle } from "@outschool/icons";
import React from "react";

interface LoginUserFeedbackProps {
  userFeedbackMessage?: React.ReactNode | null;
  isError: Boolean;
}

export default function LoginUserFeedback({
  userFeedbackMessage,
  isError,
}: LoginUserFeedbackProps) {
  if (!userFeedbackMessage) {
    return null;
  }
  return (
    <Box
      sx={{
        justifySelf: "center",
        padding: "15px",
        background: !isError
          ? "rgba(253, 200, 64, 0.1)"
          : "rgba(241, 93, 93, 0.07)",
        border: !isError
          ? "1px solid rgba(253, 200, 64, 0.4)"
          : "1px solid rgba(241, 93, 93, 0.35)",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Icon
        sx={{
          fontSize: "16px",
          color: !isError ? "#fdc840" : "#f15d5d",
          position: "absolute",
          marginTop: "3px",
        }}
        icon={fasExclamationTriangle}
      />
      <Box
        sx={{
          paddingLeft: "30px",
        }}
      >
        {userFeedbackMessage}
      </Box>
    </Box>
  );
}
