/** @jsx jsxPragma */

import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import jsxPragma from "./jsxPragma";

type FlexProps = React.PropsWithChildren<
  {
    as?: React.ElementType;
    sx?: SystemStyleObject;
    /** Flex should not accept an onClick because of keyboard accessibility challenges
     * Instead use a Link, Button, or BoxButton */
    onClick?: undefined;
  } & Record<string, any>
>;

/**
 * @deprecated This component is deprecated. Please use \@outschool/backpack Box with flex prop
 */
function LegacyFlex(
  { as: Component = "div", sx, ...props }: FlexProps,
  ref: React.Ref<unknown>
) {
  return (
    <Component
      ref={ref}
      // @ts-ignore
      sx={{
        label: "Flex",
        display: "flex",
        ...sx,
      }}
      {...props}
    />
  );
}

export default React.forwardRef<unknown, FlexProps>(LegacyFlex);
