import { Theme } from "@mui/material";
import { DataGridProComponents } from "@mui/x-data-grid-pro/themeAugmentation";
import { Checkbox } from "../Checkbox/Checkbox";

export const DATA_GRID_PRO_THEME: DataGridProComponents<Theme>["MuiDataGrid"] =
  {
    defaultProps: {
      baseCheckbox: Checkbox,
    },
  };
