import { OUTSCHOOL_TIMEZONE, dayjs } from "@outschool/time";
import * as OSSubscriptions from "./OSSubscriptions";

export const REFERRAL_PROGRAM_START_DATE = "2018-07-16";
export const REFERRAL_CREDIT_EXPIRATION_DAYS = 30;
export const STORED_VALUE_REFERRAL_PROGRAM_START_DATE = "2021-01-06";
export const REFER_CREDIT_CENTS = 20 * 100;
export const OUTREACH_CASH_CENTS = 50 * 100;
export const REFER_TEACHER_CREDIT_CENTS = 200 * 100;
export const REFER_TEACHER_CREDIT_REQ_CENTS = 100 * 100;
export const MINIMUM_PRICE_CENTS = 100;
export const MAXIMUM_PRICE_CENTS = 1000000; // $10k
export const MINIMUM_REFUND_CENTS = 10;
export const UPFRONT_DISCOUNT_PERCENTAGES = [0, 5, 10, 15, 20, 25, 30];
export const WEEKLY_SURCHARGE_PERCENTAGES = UPFRONT_DISCOUNT_PERCENTAGES.map(
  percent => parseFloat(discountToSurchargePercentage(percent).toFixed(8))
);

export const DEFAULT_WEEKLY_SURCHARGE_PERCENTAGE = parseFloat(
  discountToSurchargePercentage(15).toFixed(8)
);

// Increasing our fee from 17% to 30%
const SERVICE_FEE_INCREASE_DATE = "2018-02-01";
// Some sections are grandfathered but it has a max start time limit
const SERVICE_FEE_INCREASE_GRANDFATHERING_END_DATE = "2018-06-01";

const PERCENTAGE_FOR_TEACHER = 0.7;
const PERCENTAGE_FOR_TEACHER_BEFORE_FEB_2018 = 0.83;

export function centsToDollars(priceInCents: number | null): number {
  if (!priceInCents) {
    return 0;
  }

  return priceInCents / 100.0;
}

export function centsPerLearnerPerWeek(
  activity: {
    is_ongoing_weekly?: boolean;
    price_cents?: number | null;
    duration_weeks?: number | null;
  },
  weeklySurchargePercentage?: number
): number {
  const centsPerLearnerPerWeek = !!activity.is_ongoing_weekly
    ? activity.price_cents!
    : activity.price_cents! / activity.duration_weeks!;

  if (weeklySurchargePercentage) {
    return Math.round(
      centsPerLearnerPerWeek * (1 + weeklySurchargePercentage / 100)
    );
  }
  return centsPerLearnerPerWeek;
}

export function centsPerLearnerPerMeeting(activity: {
  is_ongoing_weekly?: boolean;
  price_cents?: number | null;
  duration_weeks?: number | null;
  weekly_meetings?: number | null;
  isFlexSchedule?: boolean;
  isClub?: boolean;
  hasTeacherSchedule?: boolean;
}): number {
  // This will only be called on published activities, which will have all these fields
  if (activity.isClub) {
    // There are no "meetings" for clubs/interest groups.
    return activity.price_cents!;
  } else if (activity?.hasTeacherSchedule) {
    return activity.price_cents!;
  } else if (!!activity.is_ongoing_weekly) {
    return activity.price_cents! / activity.weekly_meetings!;
  } else if (activity.isFlexSchedule) {
    return activity.price_cents! / activity.duration_weeks!;
  } else {
    return (
      activity.price_cents! /
      (activity.duration_weeks! * activity.weekly_meetings!)
    );
  }
}

export function creditsPerLearnerPerMeeting(activity: {
  is_ongoing_weekly?: boolean;
  price_cents?: number | null;
  duration_weeks?: number | null;
  weekly_meetings?: number | null;
  isFlexSchedule?: boolean;
  isClub?: boolean;
  hasTeacherSchedule?: boolean;
}): number {
  return OSSubscriptions.priceCentsToCredits(
    centsPerLearnerPerMeeting(activity)
  );
}

export function perStudentPriceInCents(
  activity: { price_cents?: number | null },
  section?: { price_cents?: number | null } | null,
  enrollment?: { price_cents?: number | null } | null
): number {
  if (enrollment?.price_cents != null && enrollment.price_cents >= 0) {
    return enrollment.price_cents;
  } else if (section?.price_cents != null && section.price_cents >= 0) {
    return section.price_cents;
  } else if (activity?.price_cents != null && activity.price_cents >= 0) {
    return activity.price_cents;
  } else {
    return 0;
  }
}

export function estimatedWeeklyEarningsString(
  activity: {
    is_ongoing_weekly?: boolean;
    price_cents?: number | null;
    duration_weeks?: number | null;
    weekly_meetings?: number | null;
    isFlexSchedule?: boolean;
    isClub?: boolean;
    hasTeacherSchedule?: boolean;
    size_min?: number | null;
    size_max?: number | null;
  },
  weeklySurchargePercentage?: number
): string {
  const cents = centsPerLearnerPerWeek(activity, weeklySurchargePercentage);
  const lowerCents = activity.size_min! * cents * PERCENTAGE_FOR_TEACHER;
  const upperCents = activity.size_max! * cents * PERCENTAGE_FOR_TEACHER;

  if (activity?.hasTeacherSchedule) {
    const priceCents = activity.price_cents! * PERCENTAGE_FOR_TEACHER;
    return `$${Math.round(centsToDollars(priceCents))} USD per session`;
  }

  return `$${Math.round(centsToDollars(lowerCents))} - ${Math.round(
    centsToDollars(upperCents)
  )} USD per week`;
}

export function parseDollarStringToCents(dollarString: string): number {
  let parsedFloat = parseFloat(dollarString);
  if (isNaN(parsedFloat)) {
    return parsedFloat;
  }
  return Math.round(parsedFloat * 100);
}

// How much the seller expects to receive for these enrollments.
// This is the total list price, not accounting for any Outschool pricing offers
// the buyer took advantage of (e.g. financial assistance offer)
export function totalSellerPriceCentsForEnrollments(
  enrollments: Array<{
    price_cents?: number | null;
    priceCents?: number | null;
  }>
): number {
  if (!enrollments) {
    return 0;
  }
  // TODO: Remove price_cents once all instances have been replaced.
  return enrollments.reduce(function (
    currentTotal: number,
    enrollment: { price_cents?: number | null; priceCents?: number | null }
  ) {
    return (
      currentTotal + (enrollment.price_cents || enrollment.priceCents || 0)
    );
  },
  0);
}

export const ONE_ONE_ONE_MINIMUM_PRICE_CENTS_PER_HOUR = 3000;

export function oneOnOnePriceFloor(durationMinutes: number) {
  const pctHour = durationMinutes / 60;

  return (
    Math.round(
      centsToDollars(ONE_ONE_ONE_MINIMUM_PRICE_CENTS_PER_HOUR * pctHour)
    ) * 100
  );
}

export function standardPayoutCents(
  enrollmentVolumeCents: number,
  section: {
    start_time?: Date | null;
    checklist?: any;
  }
): number {
  const thresholdDate = dayjs.tz(SERVICE_FEE_INCREASE_DATE, OUTSCHOOL_TIMEZONE);
  const sectionDate = dayjs(section.start_time);

  const grandfatheringEndDate = dayjs.tz(
    SERVICE_FEE_INCREASE_GRANDFATHERING_END_DATE,
    OUTSCHOOL_TIMEZONE
  );

  const isGrandfathered =
    !!section.checklist &&
    !!section.checklist.grandfatheredAt2017Rate &&
    sectionDate.isBefore(grandfatheringEndDate);

  let payoutInCents;
  if (!isGrandfathered && sectionDate.isAfter(thresholdDate)) {
    payoutInCents = 1.0 * enrollmentVolumeCents * PERCENTAGE_FOR_TEACHER;
  } else {
    payoutInCents =
      1.0 * enrollmentVolumeCents * PERCENTAGE_FOR_TEACHER_BEFORE_FEB_2018;
  }
  // We cannot pay a fraction of a cent
  return Math.round(payoutInCents);
}

/**
 * Converts a discount percentage to a surcharge percentage
 * i.e. if the discount is 10%, the surcharge is 11.11%
 */
export function discountToSurchargePercentage(
  discountPercentage: number
): number {
  return (1 / (1 - discountPercentage / 100) - 1) * 100;
}

/**
 * Converts a surcharge percentage to a discount percentage
 */
export function surchargeToDiscountPercentage(
  surchargePercentage: number
): number {
  return (1 - 1 / (1 + surchargePercentage / 100)) * 100;
}

export const applyServiceFee = (
  price: number | null,
  serviceFeePct: number
) => {
  const serviceFee = 1 + serviceFeePct / 100;
  return Math.ceil((price || 0) * serviceFee);
};

export const serviceFeeCentsOnItem = (
  priceWithServiceFee: number | null,
  serviceFeePercentage: number
) => {
  const nonNullPrice = priceWithServiceFee ?? 0;
  return (
    nonNullPrice - Math.round(nonNullPrice / (1 + serviceFeePercentage / 100))
  );
};
