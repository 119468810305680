import { Components, Theme } from "@mui/material";

const TOOLTIP_THEME: Components<Theme>["MuiTooltip"] = {
  defaultProps: {
    arrow: true,
    enterTouchDelay: 250,
  },
  styleOverrides: {
    popper: () => ({
      zIndex: 9999,
    }),
    arrow: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.common.white,
      "&::before": {
        border: "1px solid",
        borderColor: theme.palette.neutral.light,
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.18)",
      },
    }),
    tooltip: ({ theme }: { theme: Theme }) => ({
      maxWidth: theme.spacing(500),
      whiteSpace: "normal",
      overflowWrap: "break-word",
      color: theme.palette.neutral.dark,
      backgroundColor: theme.palette.common.white,
      border: "1px solid",
      borderColor: theme.palette.neutral.light,
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.18)",
      borderRadius: theme.spacing(8),
      padding: theme.spacing(10),
      ...theme.typography.body2,
    }),
  },
};

export default TOOLTIP_THEME;
