import { Components, Theme } from "@mui/material";

import { SHARED_BUTTON_VARIANTS } from "../shared/buttonVariants";

export const ICON_BUTTON_THEME: Components<Theme>["MuiIconButton"] = {
  // Do not specify default props for variant, color, and size.
  // Instead, specifty them on the component itself.
  // This is because IconButton "variant" is custom, and it is not
  // playing well with "defaultProps" and "variants" theming.
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
  },
  variants: [
    /**
     * sizes
     */
    // small - matches Button small size
    {
      props: { size: "small" },
      style: ({ theme }: { theme: Theme }) => ({
        padding: theme.spacing(8),
        fontSize: theme.typography.pxToRem(14),
      }),
    },
    {
      props: { size: "small", edge: "start" },
      style: ({ theme }: { theme: Theme }) => ({
        marginLeft: theme.spacing(-8),
      }),
    },
    {
      props: { size: "small", edge: "end" },
      style: ({ theme }: { theme: Theme }) => ({
        marginRight: theme.spacing(-8),
      }),
    },
    // medium - matches Button medium size
    {
      props: { size: "medium" },
      style: ({ theme }: { theme: Theme }) => ({
        // matches Button medium size
        padding: theme.spacing(12),
        fontSize: theme.typography.pxToRem(16),
      }),
    },
    {
      props: { size: "medium", edge: "start" },
      style: ({ theme }: { theme: Theme }) => ({
        marginLeft: theme.spacing(-12),
      }),
    },
    {
      props: { size: "medium", edge: "end" },
      style: ({ theme }: { theme: Theme }) => ({
        marginRight: theme.spacing(-12),
      }),
    },
    // large - matches Button large size
    {
      props: { size: "large" },
      style: ({ theme }: { theme: Theme }) => ({
        // matches Button large size
        padding: theme.spacing(16),
        fontSize: theme.typography.pxToRem(18),
      }),
    },
    {
      props: { size: "large", edge: "start" },
      style: ({ theme }: { theme: Theme }) => ({
        marginLeft: theme.spacing(-16),
      }),
    },
    {
      props: { size: "large", edge: "end" },
      style: ({ theme }: { theme: Theme }) => ({
        marginRight: theme.spacing(-16),
      }),
    },
    ...SHARED_BUTTON_VARIANTS,
  ],
};
