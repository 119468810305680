import { Components, Theme } from "@mui/material";

export const SLIDER_THEME: Components["MuiSlider"] = {
  styleOverrides: {
    thumb: ({ theme }: { theme: Theme }) => ({
      height: theme.spacing(34),
      width: theme.spacing(34),
    }),
    rail: ({ theme }: { theme: Theme }) => ({
      backgroundColor: theme.palette.primary[200],
      height: theme.spacing(6),
    }),
    mark: ({ theme }: { theme: Theme }) => ({
      backgroundColor: theme.palette.primary[900],
      height: theme.spacing(6),
      width: theme.spacing(6),
      borderRadius: "50%",
    }),
    markLabel: ({ theme }: { theme: Theme }) => ({
      fontWeight: theme.typography.fontWeightBold,
      paddingTop: theme.spacing(8),
      fontSize: theme.typography.pxToRem(14),
    }),
  },
};
