import { Icon } from "@outschool/backpack";
import { fasExclamationTriangle } from "@outschool/icons";
import { UserFacingError, isApolloError } from "@outschool/ui-utils";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import LegacyBox from "./LegacyBox";

interface Props {
  includeIcon?: boolean;
  sx?: SystemStyleObject;
}

const ErrorMessage: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  includeIcon,
  sx,
}) => {
  const message = React.useMemo(() => {
    if (typeof children === "undefined" || children === null) {
      return null;
    } else if (isApolloError(children)) {
      return new UserFacingError(children).toString();
    } else if (React.isValidElement(children)) {
      return children;
    } else {
      return children.toString ? children.toString() : children;
    }
  }, [children]);

  if (message === null) {
    return null;
  }

  return (
    <LegacyBox
      sx={{
        marginTop: "tiny",
        fontSize: "tiny",
        lineHeight: "14px",
        height: "14px",
        color: "red",
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
    >
      {includeIcon && <Icon icon={fasExclamationTriangle} />}
      <span role="alert" style={{ marginLeft: "0.5em" }}>
        {message}
      </span>
    </LegacyBox>
  );
};

export default ErrorMessage;
