import { useMediaQuery, useTheme } from "@mui/material";
import MUIAlert, { AlertProps as MUIAlertProps } from "@mui/material/Alert";
import MUIAlertTitle from "@mui/material/AlertTitle";
import {
  faDiamondExclamation,
  faExclamationTriangle,
  faInfoCircle,
  faPartyHorn,
  faTimes,
} from "@outschool/icons";
import * as React from "react";

import { Button, ButtonProps } from "../Button/Button";
import Icon, { IconProps } from "../Icon/Icon";
import { IconButton, IconButtonProps } from "../IconButton/IconButton";

export const AlertSeverities = ["success", "info", "warning", "error"] as const;
export type AlertSeverity = (typeof AlertSeverities)[number];

export type AlertProps = Omit<
  MUIAlertProps,
  "color" | "variant" | "severity"
> & {
  title?: string;
  /**
   * Severity of the alert - determines the icon and color.
   * @default "error"
   */
  severity: AlertSeverity;
};

const SEVERITY_ICONS: Record<AlertSeverity, React.ReactElement<IconProps>> = {
  error: <Icon icon={faDiamondExclamation} />,
  warning: <Icon icon={faExclamationTriangle} />,
  info: <Icon icon={faInfoCircle} />,
  success: <Icon icon={faPartyHorn} />,
};

function Alert(
  {
    title,
    severity = "error",
    action,
    onClose,
    children,
    ...props
  }: AlertProps,
  ref: React.Ref<any>
) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AlertSeverityContext.Provider value={severity}>
      <MUIAlert
        ref={ref}
        iconMapping={SEVERITY_ICONS}
        severity={severity}
        action={
          <>
            {!isMobile && action}
            {onClose && <AlertCloseButton onClick={onClose} />}
          </>
        }
        {...props}
      >
        {title && <MUIAlertTitle>{title}</MUIAlertTitle>}
        {children}
        {isMobile && action}
      </MUIAlert>
    </AlertSeverityContext.Provider>
  );
}

export default React.forwardRef(Alert);

export type AlertActionButtonProps = Omit<
  ButtonProps,
  "size" | "variant" | "color" | "edge"
>;

/**
 * Button to be used as the action in an Alert.
 * Ensures the button is small, matches the height of the "X"
 * close button, and inherits colors from the parent Alert.
 * @param props
 * @param ref
 * @returns
 */
export const AlertActionButton = React.forwardRef(
  (
    { sx, ...props }: AlertActionButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => (
    <Button
      ref={ref}
      size="medium"
      variant="text"
      color={useAlertSeverity()}
      // make edge "start" on mobile to align with Alert content
      // using hooks this way is ill-advised but hilarious
      // eslint-disable-next-line i18next/no-literal-string
      edge={useMediaQuery(useTheme().breakpoints.down("md")) ? "start" : false}
      sx={[
        {
          color: "inherit",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  )
);

const AlertCloseButton = ({
  sx,
  ...props
}: Omit<IconButtonProps, "icon" | "variant" | "color" | "size">) => (
  <IconButton
    size="medium"
    icon={faTimes}
    variant="text"
    color={useAlertSeverity()}
    sx={[{ color: "inherit" }, ...(Array.isArray(sx) ? sx : [sx])]}
    {...props}
  />
);

const AlertSeverityContext = React.createContext<AlertSeverity>("error");
const useAlertSeverity = () => React.useContext(AlertSeverityContext);
