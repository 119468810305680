// This is the regex used matching the @-mention markup in the text.
/**
 * Warning!  This function must be kept in sync with
 * lang-js/apps/website/app/shared/Text.ts
 */
export const mentionsRegex =
  /@\[([^\]]+)\]\(([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|teacher)\)/;

export function hasMentionsMarkup(text: string): boolean {
  return mentionsRegex.test(text);
}

function defaultPlainTextConverter(text: string): string {
  return text;
}

function defaultMentionsTextConverter({
  display,
}: {
  display: string;
}): string {
  return `@${display}`;
}
/**
 * Warning!  This function must be kept in sync with
 * lang-js/apps/website/app/shared/Text.ts
 */
export function mentionsMarkupToPlainText(
  text: string,
  convertText: (text: string) => string = defaultPlainTextConverter,
  convertMention: (args: {
    id: string;
    display: string;
  }) => string = defaultMentionsTextConverter
): string {
  const groups = mentionsRegex.exec(text);

  if (groups && groups.length > 1) {
    const start = text.substring(0, groups.index);
    const mentionMarkup = groups[0];
    const display = groups[1];
    const id = groups[2];
    const rest = text.substring(groups.index + mentionMarkup.length);
    return `${convertText(start)}${convertMention({
      id,
      display,
    })}${mentionsMarkupToPlainText(rest, convertText, convertMention)}`;
  }

  return text;
}

export const TEACHER_MENTION_ID = "teacher";
