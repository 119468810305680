import React from "react";

import { Theme } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import DEFAULT_THEME from "./theme";
import { LocalizationProvider as DatePickersLocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LocalizationProvider as DatePickersProLocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDayjs as DatePickersAdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDayjs as DatePickersProAdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LicenseInfo } from "@mui/x-license";
const MUI_LICENSE_KEY =
  "286b1420df625c2dee03700fb9c73b2cTz05NDIxMSxFPTE3NTI0MzAzNzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

type ThemeProviderProps = {
  children: React.ReactNode;
  theme?: Theme | ((theme: Theme) => Theme);
};

const ThemeProvider = ({ children, theme }: ThemeProviderProps) => {
  if (typeof theme === "function") {
    // For merging themes
    return (
      <MuiThemeProvider theme={theme(DEFAULT_THEME)}>
        {children}
      </MuiThemeProvider>
    );
  }

  if (typeof theme === "object") {
    // For overriding the default theme
    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
  }

  // For only using the default theme
  return <MuiThemeProvider theme={DEFAULT_THEME}>{children}</MuiThemeProvider>;
};

export default React.memo((props: ThemeProviderProps) => (
  <DatePickersProLocalizationProvider dateAdapter={DatePickersProAdapterDayjs}>
    <DatePickersLocalizationProvider dateAdapter={DatePickersAdapterDayjs}>
      <ThemeProvider {...props} />
    </DatePickersLocalizationProvider>
  </DatePickersProLocalizationProvider>
));
