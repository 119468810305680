import { priceToDollarsAndCents } from "../lib/util";
import {
  Integration,
  IntegrationCategory,
} from "../providers/AnalyticsContext";
import { AnalyticsPlugin, AnalyticsPluginType } from ".";
import type { Context, EventProperties } from "../types";

declare global {
  interface Window {
    fbq: (key: string, event: string, data?: object) => void;
  }
}

const CATEGORY_MAP: Record<string, string> = {
  "Health & Wellness": "xsk6ucq72s",
  "Social Studies": "ovhaoohb31",
  Math: "3ouolbl87v",
  "Science & Nature": "63ybxgv9h7",
  "Life Skills": "plwm9kq5b4",
  Arts: "mgmgttn6zw",
  Music: "r4wmwwgdpw",
  "World Languages": "odbsoinqfv",
  "Coding & Tech": "5alvi79rzv",
  English: "7vydgikhij",
};

type BaseEventPayload = {
  content_ids: Array<string>;
  content_type: Array<"product">;
  currency: string;
  content_category: string;
};

type EventPayload = BaseEventPayload & {
  content_name: string;
  value: string;
  contents: Array<{ id: string; quantity: number; item_price: string }>;
};

type ProductItem = {
  product_id: string;
  name: string;
  price: number;
  quantity: number;
};

/*
 * This plugin only exists to piggyback on the official `FacebookPixel`
 * to send a modified payload for a few key events.
 */
export default class FacebookPlugin implements AnalyticsPlugin {
  name = Integration.Facebook;
  category = IntegrationCategory.Advertising;
  type = AnalyticsPluginType.destination;
  version = "0.1.0";

  isLoadable() {
    return true;
  }

  // Loaded and unloaded via `FacebookPixel`
  async load() {}
  async unload() {}

  isLoaded() {
    if (typeof window === "undefined") {
      return false;
    }

    return !!window?.fbq;
  }

  async track(context: Context): Promise<Context> {
    const { event, properties } = context.event;

    let eventName: string;
    let payload: EventPayload | undefined;
    switch (event) {
      case "Order Completed":
        eventName = "Purchase";
        payload = this.getOrderCompletedPayload(properties);
        break;
      case "Product Added":
        eventName = "AddToCart";
        payload = this.getProductAddedPayload(properties);
        break;
      case "Product Viewed":
        eventName = "ViewContent";
        payload = this.getProductViewedPayload(properties);
        break;
      default:
        return context;
    }

    window?.fbq?.("track", eventName, payload);

    return context;
  }

  getBasePayload(
    properties: EventProperties | undefined
  ): BaseEventPayload | undefined {
    if (!properties) {
      return undefined;
    }

    const { category, currency } = properties;
    const categoryId = CATEGORY_MAP[category];

    return {
      content_ids: [categoryId],
      content_type: ["product"],
      currency: currency || "USD",
      content_category: category,
    };
  }

  getOrderCompletedPayload(
    properties: EventProperties | undefined
  ): EventPayload | undefined {
    const basePayload = this.getBasePayload(properties);

    if (!basePayload) {
      return;
    }

    const products = properties?.products || [];
    const total = properties?.total;
    const product_id = products?.[0]?.product_id;
    const name = products?.[0].name;

    basePayload.content_ids.unshift(product_id);

    const value = priceToDollarsAndCents(total);

    return {
      ...basePayload,
      content_name: name,
      contents: products.map((item: ProductItem) => ({
        id: item.product_id,
        quantity: 1,
        item_price: priceToDollarsAndCents(item.price),
      })),
      value,
    };
  }

  getProductViewedPayload(
    properties: EventProperties | undefined
  ): EventPayload | undefined {
    const basePayload = this.getBasePayload(properties);

    if (!basePayload) {
      return;
    }

    const product_id = properties?.product_id;
    const name = properties?.name;
    const price = properties?.price;
    const value = priceToDollarsAndCents(price);

    basePayload.content_ids.unshift(product_id);

    return {
      ...basePayload,
      content_name: name,
      contents: [{ id: product_id, quantity: 1, item_price: value }],
      value,
    };
  }

  getProductAddedPayload(
    properties: EventProperties | undefined
  ): EventPayload | undefined {
    const basePayload = this.getBasePayload(properties);

    if (!basePayload) {
      return;
    }

    const product_id = properties?.product_id;
    const name = properties?.name;
    const price = properties?.price;
    const value = priceToDollarsAndCents(price);

    basePayload.content_ids.unshift(product_id);

    return {
      ...basePayload,
      content_name: name,
      contents: [{ id: product_id, quantity: 1, item_price: value }],
      value,
    };
  }
}
