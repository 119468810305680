import { CookieKeys } from "@outschool/data-schemas";
import {
  ANALYTICS_EVENTS_SCHEMA_ID,
  ANALYTICS_EVENTS_TOPIC,
  AnalyticsPluginRestProxyTrackArgs,
  RestProxyBrowserClient,
} from "@outschool/kafka-rest-proxy-client";
import { useFeatureFlag } from "@outschool/ui-components-shared";
import Cookies from "js-cookie";
import React from "react";

import pkg from "../../lib/pkg";
import useAnalytics from "../useAnalytics";

let kafkaRestProxyClient: RestProxyBrowserClient;

export default function useAnalyticsEventLogger() {
  const analytics = useAnalytics();
  const shouldLog = useFeatureFlag("enable-fe-signup-logging");
  if (!kafkaRestProxyClient) {
    if (pkg.env !== "test" && !pkg.kafkaRestProxy) {
      throw new Error(
        "Cannot call useAnalyticsEventLogger without environment variables defined"
      );
    }
    kafkaRestProxyClient = new RestProxyBrowserClient({
      endpoint: pkg.kafkaRestProxy,
    });
  }

  const loggedInUserExperimentUid = Cookies.get(
    CookieKeys.LoggedInUserExperimentUid
  );
  const loggedOutUserExperimentUid = Cookies.get(
    CookieKeys.LoggedOutUserExperimentUid
  );

  return React.useCallback(
    async (eventData: any) => {
      if (!shouldLog) {
        return;
      }
      logEvent({
        anonymous_id: await analytics.anonymousId(),
        loggedInUserExperimentUid,
        loggedOutUserExperimentUid,
        eventData,
      });
    },
    [
      loggedInUserExperimentUid,
      loggedOutUserExperimentUid,
      analytics,
      shouldLog,
    ]
  );
}

function getEventTrackArgs(payload: any): AnalyticsPluginRestProxyTrackArgs {
  return {
    event: payload,
    topic: ANALYTICS_EVENTS_TOPIC,
    topic_schema_id: ANALYTICS_EVENTS_SCHEMA_ID,
  };
}

function logEvent({
  anonymous_id,
  loggedInUserExperimentUid,
  loggedOutUserExperimentUid,
  eventData,
}: {
  anonymous_id: string | undefined;
  loggedInUserExperimentUid?: string;
  loggedOutUserExperimentUid?: string;
  eventData: any;
}) {
  try {
    kafkaRestProxyClient.sendEvent(
      getEventTrackArgs({
        original_timestamp: new Date().toISOString(),
        event_name: "signup",
        event_data: JSON.stringify(eventData),
        user_uid: null,
        anonymous_id,
        logged_in_user_experiment_uid: loggedInUserExperimentUid,
        logged_out_user_experiment_uid: loggedOutUserExperimentUid,
        user_agent: navigator.userAgent.slice(0, 200),
      })
    );
  } catch {
    console.error("Was unable to log");
  }
}
