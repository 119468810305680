import { BASE_LOCALE, I18nLocale } from "@outschool/localization";
import { guessBrowserTimeZone } from "@outschool/time";
import { createSetContextLink } from "@outschool/ui-apollo";
import { getDeviceUid, getResolution } from "@outschool/ui-utils";

import type { OutschoolTrackingHeaders } from "@outschool/data-schemas";
import { PageContext } from "@outschool/ui-analytics";
import { cacheFn } from "../lib/concurency-control";

export function createTrackingHeadersLink(
  clientVersion: number,
  locale: I18nLocale | undefined,
  pageContextRef: { current: PageContext } | undefined
) {
  // getResolution may be an expensive call if the browser determines that it
  // needs to repaint the UI. A little bit of delay here is probably tolerable
  // for a setting that would only change if the user resized their window.
  // The resolution will be fetched when needed and cached for 30 seconds.
  let cachedResolutionHeaders = cacheFn(getResolutionHeaders, 30_000);

  const staticHeaders = {
    "Accept-Language": locale ?? BASE_LOCALE,
    "x-client-version": clientVersion.toString(),
    "x-os-client-version": clientVersion.toString(),
    "x-os-device-uid": getDeviceUid() ?? null,
    "x-os-browser-timezone": guessBrowserTimeZone(),
  };

  return createSetContextLink((op, prevCtx) => {
    const resolutionHeaders = cachedResolutionHeaders();
    const pageCtx = pageContextRef?.current;
    const trackingHeaders: OutschoolTrackingHeaders = {
      ...staticHeaders,
      ...resolutionHeaders,
      "x-os-page-category": pageCtx?.pageCategory ?? null,
      "x-os-page-impression-id": pageCtx?.pageImpressionId ?? null,
      "x-os-page-load-id": pageCtx?.pageLoadId ?? null,
      "x-os-page-name": pageCtx?.pageName ?? null,
      "x-os-page-path": pageCtx?.pagePath ?? null,
    };
    return { headers: { ...prevCtx.headers, ...trackingHeaders } };
  });
}

function getResolutionHeaders() {
  const res = getResolution();
  return {
    "x-os-res-width": res.width?.toString() ?? null,
    "x-os-res-height": res.height?.toString() ?? null,
    "x-os-res-is-mobile": res.isMobile?.toString() ?? null,
  };
}
