/** @jsx jsxPragma */

import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import jsxPragma from "../jsxPragma";
import { useImpressionTracking } from "../tracking";

const ButtonBase = React.forwardRef(
  (props, ref: React.Ref<HTMLButtonElement>) => (
    <button
      // By default, button type is "submit" when the button is inside of a
      // form. We don't want that.
      type="button"
      ref={ref}
      {...props}
    />
  )
);

export interface LegacyTrackedButtonProps<
  C extends React.ElementType = "button"
> {
  component?: C; // support for Backpack
  as?: C; // support for legacy
  trackingName?: string;
  trackingViewEventName?: string;
  trackingTouchEventName?: string;
  trackingUniqueId?: string;
  trackingSharedProperties?: Record<string | number, any>;
  immediateAction?(e: MouseEvent): void;
  trackViews?: boolean;
  children?: React.ReactNode;
  sx?: SystemStyleObject;
  // [propName: string]: any;
}

export const LegacyTrackedButton = <C extends React.ElementType = "button">(
  {
    component,
    as,
    trackingName,
    trackingViewEventName,
    trackingTouchEventName,
    trackingUniqueId,
    trackingSharedProperties,
    immediateAction,
    trackViews,
    onClick,
    ...props
  }: LegacyTrackedButtonProps<C> &
    Omit<React.ComponentPropsWithoutRef<C>, keyof LegacyTrackedButtonProps<C>>,
  ref: React.Ref<any>
) => {
  const impressionNodeRef = React.useRef<HTMLElement | null>();

  const Component = component || as || ButtonBase;

  const { trackTouch } = useImpressionTracking({
    node: impressionNodeRef.current,
    trackingLabel: trackingName!,
    uniqueId: trackingUniqueId!,
    trackingEventName: trackingTouchEventName,
    impressionEventName: trackingViewEventName,
    sharedProperties: trackingSharedProperties,
    trackViews,
  });

  return (
    <Component
      ref={(node: any) => {
        if (node && node !== impressionNodeRef.current) {
          impressionNodeRef.current = node;
          if (typeof ref === "function") {
            ref(node);
          } else if (ref && ref.current !== node) {
            // @ts-ignore ref.current can be reassigned in this use case
            ref.current = node;
          }
        }
      }}
      onClick={(e: any) => {
        if (trackingName) {
          trackTouch();
        }
        immediateAction?.(e);
        onClick?.(e);
      }}
      {...props}
    />
  );
};
LegacyTrackedButton.displayName = "TrackedButton";

export default React.forwardRef(LegacyTrackedButton);
