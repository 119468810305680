import { CookieKeys } from "@outschool/data-schemas";
import { OsRefInput } from "@outschool/gql-frontend-generated";
import { redirectAfterLoginPath } from "@outschool/routes";
import Cookies from "js-cookie";
import omit from "lodash/omit";

import { findQueryString, makeUrl } from "./lib/UrlUtils";
import { getCouponCode } from "./CouponCode";
import { getTrackingSessionUid } from "./TrackingSession";
import { UserReferrerParams, getUserReferrerParams } from "./UserReferrer";
import { UtmKey, UtmParamKey, UtmParams, getUtmParams } from "./Utm";
import { A8AttributionFrom } from "./A8";

const ATTRIBUTION_VERSION = 7;

export type ReferrerAttribution = {
  attributionVersionNumber: number;
  trackingSessionUid?: string;
  originalReferrer: string | null; // the website the session originated from
  landingPage: string; // the link that the session began in
  currentUrl: string; // the current page url
  previousUrl: string | null; // the last page url
  pageViewNumberOfSession: number; // the page view number within the session (starts at 1)
  resetReason: ShouldResetAttributionReason | null;
  noResetReason: ShouldNotResetAttributionReason | null;
  resetTimestamp: string;
  couponCode: string | null;
  a8?: string | null; // JP tracking parameter
} & UtmParams &
  UserReferrerParams;

function isOutschoolWebUrl({
  url,
  learnerHostPrefix,
  hostPrefix,
}: {
  learnerHostPrefix: string;
  hostPrefix: string;
  url?: string | null;
}) {
  if (!url) {
    return false;
  }
  const considerAsExternalSubpaths = ["blog"];
  for (const subpath of considerAsExternalSubpaths) {
    if (url.startsWith(`${hostPrefix}/${subpath}`)) {
      return false;
    }
  }
  return url.startsWith(learnerHostPrefix) || url.startsWith(hostPrefix);
}

export enum ShouldResetAttributionReason {
  AttributionVersionNumberChanged = "AttributionVersionNumberChanged",
  CouponCodeChanged = "CouponCodeChanged",
  AttributionCookieNotYetSet = "AttributionCookieNotYetSet",
  ExternalNonOAuthReferrer = "ExternalNonOAuthReferrer",
  UtmParamChanged = "UtmParamChanged",
  AddressBarTrackingChanged = "AddressBarTrackingChanged",
  UsidChanged = "UsidChanged",
}

export enum ShouldNotResetAttributionReason {
  RedirectFromOAuthFlow = "RedirectFromOAuthFlow",
  OutschoolReferrer = "OutschoolReferrer",
  NoChanges = "NoChanges",
  RedirectFromStripe = "RedirectFromStripe",
  RedirectFromClassWallet = "RedirectFromClassWallet",
  NotInitialPageLoad = "NotInitialPageLoad",
}

function getShouldResetAttributionReason({
  existingAttribution,
  newAttribution,
  hostPrefix,
  learnerHostPrefix,
  isInitialPageLoad,
}: {
  existingAttribution: ReferrerAttribution;
  newAttribution: ReferrerAttribution;
  learnerHostPrefix: string;
  hostPrefix: string;
  isInitialPageLoad: boolean;
}):
  | {
      resetReason: ShouldResetAttributionReason;
      noResetReason: null;
    }
  | {
      resetReason: null;
      noResetReason: ShouldNotResetAttributionReason;
    } {
  if (!isInitialPageLoad) {
    return {
      resetReason: null,
      noResetReason: ShouldNotResetAttributionReason.NotInitialPageLoad,
    };
  }
  const hasKeyChanged = (key: keyof ReferrerAttribution) =>
    !!newAttribution[key] && newAttribution[key] !== existingAttribution[key];

  const utmParamKeys = Object.keys(UtmParamKey) as UtmParamKey[];
  const usidKeys: (keyof ReferrerAttribution)[] = ["usid"];
  // Update if os-ref has not yet been set
  if (Object.keys(getReferrerAttribution()).length === 0) {
    return {
      resetReason: ShouldResetAttributionReason.AttributionCookieNotYetSet,
      noResetReason: null,
    };
  }

  // Attribution version updated, reset
  if (existingAttribution.attributionVersionNumber !== ATTRIBUTION_VERSION) {
    return {
      resetReason: ShouldResetAttributionReason.AttributionVersionNumberChanged,
      noResetReason: null,
    };
  }

  // Do not update attribution for redirects from oauth flows
  if (newAttribution.landingPage?.startsWith(redirectAfterLoginPath())) {
    return {
      resetReason: null,
      noResetReason: ShouldNotResetAttributionReason.RedirectFromOAuthFlow,
    };
  }
  // We do not want to reset when redirecting from stripe in the case of afterpay
  if (newAttribution.originalReferrer?.includes("stripe.com")) {
    return {
      resetReason: null,
      noResetReason: ShouldNotResetAttributionReason.RedirectFromStripe,
    };
  }
  // We do not want to reset when redirecting from ClassWallet so that we can track the original attribution
  if (newAttribution.originalReferrer?.includes("classwallet.com")) {
    return {
      resetReason: null,
      noResetReason: ShouldNotResetAttributionReason.RedirectFromClassWallet,
    };
  }
  if (
    !isOutschoolWebUrl({
      url: newAttribution.originalReferrer,
      hostPrefix,
      learnerHostPrefix,
    }) &&
    hasKeyChanged("originalReferrer")
  ) {
    return {
      resetReason: ShouldResetAttributionReason.ExternalNonOAuthReferrer,
      noResetReason: null,
    };
  }
  if (!!newAttribution.couponCode && hasKeyChanged("couponCode")) {
    return {
      resetReason: ShouldResetAttributionReason.CouponCodeChanged,
      noResetReason: null,
    };
  }
  for (const key of utmParamKeys) {
    if (hasKeyChanged(key)) {
      return {
        resetReason: ShouldResetAttributionReason.UtmParamChanged,
        noResetReason: null,
      };
    }
  }
  for (const key of usidKeys) {
    if (hasKeyChanged(key)) {
      return {
        resetReason: ShouldResetAttributionReason.UsidChanged,
        noResetReason: null,
      };
    }
  }
  if (
    isOutschoolWebUrl({
      url: newAttribution.originalReferrer,
      hostPrefix,
      learnerHostPrefix,
    })
  ) {
    return {
      resetReason: null,
      noResetReason: ShouldNotResetAttributionReason.OutschoolReferrer,
    };
  }
  return {
    resetReason: null,
    noResetReason: ShouldNotResetAttributionReason.NoChanges,
  };
}

export function getReferrerAttribution() {
  let existingCookieValueStr = Cookies.get(CookieKeys.OsRef);
  const referrerAttribution = !!existingCookieValueStr
    ? JSON.parse(existingCookieValueStr)
    : {};

  if (!!referrerAttribution?.source) {
    delete referrerAttribution.source;
  }

  return referrerAttribution;
}

export function updateReferrerAttribution({
  isInitialPageLoad,
  originalReferrer,
  originalLocation,
  currentLocation,
  learnerHostPrefix,
  hostPrefix,
  useSecureCookie = false,
}: {
  isInitialPageLoad: boolean;
  originalReferrer: string;
  originalLocation: Location;
  currentLocation: Location;
  learnerHostPrefix: string;
  hostPrefix: string;
  useSecureCookie?: boolean;
}) {
  const trackingSessionUid = getTrackingSessionUid();
  let existingAttribution = getReferrerAttribution();
  const originalReferrerNoOutschool = isOutschoolWebUrl({
    url: originalReferrer,
    hostPrefix,
    learnerHostPrefix,
  })
    ? null
    : originalReferrer;
  if (!!existingAttribution.originalReferrer) {
    existingAttribution.originalReferrer = isOutschoolWebUrl({
      url: existingAttribution.originalReferrer,
      hostPrefix,
      learnerHostPrefix,
    })
      ? null
      : existingAttribution.originalReferrer;
  }

  const queryString = findQueryString(currentLocation.search);

  const newAttribution: ReferrerAttribution = {
    attributionVersionNumber: ATTRIBUTION_VERSION,
    trackingSessionUid,
    originalReferrer: originalReferrerNoOutschool,
    landingPage: makeUrl(originalLocation),
    currentUrl: makeUrl(currentLocation),
    previousUrl: null,
    pageViewNumberOfSession: 1,
    resetReason: null,
    noResetReason: null,
    resetTimestamp: new Date().toISOString(),
    couponCode: getCouponCode(queryString),
    ...getUtmParams(queryString),
    ...getUserReferrerParams(queryString),
    ...A8AttributionFrom(queryString),
  };
  const { resetReason, noResetReason } = getShouldResetAttributionReason({
    existingAttribution,
    newAttribution,
    learnerHostPrefix,
    hostPrefix,
    isInitialPageLoad,
  });
  if (!!resetReason) {
    /**
     * Carry over any previous attribution done by a8.net as per their
     * documentation.
     *
     * https://document.a8.net/a8docs/a8-tracking/session-tracking/a8-session-tracking.html
     */
    if (existingAttribution.a8) {
      newAttribution.a8 = existingAttribution.a8;
    }

    existingAttribution = newAttribution;
  } else {
    existingAttribution.previousUrl = existingAttribution.currentUrl;
    existingAttribution.currentUrl = makeUrl(currentLocation);
    try {
      existingAttribution.pageViewNumberOfSession += 1;
    } catch (e) {
      existingAttribution.pageViewNumberOfSession = 1;
    }
  }
  existingAttribution.resetReason = resetReason;
  existingAttribution.noResetReason = noResetReason;
  Cookies.set(CookieKeys.OsRef, JSON.stringify(existingAttribution), {
    secure: useSecureCookie,
    sameSite: "lax",
  });
  return existingAttribution as ReferrerAttribution;
}

type OsRefToAttributionMap = {
  [UtmParamKey.utm_campaign]: UtmKey.campaign;
  [UtmParamKey.utm_content]: UtmKey.content;
  [UtmParamKey.utm_medium]: UtmKey.medium;
  [UtmParamKey.utm_source]: UtmKey.source;
  [UtmParamKey.utm_term]: UtmKey.term;
  originalReferrer: "referer";
};

type AttributionToOsRefMap = {
  [UtmKey.campaign]: UtmParamKey.utm_campaign;
  [UtmKey.content]: UtmParamKey.utm_content;
  [UtmKey.medium]: UtmParamKey.utm_medium;
  [UtmKey.source]: UtmParamKey.utm_source;
  [UtmKey.term]: UtmParamKey.utm_term;
  referer: "originalReferrer";
};

export type MappedAttribution = Omit<
  ReferrerAttribution,
  keyof OsRefToAttributionMap
> & {
  [key in keyof AttributionToOsRefMap]: string | null | undefined;
} & {
  addressBar?: boolean;
};

export const osRefToAttributionMap: OsRefToAttributionMap = Object.freeze({
  [UtmParamKey.utm_campaign]: UtmKey.campaign,
  [UtmParamKey.utm_content]: UtmKey.content,
  [UtmParamKey.utm_medium]: UtmKey.medium,
  [UtmParamKey.utm_source]: UtmKey.source,
  [UtmParamKey.utm_term]: UtmKey.term,
  originalReferrer: "referer",
});

export function mapReferrerAttribution(
  osRef: ReferrerAttribution | OsRefInput
): MappedAttribution {
  const attribution = omit(
    osRef,
    Object.keys(osRefToAttributionMap)
  ) as MappedAttribution;
  Object.keys(osRef).forEach(key => {
    if (!osRefToAttributionMap.hasOwnProperty(key)) {
      return;
    }

    const originalKey = key as keyof OsRefToAttributionMap;
    const mappedKey = osRefToAttributionMap[originalKey];
    attribution[mappedKey] = osRef[originalKey];
  });

  return attribution;
}
