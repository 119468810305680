/** @jsx jsxPragma */

import { SystemStyleObject } from "@styled-system/css";
import lodashOmit from "lodash/omit";
import React from "react";

import jsxPragma from "./jsxPragma";

export interface LegacyBoxProps {
  /** Style props (extended to include some non-standard style options). */
  sx?: SystemStyleObject | null;
  /** Change wrapping element, e.g. "span". */
  as?: React.ElementType;
  [prop: string]: any;
}

/**
 * Basic Box primitive.
 * @deprecated This component is deprecated. Please use \@outschool/backpack Box
 */
export const LegacyBox: React.ForwardRefExoticComponent<LegacyBoxProps> =
  React.forwardRef<unknown, LegacyBoxProps>(
    ({ as: Component = "div", sx, ...props }, ref) => {
      return (
        // @ts-ignore
        /* eslint-disable-next-line i18next/no-literal-string */
        <Component ref={ref} sx={sx} {...lodashOmit(props, "data-testid")} />
      );
    }
  );

export default LegacyBox;
