import { Box } from "@outschool/backpack";
import { Screen } from "@outschool/ui-utils";
import React from "react";

import { DismissibleAlert } from "../DismissibleAlert";
import { usePageAlerts } from "./PageAlertsProvider";
import { PageAlertProps } from "./PageAlertTypes";

const PageAlert = ({ content, onCollapseEnd, ...alert }: PageAlertProps) => {
  const { removePageAlert } = usePageAlerts();

  React.useEffect(() => {
    // Scroll to the top of the page where the alert is so the user sees it.
    Screen.scrollToTop();
  }, []);

  return (
    <DismissibleAlert
      {...alert}
      onCollapseEnd={() => {
        onCollapseEnd?.();
        removePageAlert(alert.id);
      }}
      containerSx={{
        width: "100%",
      }}
      sx={{
        // Page width Alert styling
        borderRadius: 0,
        border: "none",
      }}
    >
      {content}
    </DismissibleAlert>
  );
};

const PageAlerts = () => {
  const { pageAlerts } = usePageAlerts();
  const pageAlertsEntries = Object.entries(pageAlerts);

  if (pageAlertsEntries.length === 0) {
    return null;
  }

  return (
    <Box>
      {pageAlertsEntries.map(([id, pageAlert]) => (
        <PageAlert key={id} {...pageAlert} />
      ))}
    </Box>
  );
};

export default React.memo(PageAlerts);
