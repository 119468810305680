import { queryStringToObject } from "./lib/UrlUtils";

export type UserReferrerParams = {
  ruid: string;
  usid: string;
};

export function getUserReferrerParams(queryString: string): UserReferrerParams {
  const { ruid, usid: rawUsid } = queryStringToObject(queryString);
  // edge case: scrub the users # out of the usid
  const usid = rawUsid?.split("#")?.[0];
  return {
    ruid,
    usid,
  };
}
