import { Icon } from "@outschool/backpack";
import { faChevronDown } from "@outschool/icons";
import isEqual from "lodash/isEqual";
import React from "react";

import jsxPragma from "./jsxPragma";
import LegacyBox from "./LegacyBox";
import LegacyFlex from "./LegacyFlex";
import {
  BaseSelectProps,
  SelectOption,
  isSelectOption,
  makeOptionLabel,
  makeOptionValue,
  makeValue,
} from "./Select";

const SelectWithSx = (props: any) =>
  jsxPragma(
    ({ selectRef, ...props }: any) => <select {...props} ref={selectRef} />,
    props
  );

/**
 * The same basic behavior as `Select`, but uses html `<optgroup>`s to group options.
 * Accepts an `optGroups` prop rather than `options`, which must be structured as an
 * object with the optgroup names as keys and arrays of options as values.
 * @example { "Dogs": ["Dachshund", "Poodle", "Lab"], "Cats": ["Maine Coon", "Calico"] }
 * @example { "Group 1":
 *            [
 *              { value: "opt1", label: "Option 1" },
 *              { value: "opt2", label: "Option 2" },
 *            ],
 *            "Group 2":
 *            [
 *              { value: "opt3", label: "Option 3" },
 *              { value: "opt4", label: "Option 4" },
 *            ],
 *          }
 */
export default React.forwardRef(SelectWithOptGroups);

const PLACEHOLDER_VALUE = "Select.PLACEHOLDER_VALUE";

type OptGroupsPropType<T> = Record<string, Array<SelectOption<T> | T>>;
type OptGroupsType<T> = Record<string, Array<SelectOption<T>>>;
type SelectProps<T> = {
  onChange?: (v: T | null) => void;
  optGroups: OptGroupsPropType<T>;
  value?: T;
} & BaseSelectProps;

export function prepareOptGroups<T>(
  optGroups: OptGroupsPropType<T>
): OptGroupsType<T> {
  let preparedOptGroups: OptGroupsType<T> = {};
  Object.keys(optGroups).forEach(
    (group, idx) =>
      (preparedOptGroups[group] = optGroups[group].map(option =>
        isSelectOption<T>(option)
          ? { optionValue: makeOptionValue(option.value, idx), ...option }
          : {
              optionValue: makeOptionValue(option, idx),
              label: makeOptionLabel(option),
              value: makeValue(option),
            }
      ))
  );
  return preparedOptGroups;
}

function findOptionWithValue<T>(
  optGroups: OptGroupsType<T>,
  valueToMatch: any
) {
  let opt;
  for (let group in optGroups) {
    opt = optGroups[group].find(({ value }) => isEqual(value, valueToMatch));
    if (opt) {
      return opt;
    }
  }
  return null;
}

function SelectWithOptGroups<T>(
  {
    placeholder,
    includeBlank,
    onChange,
    optGroups: propOptGroups,
    name,
    value: propValue,
    handleChangeOnBlur = true,
    selectPlaceholderOnNull = false,
    style,
    sx = {},
    ...selectProps
  }: SelectProps<T>,
  ref: React.Ref<unknown>
) {
  const optGroups = prepareOptGroups(propOptGroups);

  // Find a matching option and select it
  const selectedOption = findOptionWithValue(optGroups, propValue);
  let value = selectedOption?.optionValue;
  if (selectPlaceholderOnNull && (placeholder || includeBlank)) {
    value = value ?? PLACEHOLDER_VALUE;
  }

  const handleChange = ({
    target: { value: eventValue },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    const opt = findOptionWithValue(optGroups, eventValue);
    onChange && onChange(opt?.value ?? null);
  };

  const { borderColor, color, ...selectSx } = sx as any;

  return (
    <LegacyBox
      sx={{
        display: "inline-block",
        position: "relative",
        border: "2px solid",
        borderRadius: 999,
        backgroundColor: "white",
        height: "42px",
        borderColor: selectProps.disabled
          ? "disabledText"
          : borderColor ?? "primary",
        ...selectSx,
        select: {
          height: "100%",
          width: "100%",
          appearance: "none",
          border: "none",
          color: selectProps.disabled ? "disabledText" : color ?? "primary",
          fontWeight: "semibold",
          backgroundColor: "transparent",
          paddingInlineStart: "1em",
          paddingInlineEnd: "2.5em",
          textOverflow: "ellipsis",
          ...selectSx.select,
        },
      }}
      style={style}
      ref={ref}
    >
      <SelectWithSx
        {...selectProps}
        value={value}
        name={name}
        onChange={handleChange}
        onBlur={handleChangeOnBlur ? handleChange : undefined}
      >
        {(placeholder || includeBlank) && (
          <option value={PLACEHOLDER_VALUE} key="placeholder">
            {placeholder || ""}
          </option>
        )}
        {Object.keys(optGroups).map((optGroupLabel, idx) => {
          return (
            <optgroup label={optGroupLabel} key={idx}>
              {optGroups[optGroupLabel].map(
                ({ label, optionValue, ...optionProps }, idx2) => (
                  <option
                    {...optionProps}
                    value={optionValue}
                    key={`${idx}-${idx2}`}
                  >
                    {label}
                  </option>
                )
              )}
            </optgroup>
          );
        })}
      </SelectWithSx>
      <LegacyFlex
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          alignItems: "center",
          justifyContent: "center",
          paddingRight: "medium",
          pointerEvents: "none",
        }}
      >
        <Icon
          icon={faChevronDown}
          sx={{
            paddingTop: "3px",
            color: selectProps.disabled ? "grey.400" : color ?? "primary",
          }}
        />
      </LegacyFlex>
    </LegacyBox>
  );
}
