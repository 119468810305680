enum A8Field {
  a8 = "a8",
}

export type A8Attribution = {
  [key in A8Field]?: string | null;
};

/**
 * Extracts relevant query params for A8 tracking from URL.
 *
 * @param queryString - A query string taken from a path or URL.
 */
export function A8AttributionFrom(queryString: string): A8Attribution {
  const attribution: { [key in A8Field]?: string | null } = {};

  if (!queryString) {
    return attribution;
  }

  const params = new URLSearchParams(queryString);

  if (params.has(A8Field.a8)) {
    attribution[A8Field.a8] = params.get(A8Field.a8);
  }

  return attribution;
}
