/** @jsx jsxPragma */

import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import jsxPragma from "./jsxPragma";

export type LegacyImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  sx?: SystemStyleObject;
};

function LegacyImage(
  { sx, ...props }: LegacyImageProps,
  ref: React.Ref<HTMLImageElement>
) {
  // @ts-ignore sx is present via jsxPragma
  // eslint-disable-next-line
  return <img ref={ref} sx={sx} {...props} />;
}

/**
 * @deprecated Use `Image` from `@outschool/backpack` instead.
 */
export default React.forwardRef(
  LegacyImage
) as React.ForwardRefExoticComponent<LegacyImageProps>;
