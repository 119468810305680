import { Icon } from "@outschool/backpack";
import { faChevronDown } from "@outschool/icons";
import { SystemStyleObject } from "@styled-system/css";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import React from "react";

import jsxPragma from "./jsxPragma";
import { LegacyBox } from "./LegacyBox";
import LegacyFlex from "./LegacyFlex";

const SelectWithSx = (props: any) =>
  jsxPragma(
    ({ selectRef, ...props }: any) => <select {...props} ref={selectRef} />,
    props
  );

export default React.forwardRef(Select);

/**
 * Select
 * a simplification of the `select` intrinsic component.
 * The differences are
 * 1. direct support for any type of value
 * 2. `onChange` is called with the selected value, not a mouse event
 * 3. a `options` prop, which is a list of strings, numbers, or `SelectOption`s
 *    and are presented as the options in the select
 * 4. a `includeBlank`prop , which will add a blank option to the front of the
 *    list. Selecting this will cause the onChange to be called with `null`.
 * 5. a `includeBlank`prop , a string which will be added to the front of the
 *    listed as an option. Selecting this will cause the onChange to be called with `null`.
 *
 * A `SelectOption` must have a string `label`, that will be shown to the user, and a value.
 * If it has an `optionValue`, that will be used as the value of the corresponding `option`
 * component; if not, if the value is a string or number, that will be the `option`'s value;
 * if not, the `Select` component will generate an option-value.
 *
 * Any other member of the `SelectOption` object will be passed as props to the `option`
 * component; `disabled` is probably the most useful.
 */
const PLACEHOLDER_VALUE = "Select.PLACEHOLDER_VALUE";

export type SelectOption<T> = {
  label: string | JSX.Element;
  value: T;
  optionValue?: string | number;
  sx?: SystemStyleObject;
} & Omit<JSX.IntrinsicElements["option"], "chidren" | "value" | "key">;

export function isSelectOption<T>(s: any): s is SelectOption<T> {
  return s && s["label"] && s["value"] !== undefined;
}

export function prepareSelectOption<T>(
  options: Array<SelectOption<T> | T>
): Array<SelectOption<T>> {
  return options.map((e, idx) =>
    isSelectOption<T>(e)
      ? { optionValue: makeOptionValue(e.value, idx), ...e }
      : {
          optionValue: makeOptionValue(e, idx),
          label: makeOptionLabel(e),
          value: makeValue(e),
        }
  );
}

export type BaseSelectProps = {
  placeholder?: string;
  includeBlank?: boolean;
  handleChangeOnBlur?: boolean;
  selectPlaceholderOnNull?: boolean;
  sx?: SystemStyleObject;
  selectRef?: React.Ref<HTMLSelectElement>;
} & Omit<JSX.IntrinsicElements["select"], "onChange" | "value">;

export type SelectProps<T> = {
  onChange?: (v: T | null) => void;
  options: Array<SelectOption<T> | T>;
  value?: T;
} & BaseSelectProps;

export function makeOptionValue<T>(e: T, idx: number): string {
  switch (typeof e) {
    case "string":
      return e;
    case "number":
      return String(e);
    default:
      return `__OPTION-${idx}`;
  }
}

export function makeOptionLabel<T>(e: T): string {
  return e === null || e === undefined ? "" : String(e);
}
export function makeValue<T>(e: T): T {
  /**
   * There is an odd legacy behavior we have to mimic here:
   * undefined (and null, I guess) should be treated as blank.
   */
  return (e === null || e === undefined ? "" : e) as unknown as T;
}

function Select<T>(
  {
    placeholder,
    includeBlank,
    onChange,
    options: propOptions,
    name,
    value: propValue,
    handleChangeOnBlur = true,
    selectPlaceholderOnNull = false,
    style,
    sx = {},
    ...selectProps
  }: SelectProps<T>,
  ref: React.Ref<unknown>
) {
  const options = prepareSelectOption(propOptions);

  const opt = find(options, ({ value }) => isEqual(propValue, value));

  const handleChange = ({
    target: { value: tvalue },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    const opt = find(
      options,
      ({ optionValue }) => String(optionValue) === String(tvalue)
    );
    onChange && onChange(opt ? opt.value : null);
  };

  let value = opt?.optionValue;
  if (selectPlaceholderOnNull && (placeholder || includeBlank)) {
    value = value ?? PLACEHOLDER_VALUE;
  }

  const { borderColor, color, ...selectSx } = sx as any;

  return (
    <LegacyBox
      sx={{
        display: "inline-block",
        position: "relative",
        border: "2px solid",
        borderRadius: 999,
        backgroundColor: "white",
        borderColor: selectProps.disabled
          ? "disabledText"
          : borderColor ?? "primary",
        height: "42px",
        ...selectSx,
        select: {
          height: "100%",
          width: "100%",
          appearance: "none",
          border: "none",
          color: selectProps.disabled ? "disabledText" : color ?? "primary",
          fontSize: "1.6rem",
          lineHeight: 1.6,
          fontWeight: "semibold",
          backgroundColor: "transparent",
          paddingInlineStart: "1em",
          paddingInlineEnd: "2.5em",
          textOverflow: "ellipsis",
          ...selectSx.select,
        },
      }}
      ref={ref}
      style={style}
    >
      <SelectWithSx
        {...selectProps}
        value={value}
        name={name}
        onChange={handleChange}
        onBlur={handleChangeOnBlur ? handleChange : undefined}
      >
        {(placeholder || includeBlank) && (
          <option value={PLACEHOLDER_VALUE} key="placeholder">
            {placeholder || ""}
          </option>
        )}
        {options.map(({ label, optionValue, ...optionProps }, idx) => (
          <option {...optionProps} value={optionValue} key={idx}>
            {label}
          </option>
        ))}
      </SelectWithSx>
      <LegacyFlex
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          alignItems: "center",
          justifyContent: "center",
          paddingRight: "medium",
          pointerEvents: "none",
        }}
      >
        <Icon
          icon={faChevronDown}
          sx={{
            paddingTop: "3px",
            color: selectProps.disabled ? "grey.400" : color ?? "primary",
          }}
        />
      </LegacyFlex>
    </LegacyBox>
  );
}
