import { DAY_END_HOUR, DAY_START_HOUR, dayjs } from "@outschool/time";
import ldIsNumber from "lodash/isNumber";
import ldOmitBy from "lodash/omitBy";
import ldPick from "lodash/pick";

import * as ActivitySubjects from "../ActivitySubjects";
import * as SEO from "../SEO";
import {
  START_END_DATE_FORMAT,
  dateFilterToDayjs,
  isDateAfterOtherDates,
} from "./DateOrTime";

// Utility for manipulating a "filters" object, which represents ALL query parameters on the
// listings page.

export function slugifyTitle(title?: string | null) {
  if (title) {
    return title
      .toLowerCase()
      .replace(/\([^)]*\)/g, "")
      .replace(/[!'",:.&]/g, "")
      .replace(/[\s]/g, "-")
      .replace(/[-]+/g, "-")
      .replace(/-$/g, "");
  } else {
    return null;
  }
}

// this hash stores a list of query terms that should be mapped to actual themes
// doubtless more sophisticated ways to handle this but it's a simple first pass
let queryToThemesHash: any = {};
// populate with full theme terms
ActivitySubjects.Subjects.forEach(
  e => (queryToThemesHash[slugifyTitle(e)!] = e)
);
ActivitySubjects.Subjects.forEach(
  e => (queryToThemesHash[e.toLowerCase()] = e)
);

// add in other terms
const otherTerms = {
  health: "Health & Wellness",
  "study skills": "Life Skills",
  coding: "Coding & Tech",
  technology: "Coding & Tech",
  programming: "Coding & Tech",
  science: "Science & Nature",
  art: "Arts",
  ela: "English",
  "foreign language": "World Languages",
  "foreign languages": "World Languages",
};
Object.assign(queryToThemesHash, otherTerms);

const FILTER_CATCHALL_VALUES = {
  age: "All ages",
  theme: "All subjects",
  time: "Any time",
  timeOfDay: "Any time",
  format: "All formats",
  delivery: "Any length",
  startAfterTime: DAY_START_HOUR,
  endByTime: DAY_END_HOUR,
};

// looks for the param themeOrQuery and maps it into either the
// theme or q parameter as appropriate
export function mapThemeOrQuery(query: Record<string, any>) {
  let queryCopy = Object.assign({}, query);

  if (queryCopy.themeOrQuery) {
    let theme = queryToThemesHash[queryCopy.themeOrQuery.toLowerCase()];
    if (theme) {
      queryCopy.theme = theme;
    } else {
      queryCopy.q =
        queryCopy.themeOrQuery + (queryCopy.q ? " " + queryCopy.q : "");
    }
    delete queryCopy.themeOrQuery;
  }

  if (queryCopy.theme && SEO.isTermWithinSubjectList(queryCopy.theme)) {
    queryCopy.theme = SEO.niceSubjectTerm(queryCopy.theme);
  }

  return queryCopy;
}

// used when a route populates themeOrQuery into a param
export function fromQueryAndParams(
  query: Record<string, any>,
  params: Record<string, any>
) {
  if (params.themeOrQuery) {
    params.themeOrQuery = cleanSearchTerm(params.themeOrQuery);
    let queryCopy = Object.assign({}, query);
    queryCopy.themeOrQuery = params.themeOrQuery;

    return fromQuery(queryCopy);
  }
  return fromQuery(query);
}

export const SAVED_ORDER_OPTION_DEFAULT = "saved";

export const PRICE_PER_MEETING_FILTERS = [
  "pricePerMeetingMax",
  "pricePerMeetingMin",
];

export const PRICE_PER_COURSE_FILTERS = [
  "pricePerCourseMax",
  "pricePerCourseMin",
];

export type CapacityFilterType = {
  capacityMax?: number;
  capacityMin?: number;
};

export const CAPACITY_FILTERS = ["capacityMax", "capacityMin"];

// This isn't a filter, but rather a tracker to help us reference elements within the Schedule Planner
// E.g. adding/replacing sections to the planner.
export const SCHEDULE_PLANNER_TRACKER = "learnerPlanGoalUid";

// filters currently set from the query textbox
export const QUERY_FILTERS = ["q", "teacherName", "teacherUid"];

export const TIME_FILTERS = [
  "startAfterTime",
  "endByTime",
  "dow",
  "durationWeeksMax",
  "durationWeeksMin",
  "weeklyMeetingsMax",
  "weeklyMeetingsMin",
];

export const CAPACITY_OPTIONS = [{}, { capacityMax: 1 }, { capacityMin: 2 }];

// These URL parameters are also used in Google Analytics to define "Site Search Settings".
// If you add/remove parameter names, please also update those settings in Google Analytics:
// Admin > View Settings > Site Search Settings > Query parameter & Category parameter.
// Do this once for each "view" (e.g. "All Web Site Data" and "Website: no admin traffic")
// TODO: ^ Is this still relevant????
const FILTER_QUERY_PARAMETER_NAMES = [
  "adminTags",
  "age",
  "curriculums",
  "delivery",
  "dow",
  "durationSessionMin",
  "durationWeeksMax",
  "durationWeeksMin",
  "enabledBooleanFilters",
  "endBy",
  "endByTime",
  "englishProficiencyLevel",
  "format",
  "fundingPrograms",
  "gradeLevel",
  "hasAssessment",
  "hasHomework",
  "hasGrades",
  "includeInProgressFixedLengthLiveFormat",
  "languageOfInstruction",
  "multiTermQuery",
  "order",
  "originalSpelling",
  "pricePerCourseMax",
  "pricePerCourseMin",
  "q",
  "standards",
  "startAfter",
  "startAfterTime",
  "startBefore",
  "theme",
  "themeOrQuery",
  "time",
  "timeOfDay",
  "topics",
  "userUid",
  "userName",
  "weeklyMeetingsMax",
  "weeklyMeetingsMin",
  SCHEDULE_PLANNER_TRACKER,
  ...PRICE_PER_MEETING_FILTERS,
  ...CAPACITY_FILTERS,
];

export function equal(
  filters1: Record<string, any>,
  filters2: Record<string, any>
) {
  return (
    filters1 === filters2 ||
    FILTER_QUERY_PARAMETER_NAMES.every(param => {
      const filters1Param = Array.isArray(filters1[param])
        ? filters1[param].join(",")
        : filters1[param];
      const filters2Param = Array.isArray(filters2[param])
        ? filters2[param].join(",")
        : filters2[param];
      return filters1Param === filters2Param;
    })
  );
}

// TODO: memoize
export function fromQuery(query: Record<string, any>) {
  let filters = ldPick(query, FILTER_QUERY_PARAMETER_NAMES);
  filters = mapThemeOrQuery(filters);

  const now = dayjs().startOf("day");
  const startAfterDayjs = dateFilterToDayjs(filters.startAfter);
  const endByDayjs =
    filters.endBy !== catchallFor("endBy") && dateFilterToDayjs(filters.endBy);
  const startBeforeDayjs =
    filters.startBefore && dateFilterToDayjs(filters.startBefore);
  if (filters.multiTermQuery) {
    filters.multiTermQuery = filters.multiTermQuery.split(",");
  }
  if (filters.curriculums) {
    filters.curriculums = filters.curriculums.split(",");
  }
  if (filters.standards) {
    filters.standards = filters.standards.split(",");
  }
  if (filters.durationSessionMin) {
    filters.durationSessionMin = parseInt(filters.durationSessionMin, 10);
  }
  if (
    !!endByDayjs &&
    !isDateAfterOtherDates(endByDayjs, [now, startAfterDayjs])
  ) {
    delete filters.endBy;
  }

  // delete startBefore if it is before startAfter and not the same date
  if (
    !!startBeforeDayjs &&
    !startBeforeDayjs.isSame(startAfterDayjs) &&
    !isDateAfterOtherDates(startBeforeDayjs, [now, startAfterDayjs])
  ) {
    delete filters.startBefore;
  }

  // delete startAfter if its before today
  if (
    !!startAfterDayjs &&
    !isDateAfterOtherDates(startAfterDayjs, [now.subtract(1, "day")])
  ) {
    delete filters.startAfter;
  }

  if (!!filters.endBy && !!endByDayjs) {
    filters.endBy = endByDayjs.format(START_END_DATE_FORMAT);
  }
  if (!!filters.startBefore && !!startBeforeDayjs) {
    filters.startBefore = startBeforeDayjs.format(START_END_DATE_FORMAT);
  }
  if (!!filters.startAfter && !!startAfterDayjs) {
    filters.startAfter = startAfterDayjs.format(START_END_DATE_FORMAT);
  }
  const { startAfterTime, endByTime, includeInProgressFixedLengthLiveFormat } =
    filters;
  if (startAfterTime) {
    filters.startAfterTime = parseFloat(startAfterTime);
  }
  if (endByTime) {
    filters.endByTime = parseFloat(endByTime);
  }

  for (const booleanField of ["hasAssessment", "hasGrades", "hasHomework"]) {
    if (filters.hasOwnProperty(booleanField)) {
      filters[booleanField] = Boolean(
        filters[booleanField].replace(/\s*(false|null|undefined|0)\s*/i, "")
      );
    }
  }
  for (const numberField of ["pricePerCourseMax", "pricePerCourseMin"]) {
    if (filters[numberField] && ldIsNumber(Number(filters[numberField]))) {
      filters[numberField] = Number(filters[numberField]);
    }
  }
  for (const integerField of [
    "capacityMax",
    "capacityMin",
    "durationWeeksMax",
    "durationWeeksMin",
    "pricePerMeetingMax",
    "pricePerMeetingMin",
    "weeklyMeetingsMax",
    "weeklyMeetingsMin",
  ]) {
    if (
      filters[integerField] &&
      ldIsNumber(parseInt(filters[integerField], 10))
    ) {
      filters[integerField] = parseInt(filters[integerField], 10);
    }
  }

  if (typeof filters.includeInProgressFixedLengthLiveFormat !== "undefined") {
    filters.includeInProgressFixedLengthLiveFormat = Boolean(
      (includeInProgressFixedLengthLiveFormat || "").replace(
        /\s*(false|null|undefined|0)\s*/i,
        ""
      )
    );
  }

  return setDefaultSearch(filters);
}

export function catchallFor(filterName: any) {
  return FILTER_CATCHALL_VALUES[
    filterName as keyof typeof FILTER_CATCHALL_VALUES
  ];
}

export function setDefaultSearch(filters: Record<string, any>) {
  if (
    !filters.q ||
    typeof filters.q !== "string" ||
    !filters.q.trim() ||
    filters.q === "one on one"
  ) {
    delete filters.q;
  }

  return filters;
}

// ie. Code & Tech --> coding-and-tech
export function convertThemeFilterToQuery(themes?: string | null) {
  return cleanThemeParam(themes);
}

// ie. coding-and-tech --> Code & Tech
export function convertThemeQueryToFilter(themes?: string | null) {
  return cleanThemeParam(themes, true);
}

export function cleanThemeParam(
  themes?: string | null,
  returnNiceTerms = false
) {
  return themes
    ?.split(",")
    .filter(t => SEO.isTermWithinSubjectList(t))
    .map(t => (!returnNiceTerms ? SEO.cleanUrlTerm(t) : SEO.niceSubjectTerm(t)))
    .join(",");
}

export function omitCatchalls(filters: Record<string, any>) {
  return ldOmitBy(
    filters,
    (value, filterName) => value === catchallFor(filterName)
  );
}

function cleanSearchTerm(searchTerm: string): string {
  return (
    searchTerm
      // replace dashes with spaces
      ?.replace(/c-plus-plus/i, "c++")
      .replace(/c-sharp/i, "c#")
      .replace(/-and-/i, "-&-")
      .replace(/-/g, " ")
      .trim()
      .toLowerCase()
  );
}
