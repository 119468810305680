import { gql } from "@outschool/ui-apollo";

export default gql`
  query SignupModal(
    $activityUid: ID
    $activitySlugId: String
    $skipActivity: Boolean!
    $referrerSlugId: String
    $skipReferrer: Boolean!
    $leaderUid: ID
    $leaderLink: String
    $skipLeader: Boolean!
  ) {
    activity(uid: $activityUid, slugId: $activitySlugId)
      @skip(if: $skipActivity) {
      uid
      slug_id
      user_uid
      title
      url
      path
      class_experience
      learning_goals
      assessment
      homework
      prerequisites
      published_at
      is_online
      age_min
      age_max
      size_min
      size_max
      duration_minutes
      duration_weeks
      weekly_meetings
      price_cents
      summary
      is_ongoing_weekly
      subject
      refundPolicy {
        name
        description
      }
      details {
        photo
      }
      languageOfInstruction
      summaryForLearners
      isClub
      isAutoSchedulingEnabled
      autoSchedulingDaysNoticeMin
      autoSchedulingDaysNoticeMax
      hasTeacherSchedule
      allows_recurring_payment
    }
    leader: publicProfileNew(uid: $leaderUid, leader_link: $leaderLink)
      @skip(if: $skipLeader) {
      uid
      leader {
        name
      }
    }
    referrer: publicProfileNew(slug_id: $referrerSlugId)
      @skip(if: $skipReferrer) {
      uid
      parent {
        uid
        name
        photo
        publicName
        introduction
        created_at
        deleted_at
        timeZone
        socialOptOut
      }
      leader {
        uid
        name
        leader_link
        created_at
        photo
        cityState
        details {
          about
          headline
          countryOfResidence
        }
        is_crosslister
        timeZone
        hasAvailabilitySet
        approved
        socialOptOut
        ownerOfSellerOrg {
          uid
        }
        teacherForSellerOrg {
          uid
          name
        }
        hasBackgroundCheckExpired
      }
    }
  }
`;
