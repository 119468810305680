import { Box, SxProps, Theme } from "@outschool/backpack";
import { faTimes } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import ModalHelper from "./ModalHelper";
import { IconButton, IconButtonProps } from ".";

export interface ModalProps {
  open: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
  closeViaMaskClick?: boolean;
  closeViaEscButton?: boolean;
  fullBleed?: boolean;
  sx?: SystemStyleObject;
  iconButtonSx?: SxProps;
  iconButtonProps?: Omit<IconButtonProps, "icon" | "sx">;
  hasCloseButton?: boolean;
  stickyHeader?: boolean;
  ariaLabel?: string;
}

/**
 * @deprecated Please use `Modal` or `BasicModal` from `@outschool/backpack` instead.
 */
const Modal = (
  {
    open,
    children,
    onClose = () => {},
    hasCloseButton = true,
    stickyHeader = false,
    closeViaMaskClick = true,
    closeViaEscButton = true,
    fullBleed = true,
    iconButtonSx = {},
    iconButtonProps = {},
    ariaLabel,
    sx,
  }: React.PropsWithChildren<ModalProps>,
  ref: any
) => {
  const { t } = useTranslation("ui-legacy-component-library");
  return !!open ? (
    <ModalHelper
      fullBleed={fullBleed}
      onClose={onClose}
      closeViaMaskClick={closeViaMaskClick}
      closeViaEscButton={closeViaEscButton}
      ariaLabel={ariaLabel}
    >
      <Box
        ref={ref}
        sx={(theme: Theme) => ({
          minHeight: "auto",
          borderRadius: "1rem",
          [theme.breakpoints.down("md")]: {
            minHeight: fullBleed ? "100%" : "auto",
          },
          position: "relative",
          width: "100%",
          flexShrink: 0,
          maxWidth: 600,
          backgroundColor: "white",
          padding: "2em",
          ...sx,
        })}
      >
        {hasCloseButton && (
          <Box
            sx={
              stickyHeader
                ? {
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    background: "white",
                    textAlign: "end",
                  }
                : {
                    position: "absolute",
                    top: "0.6em",
                    right: "0.5em",
                    zIndex: 1,
                  }
            }
          >
            <IconButton
              icon={faTimes}
              sx={(theme: Theme) => ({
                fontSize: "1.5em",
                color: theme.palette.text.primary,
              })}
              iconProps={{
                sx: [
                  {
                    fontSize: "1.333em",
                  },
                  ...(Array.isArray(iconButtonSx)
                    ? iconButtonSx
                    : [iconButtonSx]),
                ],
              }}
              onClick={onClose}
              aria-label={t("Close")}
              {...iconButtonProps}
            />
          </Box>
        )}

        {children}
      </Box>
    </ModalHelper>
  ) : null;
};

export default React.forwardRef(Modal);
