import { CurrencyCode } from "@outschool/gql-backend-generated";
import { City } from "@outschool/iplookup-client";
import lodashFromPairs from "lodash/fromPairs";

import {
  ACTIVELY_CHARGED_CURRENCIES,
  BASE_CURRENCY,
  COUNTRIES_INFO,
  COUNTRIES_OF_RESIDENCE,
  CountryCode,
  CountryOfResidence,
  ISO_COUNTRY_CODE_TO_CURRENCY_CODE,
  CURRENCIES_INFO,
  I18nLocale,
  ISOCountryCodeKey,
  STANDARD_CURRENCY_RADIX,
} from "../constants";

// Functions must be exported directly to allow stubbing
export function currencyCodeFromString(
  currencyCode: string | null
): CurrencyCode {
  if (!currencyCode) {
    return BASE_CURRENCY.code;
  }
  const reverseCurrencyCode: { [key: string]: keyof typeof CurrencyCode } =
    lodashFromPairs(
      Object.entries(CurrencyCode).map(
        ([a, b]: [keyof typeof CurrencyCode, CurrencyCode]) => [b, a]
      )
    );
  return CurrencyCode[reverseCurrencyCode[currencyCode]];
}

export function convertCurrency({
  amount,
  fromCurrencyCode,
  fromExchangeRate,
  toCurrencyCode,
  toExchangeRate,
}: {
  amount: number;
  fromCurrencyCode: CurrencyCode;
  fromExchangeRate: number;
  toCurrencyCode: CurrencyCode;
  toExchangeRate: number;
}) {
  const { precision: fromPrecision } = CURRENCIES_INFO[fromCurrencyCode];
  const { precision: toPrecision } = CURRENCIES_INFO[toCurrencyCode];
  return (
    Math.sign(amount) *
    Math.round(
      Math.abs(
        (amount *
          (toExchangeRate / fromExchangeRate) *
          Math.pow(STANDARD_CURRENCY_RADIX, toPrecision)) /
          Math.pow(STANDARD_CURRENCY_RADIX, fromPrecision)
      )
    )
  );
}

export function convertFromUSDCents({
  amount,
  toCurrencyCode,
  toExchangeRate,
}: {
  amount: number;
  toCurrencyCode: CurrencyCode;
  toExchangeRate: number;
}) {
  return convertCurrency({
    amount,
    fromCurrencyCode: CurrencyCode.Usd,
    fromExchangeRate: 1,
    toCurrencyCode,
    toExchangeRate,
  });
}

export function convertToUSDCents({
  amount,
  fromCurrencyCode,
  fromExchangeRate,
}: {
  amount: number;
  fromCurrencyCode: CurrencyCode;
  fromExchangeRate: number;
}) {
  return convertCurrency({
    amount,
    fromCurrencyCode,
    fromExchangeRate,
    toCurrencyCode: CurrencyCode.Usd,
    toExchangeRate: 1,
  });
}

export function convertMinorToMajorDenomination({
  amount,
  currencyCode,
}: {
  amount: number;
  currencyCode: CurrencyCode;
}): string {
  const { precision } = CURRENCIES_INFO[currencyCode];
  return (amount / Math.pow(STANDARD_CURRENCY_RADIX, precision)).toFixed(
    precision
  );
}

export function shouldChargeInLocalCurrency(currencyCode: CurrencyCode) {
  return currencyCode && ACTIVELY_CHARGED_CURRENCIES.includes(currencyCode);
}

/**
 * Converts a standard language code into a string for use in comparisons.
 * With `strict=false`, strips the locale suffix so e.g. `"en-us"` == `"en-uk"`
 */
export function makeLanguageString(language: string, strict: boolean): string {
  return strict ? language : language.split("-")[0];
}

/**
 * Converts a locale string (xx-xx) to an ISO 639-1 two-letter code (xx).
 * Chinese (zh) is an exception and retains its specifier.
 * E.g. "en-US" -> "en", "es-es" -> "es", "zh-CN" -> "zh-CN"
 */
export function toISO6391Locale(locale: string) {
  if (!/^\w{2}(\-\w{2})?$/.test(locale)) {
    return locale;
  }
  const [language] = locale.split("-");
  if (language === "zh") {
    return locale;
  } else {
    return language;
  }
}

export function centsToNumericDollarString(priceInCents: number): string {
  if (!priceInCents) {
    return (0).toLocaleString();
  }

  return convertMinorToMajorDenomination({
    amount: priceInCents,
    currencyCode: CurrencyCode.Usd,
  }).toLocaleString();
}

export const getCountryFromCurrency = (
  currencyCode: CurrencyCode | null
): CountryCode | undefined => {
  return Object.values(COUNTRIES_INFO).find(country => {
    return country?.currency?.code === currencyCode;
  })?.code;
};

export function getCountryOfResidence(
  countryCode: string | undefined | null
): CountryOfResidence | null {
  if (!countryCode) {
    return null;
  }
  return (
    COUNTRIES_OF_RESIDENCE.find(({ code }) => code === countryCode) || null
  );
}

export function getCurrencyCodeFromLocaleAndIP(
  locale: I18nLocale,
  ipInfo: City | null
): CurrencyCode {
  // For users in English and Spanish locales, map from their IP to a currency instead of mapping from locale
  // Goal: more granular matches since English and Spanish are very broad locales unlike Japan -> Yen or Korea -> Won
  // i.e. English locales could use USD in US or GBP in UK, Spanish locales could use Euros in Spain, MXN in Mexico, etc.
  const ipIsoCountryCode = ipInfo?.country?.isoCode as ISOCountryCodeKey;
  if (
    (locale === I18nLocale.En || locale === I18nLocale.Es) &&
    ipIsoCountryCode &&
    ISO_COUNTRY_CODE_TO_CURRENCY_CODE[ipIsoCountryCode]
  ) {
    return ISO_COUNTRY_CODE_TO_CURRENCY_CODE[ipIsoCountryCode];
  } else {
    // For all other users, set currency based on locale
    for (const currencyCode of Object.values(CurrencyCode)) {
      const currencyInfo = CURRENCIES_INFO[currencyCode];
      if (currencyInfo.defaultLocales.includes(locale)) {
        return currencyCode;
      }
    }
    // If there is no match from locale, default to USD
    return CurrencyCode.Usd;
  }
}
