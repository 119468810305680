import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

const SNACKBAR_THEME: Components["MuiSnackbar"] = {
  defaultProps: {
    anchorOrigin: { vertical: "bottom", horizontal: "center" },
  },
};

const SNACKBAR_CONTENT_THEME: Components["MuiSnackbarContent"] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      display: "flex",
      justifyContent: "space-between",
      minWidth: "300px",
      maxWidth: "100%",
      borderRadius: "1em",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.common.white,
      padding: "1.5em",
    }),
    message: {
      maxWidth: "400px",
      padding: "0",
      margin: "0",
    },
    action: {
      padding: "0",
      marginRight: "0",
      marginLeft: "1em",
    },
  },
};

export { SNACKBAR_THEME, SNACKBAR_CONTENT_THEME };
