import React from "react";

import { IntegrationCategory } from "../../providers/AnalyticsContext";
import { usePageContext } from "../../providers/PageContext";
import usePageEvent from "../usePageEvent";

export default function usePageTracking() {
  const trackPage = usePageEvent(IntegrationCategory.Advertising);
  const [currentPageImpressionId, setCurrentPageImpressionId] =
    React.useState<string>();
  const page = usePageContext();

  React.useEffect(() => {
    const { pageCategory, pageImpressionId, pageName, pagePath, ready } = page;

    if (!ready) {
      return;
    }

    if (currentPageImpressionId === pageImpressionId) {
      return;
    }

    setCurrentPageImpressionId(pageImpressionId);
    trackPage(pageCategory, pageName, { pageImpressionId, pagePath });
  }, [currentPageImpressionId, page, trackPage]);
}
