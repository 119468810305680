import legacyButtonTheme from "../LegacyButton/LegacyButton.theme";
import colors from "./colors";

const breakpoints: any = ["575px", "768px", "1000px", "1440px"];

breakpoints.small = breakpoints[0];
breakpoints.medium = breakpoints[1];
breakpoints.large = breakpoints[2];
breakpoints.xlarge = breakpoints[3];

export default {
  colors,
  breakpoints,
  sizes: {
    container: {
      tiny: "450px",
      small: "750px",
      medium: "1120px",
      large: "1440px",
    },
    avatar: {
      small: 30,
    },
  },
  space: {
    tiny: "0.25em",
    small: "0.5em",
    medium: "1em",
    large: "2em",
    xlarge: "3em",
  },
  shadows: {
    default: "0 2px 10px rgba(0, 0, 0, 0.25)",
    light: "0px 2px 2px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.04)",
    soft: "0 4px 24px rgba(0, 0, 0, 0.12)",
  },
  iconSize: {
    small: "0.7em",
    medium: "1em",
    large: "1.333em",
  },
  fonts: {
    default: "'Ginto Normal',sans-serif",
    heading: "'Ginto Normal',sans-serif",
    headingEmphasized: "'Ginto Nord','Ginto Normal',sans-serif",
  },
  fontSizes: {
    tiny: "0.75em",
    small: "0.875em",
    medium: "1em",
    large: "1.5em",
    xlarge: "2em",
  },
  fontWeights: {
    light: "300",
    normal: "normal",
    semibold: "500",
    bold: "bold",
  },
  radii: {
    medium: "0.25em",
    large: "0.5em",
    xlarge: "1em",
    xxlarge: "1.5em",
  },
  // Component specific themes
  buttons: legacyButtonTheme,
};
