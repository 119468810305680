import {
  Modal,
  Box,
  Typography,
  ModalContent,
  Theme,
  List,
  ListItem,
} from "@outschool/backpack";
import { TrackedButton } from "@outschool/ui-components-shared";
import { useTranslation, renderCurrencyString } from "@outschool/localization";
import { centsToDollars } from "@outschool/business-rules";
import { autoPlayPath } from "@outschool/routes";
import { useNavigation } from "@outschool/ui-utils";
import React from "react";

export function JoinSubscriptionModal({
  isOpen,
  onClose,
  originalPrice,
  discountPrice,
}: {
  isOpen: boolean;
  onClose: () => void;
  originalPrice?: number;
  discountPrice?: number;
}) {
  const { t } = useTranslation(
    "ui-components-website\\Modals\\JoinSubscriptionModal"
  );
  const navigate = useNavigation();
  const showDiscount = originalPrice && discountPrice;
  const [
    originalPriceWithCurrency,
    originalPriceWithoutCurrency,
    discountPriceWithCurrency,
  ] = React.useMemo(() => {
    return originalPrice && discountPrice
      ? [
          renderCurrencyString({ priceInCents: originalPrice }),
          centsToDollars(originalPrice),
          renderCurrencyString({ priceInCents: discountPrice }),
        ]
      : [];
  }, [originalPrice, discountPrice]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent
        sx={{
          maxWidth: "640px",
        }}
      >
        <Box>
          <Box
            sx={(theme: Theme) => ({
              backgroundColor: theme.palette.warning[100],
              borderRadius: "8px",
              paddingY: "1rem",
              paddingX: "2rem",
            })}
          >
            <Box
              flex={true}
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  maxWidth: showDiscount ? "365px" : "none",
                }}
              >
                <Typography variant="h5">
                  {t("Save up to 15% on every class!")}
                </Typography>
                <Typography variant="body1">
                  {t(
                    "Engaging learning experiences you love—now with even more savings!"
                  )}
                </Typography>
              </Box>
              {showDiscount && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ textDecoration: "line-through" }}
                  >{`$${originalPriceWithoutCurrency}`}</Typography>
                  <Typography
                    variant="h5"
                    sx={{ paddingLeft: "0.5rem" }}
                  >{`${discountPriceWithCurrency}`}</Typography>
                </Box>
              )}
            </Box>
            <Box
              flex={true}
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Box>
                <List
                  sx={{
                    "& li": {
                      paddingX: "0rem",
                    },
                  }}
                >
                  <ListItem>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      😘 {t("No Outschool fees—ever")}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      🤩 {t("Save up to 15% ")}&nbsp;
                    </Typography>
                    <Typography variant="body1">
                      {" "}
                      {t("on every enrollment")}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body1">
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        😀 {t("Roll over")}&nbsp;
                      </Typography>
                      <Typography variant="body1">
                        {t("unused credits (up to 1X)")}
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body1">
                      👍 {t("Switch plans anytime, hassle-free")}
                    </Typography>
                  </ListItem>
                </List>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  paddingY: "1rem",
                  paddingLeft: "3rem",
                }}
              >
                <TrackedButton
                  variant="contained"
                  trackingName="subscription_join_modal_cta"
                  onClick={(_e: React.MouseEvent<HTMLButtonElement>) => {
                    navigate(autoPlayPath());
                  }}
                >
                  {t("View Membership")}
                </TrackedButton>
              </Box>
            </Box>
          </Box>
        </Box>
        {showDiscount && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingY: "1rem",
            }}
          >
            <TrackedButton
              variant="link"
              trackingName="subscription_join_modal_no_thanks"
            >
              <Typography variant="body1">
                {t("No thanks, enroll for {{enrollPrice}}", {
                  enrollPrice: originalPriceWithCurrency,
                })}
              </Typography>
            </TrackedButton>
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
}
