import { SEO } from "@outschool/business-rules";

import slug from "slugify";

import { makeUrlFromPathFn } from "./config";
import { searchPath } from "./websiteRoutes";

//TODO : Refactor to flip the order of arguments here (basePath, category) since it ends up concatenated in that same order.
//     it'd be similar / familiar to all the node.js path.join, path.resolve, etc. functions
export function onlineClassesPath(
  category?: string,
  pageBasePath?: string
): string {
  const tradeMarkTerms = ["fortnite", "pokemon"];
  if (category) {
    if (tradeMarkTerms.includes(category.toLowerCase())) {
      return searchPath({ q: category });
    }

    const categorySlug = toOnlineClassesSlugID(category);
    const finalPath =
      `/online-classes` +
      (pageBasePath
        ? (pageBasePath.startsWith("/") ? "" : "/") +
          pageBasePath +
          (pageBasePath.endsWith("/") ? "" : "/")
        : "/");

    if (categorySlug) {
      return finalPath + categorySlug;
    } else {
      return finalPath + category;
    }
  } else {
    return `/online-classes/popular`;
  }
}

export const onlineClassesUrl = makeUrlFromPathFn(onlineClassesPath);

export function toOnlineClassesSlugID(name: string): string | undefined {
  if (!name) {
    return undefined;
  }
  const formattedCategory = slug(SEO.cleanUrlTerm(name));
  if (/^[\w\-]+$/.test(formattedCategory)) {
    const override = getCategorySlugOverride(formattedCategory);

    return override ? override : formattedCategory;
  }
  return undefined;
}

export function getCategorySlugOverride(
  categorySlug: string
): string | undefined {
  const overrideForIndexing: { [key: string]: string } = {
    "african-american-history": "about-african-american-history",
    cello: "cello-music",
    depression: "about-depression",
    "dog-training": "training-dogs",
    fencing: "fencing-tips",
    flute: "flute-music",
    "french-conversation": "conversing-in-french",
    geology: "about-geology",
    "jazz-dance": "dancing-jazz",
    "minecraft-java": "minecraft-java-edition",
    montessori: "montessori-education",
    "real-estate": "about-real-estate",
    russia: "about-russia",
    "saxon-math": "about-saxon-math",
    trolls: "about-trolls",
    ukulele: "ukulele-music",
    weather: "about-the-weather",
  };

  const override: { [key: string]: string } = {
    ...overrideForIndexing,
    "5-paragraph-essay": "5-paragraph-essays",
    add: "adding",
    adventure: "adventures",
    "american-sign-language": "sign-language",
    "anger-mament": "anger-management",
    art: "arts-and-crafts",
    "art-club": "art-clubs",
    arts: "arts-and-crafts",
    birthday: "birthdays",
    "book-club": "book-clubs",
    "book-study": "book-studies",
    brain: "brains",
    camp: "camps",
    cheer: "cheerleading",
    "chess-club": "chess-clubs",
    "coding-tech": "coding-and-tech",
    "conding-and-tech-homeschool": "coding-and-tech",
    "conding-and-tech-story-time": "coding-and-tech",
    creative: "creativity",
    crochet: "crocheting",
    "debate-club": "debate-clubs",
    detective: "detectives",
    doctor: "doctors",
    dungeons: "dungeons-and-dragons",
    "father-day": "fathers-day",
    "game-design": "video-game-design",
    "girls-club": "girls-clubs",
    "guitar-lessons": "guitar",
    gym: "gymnastics",
    "health-and-wellness": "wellness",
    "health-wellness": "wellness",
    "hip-hop": "hip-hop-dance",
    instrument: "musical-instruments",
    instruments: "musical-instruments",
    java: "java-coding",
    "jurassic-world": "jurassic",
    "language-arts": "english-language-arts",
    lightsaber: "lightsabers",
    mandarin: "mandarin-chinese",
    "math-camp": "math-camps",
    "math-tutor": "math-tutors",
    mindfullness: "mindfulness",
    "music-theory-theory": "music-theory",
    "novel-study": "novel-studies",
    nba: "nba-basketball",
    nasa: "nasa-space",
    outdoor: "outdoors",
    "paragraph-writing": "writing-paragraphs",
    python: "python-coding",
    "reading-club": "reading-clubs",
    "reading-tutor": "reading-tutors",
    "research-paper": "research-papers",
    "rubiks-cube": "rubiks-cubes",
    resume: "resumes",
    "science-and-nature": "nature",
    "science-nature": "nature",
    "self-confidence": "confidence",
    "sewing-machine": "sewing-machines",
    "singing-club": "singing-clubs",
    "Spanish-tutor": "Spanish-tutors",
    spy: "spies",
    "summer-camp": "summer-camps",
    theatre: "theater",
    tween: "tweens",
    "us-history": "american-history",
    "vocal-coach": "vocal-coaching",
    waldorf: "waldorf-education",
    "world-languages": "languages",
    zoo: "zoos",
    zookeeper: "zookeepers",
  };

  return override[categorySlug] || undefined;
}
