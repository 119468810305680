import { I18nLocale, useLocale, useTranslation } from "@outschool/localization";
import { LineButton } from "@outschool/ui-components-shared";
import React from "react";

import { useOidcOnClick } from "./useOidcOnClick";

interface ContinueWithLineProps {
  isLearnerApp: boolean;
  setAuthStrategy?: (strategy: "line") => void;
  trackingName: string;
  getAnonymousId?: () => Promise<string | undefined>;
  disableWhileLoading?: boolean;
  onClick?: () => void;
}

export default function ContinueWithLine({
  isLearnerApp,
  trackingName,
  setAuthStrategy = () => undefined,
  getAnonymousId = () => Promise.resolve(undefined),
  disableWhileLoading = false,
  onClick: onClickProp = () => {},
}: ContinueWithLineProps) {
  const { t } = useTranslation("ui-auth\\src\\ContinueWithLine");
  // Line auth handler needs the locale to know which secrets to use.
  const locale = useLocale();
  const oidcOnClick = useOidcOnClick({
    getAnonymousId,
    isLearnerApp,
    setAuthStrategy,
    provider: "line",
    locale,
  });

  return (
    <LineButton
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        onClickProp();
        oidcOnClick(event);
      }}
      trackingName={trackingName}
      disabled={disableWhileLoading}
      sx={{
        "&:disabled": {
          pointerEvents: "none",
          opacity: 0.5,
          cursor: "not-allowed",
        },
      }}
    >
      {t("Continue with LINE")}
    </LineButton>
  );
}

export function useShouldShowLine() {
  const locale = useLocale();
  return locale === I18nLocale.Ja || locale === I18nLocale.ZhTw;
}
