import React from "react";

import { TrackedButton, TrackedButtonProps } from "./TrackedButton";

const APPLE_COLOR = "common.black";
const APPLE_COLOR_HOVER = "common.black";
const APPLE_COLOR_ACTIVE = "common.black";

export type AppleButtonProps = TrackedButtonProps;

const AppleButton = (
  { sx, ...props }: TrackedButtonProps,
  ref: React.Ref<any>
) => (
  <TrackedButton
    ref={ref}
    color="inherit"
    sx={[
      {
        color: "common.white",
        borderColor: APPLE_COLOR,
        backgroundColor: APPLE_COLOR,
        "&:hover:not(:disabled), &:focus:not(:disabled)": {
          color: "common.white",
          borderColor: APPLE_COLOR_HOVER,
          backgroundColor: APPLE_COLOR_HOVER,
          textDecoration: "none",
        },
        "&:active:not(:disabled)": {
          color: "common.white",
          borderColor: APPLE_COLOR_ACTIVE,
          backgroundColor: APPLE_COLOR_ACTIVE,
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  />
);

export default React.forwardRef(AppleButton);
