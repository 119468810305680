import { useSession } from "@outschool/ui-auth";
import { useExperiment } from "@outschool/ui-experiments";

const EXPERIMENT_NAME = "TeacherRegistrationLink";

export const useTeacherRegistrationLinkExperiment = () => {
  const { currentUserHasLoaded } = useSession();

  const { variant, trigger } = useExperiment({
    currentUserHasLoaded,
    experimentName: EXPERIMENT_NAME,
  });

  const isInTreatment = variant === "treatment";

  return {
    isInTreatment,
    trigger,
  };
};
