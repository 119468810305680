import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  useQuery,
} from "@apollo/client";

/**
 * This is how useQuery behaved in apollo-client v2.
 * https://github.com/apollographql/apollo-client/pull/6566
 */
export const useQueryWithPreviousData = <
  TData = any,
  TVariables extends OperationVariables = any
>(
  query: any,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> => {
  const result = useQuery<TData, TVariables>(query, options);
  if ("previousData" in result && result.loading && !result.data) {
    return {
      ...result,
      data: result.previousData,
    };
  }
  return result;
};
