// ** Note: we are intentionally manipulating the DOM directly
// for the purpose of this transient and temporary view
import OsLogo from "../../images/Outschool_Logo_Color.png";
import HappyKey from "../../images/TrustAndSafety_2023_HappyKey_200x200_Waterslide.png";

function applyStyle(
  current: CSSStyleDeclaration,
  override: Partial<CSSStyleDeclaration>
) {
  Object.assign(current, override);
}

function createDivWrapper() {
  const d = document.createElement("div");
  d.setAttribute("id", "managed-challenge-wrapper");
  applyStyle(d.style, {
    zIndex: "999999",
    position: "fixed",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    background: "rgba(0, 0, 0, 0.5)",
    flexFlow: "column",
  });

  return d;
}

function createDivModal() {
  const d = document.createElement("div");
  d.setAttribute("id", "managed-challenge-modal");
  applyStyle(d.style, {
    zIndex: "9999999",
    display: "flex",
    minWidth: "35vw",
    minHeight: "500px",
    maxHeight: "70vh",
    backgroundColor: "#ffffff",
    borderRadius: "16px",
    border: "0px",
    flex: "1 1 auto",
    flexDirection: "column",
    padding: "1.5em",
  });

  return d;
}

function createOutschoolImg() {
  const img = document.createElement("img");
  img.setAttribute("src", OsLogo);
  img.setAttribute("id", "os-logo");
  applyStyle(img.style, {
    height: "1.5em",
    margin: "0 auto 2em",
  });

  return img;
}

function createKeyImg() {
  const img = document.createElement("img");
  img.setAttribute("src", HappyKey);
  img.setAttribute("id", "os-happy-key");
  applyStyle(img.style, {
    height: "7em",
    margin: "0px auto 1em",
  });

  return img;
}

function createIframe() {
  const url = new URL("/managed-challenge-iframe", window.location.origin);

  const f = document.createElement("iframe");
  f.setAttribute("id", "managed-challenge-iframe-content");
  f.src = url.toString();
  applyStyle(f.style, {
    display: "block",
    border: "0px",
    flex: "1 1 auto",
  });
  return f;
}

export function createManagedChallengeViewInDom() {
  const divWrapper = createDivWrapper();
  const divModal = createDivModal();
  const imgOsLogo = createOutschoolImg();
  const imgHappyKey = createKeyImg();
  const iframe = createIframe();

  divModal.appendChild(imgHappyKey);
  divModal.appendChild(imgOsLogo);
  divModal.appendChild(iframe);
  divWrapper.appendChild(divModal);

  return divWrapper;
}
