export const OUTSCHOOL_ENV = process?.env?.OUTSCHOOL_ENV ?? "development";

export const APP_HOSTNAME = process?.env?.APP_HOSTNAME;
export const isProduction = OUTSCHOOL_ENV === "production";
export const isTest = OUTSCHOOL_ENV === "test";

const TRANSLATIONS_CF_ENDPOINT = process?.env?.TRANSLATIONS_CF_ENDPOINT;

export const TRANSLATIONS_ENDPOINT =
  TRANSLATIONS_CF_ENDPOINT !== undefined && TRANSLATIONS_CF_ENDPOINT !== ""
    ? TRANSLATIONS_CF_ENDPOINT
    : isProduction ||
      // running client-side on outschool.com
      (typeof window !== "undefined" &&
        window?.location?.hostname === "outschool.com")
    ? "https://gtranslate.app.outschool.com/1/translate"
    : "https://master-gtranslate.review.outschool.dev/1/translate";

// Allows us to avoid the CF redirect for google login in staging.
export const CF_ACCESS_CLIENT_ID = process?.env?.CF_ACCESS_CLIENT_ID;
export const CF_ACCESS_CLIENT_SECRET = process?.env?.CF_ACCESS_CLIENT_SECRET;
