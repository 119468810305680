import { useLocale } from "@outschool/localization";
import { gql, useQuery } from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import { Banner } from "@outschool/ui-legacy-component-library";
import Cookie from "js-cookie";
import React, { useEffect, useMemo, useState } from "react";

export type ContentfulAnnouncementBannerItem = {
  name: string | null;
  copy: string | null;
  linkCopy: string | null;
  linkUrl: string | null;
  dateToRemove: any | null;
} | null;

interface ContentfulAnnouncementBannerProps {
  bannerData: ContentfulAnnouncementBannerItem;
}

const ContentfulAnnouncementBanner: React.FC<ContentfulAnnouncementBannerProps> =
  React.memo(({ bannerData }) => {
    const banner = bannerData;

    const bannerName = useMemo(
      () =>
        `contentful-announcement-banner-${banner?.name
          ?.trim()
          .replace(/\s+/g, "-")}`,
      [banner?.name]
    );

    const [showBanner, setShowBanner] = useState(true);

    useEffect(() => {
      const cookieExists = Cookie.get(bannerName);
      if (cookieExists) {
        setShowBanner(false);
      }
    }, [bannerName]);

    const handleClose = () => {
      setShowBanner(false);
      Cookie.set(bannerName, "true", { expires: 1 });
    };

    const { isLoggedIn } = useSession();
    if (banner == null || !showBanner || !isLoggedIn || !banner?.copy) {
      return null;
    }

    return (
      <Banner
        data-test-id="contentful-announcement-banner"
        closable={true}
        onClose={handleClose}
        sx={{ textAlign: "center" }}
      >
        {banner.copy}{" "}
        {banner.linkCopy && banner.linkUrl && (
          <a href={banner.linkUrl}>{banner.linkCopy}</a>
        )}
      </Banner>
    );
  });

export default ContentfulAnnouncementBanner;

export function useContentfulAnnouncementBannerHook(): ContentfulAnnouncementBannerItem | null {
  const locale = useLocale();
  const { data, error } = useQuery(ContentfulAnnouncementBannerQ, {
    variables: { locale, preview: false },
  });

  if (error) {
    return null;
  }

  const items = data?.contentfulAnnouncementBannerCollection?.items;
  const currentDate = new Date();
  const filteredItems = items?.filter(
    (el: any) => currentDate < new Date(el?.dateToRemove)
  );

  if (!filteredItems?.length) {
    return null;
  }

  return filteredItems[0];
}

const ContentfulAnnouncementBannerQ = gql`
  query ContentfulAnnouncementBanner($locale: String, $preview: Boolean) {
    contentfulAnnouncementBannerCollection(
      limit: 1
      skip: 0
      preview: $preview
      locale: $locale
    ) {
      items {
        name
        copy
        linkCopy
        linkUrl
        dateToRemove
      }
    }
  }
`;
