import React, { useState } from "react";
import { Box, Button } from "@outschool/backpack";
import { isLocalStorageSupported } from "@outschool/local-storage";
import {
  getLanguageFromLocale,
  useCurrencyLocalization,
  useLocale,
  useTranslation,
} from "@outschool/localization";
import { browseRootPath, teacherDashboardPath } from "@outschool/routes";
import { guessBrowserTimeZone } from "@outschool/time";
import {
  IntegrationCategory,
  useAdSettings,
  useAnalytics,
  useIdentify,
  useSignup,
  useTrackEvent,
} from "@outschool/ui-analytics";
import {
  SKIP_LEARNERS_LOCAL_STORAGE_NAME,
  getPostLoginPath,
  persistPostLoginPath,
} from "../providers/LoginFlowProvider";
import { useSession } from "../providers/SessionProvider";
import {
  Checkbox,
  Form,
  FormField,
  Input,
  Loading,
} from "@outschool/ui-legacy-component-library";
import {
  noUrl,
  required,
  useFormState,
  validatePassword,
  isStrictlyValidEmail as isEmail,
  useNavigation,
} from "@outschool/ui-utils";
import _ from "lodash";

import { useCreateAccountMutation } from "../graphql/useCreateAccountMutation";
import { useTrackUserCreatedMutation } from "../graphql/useTrackUserCreatedMutation";
import { ErrorMessage } from "@outschool/ui-components-shared";
import { useLookupIP } from "@outschool/iplookup-client";

type JoinParentAccountFormProps = {
  searchParams: { [key: string]: any };
  setDisableSignupButtons: (loading: boolean) => void;
  onUserLoggedIn: (sessionToken: string, refreshToken: string) => void;
  onUserRefresh: () => void;
  isTeacherFlow: boolean;
  setIsRedirecting: (isRedirecting: boolean) => void;
  triggerSignupToSearchExperiment?: () => void;
};

/**
 * This function contains logic and ui to show the email
 * signup form within the modal and create a new account.
 */
export default function JoinParentAccountForm({
  searchParams,
  setDisableSignupButtons,
  onUserLoggedIn,
  onUserRefresh,
  isTeacherFlow,
  setIsRedirecting,
  triggerSignupToSearchExperiment,
}: JoinParentAccountFormProps) {
  const localStorage = isLocalStorageSupported();
  const { t } = useTranslation("ui-auth\\JoinParentAccountForm");
  const locale = useLocale();
  const userLanguage = getLanguageFromLocale(locale);

  const isOnboardingSignup = !!searchParams.isOnboardingSignup;
  const shouldCreateDefaultLearner = !!searchParams.shouldCreateDefaultLearner;
  const defaultLearnerAge = Number(searchParams.defaultLearnerAge);
  const redirectLandingPage = searchParams.redirect;
  const postLoginFromLocalStorage = getPostLoginPath();

  if (redirectLandingPage) {
    persistPostLoginPath(redirectLandingPage);
  } else if (
    postLoginFromLocalStorage &&
    postLoginFromLocalStorage !== browseRootPath()
  ) {
    persistPostLoginPath(postLoginFromLocalStorage);
  } else {
    persistPostLoginPath(browseRootPath());
  }

  const [createAccount] = useCreateAccountMutation();
  const identify = useIdentify();
  const analytics = useAnalytics();
  const logSignup = useSignup();
  const trackAdEvent = useTrackEvent(IntegrationCategory.Advertising);
  const trackEvent = useTrackEvent();
  const { currencyCode } = useCurrencyLocalization();
  const [isFormLoading, setIsFormLoading] = useState(false);

  const [queryError, setQueryError] = React.useState<string | null>(null);
  // If the current user has facebook, we want to only show them the option to
  // unsubscribe from emails.
  const { currentUserIsLoading, currentUser } = useSession();
  const { shouldNotSellPersonalInfo } = useAdSettings();

  const { ipInfoLoaded, isInGDPR } = useLookupIP();
  const [submitted, setSubmitted] = useState(false);
  const { fields, values, errors, validateAll } = useFormState({
    name: {
      value: "",
      validators: [
        required(() => t("Required")),
        noUrl(() => t("URLs are not allowed")),
      ],
    },
    email: {
      value:
        !currentUserIsLoading && currentUser && currentUser.email
          ? currentUser.email
          : "",
      validators: [
        required(() => t("Required")),
        // @ts-ignore TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        value => (isEmail(value) ? null : t`Not a valid email`),
      ],
    },
    password: {
      value: "",
      validators: [
        validatePassword(
          () =>
            t(
              "Password must be at least 8 characters long and include a number, a letter, and a special character"
            ),
          () =>
            t(
              "Password must be 8-100 characters long and include a number, a letter, and a special character"
            )
        ),
      ],
    },
    emailCheck: {
      value: ipInfoLoaded ? !isInGDPR : false,
      getValue(e: React.ChangeEvent<HTMLInputElement>) {
        return (e as React.SyntheticEvent<HTMLInputElement>).currentTarget
          .checked;
      },
    },
  });

  const trackUserCreated = useTrackUserCreatedMutation();

  const handleError = (error: any) => {
    setQueryError(error?.message);
    setIsFormLoading(false);
    setDisableSignupButtons(false);
  };

  const onSubmit = async (e: React.FormEvent) => {
    trackEvent("Join Parent Account Form Submitted");
    e.preventDefault();
    setSubmitted(true);
    const validationErrors = await validateAll();

    if (validationErrors.hasErrors) {
      setIsFormLoading(false);
      return;
    }
    triggerSignupToSearchExperiment && triggerSignupToSearchExperiment();
    setIsFormLoading(true);
    setDisableSignupButtons(true);

    const attribution = await analytics.attribution();
    const browserTimeZone = guessBrowserTimeZone();

    try {
      logSignup({ email: values.email, name: values.name });
      const { data } = await createAccount({
        variables: {
          name: values.name,
          email: values.email,
          password: values.password,
          attribution,
          browserTimeZone,
          locale: userLanguage.userLocale,
          isLeader: isTeacherFlow,
          subscribe: values.emailCheck,
          shouldNotSellPersonalInfo,
          isOnboardingSignup,
          shouldCreateDefaultLearner,
          defaultLearnerAge,
        },
      });

      const createdUser = data?.createAccount?.currentUser;

      // we must do this to tell Segment client to wait for user_id from refresh
      // @ts-ignore TS(2345): Argument of type '{ __typename?: "User" | undefine... Remove this comment to see the full error message
      identify(createdUser?.uid, createdUser);

      if (
        data?.createAccount?.sessionToken &&
        data?.createAccount?.refreshToken
      ) {
        setQueryError(null);
        setIsFormLoading(false);
        setIsRedirecting(true);
        onUserLoggedIn(
          data.createAccount.sessionToken,
          data.createAccount.refreshToken
        );
      } else {
        handleError("Session token or refresh token is missing.");
        setIsFormLoading(false);
      }
      await trackUserCreated({});
      trackAdEvent("signup-by-email");
      trackAdEvent("user-created", {
        value: 0,
        currency: currencyCode,
        userUid: currentUser?.uid,
      });
      handleComplete();
    } catch (error) {
      handleError(error);
    }
  };

  const navigate = useNavigation();

  function handleComplete() {
    trackEvent("Signup flow completed");
    if (localStorage) {
      localStorage.removeItem(SKIP_LEARNERS_LOCAL_STORAGE_NAME);
    }
    if (isTeacherFlow) {
      navigate(teacherDashboardPath());
    } else {
      onUserRefresh();
    }
  }

  return (
    <Form
      data-test-id="join-parent"
      sx={{ display: "grid", rowGap: "medium" }}
      onSubmit={onSubmit}
    >
      {queryError && (
        <ErrorMessage value={queryError} showStatusPageLink={false} />
      )}

      <FormField
        label={t`Adult Full Name`}
        error={submitted ? _.first(errors.name) : undefined}
        fieldId={fields.name.id}
        sx={{ marginTop: "10px" }}
      >
        <Input
          name="name"
          placeholder={t`Jane Doe`}
          data-test-id="join-name"
          autoComplete="name"
          {...fields.name}
          disabled={isFormLoading}
        />
      </FormField>

      <FormField
        label={t`Adult Email`}
        error={submitted ? _.first(errors.email) : undefined}
        fieldId={fields.email.id}
      >
        <Input
          name="email"
          type="email"
          data-test-id="join-email"
          // eslint-disable-next-line i18next/no-literal-string
          placeholder="superawesome@emailaddress.com"
          autoComplete="email"
          {...fields.email}
          disabled={isFormLoading}
        />
      </FormField>

      <FormField
        label={t`Password`}
        error={submitted ? _.first(errors.password) : undefined}
        fieldId={fields.password.id}
      >
        <Input
          name="password"
          data-test-id="join-password"
          type="password"
          autoComplete="new-password"
          {...fields.password}
          disabled={isFormLoading}
        />
      </FormField>

      <Box
        sx={{
          fontSize: "0.875em",
          color: "grey.700",
        }}
      >
        <Checkbox
          data-test-id="join-offer"
          label={t`Want to receive personalized recommendations and special offers? Opt out anytime.`}
          checked={fields.emailCheck.value}
          {..._.omit(fields.emailCheck, "value")}
          disabled={isFormLoading}
        />
      </Box>

      <Box
        sx={{
          display: "grid",
          gridRowGap: "0.5em",
          textAlign: "center",
          justifyItems: "center",
        }}
      />
      <Button
        variant="contained"
        type="submit"
        disabled={isFormLoading}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxHeight: "40px",
        }}
      >
        {isFormLoading ? (
          <Loading
            size="small"
            color="inherit"
            iconSx={{ fontSize: "1.5em" }}
          />
        ) : (
          t`Join Outschool`
        )}
      </Button>
    </Form>
  );
}
