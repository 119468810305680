/** @jsx jsxPragma */

import { Box } from "@outschool/backpack";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";
import TextareaAutosize from "react-autosize-textarea";

import jsxPragma from "./jsxPragma";

export interface TextAreaProps extends TextareaAutosize.Props {
  value?: string;
  showCount?: boolean;
  minLength?: number;
  maxLength?: number;
  sx?: SystemStyleObject;
}

const TextArea = React.forwardRef(
  (
    {
      sx,
      value,
      showCount = true,
      minLength = 0,
      maxLength = Infinity,
      ...props
    }: TextAreaProps,
    ref: React.MutableRefObject<HTMLTextAreaElement>
  ) => {
    const [hasScrollbar, setHasScrollbar] = React.useState(false);
    React.useEffect(() => {
      if (ref?.current) {
        setHasScrollbar(ref.current.scrollHeight > ref.current.offsetHeight);
      }
    }, [value, ref]);

    return (
      <Box sx={{ position: "relative" }}>
        <TextareaAutosize
          ref={ref}
          maxLength={maxLength}
          value={value !== null ? value : undefined}
          // @ts-ignore
          sx={{
            padding: "6px 10px",
            border: "1px solid",
            borderColor: "border",
            borderRadius: "4px",
            width: "100%",
            paddingRight: showCount ? "large" : "inherit",
            // Fix a height issue with react-textarea-autosize
            maxHeight: "none !important",
            "&:focus, &:focus-within, &:focus-visible": {
              outlineColor: "#4b01d4",
              outlineWidth: "2px",
            },
            resize: "none",
            ...sx,
          }}
          {...props}
        />
        {showCount && (
          <Box
            sx={{
              position: "absolute",
              bottom: "0.5em",
              right: hasScrollbar ? "2em" : "0.5em",
              color:
                value && (value.length < minLength || value.length >= maxLength)
                  ? "error.main"
                  : "gray600",
            }}
          >
            {value && maxLength != Infinity && maxLength - value.length}
          </Box>
        )}
      </Box>
    );
  }
);

export default TextArea;
