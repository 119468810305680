import { keyframes } from "@emotion/react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { IconDefinition } from "@outschool/icons";
import React from "react";

const SPIN = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`;

export const IconSizes = ["inherit", "small", "medium", "large"] as const;
export type IconSize = (typeof IconSizes)[number];

export const IconColors = [
  "inherit",
  "primary",
  "secondary",
  "success",
  "error",
  "info",
  "warning",
  "disabled",
] as const;
export type IconColor = (typeof IconColors)[number];

export interface IconProps
  // There shouldn't be a reason to override the underlying component, omit so we can nail down typings.
  extends Omit<SvgIconProps<"svg">, "component" | "fontSize"> {
  icon: IconDefinition;
  /**
   * @default 'inherit'
   */
  size?: IconSize;
  /**
   * @default 'inherit'
   */
  color?: IconColor;
  title?: string;
  spin?: boolean;
  /**
   * Mimics the fixed-width style from FontAwesome. Useful for aligning icons vertically.
   * @default false
   * @see https://fontawesome.com/how-to-use/on-the-web/styling/fixed-width-icons
   */
  fixedWidth?: boolean;
}

const Icon = (
  { icon, size, title, spin, fixedWidth = spin, sx, ...props }: IconProps,
  ref: React.Ref<SVGElement>
) => {
  const {
    icon: [width, height, , , svgPathData],
  } = icon;

  return (
    <SvgIcon
      ref={ref}
      viewBox={`0 0 ${width} ${height}`}
      titleAccess={title}
      fontSize={size}
      sx={[
        ...(spin
          ? [
              {
                transition: "all 0.75s linear",
                animation: `${SPIN} 0.75s infinite linear`,
              },
            ]
          : []),
        ...(fixedWidth ? [{ textAlign: "center", width: "1.25em" }] : []),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      {typeof svgPathData === "string" ? (
        <path d={svgPathData} />
      ) : (
        /**
         * A multi-path Font Awesome icon seems to imply a duotune icon. The 0th path seems to
         * be the faded element (referred to as the "secondary" path in the Font Awesome docs)
         * of a duotone icon. 40% is the default opacity.
         *
         * @see https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons#changing-opacity
         */
        svgPathData.map((d: string, i: number) => (
          <path style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />
        ))
      )}
    </SvgIcon>
  );
};

export default React.forwardRef<SVGElement, IconProps>(Icon);
