/**
 * Takes any number of pixels and creates a string with rem units based on our base font size.
 * e.g. pxToRem(12, 24) => "1.2rem 2.4rem"
 * @param pixelsArray Any number of pixels
 * @returns String with rem units
 */
export const pxToRem = (...pixelsArray: number[]) =>
  // Divide by 10 because 10px (62.5%) is the base font size.
  pixelsArray.map(pixels => `${pixels / 10}rem`).join(" ");

export default pxToRem;
