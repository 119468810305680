import React, { Dispatch, SetStateAction } from "react";

import useSessionStorageReference from "./useSessionStorageReference";

function useSessionStorageState<T>(
  key: string,
  defaultValue?: T
): [T, Dispatch<SetStateAction<T>>] {
  const { canUseSessionStorage, sessionStorage } = useSessionStorageReference();
  const [value, _setValue] = React.useState<T>(() => {
    const storedValue = sessionStorage?.getItem(key);
    const parsedObject = storedValue ? JSON.parse(storedValue) : null;

    if (!parsedObject || !parsedObject.value) {
      // the value hasn't been set yet
      return defaultValue;
    }

    return parsedObject?.value;
  });

  const saveToStorage = React.useCallback(
    (value: T) => {
      let storedObject = {};
      if (canUseSessionStorage) {
        if (value !== undefined) {
          storedObject = { value: value };
        } else {
          storedObject = {};
        }

        sessionStorage!.setItem(key, JSON.stringify(storedObject));
      }
    },
    [canUseSessionStorage, sessionStorage, key]
  );

  React.useEffect(() => {
    saveToStorage(value);
  }, [value, saveToStorage]);

  const setValue = React.useCallback(
    (value: T) => {
      _setValue(value);
      saveToStorage(value);
    },
    [saveToStorage]
  );

  return [value, setValue];
}

export default useSessionStorageState;
