// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Button, Image, Typography } from "@outschool/backpack";
import { ExternalLink } from "@outschool/ui-components-shared";
import { Card } from "@outschool/ui-legacy-component-library";
import { useIsSmall, useIsTablet } from "@outschool/ui-utils";
import React from "react";

import CookieGraphic from "../../images/CookieLock.png";

export default function CookieConsent({
  onSubmit,
}: {
  onSubmit: (_accept: boolean) => void;
}) {
  const isSmall = useIsSmall();
  const isTablet = useIsTablet();

  return (
    <Card
      sx={{
        position: "fixed",
        left: isTablet ? "0" : "45px",
        bottom: isTablet ? "0" : "27px",
        width: isTablet ? "100%" : "470px",
        minHeight: "188px",
        margin: "0",
        padding: isTablet ? "16px" : "16px 28px",
        borderRadius: isTablet ? "0" : "8px",
        background: "#FFFFFF",
        border: "1px solid rgba(23, 25, 28, 0.05)",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
        zIndex: 1001,
      }}
    >
      <Box
        flex
        sx={{
          marginBottom: "20px",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box flex>
          <Box sx={{ marginRight: "20px" }}>
            <Image
              height="80px"
              src={
                typeof CookieGraphic === "string"
                  ? CookieGraphic
                  : CookieGraphic.src
              }
            />
          </Box>
          <Box
            flex
            sx={{
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="inherit"
              sx={{
                fontWeight: 500,
              }}
            >
              Will you share your cookies?
            </Typography>
            <Typography
              variant="inherit"
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
              }}
            >
              We use cookies to make our site better. Some cookies are
              necessary, but having extra cookies lets us personalize your
              experience. Read our{" "}
              <ExternalLink
                url="https://support.outschool.com/en/articles/2663595-cookies-and-similar-technologies-for-analytics-and-advertising-on-outschool"
                trackingName="cookie_policy"
              >
                cookie policy.
              </ExternalLink>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        flex
        sx={{
          width: "100%",
          justifyContent: "space-between",
          gap: isSmall ? 0 : "10px",
          flexDirection: isSmall ? "column" : "row",
        }}
      >
        <Button
          sx={{
            flexGrow: 1,
            margin: isSmall ? "8px 0px" : "0px",
            fontSize: "0.75em",
          }}
          onClick={() => {
            onSubmit(false);
          }}
        >
          Reject extra cookies
        </Button>
        <Button
          sx={{
            flexGrow: 1,
            margin: isSmall ? "8px 0px" : "0px",
            fontSize: "0.75em",
          }}
          variant="contained"
          onClick={() => {
            onSubmit(true);
          }}
        >
          Accept all
        </Button>
      </Box>
    </Card>
  );
}
