import { Components, Theme, chipClasses } from "@mui/material";

declare module "@mui/material/Chip" {
  interface ChipClasses {
    colorNeutral: string;
  }
}

const CHIP_THEME: Components<Theme>["MuiChip"] = {
  defaultProps: {
    // Use filled variant by default to match designs
    variant: "filled",
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      // Override the root border radius to 4px as specified by design to help distinguish Chips from rounded buttons
      borderRadius: theme.spacing(4),
      cursor: "inherit",
      [`&.${chipClasses.sizeSmall}`]: {
        gap: theme.spacing(4),
      },
      [`&.${chipClasses.sizeMedium}`]: {
        gap: theme.spacing(8),
      },
    }),
    sizeMedium: ({ theme }: { theme: Theme }) => ({
      height: theme.spacing(32),
      padding: theme.spacing(8, 12),
    }),
    sizeSmall: ({ theme }: { theme: Theme }) => ({
      height: theme.spacing(21),
      padding: theme.spacing(4, 6),
    }),
    label: {
      lineHeight: 1,
      padding: 0,
      // Let taller letters overflow the lineHeight
      overflow: "visible",
    },
    labelMedium: ({ theme }: { theme: Theme }) => ({
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(16),
    }),
    labelSmall: ({ theme }: { theme: Theme }) => ({
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(13),
    }),
    icon: {
      margin: 0,
      padding: 0,
    },
    iconMedium: ({ theme }: { theme: Theme }) => ({
      height: theme.spacing(24),
      width: theme.spacing(24),
    }),
    iconSmall: ({ theme }: { theme: Theme }) => ({
      height: theme.spacing(12),
      width: theme.spacing(12),
    }),
    colorNeutral: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.grey[900],
      backgroundColor: theme.palette.grey[300],
    }),
    colorPrimary: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.primary[800],
      backgroundColor: theme.palette.primary[200],
    }),
    colorSecondary: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.secondary[700],
      backgroundColor: theme.palette.secondary[200],
    }),
    colorError: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.error[700],
      backgroundColor: theme.palette.error[200],
    }),
    colorWarning: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.warning[800],
      backgroundColor: theme.palette.warning[300],
    }),
    colorInfo: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.info[700],
      backgroundColor: theme.palette.info[200],
    }),
    colorSuccess: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.success[700],
      backgroundColor: theme.palette.success[200],
    }),
    deleteIcon: ({ theme }: { theme: Theme }) => ({
      margin: 0,
      marginLeft: theme.spacing(8),
    }),
  },
};

export default CHIP_THEME;
