/**
 * This file was generated by the `pnpm new` component script.
 *
 * THIS SKELETON TEMPLATE IS ONLY FOR QUICKSTART PURPOSES!
 * Make sure to review and test all code after filling in the skeleton!
 */

import { Components, Theme } from "@mui/material";
import { linearProgressClasses } from "@mui/material/LinearProgress";

const PROGRESS_BAR_THEME: Components<Theme>["MuiLinearProgress"] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      borderRadius: 20,
      height: 7,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
          theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
      },
    }),
  },
};

export default PROGRESS_BAR_THEME;
