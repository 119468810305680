/** @jsx jsxPragma */

import { useTheme } from "@emotion/react";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import jsxPragma from "../jsxPragma";
import {
  default as LegacyTrackedButton,
  LegacyTrackedButtonProps,
} from "./LegacyTrackedButton";

export type LegacyButtonVariant = "raised" | "contained" | "link";

export type LegacyButtonColor =
  | "primary"
  | "secondary"
  | "confirm"
  | "danger"
  | "facebook"
  | "google"
  | "social"
  | "black";

export type LegacyButtonSize = "tiny" | "small" | "medium" | "large";

export type LegacyVariantType = {
  baseStyle: SystemStyleObject;
  colors: Record<LegacyButtonColor, SystemStyleObject>;
  sizes: Record<
    LegacyButtonSize,
    SystemStyleObject & { round?: SystemStyleObject }
  >;
  roundStyle?: SystemStyleObject;
};

export type LegacyButtonThemeType = {
  baseStyle: SystemStyleObject;
  variants: Record<LegacyButtonVariant, LegacyVariantType>;
};

declare module "@emotion/react" {
  export interface Theme {
    buttons?: LegacyButtonThemeType;
  }
}

export type LegacyButtonType = "button" | "submit";

export const useLegacyButtonTheme = ({
  variant,
  color,
  size,
  round,
  sx,
}: {
  variant: LegacyButtonVariant;
  color: LegacyButtonColor;
  size: LegacyButtonSize;
  round: boolean;
  sx: any;
}): SystemStyleObject => {
  const theme = useTheme();

  if (!theme || theme.buttons === undefined) {
    return sx;
  }

  const {
    buttons: { baseStyle: baseSx, variants },
  } = theme;
  const {
    baseStyle: variantSx,
    colors,
    sizes,
    roundStyle = {},
  }: LegacyVariantType = variants[variant];
  const colorSx: SystemStyleObject = colors[color];
  const { round: _sizeRoundSx, ..._sizeSx } = sizes[size] || {};
  const sizeRoundSx: SystemStyleObject = _sizeRoundSx || {};
  const sizeSx: SystemStyleObject = _sizeSx;
  const roundSx: SystemStyleObject = round
    ? {
        ...roundStyle,
        ...sizeRoundSx,
      }
    : {};

  return {
    ...baseSx,
    ...variantSx,
    ...colorSx,
    ...sizeSx,
    ...roundSx,
    ...sx,
  };
};

export interface LegacyButtonProps extends LegacyTrackedButtonProps {
  variant?: LegacyButtonVariant;
  color?: LegacyButtonColor;
  size?: LegacyButtonSize;
  type?: LegacyButtonType;
  as?: any;
  round?: boolean;
  disabled?: boolean;
  sx?: SystemStyleObject;
  onClick?(e: React.SyntheticEvent): void;
  children?: React.ReactNode;
  [propName: string]: any;
}
/**
 * A button.
 * @deprecated This component is deprecated. Please use \@outschool/backpack Button
 */
const LegacyButton: React.ForwardRefExoticComponent<LegacyButtonProps> =
  React.forwardRef<React.Ref<any>, LegacyButtonProps>(
    (
      {
        variant = "raised",
        color = "secondary",
        size = "medium",
        round = false,
        sx,
        ...props
      },
      ref
    ) => {
      const sxObject = useLegacyButtonTheme({
        variant,
        color,
        size,
        round,
        sx,
      });

      return <LegacyTrackedButton sx={sxObject} {...props} ref={ref} />;
    }
  );
LegacyButton.displayName = "Button";

export default LegacyButton;
