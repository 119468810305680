import { ApolloError } from "@outschool/ui-apollo";
import isPlainObject from "lodash/isPlainObject";
import mapValues from "lodash/mapValues";
import omit from "lodash/omit";

export function isApolloError(err: unknown): err is ApolloError {
  return (
    !!err &&
    (err instanceof ApolloError ||
      (err as Object).hasOwnProperty("graphQLErrors"))
  );
}

export class UserFacingError extends Error {
  constructor(apolloError: ApolloError) {
    const message = userFriendlyErrorMessage(apolloError);
    super(message);
    // captureStackTrace is a non-standard v8 extension
    // see http://stackoverflow.com/questions/1382107/whats-a-good-way-to-extend-error-in-javascript
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = apolloError.stack;
    }
    this.name = "";
    this.message = message;
  }

  public toString() {
    return this.message;
  }
}

function userFriendlyErrorMessage(apolloError: ApolloError): string {
  if (!apolloError.networkError && apolloError.graphQLErrors.length > 0) {
    return apolloError.graphQLErrors
      .filter(e => !!e)
      .map(e => e.message)
      .join("\n");
  }
  return "Sorry, that didn't work. We'll look into it. You can reload the page or contact support@outschool.com.";
}

interface BaseObject {
  [key: string]: any;
}

type ObjectWithTypename<T extends BaseObject> = T & {
  __typename?: string;
};

type ObjectWithoutTypename<T extends BaseObject> = Omit<T, "__typename">;

export function cleanTypeNameField<T extends ObjectWithTypename<BaseObject>>(
  obj: T
): ObjectWithoutTypename<T> {
  if (!isPlainObject(obj)) {
    return obj;
  }
  return mapValues(
    omit(obj, "__typename"),
    cleanTypeNameField
  ) as ObjectWithoutTypename<T>;
}

export function addTypeName<T extends BaseObject>(
  typeName: string,
  objOrArray: T | T[]
): ObjectWithTypename<T> | ObjectWithTypename<T>[] {
  if (!typeName) {
    throw new Error("typeName is not set");
  }
  if (!objOrArray) {
    return objOrArray;
  }

  if (objOrArray instanceof Array) {
    return objOrArray.map(item =>
      addTypeName(typeName, item)
    ) as ObjectWithTypename<T>[];
  } else if (objOrArray instanceof Object) {
    return {
      __typename: typeName,
      ...objOrArray,
    };
  }

  return objOrArray;
}

/**
 * Validates that optimisticResponse and every field of it has __typename set.
 *
 * @param optimisticResponse
 */
export function isValidOptimisticResponse(optimisticResponse: any) {
  if (!optimisticResponse) {
    return true;
  }
  if (optimisticResponse instanceof Array) {
    if (
      optimisticResponse.some(item =>
        item === undefined ? false : !isValidOptimisticResponse(item)
      )
    ) {
      return false;
    }
  } else if (optimisticResponse instanceof Object) {
    if (typeof optimisticResponse.__typename === "undefined") {
      return false;
    }
    for (const field in optimisticResponse) {
      const fieldVal = optimisticResponse[field];
      if (fieldVal instanceof Array) {
        if (!isValidOptimisticResponse(fieldVal)) {
          return false;
        }
      } else if (fieldVal instanceof Date) {
        // skip Date fields
      } else if (fieldVal instanceof Object) {
        if (!isValidOptimisticResponse(fieldVal)) {
          return false;
        }
      }
    }
  }

  return true;
}

/**
 * Validates that optimisticResponse and every field of it has __typename set.
 * It returns the optimisticResponse if it is valid, undefined otherwise.
 *
 * @param optimisticResponse
 * @param logWarning
 */
export function validOptimisticResponse(
  optimisticResponse: any,
  logWarning = true
) {
  const isValid = isValidOptimisticResponse(optimisticResponse);

  if (!isValid && logWarning) {
    const error = new Error("Not a valid optimisticResponse");
    OsPlatform.captureError(error, {
      tags: { package: "ui-utils" },
      extra: { optimisticResponse },
    });
  }

  if (!isValid) {
    return undefined;
  }

  return optimisticResponse;
}
