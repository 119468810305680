import { BackpackThemeProvider, Theme } from "@outschool/backpack";
import React from "react";

import LEGACY_THEME from "./theme";

type ThemeProviderProps = {
  children: React.ReactNode;
  theme?: Theme | ((theme: Theme) => Theme);
};

/**
 * Backpack ThemeProvider wrapped in support for legacy components.
 */
const LegacyThemeProvider = ({ children, theme }: ThemeProviderProps) => (
  <BackpackThemeProvider
    theme={baseTheme => {
      const newBaseTheme =
        typeof theme === "function"
          ? // Get modified theme using callback
            theme(baseTheme)
          : // override the theme completely
          typeof theme === "object"
          ? theme
          : // just use baseTheme
            baseTheme;

      // Merge legacy and new theme so that legacy components / theme references still work
      // breakpoints and shadows keys are used by MUI theme AND legacy components,
      // so make sure they're the correct shape for both.
      const breakpoints = Object.assign(
        LEGACY_THEME.breakpoints,
        newBaseTheme.breakpoints
      );
      breakpoints.values = {
        small: breakpoints.small,
        medium: breakpoints.medium,
        large: breakpoints.large,
        xlarge: breakpoints.xlarge,
      };
      // eslint-disable-next-line i18next/no-literal-string
      breakpoints.keys = ["small", "medium", "large", "xlarge"];

      const shadows = Object.assign(newBaseTheme.shadows, LEGACY_THEME.shadows);

      return {
        ...LEGACY_THEME,
        ...newBaseTheme,
        breakpoints,
        shadows,
      };
    }}
  >
    {children}
  </BackpackThemeProvider>
);

export default LegacyThemeProvider;
