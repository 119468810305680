// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

import { getReferrerAttribution } from "@outschool/attribution";
import {
  TrackUserCreatedMutation,
  TrackUserCreatedMutationVariables,
  TrackUserCreatedProperties,
} from "@outschool/gql-frontend-generated";
import { gql, useMutation } from "@outschool/ui-apollo";
import React from "react";

export const trackUserCreatedMutation = gql`
  mutation TrackUserCreated($properties: TrackUserCreatedProperties!) {
    trackUserCreated(properties: $properties)
  }
`;

export function useTrackUserCreatedMutation() {
  const [trackUserCreated] = useMutation<
    TrackUserCreatedMutation,
    TrackUserCreatedMutationVariables
  >(trackUserCreatedMutation);

  return React.useCallback(
    async (properties: TrackUserCreatedProperties, anonymousId?: string) => {
      const { data } = await trackUserCreated({
        variables: {
          properties: {
            osRef: {
              ...getReferrerAttribution(),
              anonymousId,
            },
            ...properties,
          },
        },
      });

      if (!data) {
        throw new Error("No data returned");
      }

      return data.trackUserCreated;
    },
    [trackUserCreated]
  );
}
