import {
  LoginWithGoogleOneTapMutation,
  LoginWithGoogleOneTapMutationVariables,
  LoginWithGoogleOneTapV2Mutation,
  LoginWithGoogleOneTapV2MutationVariables,
} from "@outschool/gql-frontend-generated";
import { useMutation } from "@outschool/ui-apollo";

import LoginOrCreateAccountWithGoogleOneTapMutation, {
  loginOrCreateAccountWithGoogleOneTapMutationV2,
} from "../graphql/LoginOrCreateAccountWithGoogleOneTapMutation";

export function useLoginWithGoogleOneTapMutation() {
  const [loginWithGoogle] = useMutation<
    LoginWithGoogleOneTapMutation,
    LoginWithGoogleOneTapMutationVariables
  >(LoginOrCreateAccountWithGoogleOneTapMutation);

  return loginWithGoogle;
}

export function useLoginWithGoogleOneTapMutationV2() {
  const [loginWithGoogle] = useMutation<
    LoginWithGoogleOneTapV2Mutation,
    LoginWithGoogleOneTapV2MutationVariables
  >(loginOrCreateAccountWithGoogleOneTapMutationV2);

  return loginWithGoogle;
}
