import { CurrencyCode } from "@outschool/gql-backend-generated";
import { useCallback } from "react";
import { useExchangeRate } from "./useExchangeRate";
import {
  convertFromUSDCents,
  convertMinorToMajorDenomination,
} from "../utils/currency";

export function useConvertToUserDefaultCurrency(currencyCode: CurrencyCode) {
  const { loading, error, exchangeRate } = useExchangeRate(
    currencyCode as CurrencyCode
  );

  // Convert an amount in USD cents to "minor" units in another currency
  const convertCents = useCallback(
    (amountCents: number) => {
      if (!loading && !error && exchangeRate && exchangeRate > 0.0) {
        return convertFromUSDCents({
          amount: amountCents,
          toCurrencyCode: currencyCode as CurrencyCode,
          toExchangeRate: exchangeRate,
        });
      }
      // Default to USD, most likely to indicate user not in experiment
      return amountCents;
    },

    [currencyCode, exchangeRate, loading, error]
  );

  // Convert an amount in USD dollars to the main denomination of another currency
  const convertDollars = useCallback(
    (amountDollars: number) => {
      return convertMinorToMajorDenomination({
        amount: convertCents(amountDollars * 100),
        currencyCode: currencyCode as CurrencyCode,
      });
    },
    [convertCents, currencyCode]
  );

  return { convertCents, convertDollars, loading, error };
}
