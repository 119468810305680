/*
 * Geo headers: https://dash.cloudflare.com/0760e1cb4567103998058ce3dbacd328/outschool.com/rules/transform-rules/modify-request-header/c5afe39d05da467e96d2e2b52226a3c4
 * Bot headers: https://dash.cloudflare.com/0760e1cb4567103998058ce3dbacd328/outschool.com/rules/transform-rules/modify-request-header/abbf8277997e4b589e56b123b3c5119d
 */
export enum HeaderKeys {
  Agent = "x-os-agent",
  AuthError = "x-os-auth-error",
  BotScore = "x-bot-score",
  BotVerified = "x-bot-verified",
  BrowserTimezone = "x-os-browser-timezone",
  ClientVersion = "x-os-client-version",
  DeviceUid = "x-os-device-uid",
  Ip = "x-geoip-ip",
  IpCountry = "x-geoip-country",
  IsInEuropeanUnion = "x-geoip-is-in-european-union",
  Locale = "Accept-Language",
  LoggedOutUserExperimentUid = "x-os-logged-out-user-experiment-uid",
  PageCategory = "x-os-page-category",
  PageImpressionId = "x-os-page-impression-id",
  PageLoadId = "x-os-page-load-id",
  PageName = "x-os-page-name",
  PagePath = "x-os-page-path",
  ResolutionHeight = "x-os-res-height",
  ResolutionIsMobile = "x-os-res-is-mobile",
  ResolutionWidth = "x-os-res-width",
  ThreatScore = "x-threat-score",
}

export enum CookieKeys {
  AnonymousId = "ajs_anonymous_id",
  CookiePreferences = "tracking-preferences",
  EsaSession = "esaSession",
  EsaSessionType = "esaSessionType",
  InternalTester = "isImpersonating",
  LoggedInUserExperimentUid = "loggedInUserExperimentUid",
  LoggedOutUserExperimentUid = "loggedOutUserExperimentUid",
  OsBot = "os-bot",
  OsBrowserName = "os-browser",
  // Class details page first landing experience
  OsFleExp = "os-fle-exp",
  // Managed challenge
  OsMcActExp = "os-mc-act-exp",
  OsPageLoadId = "os-plid",
  OsRef = "os-ref",
  AnalyticsSessionId = "analytics_session_id",
}

export enum UrlParamKeys {
  // first landing experience
  OsFleExp = "os-fle-exp",
}
