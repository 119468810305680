import { styled } from "@mui/system";

import { BaseImageProps } from "./types";

/**
 * A simple img element wrapped in styled(), exposing the `sx` prop.
 */
export const BaseImage = styled("img")(
  {}
) as React.ExoticComponent<BaseImageProps>;
