import {
  PLATFORM_LIB_INITIALIZER,
  PlatformImpl,
  getSentryOsPlatformIntegration,
} from "@outschool/platform/initialize";

import type { OsEnvironment } from "@outschool/platform";
import type { Client } from "@sentry/types";

type SentrySdk = { getClient(): Client | undefined };

export type PlatformBrowserInit = {
  env: OsEnvironment;
  sentry: SentrySdk | undefined;
};

export function initializePlatformBrowser(opts: PlatformBrowserInit) {
  const client = opts.sentry?.getClient();
  if (client) {
    const key = client.getOptions().enabled ? "Sentry" : "Sentry(disabled)";
    client.addIntegration?.(
      getSentryOsPlatformIntegration({
        logMessage: msg => console.log(`${key}::message`, msg),
        logError: err => console.error(`${key}::error`, err),
      })
    );
  }

  PLATFORM_LIB_INITIALIZER.setImplementation({
    ...buildCaptureImpl(opts.sentry, client),

    environment: opts.env,

    // there's no delay on the frontend, so call them now.
    addInitHook: fn => fn(),

    // cannot delay exiting in the browser, so probably no need
    // to support exit hooks
    addExitHook: () => undefined,

    // no otel in the frontend right now
    addSpanAttributes: () => undefined,
    runSpan: (_a, _b, fn) => fn(),

    // no async-hooks or sentry isolation scope in the frontend
    runWithContextBaggage: (_a, fn) => fn(),
    getContextBaggage: () => undefined,
    updateContextBaggage: () => undefined,
  });
}

function buildCaptureImpl(
  sentry: SentrySdk | undefined,
  client: Client | undefined
): Pick<PlatformImpl, "captureError" | "captureMessage"> {
  if (client) {
    return {
      captureError: (thing, ctx) => {
        client.captureException(thing, { captureContext: ctx });
      },
      captureMessage: (msg, ctx) => {
        client.captureMessage(msg, undefined, { captureContext: ctx });
      },
    };
  }

  const key = sentry ? "Sentry(not initialized)" : "Sentry(missing)";
  return {
    captureError: e => console.error(`${key}::error`, e),
    captureMessage: m => console.log(`${key}::message`, m),
  };
}
