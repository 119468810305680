// Themes
export enum ActivitySubject {
  Arts = "Arts",
  CodingAndTech = "Coding & Tech",
  EarlyEducation = "Early Education",
  English = "English",
  GamesAndHobbies = "Games & Hobbies",
  HealthAndWellness = "Health & Wellness",
  LifeSkills = "Life Skills",
  Math = "Math",
  Music = "Music",
  ScienceAndNature = "Science & Nature",
  SocialStudies = "Social Studies",
  TestPrep = "Test Prep",
  WorldLanguages = "World Languages",
}

export const courseActivitySubjects = [
  ActivitySubject.English,
  ActivitySubject.Math,
  ActivitySubject.ScienceAndNature,
  ActivitySubject.SocialStudies,
];

export const Subjects = Object.values(ActivitySubject);

export function isActivitySubject(name: string): name is ActivitySubject {
  return Object.values(ActivitySubject).includes(name as any);
}

export function isCourseActivitySubject(name: string): name is ActivitySubject {
  return courseActivitySubjects.includes(name as ActivitySubject);
}

/**
 * Primarily used on the homepage in "Classes by subject"
 */
export const SubjectThemes = [
  ...Object.values(ActivitySubject),
  "Learner Favorites",
] as const;
export type SubjectThemeName = (typeof SubjectThemes)[number];

export function isSubjectThemeName(name: string): name is SubjectThemeName {
  return SubjectThemes.includes(name as any);
}

export const SubtopicsForThemes: Record<ActivitySubject, string[]> = {
  [ActivitySubject.Arts]: [
    "drawing",
    "photography",
    "dance",
    "theater",
    "film",
    "sewing",
  ],
  [ActivitySubject.CodingAndTech]: [
    "coding",
    "video game design",
    "robotics",
    "engineering",
    "electronics",
    "internet safety",
    "animation",
  ],
  [ActivitySubject.EarlyEducation]: [],
  [ActivitySubject.English]: [
    "creative writing",
    "grammar & spelling",
    "book club",
    "essay writing",
    "poetry",
    "literature",
    "book clubs",
  ],
  [ActivitySubject.GamesAndHobbies]: [],
  [ActivitySubject.HealthAndWellness]: [
    "hygiene",
    "managing emotions",
    "sex ed",
    "exercise",
  ],
  [ActivitySubject.LifeSkills]: [
    "cooking & nutrition",
    "financial skills",
    "study skills",
    "social skills",
    "critical thinking",
  ],
  [ActivitySubject.Math]: [
    "algebra",
    "arithmetic",
    "geometry",
    "fractions",
    "calculus",
    "statistics & probability",
  ],
  [ActivitySubject.Music]: [
    "instrument lessons",
    "singing",
    "music composition",
    "music studies",
  ],
  [ActivitySubject.ScienceAndNature]: [
    "chemistry",
    "biology",
    "animal studies",
    "physics",
    "astronomy",
    "anatomy",
    "marine biology",
    "psychology",
  ],
  [ActivitySubject.SocialStudies]: [
    "geography",
    "world history",
    "us history",
    "anthropology",
    "economics",
    "political science",
  ],
  [ActivitySubject.TestPrep]: [],
  [ActivitySubject.WorldLanguages]: [
    "spanish",
    "sign language",
    "french",
    "japanese",
    "latin",
    "german",
    "chinese",
    "greek",
    "italian",
    "mandarin",
    "russian",
  ],
};
export type Subtopic =
  (typeof SubtopicsForThemes)[keyof typeof SubtopicsForThemes][number];

/**
 * Primarily used on the homepage in "Classes by subject"
 */
export const SubtopicsForSubjectThemes = {
  ...SubtopicsForThemes,
  "Learner Favorites": [
    "adhd",
    "adventure",
    "american history",
    "camp",
    "cats",
    "composers",
    "cooking",
    "dog training",
    "dogs",
    "emotions",
    "french conversation",
    "geology",
    "grammar",
    "guitar",
    "mindfulness",
    "music theory",
    "numbers",
    "nutrition",
    "piano",
    "politics",
    "probability",
    "science experiments",
    "spelling",
    "statistics",
    "travel",
    "ukulele",
    "unicorns",
    "weather",
    "zoology",
  ] as const,
};
export type SubjectSubtopic =
  (typeof SubtopicsForSubjectThemes)[keyof typeof SubtopicsForSubjectThemes][number];

export const TutorSubtopicsForThemes: Record<ActivitySubject, string[]> = {
  [ActivitySubject.Arts]: [
    "acting",
    "animation",
    "dance",
    "digital art",
    "drawing",
    "film",
    "painting",
    "photography",
  ],
  [ActivitySubject.CodingAndTech]: [
    "artificial intelligence",
    "programming",
    "robotics",
    "typing",
    "video game design",
  ],
  [ActivitySubject.EarlyEducation]: [],
  [ActivitySubject.English]: [
    "creative writing",
    "debate",
    "literature",
    "public speaking",
    "reading",
    "writing",
  ],
  [ActivitySubject.GamesAndHobbies]: ["chess", "video games"],
  [ActivitySubject.HealthAndWellness]: [
    "mental & emotional health",
    "nutrition",
    "sex education",
    "sports & fitness",
  ],
  [ActivitySubject.LifeSkills]: [
    "business",
    "critical thinking",
    "financial Literacy",
    "social skills",
    "study skills",
  ],
  [ActivitySubject.Math]: [
    "algebra 1",
    "algebra 2",
    "arithmetic",
    "calculus",
    "geometry",
    "pre-algebra",
    "pre-calculus & trigonometry",
    "statistics & probability",
  ],
  [ActivitySubject.Music]: [
    "cello",
    "clarinet",
    "drums & percussion",
    "flute",
    "guitar",
    "music theory",
    "piano & keyboard",
    "saxophone",
    "singing",
    "trombone",
    "trumpet",
    "uklele",
    "violin",
  ],
  [ActivitySubject.ScienceAndNature]: [
    "anatomy",
    "biology",
    "chemistry",
    "environmental science",
    "marine biology",
    "physics",
    "space & astronomy",
  ],
  [ActivitySubject.SocialStudies]: [
    "economics",
    "geography",
    "government & civics",
    "history",
    "philosophy",
    "psychology",
  ],
  [ActivitySubject.TestPrep]: ["ACT prep", "SAT prep"],
  [ActivitySubject.WorldLanguages]: [
    "chinese",
    "english",
    "french",
    "german",
    "japanese",
    "latin",
    "sign language",
    "spanish",
  ],
};
