/**
 * This file consolidates seo logic
 */
import ldCompact from "lodash/compact";
import ldStartCase from "lodash/startCase";
import ldUniq from "lodash/uniq";

import {
  DEFAULT_SUBJECTS,
  DEFAULT_SUBJECTS_LIST_VERSION,
} from "./constants/subjects";
import type { Subject } from "./types";

export {
  DEFAULT_SUBJECTS_LIST_VERSION,
  DEFAULT_SUBJECTS,
} from "./constants/subjects";
export type {
  Testimonial,
  CategoryItem,
  Category,
  Subject,
  Topic,
  SubjectTopic,
} from "./types";

export function cleanUrlTerm(term: string): string {
  return (
    term
      // spell out certain special characters
      ?.replace(/\+/g, "-plus")
      .replace(/c\#/i, "c-sharp")
      .replace(/&/g, "and")
      .replace(/'/g, "")
      // remove non-alphanumeric characters with spaces
      .replace(/[\W-]+/g, " ")
      .trim()
      .toLowerCase()
      // replace spaces with a single dash
      .replace(/\s+/g, "-")
  );
}

export function isTermWithinSubjectList(
  term: string,
  version: keyof typeof DEFAULT_SUBJECTS = DEFAULT_SUBJECTS_LIST_VERSION
): boolean {
  return fetchSubjects(version).includes(cleanUrlTerm(term));
}

export function fetchSubjects(
  version: keyof typeof DEFAULT_SUBJECTS = DEFAULT_SUBJECTS_LIST_VERSION
): string[] {
  return cleanSubjectTerms(DEFAULT_SUBJECTS[version]);
}

export function niceSubjectTerm(subject: string): string {
  return ldStartCase(cleanUrlTerm(subject)).replace("And", "&");
}

function cleanSubjectTerms(subjectList: Subject[]): string[] {
  return ldCompact(
    ldUniq(
      subjectList.map((subject: Subject) => {
        return cleanUrlTerm(subject.slug);
      })
    )
  ).sort();
}

// note: no trailing slash for online classes but it is also a root url that can contain search query parameters
const allowedNoIndexPaths = [
  "/classes/",
  "/online-classes",
  "/teachers/",
  "/users/login",
];
export function shouldServeNoIndex({
  host = "",
  urlPath,
  hasSearchQuery,
  osAgent = "",
}: {
  host?: string;
  urlPath: string;
  hasSearchQuery: boolean;
  osAgent?: string;
}): boolean {
  if (host === "website.app.outschool.com" && !osAgent.includes("Outschool")) {
    return true;
  }

  // then check if the urlPath is allowed
  if (
    !urlPath ||
    !allowedNoIndexPaths.some((allowedPath: string) => {
      // also check for i18n url by removing first url segment
      // ex: /ja/classes/* --> /classes/*
      const urlPathFilteredFirstSegment = `/${urlPath
        .split("/")
        .slice(2)
        .join("/")}`;

      return (
        urlPath.startsWith(allowedPath) ||
        urlPathFilteredFirstSegment.startsWith(allowedPath)
      );
    })
  ) {
    return false;
  }

  // if any search params are present then show noindex
  if (hasSearchQuery) {
    return true;
    // if /enroll is present within a class url
  } else if (urlPath.includes("/classes/") && urlPath.includes("/enroll")) {
    return true;
  }

  return false;
}
