import React from "react";
import { useSessionStorage } from "usehooks-ts";

import { BASE_LOCALE, I18nLocale } from "../constants";
import { useLocale } from "../localization/LocaleProvider";
import { getMachineTranslation } from "./machineTranslation";

type TranslationsCache = {
  [key: string]: {
    [lang: string]: string;
  };
};
type TranslationState = {
  lookupName?: string;
  baseText: string;
  locale?: I18nLocale;
  translatedText: string;
};
type SetTranslationCacheFn = (translation: TranslationsCache) => void;

function translationStateReducer(setTranslationCache: SetTranslationCacheFn) {
  return function (state: TranslationsCache, action: TranslationState) {
    const { lookupName, baseText, locale, translatedText } = action;
    if (locale) {
      state[lookupName ?? baseText] = {
        ...state[lookupName ?? baseText],
        ...{ [locale]: translatedText },
      };
      setTranslationCache(state);
    }
    return state;
  };
}
function getTranslationError(locale?: I18nLocale) {
  console.log("getTranslationError", locale);
  switch (locale) {
    case I18nLocale.Ko:
      return "자동 번역 오류가 발생했습니다. 페이지를 새로고침 해주세요.";
    case I18nLocale.Ja:
      return "自動翻訳エラーが発生しました。ページをリフレッシュしてください。";
    case I18nLocale.ZhTw:
      return "我們無法翻譯此文，請刷新頁面並再試一次。";
    case I18nLocale.Es:
      return "Se produjo un error al traducir este texto. Actualice la página e inténtelo de nuevo.";
    case I18nLocale.En:
    default:
      return "We were unable to translate this text. Please refresh the page and try again.";
  }
}

export type UseMachineTranslationType = () => {
  translate: (baseText: string, lookupName?: string) => string;
  locale: I18nLocale;
};

export function createUseMachineTranslationHook({
  useIsTranslating,
  onError,
  onTranslated,
}: {
  useIsTranslating: () => boolean;
  onError(_args: { error: Error; translatedErrorMessage: string }): void;
  onTranslated(): void;
}) {
  return () => {
    const locale = useLocale();
    const isTranslating = useIsTranslating();
    const loadingTranslation = React.useRef<{ [key: string]: boolean }>({});
    const [cachedTranslations, setTranslations] =
      useSessionStorage<TranslationsCache>("translations-cache", {});
    const [translations, updateTranslations] = React.useReducer(
      translationStateReducer(setTranslations),
      cachedTranslations
    );
    const [translationsInProgress, setTranslationsInProgress] =
      React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    React.useEffect(() => {
      if (error) {
        setError(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    const googleTranslate = React.useMemo(
      () => getMachineTranslation(locale, isTranslating),
      [locale, isTranslating]
    );

    const translate = React.useCallback(
      (baseText: string, lookupName?: string) => {
        if (locale === BASE_LOCALE) {
          return baseText;
        }
        if (!locale) {
          return baseText;
        }
        const cachedTranslation =
          translations?.[lookupName ?? baseText]?.[locale];
        if (cachedTranslation || cachedTranslation === "") {
          onTranslated();
          return cachedTranslation;
        }
        if (!loadingTranslation.current[lookupName ?? baseText] && !error) {
          !translationsInProgress && setTranslationsInProgress(true);
          loadingTranslation.current = {
            ...loadingTranslation.current,
            ...{ [lookupName ?? baseText]: true },
          };

          googleTranslate(baseText)
            .then(translatedText => {
              updateTranslations({
                locale,
                lookupName,
                translatedText,
                baseText,
              });
              onTranslated();
            })
            .catch(err => {
              console.error(err);
              setError(true);
              onError({
                error: err,
                translatedErrorMessage: getTranslationError(locale),
              });
            })
            .finally(() => {
              loadingTranslation.current = {
                ...loadingTranslation.current,
                ...{ [lookupName ?? baseText]: false },
              };
              if (
                Object.values(loadingTranslation.current).every(value => !value)
              ) {
                setTranslationsInProgress(false);
              }
            });
        }
        return baseText;
      },
      [
        locale,
        translations,
        translationsInProgress,
        setTranslationsInProgress,
        googleTranslate,
        error,
      ]
    );

    return { translate, locale };
  };
}
