import { CurrencyCode } from "@outschool/gql-backend-generated";
import React from "react";

import {
  CountryCode,
  CountryInfo,
  COUNTRIES_INFO as COUNTRIES_INFO,
} from "../constants";

export const SELECTED_CURRENCY_CODE_LOCAL_STORAGE_KEY = "selectedCurrencyCode";

export const CurrencyLocalizationContext = React.createContext<{
  countryCode: CountryCode;
  currencyCode: CurrencyCode;
  selectedCurrencyCountry: CountryInfo;
  isLoading: boolean;
  hasLoaded: boolean;
}>({
  countryCode: CountryCode.US,
  currencyCode: CurrencyCode.Usd,
  selectedCurrencyCountry: COUNTRIES_INFO[CountryCode.US],
  isLoading: false,
  hasLoaded: true,
});

export const SetCurrencyLocalizationContext = React.createContext<{
  setCurrency: (currency: CurrencyCode) => Promise<void>;
}>({
  setCurrency: async () => {},
});

export const useSetCurrencyLocalization = () =>
  React.useContext(SetCurrencyLocalizationContext);

export const useCurrencyLocalization = () =>
  React.useContext(CurrencyLocalizationContext);
