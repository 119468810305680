import React from "react";
import { BASE_LOCALE, I18nLocale } from "../constants";

const LocaleContext = React.createContext<I18nLocale>(I18nLocale.En);

export const LocaleProvider = ({
  children,
  userSelectedLocale = BASE_LOCALE,
}: React.PropsWithChildren<{
  userSelectedLocale?: I18nLocale;
}>) => (
  <LocaleContext.Provider value={userSelectedLocale}>
    {children}
  </LocaleContext.Provider>
);

export const useLocale: () => I18nLocale = () =>
  React.useContext(LocaleContext);
