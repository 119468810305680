import { Box } from "@outschool/backpack";
import { TFunction, Trans, useTranslation } from "@outschool/localization";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { changePasswordUrl } from "@outschool/routes";
import { ExternalLink } from "@outschool/ui-components-shared";
import React from "react";

export enum AccountLockedReason {
  Inactivity,
  SuspiciousActivity,
  TooManyFailedLoginAttempts,
}

function getMessage(reason: AccountLockedReason, t: TFunction) {
  switch (reason) {
    case AccountLockedReason.Inactivity:
      return t`Your account has been locked due to inactivity.`;
    case AccountLockedReason.SuspiciousActivity:
      return t`Your account has been locked due to suspicious activity`;
    case AccountLockedReason.TooManyFailedLoginAttempts:
      return t`Your account has been locked due to too many failed password attempts.`;
    default:
      ((_reason: never) => {
        throw new Error("invalid account locked reason");
      })(reason);
  }
}
export default function AccountLockedError({
  reason = AccountLockedReason.TooManyFailedLoginAttempts,
}: {
  reason?: AccountLockedReason;
}) {
  const { t } = useTranslation("ui-auth\\src\\AccountLockedError");
  return (
    <Box>
      <Box>{getMessage(reason, t)}</Box>
      <Box
        sx={{
          marginTop: "15px",
        }}
      >
        <Trans t={t}>
          Click here to{" "}
          <ExternalLink url={changePasswordUrl()}>
            reset your password
          </ExternalLink>{" "}
          or contact{" "}
          <ExternalLink
            url={`mailto:support@outschool.com?subject=Reset Password Request`}
          >
            support@outschool.com
          </ExternalLink>
          .
        </Trans>
      </Box>
    </Box>
  );
}
