import { useEffect, useState } from "react";

// Default to false on the server since you can't match a media query without a
// browser.
const getInitialState = (query: string) =>
  (typeof window !== "undefined" && window?.matchMedia(query).matches) ?? false;

function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(() => getInitialState(query));

  useEffect(() => {
    const matchQuery = window.matchMedia(query);
    setMatches(matchQuery.matches);

    const callback = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };
    // Use `addListener` to increase legacy browser support.
    matchQuery.addListener(callback);

    // Use `removeListener` to increase legacy browser support.
    return () => matchQuery.removeListener(callback);
  }, [query]);

  return matches;
}

export default useMediaQuery;
