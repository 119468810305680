const CREDIT_TO_PRICE_CENTS_CONVERSION_RATE = 50;
const priceCentsToCredits = (priceCents: number) => {
  return (
    roundPriceCentsToNearestCreditInCents(priceCents) /
    CREDIT_TO_PRICE_CENTS_CONVERSION_RATE
  );
};

const creditsToPriceCents = (credits: number) => {
  return credits * CREDIT_TO_PRICE_CENTS_CONVERSION_RATE;
};

const roundPriceCentsToNearestCreditInCents = (priceCents: number) => {
  return (
    Math.ceil(priceCents / CREDIT_TO_PRICE_CENTS_CONVERSION_RATE) *
    CREDIT_TO_PRICE_CENTS_CONVERSION_RATE
  );
};

export {
  priceCentsToCredits,
  creditsToPriceCents,
  roundPriceCentsToNearestCreditInCents,
};
