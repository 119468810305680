import { hasExpired, shouldRefresh } from "@outschool/auth-shared";
import {
  SessionRefreshV2Mutation,
  SessionRefreshV2MutationVariables,
} from "@outschool/gql-frontend-generated";

import { ApolloClient, gql } from "@outschool/ui-apollo";

const refreshV2Mutation = gql`
  mutation SessionRefreshV2(
    $refreshToken: String
    $browserTimeZone: String
    $doNotTrack: Boolean
    $refreshSource: TokenRefreshSource
  ) {
    refreshV2(
      refreshToken: $refreshToken
      browserTimeZone: $browserTimeZone
      doNotTrack: $doNotTrack
      refreshSource: $refreshSource
    ) {
      __typename

      sessionToken

      ... on AuthenticationV2 {
        refreshToken
      }
    }
  }
`;

export function shouldAutomaticallyRefresh(
  sessionToken: string | null,
  refreshToken: string | null,
  context?: Record<string, any>
) {
  return (
    !!refreshToken &&
    shouldRefresh(sessionToken) &&
    !hasExpired(refreshToken) &&
    !context?.ignoreAutoRefresh
  );
}

/**
 * Request the client perform a session refresh
 * TODO: refresh on an interval automatically within the client
 */
export async function sessionRefresh<TCacheShape = unknown>(
  client: ApolloClient<TCacheShape>,
  variables: SessionRefreshV2MutationVariables
) {
  return await client.mutate<
    SessionRefreshV2Mutation,
    SessionRefreshV2MutationVariables
  >({
    mutation: refreshV2Mutation,
    variables,
    context: { ignoreAutoRefresh: true },
    fetchPolicy: "no-cache",
  });
}
