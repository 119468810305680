// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useTheme } from "@outschool/backpack";
import {
  useBooleanState,
  useIsMounted,
  usePrefersReducedMotion,
} from "@outschool/ui-utils";
import React from "react";
import ReactDomConfetti, { ConfettiConfig } from "react-dom-confetti";

export interface ConfettiProps {
  /** Triggers an explosion when the prop transitions from falsy to truthy. */
  active: boolean;
  /** An array of color values, defaults to some Outschool palette colors. */
  colors?: ConfettiConfig["colors"];
  /** Direction of the explosion in degrees, defaults to 90. */
  angle?: ConfettiConfig["angle"];
  /** Spread of the explosion in degrees, defaults to 45. */
  spread?: ConfettiConfig["spread"];
  /** Initial velocity of the particles, defaults to 45. */
  startVelocity?: ConfettiConfig["startVelocity"];
  /** Number of particle elements, defaults to 50. */
  elementCount?: ConfettiConfig["elementCount"];
  /** Decrease in velocity proportional to current velocity, default to 0.1. */
  dragFriction?: ConfettiConfig["dragFriction"];
  /** Delay for each fetti in milliseconds, defaults to 0. */
  stagger?: ConfettiConfig["stagger"];
}

/**
 * Celebrate good times! Add a bit of fun to any page. The confetti only
 * triggers if the state transitions `active` from `false` to `true`.
 *
 * See also the `useConfetti` hook.
 */
const Confetti: React.FC<ConfettiProps> = ({
  active,
  angle = 90,
  spread = 45,
  startVelocity = 45,
  elementCount = 50,
  dragFriction = 0.1,
  stagger = 0,
  colors,
  ...rest
}) => {
  const theme = useTheme();
  const themeColors = (
    ["primary", "secondary", "info", "success", "error", "warning"] as const
  ).map(color => theme.palette[color].main);

  colors = colors || themeColors;

  const config: ConfettiConfig = {
    angle,
    spread,
    startVelocity,
    elementCount,
    dragFriction,
    stagger,
    colors,
    ...rest,
  };

  const hasMounted = useIsMounted();
  const prefersReducedMotion = usePrefersReducedMotion();

  if (!hasMounted || prefersReducedMotion) {
    return null;
  }

  return <ReactDomConfetti active={active} config={config} />;
};

export const useConfetti = (config?: ConfettiConfig) => {
  const [active, setActive, clearActive] = useBooleanState();
  React.useEffect(() => {
    if (active) {
      clearActive();
    }
  }, [active, clearActive]);
  return {
    confettiCannon: <Confetti active={active} {...config} />,
    fireConfetti: setActive,
  };
};
