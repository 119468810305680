import React from "react";
// eslint-disable-next-line no-restricted-imports
import { useTranslation as useTranslationI18next } from "react-i18next";

import { BASE_LOCALE } from "../constants";
import { I18nType, TFunction } from "./types";

import type { UseTranslationOptions } from "react-i18next";
import type { TOptions, StringMap } from "i18next";

type UseIsTranslatingType = () => boolean;
type UseTranslationReturnType = {
  i18n: I18nType;
  ready: boolean;
  t: TFunction;
  0: TFunction;
};

/**
 * Factory for useTranslation hook. Different apps have different translation needs, so these must be changed per app.
 * @param useIsTranslating Hook which returns boolean to determine if useTranslation returns translation or not.
 * @returns useTranslation hook
 */
export const createUseTranslationHook = (
  useIsTranslating: UseIsTranslatingType
) =>
  function useTranslation(
    ns: string,
    options?: UseTranslationOptions<undefined | string>
  ): UseTranslationReturnType {
    // For adding a namespace and key prefix, our parser wants  the useTranslation call to look like:
    // `useTranslation("namespace\\key\\prefix\\etc")
    // Where the default behavior makes that all the namespace, so we parse that in a way that react-i18next expects,
    // useTranslation("namespace", {keyPrefix: "key\\prefix\\etc"})
    const [namespace, ...keyPrefixParts] = ns.split("\\");
    const keyPrefix = options?.keyPrefix ?? keyPrefixParts.join("\\");
    const i18nOptions = React.useMemo(
      () => ({
        keyPrefix,
        ...options,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const {
      t: originalT,
      i18n,
      ready,
    } = useTranslationI18next(namespace, i18nOptions);

    const isTranslating = useIsTranslating();

    /**
     * Generate a wrapped version of i18next's `t` with a post-translation
     * interpolation step, which allows us to fall back on English plaintext keys
     */
    const newT: TFunction = React.useCallback(
      (key: string | string[], options: TOptions<StringMap> = {}) => {
        const locale = isTranslating ? i18n.language : BASE_LOCALE;

        const translatedString = originalT(key, {
          ...options,
          lng: locale,
          // We should not override the namespace passed to useTranslation().
          // <Trans/> components pass { ... ns: i18n.defaultNS ...} to their
          // t() function, which we ignore here
          ns: namespace,
        });
        return i18n.exists(key, { lng: locale })
          ? // Key was found in translations. Use it.
            translatedString
          : // Key not found in translations. Use key as string but interpolate values.
            i18n.services.interpolator.interpolate(
              translatedString,
              options,
              locale,
              {}
            );
      },
      [originalT, i18n, isTranslating, namespace]
    );

    return { i18n, ready, t: newT, 0: newT };
  };
