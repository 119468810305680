/** @jsx jsxPragma */

import { SystemStyleObject } from "@styled-system/css";
import * as React from "react";

import jsxPragma from "./jsxPragma";

interface SpaceProps {
  x?: number | string | number[] | string[];
  y?: number | string | number[] | string[];
  sx?: SystemStyleObject;
}

/**
 * @deprecated Prefer using margin, padding, or gap for spacing needs.
 */
const Space: React.FC<SpaceProps> = ({ x, y, sx }) => {
  return (
    // @ts-ignore
    // eslint-disable-next-line
    <div role="presentation" sx={{ marginLeft: x, marginTop: y, ...sx }} />
  );
};

export default Space;
