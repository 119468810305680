import { errorMap } from "../lib/implementation";
import type { Integration } from "@sentry/types";

export type SentryIntegrationOpts = {
  logMessage: (message: string) => void;
  logError: (err: unknown) => void;
};

/**
 * Returns an object for Sentry.addIntegration to log errors and inject the error context into the event.
 */
// tested via platform-node
export function getSentryOsPlatformIntegration({
  logMessage,
  logError,
}: SentryIntegrationOpts): Integration {
  return {
    name: "@outschool/platform",
    setupOnce() {},
    preprocessEvent: (evt, hint) => {
      // check this first, originalException will match the message too
      if (evt.message) {
        logMessage(evt.message);
      } else if (evt.type === undefined && hint?.originalException) {
        logError(hint.originalException);
      }
    },
    processEvent: (evt, hint) => {
      const error = hint?.originalException;
      if (evt.type === undefined && error) {
        const ctx = errorMap.get(error);
        if (ctx) {
          // code is made verbose to reduce amount of garbage generated
          // and to skip unnecessary copies.
          // Note that we consider the error context weaker than any detail
          // explicitly passed to sentry.
          if (ctx.extra) {
            evt.extra = { ...ctx.extra, ...evt.extra };
          }
          if (ctx.tags) {
            evt.tags = { ...ctx.tags, ...evt.tags };
          }
          if (ctx.user) {
            evt.user = { ...ctx.user, ...evt.user };
          }
        }
      }
      return evt;
    },
  };
}
