import { isLocalStorageSupported } from "@outschool/local-storage";
import { getDeviceUid, getResolution } from "@outschool/ui-utils";

import pkg from "./pkg";
import { AnalyticsError } from "./util";

import type { EventProperties } from "../types";
import type { PageContext } from "../providers/PageContext";

type TrackHeaders = {
  Authorization?: string;
  "Content-Type": string;
};

function getCommonProperties(page: PageContext) {
  const { pageCategory, pageImpressionId, pageLoadId, pageName, pagePath } =
    page;

  return {
    ...getResolution(),
    app: pkg.app,
    deviceUid: getDeviceUid(),
    pageCategory,
    pageImpressionId,
    pageLoadId,
    pageName,
    pagePath,
  };
}

export default async function trackAnalyticsInitialized(
  page: PageContext,
  eventProperties: EventProperties
) {
  try {
    let host: string;
    if (pkg.isProduction) {
      host = "https://outschool.com";
    } else if (!!pkg.devMode && pkg.env === "development") {
      host = "http://localhost:3000";
    } else {
      return;
    }

    const localStorage = isLocalStorageSupported();
    const headers: TrackHeaders = {
      "Content-Type": "application/json",
    };

    const token = localStorage?.getItem("jwt");

    if (!!token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const properties = {
      ...eventProperties,
      ...getCommonProperties(page),
    };

    await fetch(`${host}/graphql/trackAnalyticsInitialized`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        operationName: "TrackAnalyticsInitialized",
        query: `
            mutation TrackAnalyticsInitialized(
              $properties: TrackAnalyticsInitializedProperties
            ) {
              trackAnalyticsInitialized(properties: $properties)
            }
          `,
        variables: {
          properties,
        },
        context: { doNotRetry: true },
      }),
    });
  } catch (error) {
    pkg.onError(new AnalyticsError("Failed to report init failure", error));
  }
}
