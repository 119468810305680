import { pxToRem } from "@outschool/ui-utils";

import { LegacyButtonThemeType, LegacyVariantType } from "./LegacyButton";

function getContainedSolidColorSx(
  normal: string,
  hover: string,
  active: string
) {
  return {
    borderColor: normal,
    backgroundColor: normal,
    "&:hover:not(:disabled), &:focus:not(:disabled)": {
      borderColor: hover,
      backgroundColor: hover,
      textDecoration: "none",
    },
    "&:active:not(:disabled)": {
      borderColor: active,
      backgroundColor: active,
    },
  };
}

const TRANSITION = `
  all ease-in-out 0.05s,
  outline 0s,
`;

const FOCUS_VISIBLE = {
  outlineWidth: "2px",
  outlineStyle: "solid",
  outlineColor: "#4B01D4", // peacock500
  outlineOffset: "2px",
};

const CONTAINED_VARIANT: LegacyVariantType = {
  // contained base
  baseStyle: {
    color: "white",
    fontWeight: 500,
    lineHeight: 1,
    borderWidth: "2px",
    borderRadius: "999px",
    borderStyle: "solid",
    borderColor: "transparent",
    transition: TRANSITION,
    "&:disabled": {
      backgroundColor: "gray100",
      color: "gray600",
      cursor: "not-allowed",
    },
    "&:focus-visible": FOCUS_VISIBLE,
    ".button-group &": {
      marginX: 0,
      "&:first-of-type": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRightWidth: 0,
      },
      "&:not(:first-of-type):not(:last-of-type)": {
        borderRightWidth: 0,
        borderRadius: 0,
      },
      "&:last-of-type": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
  },
  // contained colors
  colors: {
    primary: {
      backgroundColor: "peacock500",
      color: "peacock50",
      boxShadow: "0px 0px 2px #6161F2", // peacock300
      "&:hover:not(:disabled), &:focus:not(:disabled)": {
        backgroundColor: "peacock700",
        color: "peacock50",
        textDecoration: "none",
      },
      "&:active:not(:disabled)": {
        backgroundColor: "peacock900",
        color: "peacock50",
      },
    },
    secondary: {
      borderColor: "peacock200",
      borderStyle: "solid",
      backgroundColor: "white",
      color: "peacock700",
      "&:hover:not(:disabled), &:focus:not(:disabled)": {
        borderColor: "peacock700",
        backgroundColor: "peacock50",
        color: "peacock700",
        textDecoration: "none",
      },
      "&:active:not(:disabled)": {
        backgroundColor: "peacock100",
        color: "peacock700",
      },
      ":disabled": {
        borderColor: "transparent",
      },
    },
    get confirm() {
      return this.primary;
    },
    danger: getContainedSolidColorSx("danger", "#ed2e2e", "#eb1717"),
    facebook: getContainedSolidColorSx("facebook", "#3b5c9f", "#375694"),
    google: getContainedSolidColorSx("google", "#2a75f3", "#1b6cf2"),
    social: getContainedSolidColorSx(
      "rgba(204, 212, 255, 0.5)", // peacock100 @ 50%
      "peacock100",
      "rgba(97, 97, 242, 0.4)" // peacock300 @ 40%
    ),
    black: getContainedSolidColorSx("black", "black", "black"),
  },
  // contained sizes
  sizes: {
    tiny: {
      padding: 0,
    },
    small: {
      padding: pxToRem(8, 18),
      fontSize: pxToRem(14),
      round: {
        width: "2.7rem",
        height: "2.7rem",
      },
    },
    medium: {
      padding: pxToRem(12, 28),
      fontSize: pxToRem(16),
      round: {
        width: "3.65rem",
        height: "3.65rem",
      },
    },
    large: {
      padding: pxToRem(16, 36),
      fontSize: pxToRem(22),
      round: {
        width: "4.8rem",
        height: "4.8rem",
      },
    },
  },
  roundStyle: {
    borderRadius: "50%",
    padding: 0,
  },
};

const LINK_VARIANT: LegacyVariantType = {
  // link base
  baseStyle: {
    color: "peacock700",
    lineHeight: 1,
    fontWeight: 500,
    transition: TRANSITION,
    "&:hover:not(:disabled), &:focus:not(:disabled)": {
      color: "peacock700",
      textDecoration: "underline",
    },
    "&:active:not(:disabled)": {
      color: "peacock700",
    },
    "&:disabled": {
      color: "gray4",
      cursor: "default",
    },
    "&:focus-visible": FOCUS_VISIBLE,
  },
  // link colors
  colors: {
    primary: {},
    secondary: {},
    confirm: {},
    danger: {
      color: "danger",
      "&:hover:not(:disabled), &:focus:not(:disabled)": {
        color: "#ed2e2e",
      },
      "&:active:not(:disabled)": {
        color: "#eb1717",
      },
    },
    facebook: {},
    google: {},
    social: {},
    black: {},
  },
  // link sizes
  sizes: { tiny: {}, small: {}, medium: {}, large: {} },
};

const RAISED_VARIANT: LegacyVariantType = {
  ...CONTAINED_VARIANT,
};

const BUTTON_THEME: LegacyButtonThemeType = {
  baseStyle: {
    position: "relative",
    display: "inline-block",
    fontFamily: "default",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "1.6rem",
    textAlign: "center",
    textTransform: "none",
    height: "auto",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    cursor: "pointer",
    userSelect: "none",
    outline: "none",
    border: "none",
    background: "none",
    padding: 0,
    transition: "all 0.15s",
    lineHeight: 1.2,
    "&:hover:not(:disabled), &:focus:not(:disabled)": {
      textDecoration: "none",
    },
  },
  variants: {
    contained: CONTAINED_VARIANT,
    raised: RAISED_VARIANT,
    link: LINK_VARIANT,
  },
};

export default BUTTON_THEME;
