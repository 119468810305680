import { implementation } from "./lib/implementation";

/**
 * Registers the given function so that it runs when the platform is started and before any
 * actual work is started in a server or job. You may return a promise from this function (or
 * make it async) if you need to delay the server start (like for DB initialization)
 *
 * If the given function throws an error: the server or job will not run, the platform will
 * exit in error, and the error will be sent to sentry.
 *
 * You may safely add more init hooks within an init hook. If the platform is already
 * initialized, the init hooks will be run immediately.
 */
export function onPlatformInit(fn: () => any): void {
  implementation.addInitHook(fn);
}

/**
 * Registers the given function so that it runs when the platform is shutting down but before
 * sentry or open-telemetry are shut down. You may return a promise from this function (or
 * make it async) if you need flush data out or shut down something async (like the DB).
 *
 * If the given function throws an error, the error will be sent to sentry.
 *
 * You may safely add more exit hooks within an exit hook. The platform is considered exited
 * after these are complete, so if you somehow manage to add exit hooks after they have run,
 * they will _not_ be run.
 */
export function onPlatformExit(fn: () => any): void {
  implementation.addExitHook(fn);
}
