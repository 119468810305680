import {
  getLearnerHostForEnv,
  getWebsiteHostForEnv,
  makeLocalePrefix,
} from "./utils";

export interface RouteInitOptions {
  websiteHost?: string;
  learnerHost?: string;
  appHostName?: string;
  locale?: string;
}

let gWebsiteHost = getWebsiteHostForEnv(undefined);
let gLearnerHost = getLearnerHostForEnv(undefined);
let gInitialized = false;
let locale = "en";

export function initRoutes(options: RouteInitOptions = {}) {
  if (gInitialized && !OsPlatform.isTest) {
    throw new Error("@outschool/routes is already initialized");
  }

  const { appHostName } = options;
  gWebsiteHost = options.websiteHost ?? getWebsiteHostForEnv(appHostName);
  gLearnerHost = options.learnerHost ?? getLearnerHostForEnv(appHostName);

  if (options.locale) {
    locale = options.locale;
  }

  gInitialized = true;
}

export function websiteHost() {
  return gWebsiteHost;
}

export function learnerHost() {
  return gLearnerHost;
}

export function pathToUrl(path: string, localeOverride?: string) {
  return `${websiteHost()}${makeLocalePrefix(
    localeOverride ? localeOverride : locale
  )}${path}`;
}

export type PathFn = (...args: any[]) => string;
export function makeUrlFromPathFn<T extends PathFn>(
  fn: T,
  localeOverride?: string
): (...args: Parameters<T>) => string {
  return function (...args: Parameters<T>) {
    return pathToUrl(fn(...args), localeOverride);
  };
}

export function learnerPathToUrl(path: string) {
  return `${learnerHost()}${path}`;
}

export function makeUrlFromLearnerPathFn<T extends PathFn>(
  fn: T
): (...args: Parameters<T>) => string {
  return function (...args: Parameters<T>) {
    return learnerPathToUrl(fn(...args));
  };
}
