const newPalette = {
  // New branding color palette
  peacock900: "#23055B",
  peacock800: "#2C017D",
  peacock700: "#380596",
  peacock600: "#3F00B4",
  peacock500: "#4B01D4",
  peacock400: "#5431EA",
  peacock300: "#6161F2",
  peacock200: "#A0A9F9",
  peacock100: "#CCD4FF",
  peacock50: "#F0F3FF",

  redhot900: "#35130B",
  redhot800: "#622314",
  redhot700: "#A53C21",
  redhot600: "#D24C2A",
  redhot500: "#FF5C33",
  redhot400: "#FF8769",
  redhot300: "#FFB29F",
  redhot200: "#FFCFC3",
  redhot100: "#FFEBE7",
  redhot50: "#FFFAF8",

  waterslide900: "#052434",
  waterslide800: "#0A4360",
  waterslide700: "#1071A2",
  waterslide600: "#1590CE",
  waterslide500: "#19AFFA",
  waterslide400: "#56C4FB",
  waterslide300: "#92D9FD",
  waterslide200: "#BBE7FE",
  waterslide100: "#E3F5FE",
  waterslide50: "#F8FCFF",

  sunshine900: "#352905",
  sunshine800: "#624C0A",
  sunshine700: "#A58010",
  sunshine600: "#D2A315",
  sunshine500: "#FFC619",
  sunshine400: "#FFD556",
  sunshine300: "#FFE492",
  sunshine200: "#FFEEBB",
  sunshine100: "#FFF8E3",
  sunshine50: "#FFFDF8",

  bubblegum900: "#351B2A",
  bubblegum800: "#62314E",
  bubblegum700: "#A55383",
  bubblegum600: "#D269A6",
  bubblegum500: "#FF80CA",
  bubblegum400: "#FFA2D8",
  bubblegum300: "#FFC3E6",
  bubblegum200: "#FFD9EF",
  bubblegum100: "#FFF0F9",
  bubblegum50: "#FFFBFD",

  black: "#000000",
  white: "#ffffff",

  gray900: "#262626",
  gray800: "#262626",
  gray700: "#535353",
  gray600: "#696969",
  gray500: "#808080",
  gray400: "#A0A0A0",
  gray300: "#C2C2C2",
  gray200: "#DBDBDB",
  gray100: "#F0F0F0",
  gray50: "#F7F7F7",
};

// TODO: Better names (https://design.lyft.com/re-approaching-color-9e604ba22c88)
// Base colors palette
const baseColors = {
  white: "#ffffff",
  gray7: "#f8f8f9",
  gray6: "#f1f2f3",
  gray5: newPalette.gray100,
  gray4: newPalette.gray300,
  gray3: newPalette.gray500,
  gray2: newPalette.gray700,
  gray1: newPalette.gray900,

  blue7: "#ccd5ff",
  blue6: "#fcfdfd",
  blue5: newPalette.peacock100,
  blue4: newPalette.peacock300,
  blue3: newPalette.peacock500,
  blue2: newPalette.peacock700,
  blue1: newPalette.peacock900,

  yellow6: "#fde9ba",
  yellow5: "#fffaea",
  yellow4: "#fffcf4",
  yellow3: "#e7dcbf",
  yellow2: newPalette.sunshine500,
  yellow1: "#966e07",
  yellow0: "#585037",

  green5: "#f2faf7",
  green4: "#c0dfd3",
  green3: "#36855f",
  green2: "#29a877",
  green1: "#14855a",

  blueGray: "#5c6f8b",

  // when we add more red/green/etc look into renaming red2, red3
  "red-bg": "#c74c5a",
  "green-bg": "#36855f",
  "orange-bg": "rgba(232, 104, 38, 0.85)",
  "blue-bg": "#4267b2",

  green: "#29a877",
  red: newPalette.redhot500,
  yellow: newPalette.sunshine500,
  facebook: "#4267b2",
  google: "#4285f4",
  black: "#000000",
};

const colors = {
  ...newPalette,
  ...baseColors,

  confirm: baseColors.green2,
  cancel: baseColors.gray3,
  primary: baseColors.blue3,
  link: baseColors.blue3,
  headerBackground: baseColors.blue6,
  headerForeground: baseColors.blueGray, // TODO": Where does this fit in our color palette?..
  headerBorder: "#ecf1f8",
  danger: baseColors.red,
  warning: baseColors.yellow,
  text: baseColors.gray1,
  grayText: baseColors.gray3,
  disabledText: baseColors.gray4,
  disabledBackground: baseColors.gray7,
  border: baseColors.gray5,
  cardBorder: "rgba(37, 93, 173, 0.25)",
  classInfoIcon: "#a4bbc2", // TODO": Where does this fit in our color palette?..
};

export default colors;
