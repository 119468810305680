import { Box, Image } from "@outschool/backpack";
import React, { Ref, forwardRef } from "react";

import lineLoginButtonBase from "../../images/login/line_btn_base.png";
import { TrackedButton, TrackedButtonProps } from "./TrackedButton";

export type LineButtonProps = TrackedButtonProps;

const BASE_COLOR = "#06C755";
const HOVER_COLOR = "#05b34c";
const ACTIVE_COLOR = "#048b3b";

function LineButton(
  { sx, children, ...props }: LineButtonProps,
  ref: Ref<any>
) {
  return (
    <TrackedButton
      ref={ref}
      color="inherit"
      sx={[
        {
          color: "common.white",
          borderColor: BASE_COLOR,
          backgroundColor: BASE_COLOR,
          padding: 0,
          "&:hover:not(:disabled), &:focus:not(:disabled)": {
            color: "common.white",
            borderColor: HOVER_COLOR,
            backgroundColor: HOVER_COLOR,
            textDecoration: "none",
          },
          "&:active:not(:disabled)": {
            color: "common.white",
            borderColor: ACTIVE_COLOR,
            backgroundColor: ACTIVE_COLOR,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      <Box
        sx={{
          fontSize: "1.5em",
          padding: "10px",
          width: "44px",
        }}
      >
        <Image
          src={lineLoginButtonBase}
          sx={{
            height: "25px",
            width: "25px",
            display: "block",
          }}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          padding: "12.5px",
          minWidth: "215px",
        }}
      >
        {children}
      </Box>
    </TrackedButton>
  );
}

export default forwardRef<any, LineButtonProps>(LineButton);
