import { gql } from "@outschool/ui-apollo";

import { LoginErrorOrResultFragmentV2 } from "./LoginErrorOrResultFragment";

export const loginOrCreateAccountWithAppleMutationV2 = gql`
  mutation LoginOrCreateAccountWithAppleV2(
    $code: String!
    $name: String
    $createOptions: CreateAccountWithProviderInput
    $osRef: OsRefInput
  ) {
    loginOrCreateAccountWithAppleV2(
      code: $code
      name: $name
      canCreateAccount: true
      createOptions: $createOptions
      osRef: $osRef
    ) {
      ...LoginErrorOrResultFragmentV2
    }
  }
  ${LoginErrorOrResultFragmentV2}
`;
