import { gql } from "@outschool/ui-apollo";

export const LoginErrorOrResultFragment = gql`
  fragment LoginErrorOrResultFragment on LoginResultOrError {
    __typename
    ... on LoginError {
      error
    }
    ... on LoginResult {
      isNewUser
      authentication {
        sessionToken
      }
    }
  }
`;

export const LoginErrorOrResultFragmentV2 = gql`
  fragment LoginErrorOrResultFragmentV2 on LoginResultOrErrorV2 {
    __typename
    ... on LoginError {
      error
    }
    ... on LoginResultV2 {
      isNewUser
      authentication {
        sessionToken
        refreshToken
      }
    }
  }
`;
