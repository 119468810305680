import MuiBox, { BoxProps as MUIBoxProps } from "@mui/material/Box";
import React from "react";

export type BoxProps<D extends React.ElementType<any> = "div", P = {}> = {
  flex?: boolean;
} & MUIBoxProps<D, P>;

const Box = (
  { flex, ...props }: BoxProps<React.ElementType>,
  ref: React.Ref<any>
) => <MuiBox ref={ref} {...props} {...(flex ? { display: "flex" } : {})} />;

export default React.forwardRef(Box);
