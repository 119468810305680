import pkg from "../lib/pkg";
import { injectScript } from "../lib/util";
import {
  Integration,
  IntegrationCategory,
} from "../providers/AnalyticsContext";
import { AnalyticsPlugin, AnalyticsPluginType } from ".";

import type { Context } from "../types";

// https://kakaobusiness.gitbook.io/main/tool/pixel-sdk/install
type KakaoPixel = {
  purchase: (purchaseEvent: {
    total_quantity?: string;
    total_price?: string;
    currency?: string;
    products?: Array<{
      id: string;
      name: string;
      quantity: string;
      price: string;
    }>;
  }) => void;
};
declare global {
  interface Window {
    kakaoPixel?: (trackingId: string) => KakaoPixel;
  }
}

export default class KakaoPlugin implements AnalyticsPlugin {
  name = Integration.Kakao;
  category = IntegrationCategory.Advertising;
  type = AnalyticsPluginType.destination;
  version = "0.1.0";

  // https://business.kakao.com/pixel/
  scriptId = "os-kakao";
  scriptSrc = "https://t1.daumcdn.net/kas/static/kp.js";
  key = "2217655750167382385";
  kp: KakaoPixel | null = null;

  constructor() {}

  isLoadable() {
    return pkg.isProduction;
  }

  async load(): Promise<void> {
    await injectScript(this.scriptId, this.scriptSrc);
    if (window?.kakaoPixel) {
      this.kp = window.kakaoPixel(this.key);
    }
  }

  async unload(): Promise<void> {
    const script = document.getElementById(this.scriptId);
    script?.remove();
    window.kakaoPixel = undefined;
  }

  isLoaded() {
    if (typeof window === "undefined") {
      return false;
    }

    return !!window?.kakaoPixel;
  }

  async track(context: Context) {
    if (this.kp && context.event.event === "Order Completed") {
      const total = context.event?.properties?.total;
      this.kp.purchase({
        total_price: total,
        currency: "USD",
      });
    }
    return context;
  }
}
