import { I18nLocale } from "@outschool/localization";
import { usePageContext } from "@outschool/ui-analytics";
import { useTokenContext } from "@outschool/ui-auth";
import React from "react";

import { createApolloClient } from "./apollo-client/create-apollo-client";
import { RateLimitConfig } from "./apollo-client/create-rate-limit-link";

interface UseGraphqlClientArgs {
  initialClientVersion: number;
  expectedClientVersionRef: React.MutableRefObject<number | undefined>;
  userSelectedLocale?: I18nLocale;
  rateLimits?: RateLimitConfig;
  typeDefs?: any;
  logMissingRefreshToken?: boolean;
}

/**
 * Naming is hard - this returns an ApolloClient
 */
export function useGraphqlClient({
  initialClientVersion,
  expectedClientVersionRef,
  userSelectedLocale,
  rateLimits,
  typeDefs,
  logMissingRefreshToken,
}: UseGraphqlClientArgs) {
  const pageContext = usePageContext();
  const pageContextRef = React.useRef(pageContext);
  pageContextRef.current = pageContext;

  const { getTokens, setTokens, logout } = useTokenContext();

  // Wrap a ref around logout so that we don't have to use it as a dependency in logout.
  // logout, for now, relies on onLogout, which relies on useTrackEvent,
  //  which gets reloaded whenever analytics loads. This will blow away the apollo client
  // because logout is a dependency below.
  const logoutRef =
    React.useRef<(additionalContext?: object | undefined) => void>(logout);
  logoutRef.current = logout;

  return React.useMemo(
    () =>
      createApolloClient({
        name: "outschool-web-app",
        tokenRefreshControl: {
          getTokens,
          setTokens,
          onTokensExpired: context => {
            logoutRef.current(context);
          },
          shouldLogMissingRefreshToken: Boolean(logMissingRefreshToken),
        },
        updateExpectedClientVersion: version => {
          expectedClientVersionRef.current = version;
        },
        typeDefs,
        clientVersion: initialClientVersion,
        locale: userSelectedLocale,
        rateLimits,
        pageContextRef,
      }),
    // expectedClientVersionRef and logoutRef are refs so it should not be a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getTokens,
      setTokens,
      userSelectedLocale,
      rateLimits,
      initialClientVersion,
      typeDefs,
    ]
  );
}
