import { I18nLocale } from "@outschool/localization";

import { addParamsToUrl, makeLocalePrefix } from "./utils";

/*
 * This file defines path functions that are used in more than one app or
 * ...Routes class.
 */

export const CLUB_PATH_NAME = "group";
export const CLUB_POST_PATH_NAME = "posts";
export const CLUB_POST_COMMENT_UID_PARAM_NAME = "commentUid";

export interface ClubPathEnrollmentParam {
  enrollmentUid?: string;
  locale?: string;
}

export function clubPath(
  sectionUid: string,
  {
    enrollmentUid = undefined,
    locale = undefined,
  }: ClubPathEnrollmentParam = {}
) {
  return (
    makeLocalePrefix(locale) +
    addParamsToUrl(`/${CLUB_PATH_NAME}/${sectionUid}`, { enrollmentUid })
  );
}

export function clubPostPath({
  sectionUid,
  classPostUid,
  enrollmentUid,
}: {
  sectionUid: string;
  classPostUid: string;
  enrollmentUid?: string;
}) {
  return addParamsToUrl(
    `/${CLUB_PATH_NAME}/${sectionUid}/${CLUB_POST_PATH_NAME}/${classPostUid}`,
    {
      enrollmentUid,
    }
  );
}

export function clubPostCommentPath({
  sectionUid,
  classPostUid,
  classPostCommentUid,
  enrollmentUid,
}: {
  sectionUid: string;
  classPostUid: string;
  classPostCommentUid: string;
  enrollmentUid?: string;
}) {
  return addParamsToUrl(
    `/${CLUB_PATH_NAME}/${sectionUid}/${CLUB_POST_PATH_NAME}/${classPostUid}/comments/${classPostCommentUid}`,
    {
      enrollmentUid: enrollmentUid ?? null,
    }
  );
}

export function recordingPath(
  sectionUid: string,
  recordingId: string,
  locale?: string
) {
  return (
    makeLocalePrefix(locale) +
    `/classroom/${sectionUid}/recording/${recordingId}`
  );
}

export function googleAuthRedirectPath() {
  return "/oauth2/google/callback";
}

export function facebookAuthRedirectPath() {
  return "/oauth2/facebook/callback";
}

export function appleAuthRedirectPath() {
  return "/users/login";
}

export function oidcAuthLoginRedirectPath(provider: string, osRef: any) {
  const path = `/oauth2/${provider}/callback`;
  if (!osRef) {
    return path;
  }
  return addParamsToUrl(path, {
    state: JSON.stringify({ osRef }),
  });
}

export function oidcAuthLoginRedirectToProviderPath(
  provider: string,
  state: { osRef: object; locale?: I18nLocale }
) {
  return addParamsToUrl(`/oauth2/${provider}/redirect-to-provider`, {
    state: JSON.stringify(state),
  });
}
