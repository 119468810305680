import {
  DAY_END_HOUR,
  DAY_START_HOUR,
  briefDurationHoursString,
  briefDurationStringWithoutTimes,
  dayjs,
  formatDayWithFullMonth,
  shortWeekdayRange,
  startDayShortString,
  timeOfDayToStartEndTimes,
} from "@outschool/time";
import ldFilter from "lodash/filter";
import ldHead from "lodash/head";
import ldIsEmpty from "lodash/isEmpty";
import ldIsEqual from "lodash/isEqual";
import ldIsNumber from "lodash/isNumber";
import ldLast from "lodash/last";
import ldLowerFirst from "lodash/lowerFirst";
import ldOmit from "lodash/omit";
import ldSortBy from "lodash/sortBy";
import ldUpperFirst from "lodash/upperFirst";

import { joinCommasAnd, plural } from "../Text";
import {
  RelativeDateFilter,
  WEEKDAYS,
  WEEKEND_DAYS,
  dateFilterToDayjs,
  displayTimeFromCardinalHour,
  dowAreConsecutive,
  dowIndex,
  dowStringToArray,
  sortDow,
} from "./DateOrTime";
import { sortByGradeLevel } from "./GradeLevel";
import { catchallFor } from "./QueryParser";

const BRIEF_FORMAT_NAMES = {
  "Live online": "live",
  "Flex online": "flexible schedule",
  "Flexible schedule": "flexible schedule",
  "Self-Paced": "Self-Paced",
};

const BRIEF_DELIVERY_NAMES = {
  "Live online": "live",
  "Flex online": "flexible schedule",
  "Flexible schedule": "flexible schedule",
  "Semester course": "semester",
  "Short course": "short",
  "One-time class": "one-time",
  "Ongoing class": "ongoing",
  "One-on-one class": "one-on-one",
  Camp: "camp",
  Group: "group",
};

function startEndTimeSummary(
  startAfterTime?: number,
  endByTime?: number
): string {
  if (startAfterTime === DAY_START_HOUR) {
    startAfterTime = undefined;
  }
  if (endByTime === DAY_END_HOUR) {
    endByTime = undefined;
  }
  if (startAfterTime && endByTime) {
    return `between ${displayTimeFromCardinalHour(
      startAfterTime
    )} and ${displayTimeFromCardinalHour(endByTime)}`;
  } else if (startAfterTime && !endByTime) {
    return `after ${displayTimeFromCardinalHour(startAfterTime)}`;
  } else if (endByTime && !startAfterTime) {
    return `before ${displayTimeFromCardinalHour(endByTime)}`;
  } else {
    return "";
  }
}

/**
 * @deprecated use: const { dowSummary } = useSearchFiltersString()
 */
export function dowSummary(dowString?: string | null, shortDowSummary = true) {
  if (!dowString) {
    return null;
  }
  const daysOfWeek = sortDow(dowStringToArray(dowString));
  const daysOfWeekLabels = daysOfWeek.map(formatDayOfWeek);
  if (daysOfWeek.length === 1) {
    return daysOfWeekLabels[0];
  } else if (ldIsEqual([...daysOfWeek].sort(), [...WEEKDAYS].sort())) {
    return "weekdays";
  } else if (ldIsEqual([...daysOfWeek].sort(), [...WEEKEND_DAYS].sort())) {
    return "weekends";
  } else if (dowAreConsecutive(daysOfWeek)) {
    const lastDay = ldLast(daysOfWeek);
    return shortWeekdayRange(
      dowIndex(daysOfWeek[0]),
      lastDay ? dowIndex(lastDay) : -1
    );
  } else {
    if (!shortDowSummary) {
      return daysOfWeekLabels.join(", ");
    }
    return "some days";
  }
}

export const formatDayOfWeek = (dow: string) =>
  dayjs().day(dowIndex(dow)).format("ddd");
export const formatDayOfWeekShort = (dow: string) =>
  dayjs().day(dowIndex(dow)).format("dd");

export function gradeLevelArrayToString(gradeLevels: string[]) {
  return sortByGradeLevel(gradeLevels).join(", ");
}

export function agesArrayToString(ages: string[]) {
  return ldSortBy(ages.map(a => parseInt(a)))
    .reduce<number[][]>((ageRanges, age) => {
      const ageRange = ldLast(ageRanges);
      const lastAgeInRange = ageRange && ldLast(ageRange);
      if (ldIsNumber(lastAgeInRange) && age - lastAgeInRange <= 1) {
        ageRanges[ageRanges.length - 1] = [ldHead(ageRange)!, age];
        return ageRanges;
      } else {
        return [...ageRanges, [age]];
      }
    }, [])
    .map(ageRange => (ageRange.length === 1 ? ageRange : ageRange.join("-")))
    .join(", ");
}

function dayRangeToString(
  startAfter?: string | null | undefined,
  endBy?: string | null | undefined
): string | undefined {
  const startAfterDayjs = dateFilterToDayjs(startAfter);
  const endByDayjs = dateFilterToDayjs(endBy);

  const startAfterDateString = startDayShortString(startAfter);
  const endByDateString = startDayShortString(endBy);

  if (startAfterDayjs && !endByDayjs) {
    return `from ${startAfterDateString}`;
  } else if (!startAfterDayjs && endByDayjs) {
    return `until ${endByDateString}`;
  } else if (startAfterDayjs && endByDayjs) {
    if (startAfterDayjs.isSame(endByDayjs, "day")) {
      return startAfterDateString;
    } else {
      return briefDurationStringWithoutTimes(startAfter, endBy);
    }
  } else {
    return undefined;
  }
}

export function timeRangeToString(
  startAfterTime?: number | null,
  endByTime?: number | null
) {
  if (!startAfterTime && !endByTime) {
    return null;
  }

  const startTime = startAfterTime || DAY_START_HOUR;
  const endTime = endByTime || DAY_END_HOUR;

  return briefDurationHoursString(startTime, endTime);
}

export interface ScheduleFiltersToStringParams {
  dow?: string | null;
  startAfter?: string | null;
  startBefore?: string | null;
  endBy?: string | null;
  startAfterTime?: number | null;
  endByTime?: number | null;
  shortDowSummary?: boolean;
}

export function scheduleFiltersToString({
  dow,
  startAfter,
  startBefore,
  endBy,
  startAfterTime,
  endByTime,
  shortDowSummary = true,
}: ScheduleFiltersToStringParams = {}): string {
  const dowString = dowSummary(dow, shortDowSummary) || "";
  const dayRangeString =
    dayRangeToString(startAfter, endBy || startBefore) || "";
  const timeRangeString = timeRangeToString(startAfterTime, endByTime) || "";

  return ldUpperFirst(
    ldFilter([
      `${dowString}${dowString && dayRangeString ? ", " : ""}${dayRangeString}`,
      timeRangeString,
    ]).join(" ")
  );
}

/**
 * @deprecated use: const translateRelativeDateFilter = useTranslateRelativeDateFilter()
 */
export function relativeDateFilterToString(
  relativeDateFilter: RelativeDateFilter
) {
  return relativeDateFilter === RelativeDateFilter.ThisWeek
    ? "Starting this week or later"
    : relativeDateFilter === RelativeDateFilter.NextWeek
    ? "Starting next week or later"
    : relativeDateFilter === RelativeDateFilter.NextMonth
    ? "Starting next month or later"
    : relativeDateFilter === RelativeDateFilter.Today
    ? "Today"
    : relativeDateFilter === RelativeDateFilter.SevenDays
    ? "Next 7 days"
    : relativeDateFilter === RelativeDateFilter.FourteenDays
    ? "Next 14 days"
    : relativeDateFilter === RelativeDateFilter.AllUpcoming
    ? "All upcoming dates"
    : "";
}

export function summarySentence(
  filters: Record<string, any>,
  includeOrder = false
): string {
  const orderString = (() => {
    switch (filters.order) {
      case "new":
        return "New classes first.";
      case "upcoming":
        return "Upcoming classes first.";
      case "popular":
      default:
        return "Popular classes first.";
    }
  })();

  if (ldIsEmpty(ldOmit(filters, "order"))) {
    return orderString;
  }

  const educator = filters.userName ? `taught by ${filters.userName}` : "";

  const formatList = filters.delivery?.split(",");
  const format =
    formatList?.length === 1
      ? BRIEF_FORMAT_NAMES[formatList[0] as keyof typeof BRIEF_FORMAT_NAMES]
      : "";
  const deliveryList = filters.delivery?.split(",");
  const delivery = joinCommasAnd(
    deliveryList?.map(
      (d: string) =>
        BRIEF_DELIVERY_NAMES[d as keyof typeof BRIEF_DELIVERY_NAMES]
    )
  );

  const theme =
    filters.theme && filters.theme !== catchallFor("theme")
      ? "about " + joinCommasAnd(` ${filters.theme}`.split(","))?.toLowerCase()
      : null;
  if (filters.timeOfDay) {
    const { startAfterTime, endByTime } = timeOfDayToStartEndTimes(
      filters.timeOfDay
    );
    filters.startAfterTime = startAfterTime;
    filters.endByTime = endByTime;
  }
  const startEndTime = startEndTimeSummary(
    filters.startAfterTime,
    filters.endByTime
  );
  const ages =
    filters.age && filters.age !== catchallFor("age") && filters.age.split(",");
  const age = ages
    ? ` for ${plural("age", ages.length)} ${agesArrayToString(ages)}`
    : null;

  const gradeLevels = filters.gradeLevel && filters.gradeLevel.split(",");
  const gradeLevel = gradeLevels
    ? ` in ${gradeLevelArrayToString(gradeLevels)}`
    : null;

  const relativeDateFilter = ldLowerFirst(
    relativeDateFilterToString(filters.startAfter)
  );
  const startAfterDayjs = dateFilterToDayjs(filters.startAfter);
  const endByDayjs = dateFilterToDayjs(filters.endBy);
  const dow = dowSummary(filters.dow);
  const query = filters.q ? ` containing "${filters.q}"` : null;

  const startAfterDateString = formatDayWithFullMonth(startAfterDayjs);
  const endByDateString = formatDayWithFullMonth(endByDayjs);

  let classSize = "";
  const { capacityMax, capacityMin } = filters;
  if (capacityMin && capacityMax) {
    if (capacityMin <= capacityMax) {
      classSize = `for ${capacityMin}-${capacityMax} learners`;
    }
  } else if (capacityMin) {
    classSize = `for ${capacityMin}+ learners`;
  } else if (capacityMax === 1) {
    classSize = "for 1 learner";
  } else if (capacityMax) {
    classSize = `for up to ${capacityMax} learners`;
  }

  const filterString =
    [
      delivery,
      format,
      "classes",
      educator,
      theme,
      startEndTime,
      age,
      gradeLevel,
      relativeDateFilter,
      startAfterDayjs
        ? ` ${endByDayjs ? "from" : "after"} ${startAfterDateString}`
        : "",
      endByDayjs ? ` through ${endByDateString}` : "",
      dow ? " on " + dow : null,
      classSize,
      query,
    ]
      .filter(n => Boolean(n))
      .join(" ")
      .trim() + ".";

  return (
    filterString.charAt(0).toUpperCase() +
    filterString.slice(1) +
    (includeOrder ? " " + orderString : "")
  );
}
