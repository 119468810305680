import { Components, Theme, TypographyStyle } from "@mui/material";
import { CSSProperties } from "react";

// Add additional Typography variants
declare module "@mui/material/styles" {
  interface TypographyVariants {
    display1?: TypographyStyle;
    display2?: TypographyStyle;
    display3?: TypographyStyle;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    display1?: TypographyStyle;
    display2?: TypographyStyle;
    display3?: TypographyStyle;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    display1: true;
    display2: true;
    display3: true;
  }
}

// Add emphasized to Typography theme options
declare module "@mui/material/styles/createTypography" {
  interface AdditionalFontStyleOptions {
    emphasized?: TypographyStyleOptions;
  }
  interface FontStyleOptions extends AdditionalFontStyleOptions {}
}

const TYPOGRAPHY_THEME: Components<Theme>["MuiTypography"] = {
  defaultProps: {
    // Default body* to span instead of p
    variantMapping: {
      display1: "h1",
      display2: "h1",
      display3: "h1",
      h1: "h1",
      h2: "h2",
      h3: "h3",
      h4: "h4",
      h5: "h5",
      h6: "h6",
      subtitle1: "h6",
      subtitle2: "h6",
      body1: "span",
      body2: "span",
      inherit: "span",
    },
  },
};

export default TYPOGRAPHY_THEME;

const DISPLAY_FONT_FAMILY = ["Ginto Nord", "sans-serif"].join(",");

/**
 * Modifies the given Theme object to apply all custom Typography sizes.
 * Uses a function instead of a const object so that we can reference the Theme helpers.
 * @param theme Theme
 * @returns Theme
 */
export const applyAdditionalTypographyTheme = (theme: Theme): Theme => {
  const headerBaseStyle: CSSProperties = {
    margin: 0,
    fontFamily: theme.typography.fontFamily,
  };

  const emphasizedStyle: CSSProperties = {
    fontWeight: theme.typography.fontWeightBold,
  };

  theme.typography.h1 = {
    ...headerBaseStyle,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: "1.15",
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: theme.typography.pxToRem(-0.2),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(40),
      letterSpacing: theme.typography.pxToRem(-0.4),
    },
  };

  theme.typography.h2 = {
    ...headerBaseStyle,
    fontSize: theme.typography.pxToRem(28),
    lineHeight: "1.2",
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(36),
      letterSpacing: theme.typography.pxToRem(-0.4),
    },
  };

  theme.typography.h3 = {
    ...headerBaseStyle,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: "1.2",
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(32),
      letterSpacing: theme.typography.pxToRem(-0.2),
    },
  };

  theme.typography.h4 = {
    ...headerBaseStyle,
    fontSize: theme.typography.pxToRem(22),
    lineHeight: "1.25",
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(28),
    },
  };

  theme.typography.h5 = {
    ...headerBaseStyle,
    fontSize: theme.typography.pxToRem(22),
    lineHeight: "1.25",
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0,
  };

  theme.typography.h6 = {
    ...headerBaseStyle,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: "1.25",
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0,
  };

  theme.typography.display1 = {
    ...headerBaseStyle,
    ...emphasizedStyle,
    fontFamily: DISPLAY_FONT_FAMILY,
    fontSize: theme.typography.pxToRem(40),
    lineHeight: theme.typography.pxToRem(48),
    letterSpacing: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(56),
      lineHeight: theme.typography.pxToRem(64),
      letterSpacing: theme.typography.pxToRem(-0.3),
    },
  };

  theme.typography.display2 = {
    ...headerBaseStyle,
    ...emphasizedStyle,
    fontFamily: DISPLAY_FONT_FAMILY,
    fontSize: theme.typography.pxToRem(28),
    lineHeight: theme.typography.pxToRem(32),
    letterSpacing: theme.typography.pxToRem(0.2),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(36),
      lineHeight: theme.typography.pxToRem(44),
      letterSpacing: 0,
    },
  };

  theme.typography.display3 = {
    ...headerBaseStyle,
    ...emphasizedStyle,
    fontFamily: DISPLAY_FONT_FAMILY,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: theme.typography.pxToRem(0.2),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(32),
    },
  };

  theme.typography.subtitle1 = {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "1.3",
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: theme.typography.pxToRem(0.1),
  };

  theme.typography.subtitle2 = {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "1.3",
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: theme.typography.pxToRem(0.1),
  };

  theme.typography.body1 = {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "1.5",
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 0,
  };

  theme.typography.body2 = {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 0,
    [theme.breakpoints.up("md")]: {
      lineHeight: theme.typography.pxToRem(21),
    },
  };

  theme.typography.caption = {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: theme.typography.pxToRem(0.2),
  };

  theme.typography.overline = {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: theme.typography.pxToRem(1),
  };

  theme.typography.emphasized = emphasizedStyle;

  return theme;
};
