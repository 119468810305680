import { ApolloLink } from "@outschool/ui-apollo";

export function createUpdateClientVersionLink(
  onUpdate: (newVersion: number) => void
) {
  return new ApolloLink((op, forward) => {
    return forward(op).map(data => {
      const response = op.getContext().response;
      const expHeader = response?.headers?.get("x-expected-client-version");
      if (expHeader) {
        const expVersion = Number.parseInt(expHeader, 10);
        if (Number.isFinite(expVersion)) {
          onUpdate(expVersion);
        }
      }
      return data;
    });
  });
}
