import { getAnonymousId, getResolution } from "@outschool/ui-utils";
import {
  AnalyticsPluginRestProxyTrackArgs,
  EXPERIMENT_TRIGGERS_INTAKE_TOPIC,
  EXPERIMENT_TRIGGERS_INTAKE_TOPIC_SCHEMA_ID,
  RestProxyBrowserClient,
} from "@outschool/kafka-rest-proxy-client";

import pkg from "./pkg";
import * as uuid from "uuid";

import type {
  SendEventOptions,
  SendEventResult,
} from "@outschool/kafka-rest-proxy-client";

export enum EVENT_SOURCE {
  BROWSER = "browser",
  CLOUDFLARE_WORKER = "cloudflare-worker",
  SERVER = "server",
  SSR = "ssr",
}

let kafkaRestProxyClient: RestProxyBrowserClient | undefined;

function getKafkaRestProxyClient() {
  if (!kafkaRestProxyClient) {
    kafkaRestProxyClient = new RestProxyBrowserClient({
      endpoint: pkg.KAFKA_REST_PROXY,
    });
  }

  return kafkaRestProxyClient;
}

function getBrowserEventTrackArgs(
  payload: any
): AnalyticsPluginRestProxyTrackArgs {
  const resolution = getResolution();

  return {
    event: {
      id: uuid.v4(),
      anonymous_id: getAnonymousId(),
      client_version: pkg.CLIENT_VERSION,
      git_commit_id: pkg.GIT_COMMIT_SHA,
      original_timestamp: new Date().toISOString(),
      resolution_height: resolution.height,
      resolution_is_mobile: resolution.isMobile,
      resolution_width: resolution.width,
      ...payload,
    },
    topic: EXPERIMENT_TRIGGERS_INTAKE_TOPIC,
    topic_schema_id: EXPERIMENT_TRIGGERS_INTAKE_TOPIC_SCHEMA_ID,
  };
}

function getEventTrackArgs(payload: any): AnalyticsPluginRestProxyTrackArgs {
  return {
    event: {
      id: uuid.v4(),
      git_commit_id: pkg.GIT_COMMIT_SHA,
      original_timestamp: new Date().toISOString(),
      ...payload,
    },
    topic: EXPERIMENT_TRIGGERS_INTAKE_TOPIC,
    topic_schema_id: EXPERIMENT_TRIGGERS_INTAKE_TOPIC_SCHEMA_ID,
  };
}

export async function sendExperimentTriggerThroughKafkaRestProxy(
  payload: any,
  eventSource: EVENT_SOURCE
): Promise<SendEventResult> {
  if (!pkg.initialized) {
    throw new Error(
      "Experiments-Shared Package needs to be initialized first in order to utilize this functionality."
    );
  }

  const kafkaRPC = getKafkaRestProxyClient();

  let event: AnalyticsPluginRestProxyTrackArgs;
  if (
    eventSource === EVENT_SOURCE.SERVER ||
    eventSource === EVENT_SOURCE.CLOUDFLARE_WORKER
  ) {
    event = getEventTrackArgs({
      event_source: eventSource,
      ...payload,
    });
  } else {
    event = getBrowserEventTrackArgs({
      event_source: eventSource,
      ...payload,
    });
  }

  const options: SendEventOptions = {};
  if (eventSource === EVENT_SOURCE.CLOUDFLARE_WORKER) {
    options.skipCredentials = true;
  }

  return await kafkaRPC.sendEvent(event, options);
}
