import "intersection-observer";

import React from "react";

import { useIsBot } from "./IsBotContext";

// From https://usehooks.com/useOnScreen/
export default function useOnScreen(
  node?: Element | null,
  initialValue = false,
  rootMargin = "0px"
) {
  const [isIntersecting, setIntersecting] = React.useState(initialValue);
  const isPrerenderRequest = useIsBot();

  React.useEffect(() => {
    if (!node) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );

    observer.observe(node);
    return () => {
      observer.unobserve(node);
    };
  }, [node, rootMargin]);

  return isPrerenderRequest || isIntersecting;
}
