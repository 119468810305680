import { Palette } from "@mui/material";
import { Theme } from "@mui/system";
import { PaletteColorOptionKey, Variant } from "./types";

const createContainedVariant = (colorName: keyof Palette): Variant => ({
  props: { variant: "contained", color: colorName },
  style: ({ theme }: { theme: Theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette[colorName][600 as PaletteColorOptionKey],
    boxShadow: `0px 0px 2px ${
      theme.palette[colorName][600 as PaletteColorOptionKey]
    }`,
    "&:hover:not(:disabled), &:focus:not(:disabled)": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette[colorName][800 as PaletteColorOptionKey],
    },
    "&:active:not(:disabled)": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette[colorName][900 as PaletteColorOptionKey],
    },
  }),
});

const createOutlinedVariant = (colorName: keyof Palette): Variant => ({
  props: { variant: "outlined", color: colorName },
  style: ({ theme }: { theme: Theme }) => ({
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette[colorName][300 as PaletteColorOptionKey],
    color: theme.palette[colorName][700 as PaletteColorOptionKey],
    "&:hover:not(:disabled), &:focus:not(:disabled)": {
      backgroundColor: theme.palette[colorName][100 as PaletteColorOptionKey],
      borderColor: theme.palette[colorName][700 as PaletteColorOptionKey],
    },
    "&:active:not(:disabled)": {
      backgroundColor: theme.palette[colorName][200 as PaletteColorOptionKey],
    },
  }),
});

export const createTextVariant = (colorName: keyof Palette): Variant => ({
  props: { variant: "text", color: colorName },
  style: ({ theme }: { theme: Theme }) => ({
    "&:hover:not(:disabled), &:focus:not(:disabled)": {
      backgroundColor: theme.palette[colorName][50 as PaletteColorOptionKey],
    },
    "&:active:not(:disabled)": {
      backgroundColor: theme.palette[colorName][100 as PaletteColorOptionKey],
    },
  }),
});

export const SHARED_BUTTON_VARIANTS: Variant[] = [
  {
    // Applies to all button variants / props
    props: {},
    style: ({ theme }: { theme: Theme }) => ({
      transition: ["all ease-in-out 0.05s", "outline 0s"].join(", "),
      // All buttons have borders for sake of consistent heights
      borderWidth: "2px",
      borderColor: "transparent",
      borderStyle: "solid",
      lineHeight: 1,
      outlineColor: "transparent",
      whiteSpace: "nowrap",
      minWidth: "auto",
      // focus state outline
      outlineWidth: "2px",
      outlineStyle: "solid",
      outlineOffset: "2px",
      ":focus-visible": {
        outlineColor: theme.palette.primary[700],
        textDecoration: "none",
      },
      "&:hover:not(:disabled), &:focus:not(:disabled), &:active:not(:disabled)":
        {
          // make sure border width is consistent between states
          borderWidth: "2px",
        },
    }),
  },
  /**
   * variant "contained"
   */
  {
    props: { variant: "contained" },
    style: {
      borderColor: "transparent",
    },
  },
  {
    props: { variant: "contained", color: "primary" },
    style: ({ theme }: { theme: Theme }) => ({
      ...createContainedVariant("primary").style({ theme }),
      backgroundColor: theme.palette.primary[700],
    }),
  },
  createContainedVariant("secondary"),
  createContainedVariant("success"),
  createContainedVariant("error"),
  createContainedVariant("info"),
  createContainedVariant("warning"),
  createContainedVariant("neutral"),
  /**
   * variant "outlined" (default variant)
   */
  {
    props: { variant: "outlined" },
    style: ({ theme }: { theme: Theme }) => ({
      backgroundColor: theme.palette.common.white,
      ":disabled": {
        borderColor: "transparent",
      },
    }),
  },
  createOutlinedVariant("primary"),
  createOutlinedVariant("secondary"),
  createOutlinedVariant("success"),
  createOutlinedVariant("error"),
  createOutlinedVariant("info"),
  createOutlinedVariant("warning"),
  createOutlinedVariant("neutral"),
  /**
   * variant "text"
   */
  {
    props: { variant: "text" },
    style: {
      borderColor: "transparent",
    },
  },
  {
    props: { variant: "text", color: "primary" },
    style: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.primary[700],
      ...createTextVariant("primary").style({ theme }),
    }),
  },
  createTextVariant("secondary"),
  createTextVariant("success"),
  createTextVariant("error"),
  createTextVariant("info"),
  createTextVariant("warning"),
  createTextVariant("neutral"),
];
