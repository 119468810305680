import { CookieKeys } from "@outschool/data-schemas";
import Cookies from "js-cookie";

export default function useCanShowGoogleAuth() {
  const browserName = Cookies.get(CookieKeys.OsBrowserName);

  if (!browserName) {
    return true;
  }

  return ![
    "facebook",
    "instagram",
    "chrome webview",
    "webkit",
    "silk",
    "electron",
    "qqbrowser",
  ].includes(browserName.toLowerCase());
}
