/**
 * Caches the invocation of the given function for the given number of millis.
 */
export function cacheFn<T>(action: () => T, ttl: number): () => T {
  let cache: { value: T } | null = null;
  const clearCache = () => (cache = null);

  return () => {
    if (cache) {
      return cache.value;
    }

    const result = action();
    let timer = setTimeout(clearCache, ttl);
    if (typeof timer === "object" && "unref" in timer) {
      // ensure that the timer doesn't keep backends or tests open.
      // No such function is in the frontend.
      timer.unref();
    }

    cache = { value: result };
    return result;
  };
}

/**
 * Limits the invocations of an async function to only one at a time.
 */
export function asyncOneAtATime<T, F extends (...args: any) => Promise<T>>(
  action: F
): F {
  let inProgress: Promise<T> | null = null;
  return ((...args) => {
    if (!inProgress) {
      inProgress = action(...args).finally(() => (inProgress = null));
    }
    return inProgress;
  }) as F;
}
