import { TeacherCredentialsRow } from "@outschool/db-queries";
import {
  CredentialType,
  DegreeSubject,
  DegreeType,
  TeacherCertificateDiscipline,
  TeacherCertificateState,
  TeacherCredential,
} from "@outschool/gql-backend-generated";

export const COLLEGE_DEGREE_TYPE_TO_STRING: Record<DegreeType, string> = {
  [DegreeType.AssociatesDegree]: "Associate's Degree",
  [DegreeType.BachelorsDegree]: "Bachelor's Degree",
  [DegreeType.MastersDegree]: "Master's Degree",
  [DegreeType.DoctoralDegree]: "Doctoral Degree",
  [DegreeType.DoctorOfMedicine]: "Doctor of Medicine",
  [DegreeType.JurisDoctor]: "Juris Doctor",
};

const DEGREE_DISPLAY_ORDER = {
  [DegreeType.DoctorOfMedicine]: 1,
  [DegreeType.JurisDoctor]: 2,
  [DegreeType.DoctoralDegree]: 3,
  [DegreeType.MastersDegree]: 4,
  [DegreeType.BachelorsDegree]: 5,
  [DegreeType.AssociatesDegree]: 6,
};

export const credentialDisplaySort = (
  a: TeacherCredential,
  b: TeacherCredential
) => {
  if (a.degreeType && b.degreeType) {
    return (
      DEGREE_DISPLAY_ORDER[a.degreeType] - DEGREE_DISPLAY_ORDER[b.degreeType]
    );
  }
  return 0;
};

export const COLLEGE_DEGREE_SUBJECT_TO_STRING: Record<DegreeSubject, string> = {
  [DegreeSubject.Accounting]: "Accounting",
  [DegreeSubject.ActuarialScience]: "Actuarial Science",
  [DegreeSubject.Advertising]: "Advertising",
  [DegreeSubject.AerospaceEngineering]: "Aerospace Engineering",
  [DegreeSubject.Agriculture]: "Agriculture",
  [DegreeSubject.AmericanStudies]: "American Studies",
  [DegreeSubject.AnimalScience]: "Animal Science",
  [DegreeSubject.Anthropology]: "Anthropology",
  [DegreeSubject.Architecture]: "Architecture",
  [DegreeSubject.ArtAndDesign]: "Art and Design",
  [DegreeSubject.ArtHistoryCriticismAndConservation]:
    "Art History, Criticism and Conservation",
  [DegreeSubject.AsianStudiesCivilization]: "Asian Studies/Civilization",
  [DegreeSubject.BehavioralScience]: "Behavioral Science",
  [DegreeSubject.Biochemistry]: "Biochemistry",
  [DegreeSubject.BiologyBiologicalSciences]: "Biology/Biological Sciences",
  [DegreeSubject.BiomedicalSciences]: "Biomedical Sciences",
  [DegreeSubject.BotanyPlantBiology]: "Botany/Plant Biology",
  [DegreeSubject.BusinessAdministrationAndManagement]:
    "Business Administration and Management",
  [DegreeSubject.BusinessCommerce]: "Business/Commerce",
  [DegreeSubject.ChemicalEngineering]: "Chemical Engineering",
  [DegreeSubject.Chemistry]: "Chemistry",
  [DegreeSubject.CivilEngineering]: "Civil Engineering",
  [DegreeSubject.Communications]: "Communications",
  [DegreeSubject.ComputerEngineering]: "Computer Engineering",
  [DegreeSubject.ComputerScience]: "Computer Science",
  [DegreeSubject.ConstructionManagement]: "Construction Management",
  [DegreeSubject.CriminalJustice]: "Criminal Justice",
  [DegreeSubject.Dance]: "Dance",
  [DegreeSubject.EarlyChildhoodEducation]: "Early Childhood Education",
  [DegreeSubject.Economics]: "Economics",
  [DegreeSubject.Education]: "Education",
  [DegreeSubject.ElectricalEngineering]: "Electrical Engineering",
  [DegreeSubject.ElementaryEducation]: "Elementary Education",
  [DegreeSubject.EngineeringGeneral]: "Engineering (General)",
  [DegreeSubject.EnglishLanguageAndLiterature]:
    "English Language and Literature",
  [DegreeSubject.EnvironmentalScience]: "Environmental Science",
  [DegreeSubject.EthnicStudies]: "Ethnic Studies",
  [DegreeSubject.ExerciseScience]: "Exercise Science",
  [DegreeSubject.FamilyAndConsumerSciencesHumanSciences]:
    "Family and Consumer Sciences/Human Sciences",
  [DegreeSubject.FashionMerchandising]: "Fashion Merchandising",
  [DegreeSubject.FilmCinemaVideoStudies]: "Film/Cinema/Video Studies",
  [DegreeSubject.Finance]: "Finance",
  [DegreeSubject.FineStudioArts]: "Fine/Studio Arts",
  [DegreeSubject.ForensicScienceAndTechnology]:
    "Forensic Science and Technology",
  [DegreeSubject.Forestry]: "Forestry",
  [DegreeSubject.Genetics]: "Genetics",
  [DegreeSubject.GeologyEarthScience]: "Geology/Earth Science",
  [DegreeSubject.GraphicDesign]: "Graphic Design",
  [DegreeSubject.HealthAndPhysicalEducationFitness]:
    "Health and Physical Education/Fitness",
  [DegreeSubject.HealthProfessionsAndRelatedPrograms]:
    "Health Professions and Related Programs",
  [DegreeSubject.HealthServicesAlliedHealthHealthSciences]:
    "Health Services/Allied Health/Health Sciences",
  [DegreeSubject.History]: "History",
  [DegreeSubject.Horticulture]: "Horticulture",
  [DegreeSubject.HospitalAndHealthCareFacilitiesAdministrationManagement]:
    "Hospital and Health Care Facilities Administration/Management",
  [DegreeSubject.HospitalityManagement]: "Hospitality Management",
  [DegreeSubject.HumanDevelopmentFamilyStudies]:
    "Human Development Family Studies",
  [DegreeSubject.HumanResourcesManagement]: "Human Resources Management",
  [DegreeSubject.InformationTechnology]: "Information Technology",
  [DegreeSubject.InteriorDesign]: "Interior Design",
  [DegreeSubject.InternationalBusiness]: "International Business",
  [DegreeSubject.InternationalRelations]: "International Relations",
  [DegreeSubject.Journalism]: "Journalism",
  [DegreeSubject.Kinesiology]: "Kinesiology",
  [DegreeSubject.LiberalArtsAndSciences]: "Liberal Arts and Sciences",
  [DegreeSubject.Linguistics]: "Linguistics",
  [DegreeSubject.ManagementInformationSystems]:
    "Management Information Systems",
  [DegreeSubject.MarineBiology]: "Marine Biology",
  [DegreeSubject.Marketing]: "Marketing",
  [DegreeSubject.MaterialsScience]: "Materials Science",
  [DegreeSubject.Math]: "Mathematics",
  [DegreeSubject.MechanicalEngineering]: "Mechanical Engineering",
  [DegreeSubject.MolecularBiology]: "Molecular Biology",
  [DegreeSubject.Music]: "Music",
  [DegreeSubject.Nursing]: "Nursing",
  [DegreeSubject.NutritionSciences]: "Nutrition Sciences",
  [DegreeSubject.OccupationalTherapy]: "Occupational Therapy",
  [DegreeSubject.OrganizationalLeadership]: "Organizational Leadership",
  [DegreeSubject.Philosophy]: "Philosophy",
  [DegreeSubject.Physics]: "Physics",
  [DegreeSubject.PoliticalScienceAndGovernment]:
    "Political Science and Government",
  [DegreeSubject.PreMedicinePreMedicalStudies]:
    "Pre-Medicine/Pre-Medical Studies",
  [DegreeSubject.Psychology]: "Psychology",
  [DegreeSubject.PublicAdministration]: "Public Administration",
  [DegreeSubject.PublicHealth]: "Public Health",
  [DegreeSubject.PublicRelations]: "Public Relations",
  [DegreeSubject.RealEstate]: "Real Estate",
  [DegreeSubject.ReligiousStudies]: "Religious Studies",
  [DegreeSubject.SecondaryEducation]: "Secondary Education",
  [DegreeSubject.SecondarySchoolAdministrationPrincipalship]:
    "Secondary School Administration/Principalship",
  [DegreeSubject.SocialSciences]: "Social Sciences",
  [DegreeSubject.SocialWork]: "Social Work",
  [DegreeSubject.Sociology]: "Sociology",
  [DegreeSubject.SpanishLanguageAndLiterature]:
    "Spanish Language and Literature",
  [DegreeSubject.SpecialEducation]: "Special Education",
  [DegreeSubject.SpeechCommunicationAndRhetoric]:
    "Speech Communication and Rhetoric",
  [DegreeSubject.SpeechLanguagePathologyPathologist]:
    "Speech-Language Pathology/Pathologist",
  [DegreeSubject.SportsManagement]: "Sports Management",
  [DegreeSubject.SupplyChainManagementLogistics]:
    "Supply Chain Management/Logistics",
  [DegreeSubject.TheatreArts]: "Theatre Arts",
  [DegreeSubject.UrbanAndRegionalPlanning]: "Urban and Regional Planning",
  [DegreeSubject.UrbanStudies]: "Urban Studies",
  [DegreeSubject.VeterinaryTechnology]: "Veterinary Technology",
  [DegreeSubject.ZoologyAnimalBiology]: "Zoology/Animal Biology",
  [DegreeSubject.Other]: "Other",
  [DegreeSubject.English]: "English",
  [DegreeSubject.ForeignLanguage]: "Foreign Language",
  [DegreeSubject.HealthOrPe]: "Health or PE",
  [DegreeSubject.MusicOrTheaterOrArts]: "Music or Theatre or Arts",
  [DegreeSubject.Science]: "Science",
};

export const TEACHER_CERTIFICATE_DISCIPLINE_TO_STRING: Record<
  TeacherCertificateDiscipline,
  string
> = {
  [TeacherCertificateDiscipline.EnglishOrLanguageArts]: "English/Language Arts",
  [TeacherCertificateDiscipline.Mathematics]: "Mathematics",
  [TeacherCertificateDiscipline.ForeignLanguage]: "Foreign Language",
  [TeacherCertificateDiscipline.Science]: "Science",
  [TeacherCertificateDiscipline.SocialStudiesOrHistory]:
    "Social Studies/History",
  [TeacherCertificateDiscipline.HealthOrPe]: "Health/PE",
  [TeacherCertificateDiscipline.ElementaryEducation]: "Elementary Education",
  [TeacherCertificateDiscipline.SecondaryEducation]: "Secondary Education",
  [TeacherCertificateDiscipline.SpecialEducation]: "Special Education",
  [TeacherCertificateDiscipline.MusicOrTheaterOrArts]: "Music/Theater/Arts",
  [TeacherCertificateDiscipline.EnglishToSpeakersOfOtherLanguages]:
    "English to Speakers of Other Languages",
  [TeacherCertificateDiscipline.Other]: "Other",
};

type RequiredFieldsCertificateGql =
  | "certificateDiscipline"
  | "certificateState";
type OptionalFieldsCertificateGql =
  | "certificateExpireDate"
  | "certificateIssueDate"
  | "certificateNumber"
  | "certificateSecondaryDiscipline";

type RequiredFieldsDegreeGql =
  | "degreeInstitution"
  | "degreeSubject"
  | "degreeType";
type OptionalFieldsDegreeGql = "degreeIssueDate";

type RequiredFieldsOtherGql = "otherDetails" | "otherType";

type RequiredNotNull<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

type EnsureNotNull<T, K extends keyof T> = T & RequiredNotNull<Pick<T, K>>;

export type TeacherCertificateCredentialGql = Omit<
  TeacherCredential,
  RequiredFieldsCertificateGql
> &
  EnsureNotNull<TeacherCredential, RequiredFieldsCertificateGql> &
  Record<"credentialType", CredentialType.TeachingCertificate> &
  Record<RequiredFieldsDegreeGql, null> &
  Record<OptionalFieldsDegreeGql, null> &
  Record<RequiredFieldsOtherGql, null>;

export type TeacherDegreeCredentialGql = TeacherCredential &
  EnsureNotNull<TeacherCredential, RequiredFieldsDegreeGql> &
  Record<"credentialType", CredentialType.CollegeDegree> &
  Record<RequiredFieldsCertificateGql, null> &
  Record<OptionalFieldsCertificateGql, null> &
  Record<RequiredFieldsOtherGql, null>;

export type TeacherOtherCredentialGql = TeacherCredential &
  EnsureNotNull<TeacherCredential, RequiredFieldsOtherGql> &
  Record<"credentialType", CredentialType.Other> &
  Record<RequiredFieldsCertificateGql, null> &
  Record<OptionalFieldsCertificateGql, null> &
  Record<RequiredFieldsDegreeGql, null> &
  Record<OptionalFieldsDegreeGql, null>;

export function isTeacherCertificateCredentialGql(
  credential: TeacherCredential
): credential is TeacherCertificateCredentialGql {
  return credential.credentialType === CredentialType.TeachingCertificate;
}

export function isTeacherDegreeCredentialGql(
  credential: TeacherCredential
): credential is TeacherDegreeCredentialGql {
  return credential.credentialType === CredentialType.CollegeDegree;
}

export function isTeacherOtherCredentialGql(
  credential: TeacherCredential
): credential is TeacherOtherCredentialGql {
  return credential.credentialType === CredentialType.Other;
}

// Below are type guards for converting from DB row to GQL
export function isValidCredentialType(
  credType: any
): credType is CredentialType {
  return Object.values(CredentialType).includes(credType);
}

export function isValidCertificateDiscipline(
  discipline: any
): discipline is TeacherCertificateDiscipline {
  return Object.values(TeacherCertificateDiscipline).includes(discipline);
}

export function isValidCertificateState(
  state: any
): state is TeacherCertificateState {
  return Object.values(TeacherCertificateState).includes(state);
}

export function isValidDegreeSubject(subj: any): subj is DegreeSubject {
  return Object.values(DegreeSubject).includes(subj);
}

export function isValidDegreeType(_type: any): _type is DegreeType {
  return Object.values(DegreeType).includes(_type);
}

export type TeacherCredentialsRowWithOptionalName = TeacherCredentialsRow & {
  name?: string;
  teacher_for_seller_org_user_uid?: string;
};

export function teacherCredentialRowToTeacherCredentialGql(
  row: TeacherCredentialsRowWithOptionalName
): TeacherCredential {
  const {
    credential_type,
    certificate_discipline,
    certificate_secondary_discipline,
    certificate_state,
    degree_additional_info,
    degree_subject,
    degree_type,
  } = row;
  if (!isValidCredentialType(credential_type)) {
    throw new Error(
      `Credential type ${credential_type} must be one of (${Object.values(
        CredentialType
      ).join(", ")})`
    );
  }
  if (
    certificate_discipline !== null &&
    !isValidCertificateDiscipline(certificate_discipline)
  ) {
    throw new Error(
      `Certificate discipline ${certificate_discipline} must be one of (${Object.values(
        TeacherCertificateDiscipline
      ).join(", ")})`
    );
  }
  if (
    certificate_secondary_discipline !== null &&
    !isValidCertificateDiscipline(certificate_secondary_discipline)
  ) {
    throw new Error(
      `Certificate secondary discipline ${certificate_secondary_discipline} must be one of (${Object.values(
        TeacherCertificateDiscipline
      ).join(", ")})`
    );
  }
  if (
    certificate_state !== null &&
    !isValidCertificateState(certificate_state)
  ) {
    throw new Error(
      `Certificate state ${certificate_state} must be one of (${Object.values(
        TeacherCertificateState
      ).join(", ")})`
    );
  }
  if (degree_subject !== null && !isValidDegreeSubject(degree_subject)) {
    throw new Error(
      `Degree subject ${degree_subject} must be one of (${Object.values(
        DegreeSubject
      ).join(", ")})`
    );
  }
  if (degree_type !== null && !isValidDegreeType(degree_type)) {
    throw new Error(
      `Degree type ${degree_type} must be one of (${Object.values(
        DegreeType
      ).join(", ")})`
    );
  }
  return {
    uid: row.uid,
    createdAt: row.created_at,
    credentialType: credential_type,
    certificateDiscipline: certificate_discipline,
    certificateExpireDate: row.certificate_expire_date,
    certificateDoesNotExpire: row.certificate_does_not_expire,
    certificateIssueDate: row.certificate_issue_date,
    certificateNumber: row.certificate_number,
    certificateSecondaryDiscipline: certificate_secondary_discipline,
    certificateState: certificate_state,
    degreeAdditionalInfo: degree_additional_info,
    degreeInstitution: row.degree_institution,
    degreeIssueDate: row.degree_issue_date,
    degreeSubject: degree_subject,
    degreeType: degree_type,
    otherDetails: row.other_details,
    otherType: row.other_type,
    name: row.name ?? null,
  };
}
