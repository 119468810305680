import { Components, Theme } from "@mui/material";

export const PICKERS_DAY_THEME: Components<Theme>["MuiPickersDay"] = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      "&.Mui-selected": {
        color: "inherit",
        backgroundColor: theme.palette.primary[100],
        "&:hover": {
          backgroundColor: theme.palette.primary[200],
        },
        "&:focus": {
          backgroundColor: theme.palette.primary[300],
        },
      },
    }),
  },
};
