import { isActiveSubscription } from "@outschool/business-rules";
import {
  CurrentSubscriptionQuery,
  CurrentSubscriptionQueryVariables,
} from "@outschool/gql-frontend-generated";
import { gql, useQuery } from "@outschool/ui-apollo";

export const CurrentSubscriptionGql = gql`
  query CurrentSubscription {
    currentSubscription {
      status
      defaultLearner {
        uid
      }
    }
  }
`;

export function useCurrentSubscription({
  isLoggedIn,
}: {
  isLoggedIn: boolean;
}) {
  const { data, loading, error } = useQuery<
    CurrentSubscriptionQuery,
    CurrentSubscriptionQueryVariables
  >(CurrentSubscriptionGql, {
    skip: !isLoggedIn,
    fetchPolicy: "cache-and-network",
  });
  const status = data?.currentSubscription?.status;
  const defaultLearner = data?.currentSubscription?.defaultLearner;

  const hasActiveSubscription = status ? isActiveSubscription(status) : false;

  return {
    hasActiveSubscription,
    defaultLearner,
    loading,
    error,
  };
}
