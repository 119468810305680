import React from "react";

let currId = 0;
const generateId = (prefix: string) => {
  currId++;
  return `${prefix}-${currId}`;
};

/**
 * Generate a unique id for a component.
 *
 * Useful for a11y labels and tags when composing components.
 */
export default (prefix: string = "id") => {
  const [id] = React.useState(() => generateId(prefix));
  return id;
};
