import {
  BASE_LOCALE,
  SUPPORTED_LOCALES,
  SUPPORTED_LANGUAGES,
  SupportedLanguage,
  I18nLocale,
} from "../constants";
import { UserLocale } from "@outschool/gql-backend-generated";

const toHyphenated = (locale: any) => locale.replace("_", "-");

/**
 * Checks whether a value is a supported locale string and type guards it.
 */
export function isSupportedLocale(
  locale: string | undefined | null
): locale is I18nLocale {
  return !!locale && SUPPORTED_LOCALES.includes(locale as I18nLocale);
}

/**
 * Coerces a value into a supported locale string with the `I18nLocale` type enforced.
 * If the value is not a supported locale string already, defaults to the base locale.
 */
export function toI18nLocale(
  locale: UserLocale | string | undefined | null
): I18nLocale {
  if (isSupportedLocale(locale)) {
    return locale as I18nLocale;
  } else {
    if (locale) {
      const hyphenatedLocale = toHyphenated(locale);
      if (isSupportedLocale(hyphenatedLocale)) {
        return hyphenatedLocale;
      }
    }
  }
  return BASE_LOCALE;
}

export function getLanguageFromLocale(
  locale: I18nLocale | UserLocale | string | null | undefined
): SupportedLanguage {
  if (locale) {
    const matchingLanguage = SUPPORTED_LANGUAGES.find(language => {
      return language.i18nLocale === locale || language.userLocale === locale;
    });
    if (matchingLanguage) {
      return matchingLanguage;
    }
  }
  const english = SUPPORTED_LANGUAGES[0];
  return english;
}
