import { Box, Typography } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { SystemStyleObject } from "@styled-system/css";
import React, { ReactNode, forwardRef } from "react";

interface FormFieldProps {
  label?: ReactNode;
  labelSx?: SystemStyleObject;
  description?: ReactNode;
  optional?: boolean;
  error?: ReactNode;
  children: ReactNode;
  sx?: SystemStyleObject;
  fieldId?: string;
}

/**
 * for legacy uses of FormField, that do not supply fieldId, try to simulate it
 */
const focusOnClick = (id?: string) =>
  id
    ? undefined
    : (e: React.MouseEvent) => {
        const parent = e?.currentTarget?.parentNode;
        const firstInput =
          parent &&
          parent.querySelector<HTMLElement>("input, textarea, select, button");
        firstInput?.focus();
      };

const FormField = forwardRef(function FormField(
  {
    label,
    description,
    optional,
    error,
    children,
    sx,
    labelSx = {},
    fieldId,
  }: FormFieldProps,
  ref
) {
  const errorMessage = Array.isArray(error) ? error[0] : error;
  const { t } = useTranslation("ui-legacy-component-library\\FormField");
  return (
    <Box
      flex
      sx={{
        flexDirection: "column",
        justifyContent: "space-between",
        ...sx,
      }}
      ref={ref}
    >
      {(!!label || !!description) && (
        <Box
          as="label"
          htmlFor={fieldId}
          sx={{ marginBottom: 8, ...labelSx }}
          onClick={focusOnClick(fieldId)}
        >
          {!!label && (
            <Typography variant="h6" sx={{ ...labelSx }}>
              {label}
              {optional && (
                <Typography variant="body2" color="neutral.600">
                  {" (" + t("optional") + ")"}
                </Typography>
              )}
            </Typography>
          )}
          <Typography variant="body1" sx={{ color: "grey.600", ...labelSx }}>
            {description}
          </Typography>
        </Box>
      )}
      {children}
      {errorMessage && (
        <Box sx={{ color: "error.main", minHeight: "1.5em" }}>
          {errorMessage}
        </Box>
      )}
    </Box>
  );
});

export default FormField;
