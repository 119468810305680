import { getReferrerAttribution } from "@outschool/attribution";
import { I18nLocale } from "@outschool/localization";
import {
  learnerOidcAuthLoginRedirectToProviderUrl,
  oidcAuthLoginRedirectToProviderUrl,
} from "@outschool/routes";
import { useNavigation } from "@outschool/ui-utils";
import React from "react";

interface UseOidcOnClickParams<P extends string> {
  provider: P;
  isLearnerApp: boolean;
  setAuthStrategy: (strategy: P) => void;
  getAnonymousId: () => Promise<string | undefined>;
  locale?: I18nLocale;
}

export function useOidcOnClick<P extends string>({
  provider,
  isLearnerApp,
  setAuthStrategy,
  getAnonymousId,
  locale,
}: UseOidcOnClickParams<P>) {
  const navigate = useNavigation();
  return React.useCallback(
    async (e: any) => {
      if (e) {
        e.preventDefault?.();
        e.stopPropagation?.();
      }

      setAuthStrategy(provider);

      const osRef = {
        ...getReferrerAttribution(),
        anonymousId: await getAnonymousId(),
      };
      const loginRedirectUrl = isLearnerApp
        ? learnerOidcAuthLoginRedirectToProviderUrl(provider, { osRef, locale })
        : oidcAuthLoginRedirectToProviderUrl(provider, { osRef, locale });
      navigate(loginRedirectUrl, { hardNav: true });
    },
    [getAnonymousId, setAuthStrategy, isLearnerApp, provider, navigate, locale]
  );
}
