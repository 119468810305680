import {
  createSetContextLink,
  ApolloLink,
  GraphQLRequest,
} from "@outschool/ui-apollo";
import { RateLimiter } from "limiter";
import mapValues from "lodash/mapValues";

export type RateLimitConfig = {
  [operationName: string]: { operationsPerMinute: number };
};

const globalErrorRateLimiter = new RateLimiter({
  interval: "minute",
  tokensPerInterval: 10,
});

function logRequestDetails(req: GraphQLRequest) {
  if (globalErrorRateLimiter.tryRemoveTokens(1)) {
    OsPlatform.captureError("Rate limited graphql operation with details", {
      tags: { package: "ui-gql" },
      extra: {
        name: req.operationName,
        variables: JSON.stringify(req.variables),
        query: JSON.stringify(req.query?.loc?.source),
      },
    });
  }
}

export function createRateLimitLink(
  rateLimitConfig: RateLimitConfig
): ApolloLink {
  const rateLimiters = mapValues(rateLimitConfig, config =>
    config.operationsPerMinute
      ? new RateLimiter({
          interval: "minute",
          tokensPerInterval: config.operationsPerMinute,
        })
      : undefined
  );

  return createSetContextLink(async req => {
    const opName = req.operationName;
    if (opName) {
      const rateLimiter = rateLimiters[opName];
      if (rateLimiter) {
        const allowed = rateLimiter.tryRemoveTokens(1);
        if (!allowed) {
          logRequestDetails(req);
          await rateLimiter.removeTokens(1);
        }
      }
    }
    return { rateLimitApproved: true };
  });
}
