///<reference path="./global-lib"/>
// This reference ensures that if a project initializes the platform library, it doesn't
// have to add it to the "lib" in tsconfig.

import type { PlatformImpl } from "./impl-types";
import type { OsEnvironment } from "../env";
import { OsSentryContext } from "../captures";
import { buildGlobalImpl } from "./global-lib";

const store = ((globalThis as any)[
  Symbol.for("@outschool/platform/globals")
] ??= {});

export const errorMap: WeakMap<{}, OsSentryContext> = (store.errorMap ??=
  new WeakMap());
const NULL_IMPL: PlatformImpl = (store.null ??= buildNullImpl());
export let implementation: PlatformImpl = store.impl ?? NULL_IMPL;

/**
 * Contains the system environment. Note that this value will be undefined
 * if the platform has not yet been initialized. Because initialization must
 * occur before code is executed, undefined is not listed in the types.
 */
export let osEnvironment: OsEnvironment = implementation.environment;

/**
 * This object is expected to be used ONLY by other platform libraries, as it
 * maintains the state of the platform implementation.
 */
export const PLATFORM_LIB_INITIALIZER = {
  setImplementation(impl: PlatformImpl | null) {
    if (impl === null) {
      impl = NULL_IMPL;
    } else if (implementation !== NULL_IMPL) {
      throw new Error("@ouschool/platform has already been initialized");
    }

    store.impl = implementation = impl;
    osEnvironment = implementation.environment;

    if (!globalThis.OsPlatform) {
      globalThis.OsPlatform = buildGlobalImpl();
    }
  },
  getImplementation: () =>
    implementation === NULL_IMPL ? null : implementation,
};

function buildNullImpl(): PlatformImpl {
  return {
    // intentionally breaking type for the null impl,
    // as the platform should _not_ be used when the impl hasn't been set.
    environment: undefined as any,

    addInitHook: notInitialized,
    addExitHook: notInitialized,
    getContextBaggage: notInitialized,
    runWithContextBaggage: notInitialized,
    updateContextBaggage: notInitialized,

    captureError: notInitialized,
    captureMessage: notInitialized,

    addSpanAttributes: notInitialized,
    runSpan: notInitialized,
  };

  function notInitialized(): any {
    throw new Error("@outschool/platform has not been initialized");
  }
}
