import { useTranslation } from "@outschool/localization";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import LegacyButton, {
  LegacyButtonColor,
  LegacyButtonType,
  LegacyButtonVariant,
} from "./LegacyButton/LegacyButton";
import LegacyFlex from "./LegacyFlex";
import Space from "./Space";

// Our standard modal dialogs have one primary and one secondary/cancel button. (The cancel button
// can also be hidden)
// Selected props:
// immediatePrimaryAction - an onClick handler for the primary button that bypasses the React event
//                          system, so is able to trigger popups.
interface ModalActionButtonsProps {
  trackingName?: string;
  cancelLabel?: string;
  hideCancelButton?: boolean;
  onPrimary?: (event: React.MouseEvent) => void;
  onCancel?: (event: React.MouseEvent) => void;
  shouldClosePortalOnAction?: boolean;
  closeParentPortal?: () => void;
  primaryLabel?: string;
  // FIXME: Should probably be primaryColor or primaryButtonColor.
  primaryVariant?: LegacyButtonColor;
  primaryType?: LegacyButtonType;
  // FIXME: This is probably not necessary if we have primaryColor.
  buttonColor?: LegacyButtonColor;
  disabled?: boolean;
  immediatePrimaryAction?: (event: MouseEvent) => void;
  cancelVariant?: LegacyButtonVariant;
  sx?: SystemStyleObject;
  trackingSharedProperties?: Record<string, any>;
}

export default function ModalActionButtons({
  trackingName = "",
  cancelLabel,
  hideCancelButton = false,
  onPrimary = _e => {},
  onCancel = _e => {},
  shouldClosePortalOnAction = false,
  closeParentPortal = () => {},
  primaryLabel,
  primaryVariant = "confirm",
  primaryType = "button",
  buttonColor = primaryVariant,
  disabled = false,
  cancelVariant = "link",
  sx = {},
  trackingSharedProperties = {},
  immediatePrimaryAction,
}: ModalActionButtonsProps) {
  const { t } = useTranslation(
    "ui-legacy-component-library\\ModalActionButtons"
  );
  const handleCancel = (e: React.MouseEvent) => {
    if (shouldClosePortalOnAction) {
      closeParentPortal();
    }
    onCancel(e);
  };

  const handlePrimary = (e: React.MouseEvent) => {
    if (shouldClosePortalOnAction) {
      closeParentPortal();
    }
    onPrimary(e);
  };

  return (
    <LegacyFlex
      sx={{
        flexDirection: "row-reverse",
        ...sx,
      }}
    >
      <LegacyButton
        trackingName={trackingName}
        trackingSharedProperties={{
          action: "primary",
          ...trackingSharedProperties,
        }}
        color={buttonColor}
        type={primaryType}
        disabled={disabled}
        onClick={handlePrimary}
        data-test-id="modal-primary-button"
        immediateAction={immediatePrimaryAction}
      >
        {primaryLabel || t("OK")}
      </LegacyButton>
      {!hideCancelButton && (
        <>
          <Space x="medium" />
          <LegacyButton
            trackingName={trackingName}
            trackingSharedProperties={{
              action: "cancel",
            }}
            variant={cancelVariant}
            onClick={handleCancel}
            data-test-id="modal-cancel-button"
          >
            {cancelLabel || t("Cancel")}
          </LegacyButton>
        </>
      )}
    </LegacyFlex>
  );
}
