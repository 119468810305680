import { useEffect, useRef } from "react";

export default function usePreviousValue<T>(currentValue: T): T {
  const ref = useRef<T>(currentValue);

  useEffect(() => {
    ref.current = currentValue;
  }, [currentValue]);

  return ref.current;
}
