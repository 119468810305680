import { ComponentTrackingProvider } from "@outschool/ui-legacy-component-library";
import React from "react";

import useTrackEvent from "../hooks/useTrackEvent";

export default function AnalyticsComponentTrackingProvider({
  children,
}: {
  children: React.ReactElement;
}) {
  const trackEvent = useTrackEvent();

  return (
    <ComponentTrackingProvider externalTrackFn={trackEvent}>
      {children}
    </ComponentTrackingProvider>
  );
}
