export type ScreenResolution = {
  width?: number;
  height?: number;
  isMobile?: boolean;
};

export function getResolution(): ScreenResolution {
  if (typeof window === "undefined") {
    return {
      width: undefined,
      height: undefined,
      isMobile: undefined,
    };
  }

  const width = Math.max(
    document?.documentElement?.clientWidth || 0,
    window?.innerWidth || 0
  );
  const height = Math.max(
    document?.documentElement?.clientHeight || 0,
    window?.innerHeight || 0
  );

  return {
    width: width > 0 ? width : undefined,
    height: height > 0 ? height : undefined,
    isMobile: width > 0 ? width < 768 : undefined,
  };
}
