import { useTranslation } from "@outschool/localization";
import { dayjs } from "@outschool/time";

type PlaceholderSearchSuggestions = PlaceholderSearchSuggestionsInput & {
  uid: string;
  type: string;
};

type PlaceholderSearchSuggestionsInput = {
  name: string;
  value: string;
  category: string;
  displayStartDate?: Date;
  displayEndDate?: Date;
};

export const usePlaceholderSuggestions = (
  shouldFilterDates: boolean = true
): PlaceholderSearchSuggestions[] => {
  const { t } = useTranslation(
    "ui-components-website\\search\\useSearchWithSuggestions"
  );
  const currentYear = dayjs().year();

  const placeholderSearchSuggestions: PlaceholderSearchSuggestionsInput[] = [
    {
      name: t("Summer Camps"),
      value: "Summer Camps",
      category: "Camps",
      // start showing on june 1st
      displayStartDate: dayjs("1900-06-01").year(currentYear).toDate(),
      // show until july 31st
      displayEndDate: dayjs("1900-07-31").year(currentYear).toDate(),
    },
    {
      name: t("Spanish"),
      value: "Spanish",
      category: "Languages",
    },
    {
      name: t("Writing"),
      value: "Writing",
      category: "English Language Arts",
    },
    {
      name: t("Math"),
      value: "Math",
      category: "Math",
    },
    {
      name: t("Reading"),
      value: "Reading",
      category: "English Language Arts",
    },
    {
      name: t("Coding"),
      value: "Coding",
      category: "Coding & Tech",
    },
    {
      name: t("ADHD"),
      value: "ADHD",
      category: "Health & Wellness",
    },
  ];

  return placeholderSearchSuggestions
    .map((suggestion: PlaceholderSearchSuggestionsInput, index: number) => {
      return {
        ...suggestion,
        uid: (index + 1).toString(),
        type: "placeholder",
      };
    })
    .filter(suggestion => {
      if (!shouldFilterDates) {
        return true;
      }
      return (
        !suggestion.displayStartDate ||
        !suggestion.displayEndDate ||
        (dayjs().isAfter(suggestion.displayStartDate) &&
          dayjs().isBefore(suggestion.displayEndDate))
      );
    });
};
