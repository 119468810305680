import { getReferrerAttribution } from "@outschool/attribution";
import { Box, Image } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import {
  googleAuthRedirectUrl,
  googleAuthUrl,
  learnerGoogleAuthRedirectUrl,
} from "@outschool/routes";
import { GoogleButton, GoogleIcon } from "@outschool/ui-components-shared";
import { useNavigation } from "@outschool/ui-utils";
import React from "react";

interface ContinueWithGoogleProps {
  clientId: string;
  isLearnerApp?: boolean;
  trackingName: string;
  setAuthStrategy: (strategy: "google") => void;
  getAnonymousId?: () => Promise<string | null>;
  onClick?: () => void;
  disableWhileLoading?: boolean;
}

export default function ContinueWithGoogle({
  clientId,
  isLearnerApp,
  trackingName,
  setAuthStrategy,
  getAnonymousId = () => Promise.resolve(null),
  onClick: onClickProp = () => {},
  disableWhileLoading = false,
}: ContinueWithGoogleProps) {
  const { t } = useTranslation("ui-auth\\src\\ContinueWithGoogle");
  const navigate = useNavigation();

  const onClick = async () => {
    const anonymousId = await getAnonymousId();
    setAuthStrategy("google");
    const redirectUri = isLearnerApp
      ? learnerGoogleAuthRedirectUrl()
      : googleAuthRedirectUrl();

    const loginRedirectRoute = googleAuthUrl(clientId, redirectUri, {
      ...getReferrerAttribution(),
      anonymousId,
    });
    // See http://caniuse.com/#feat=referrer-policy
    if (window && window.document) {
      const meta = window.document.createElement("meta");
      meta.setAttribute("name", "referrer");
      meta.setAttribute("content", "never");
      window.document.head.appendChild(meta);
    }
    onClickProp();
    // perform redirect
    navigate(loginRedirectRoute, { hardNav: true });
  };

  return (
    <GoogleButton
      onClick={onClick}
      trackingName={trackingName}
      disabled={disableWhileLoading}
      sx={{
        padding: "0 !important",
        display: "flex",
        "&:disabled": {
          pointerEvents: "none",
          opacity: 0.5,
          cursor: "not-allowed",
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: "common.white",
          borderRadius: "50%",
          padding: "13px",
          width: "44px",
        }}
      >
        <Image src={GoogleIcon} />
      </Box>
      <Box
        sx={{
          padding: "12.5px 0",
          flex: 1,
          minWidth: "210px",
        }}
      >
        {t("Continue with Google")}
      </Box>
    </GoogleButton>
  );
}
