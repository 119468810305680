import React from "react";

import {
  isOff,
  isReady,
  useAnalyticsContext,
} from "../providers/AnalyticsContext";

import type { AnalyticsInterface, Traits, WindowAnalytics } from "../types";

let analyticsPromise: Promise<void>;
let resolveAnalyticsPromise: () => void;

export default function useAnalytics(): AnalyticsInterface {
  const { status } = useAnalyticsContext();

  if (!analyticsPromise) {
    createAnalyticsPromise();
  }

  return React.useMemo(() => {
    const analytics: AnalyticsInterface = {
      pending: true,
      anonymousId,
      attribution,
      id,
      reset,
      traits,
    };

    if (isReady(status) || isOff(status)) {
      analytics.pending = false;
      resolveAnalyticsPromise();
    }

    return analytics;
  }, [status]);
}

function createAnalyticsPromise() {
  analyticsPromise =
    analyticsPromise ||
    new Promise(resolve => {
      resolveAnalyticsPromise = resolve;
    });
}

async function getAnalytics(): Promise<WindowAnalytics> {
  if (!analyticsPromise) {
    createAnalyticsPromise();
  }

  await analyticsPromise;

  return window.analytics;
}

async function id(): Promise<string | null> {
  const analytics = await getAnalytics();
  const userId = analytics?.user?.()?.id?.();

  if (!userId) {
    return null;
  }

  return userId as string;
}

async function anonymousId(): Promise<string | undefined> {
  const analytics = await getAnalytics();
  const anonymousId = analytics?.user?.()?.anonymousId?.();

  if (!anonymousId) {
    return undefined;
  }

  return anonymousId as string;
}

async function attribution(): Promise<Traits["attribution"]> {
  const analytics = await getAnalytics();

  return analytics?.user?.()?.traits()?.attribution || {};
}

async function reset(): Promise<void> {
  const analytics = await getAnalytics();

  analytics?.reset?.();
}

async function traits(traits?: Traits): Promise<Traits> {
  const analytics = await getAnalytics();

  return analytics?.user?.()?.traits(traits) || {};
}
