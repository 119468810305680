export { buttonClasses } from "@mui/material";

import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  styled,
} from "@mui/material";
import React from "react";

// Add "link" variant to Button variant options
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    link: true;
  }

  interface ButtonPropsSizeOverrides {
    inherit: true;
  }
}

export const ButtonSizes = ["inherit", "small", "medium", "large"] as const;
export type ButtonSize = (typeof ButtonSizes)[number];

export const ButtonVariants = [
  "contained",
  "outlined",
  "text",
  "link",
] as const;
export type ButtonVariant = (typeof ButtonVariants)[number];

export const ButtonColors = [
  "inherit",
  "primary",
  "secondary",
  "success",
  "error",
  "info",
  "warning",
  "neutral",
] as const;
export type ButtonColor = (typeof ButtonColors)[number];

export const ButtonEdges = ["start", "end", false] as const;
export type ButtonEdge = (typeof ButtonEdges)[number];

export type ButtonProps<C extends React.ElementType = "button"> =
  MuiButtonProps<C, { component?: C }> & {
    /**
     * If given, uses a negative margin to counteract the padding on one side
     * @default false
     */
    edge?: ButtonEdge;
  };

const StyledButton = styled<ButtonProps>(MuiButton, {
  shouldForwardProp: (prop: string) => prop !== "edge",
  name: "MuiButton",
})({}) as typeof MuiButton;

const _Button = <C extends React.ElementType = "button">(
  // Specify default props governed by Button theme "variants".
  // Custom props don't play well with variants.
  {
    variant = "outlined",
    color = "primary",
    size = "medium",
    edge = false,
    ...props
  }: ButtonProps<C>,
  ref: React.Ref<HTMLButtonElement>
) => {
  return (
    <StyledButton
      ref={ref}
      variant={variant}
      color={color}
      size={size}
      edge={edge}
      {...props}
    />
  );
};

export const Button = React.forwardRef(_Button);
