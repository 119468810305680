import { HttpLink, Operation, UriFunction } from "@outschool/ui-apollo";

import {
  handleManagedChallenge,
  isManagedChallengeResponse,
} from "./managed-challenge";

export function createHttpLink({
  uri,
  notificationServiceUrl,
  extraHeaders,
}: {
  uri: string;
  notificationServiceUrl: string | undefined;
  extraHeaders: Record<string, string> | undefined;
}) {
  let uriFn: UriFunction = op => appendOpName(uri, op);

  if (notificationServiceUrl) {
    const notificationsGraphqlOperationNames = [
      "LearnerNotifications",
      "MarkLearnerNotificationsRead",
    ];
    uriFn = op => {
      if (notificationsGraphqlOperationNames.includes(op.operationName)) {
        return appendOpName(`${notificationServiceUrl}/graphql`, op);
      } else {
        return appendOpName(uri, op);
      }
    };
  }

  return new HttpLink({
    uri: uriFn,
    headers: extraHeaders,
    fetch: async (reqUrl, options) => {
      let response = await fetch(reqUrl, options);

      if (isManagedChallengeResponse(response)) {
        try {
          await handleManagedChallenge();
          response = await fetch(reqUrl, options);
        } catch (error) {
          response = new Response(JSON.stringify({ error: error.message }), {
            status: 403,
            statusText: "Cloudflare Denied Request",
          });
        }
      }
      return response;
    },
  });
}

function appendOpName(uri: string, op: Operation) {
  return `${uri}/${encodeURIComponent(op.operationName)}`;
}
