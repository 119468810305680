import type { Context, EventProperties } from "../types";

import { addParamsToUrl } from "@outschool/routes";

import pkg from "../lib/pkg";
import { injectImg, injectScript, priceToDollarsAndCents } from "../lib/util";
import {
  Integration,
  IntegrationCategory,
} from "../providers/AnalyticsContext";
import { AnalyticsPlugin, AnalyticsPluginType } from ".";

declare global {
  interface Window {
    AWIN?: {
      Tracking: {
        Sale: object;
        run: () => void;
      };
    };
  }
}

type OrderCompletedSalePayload = {
  channel?: string;
  click?: string;
  currency: string;
  orderRef: string;
  parts: string;
  saleAmount: string;
  test: string;
};

type OrderCompletedPixelPayload = {
  amount: string;
  ch?: string;
  cr: string;
  merchant: number;
  parts: string;
  ref: string;
  testmode: string;
  tt: string;
  tv: number;
};

export default class AwinPlugin implements AnalyticsPlugin {
  name = Integration.Awin;
  category = IntegrationCategory.Advertising;
  type = AnalyticsPluginType.destination;
  version = "0.1.0";

  iframeId = "AWIN_CDT";
  imgId = "os-awin-pixel";
  imgUrl = "https://www.awin1.com/sread.img";
  scriptId = "os-awin";
  scriptSrc = "https://www.dwin1.com/18973.js";

  isLoadable() {
    return true;
  }

  async load(): Promise<void> {
    await injectScript(this.scriptId, this.scriptSrc);
  }

  async unload(): Promise<void> {
    const script = document.getElementById(this.scriptId);
    const iframe = document.getElementById(this.iframeId);
    const img = document.getElementById(this.imgId);

    iframe?.remove?.();
    script?.remove?.();
    img?.remove?.();

    window.AWIN = undefined;
  }

  isLoaded() {
    if (typeof window === "undefined") {
      return false;
    }

    return !!window?.AWIN;
  }

  async track(context: Context): Promise<Context> {
    const { event, properties } = context.event;

    switch (event) {
      case "Order Completed":
        await this.sendOrderCompletedEvent(properties);
        break;
      default:
        return context;
    }

    return context;
  }

  async sendOrderCompletedEvent(properties: EventProperties | undefined) {
    if (!window.AWIN?.Tracking || !properties) {
      return;
    }

    const orderRef = properties?.order_id;
    const saleAmount = priceToDollarsAndCents(properties?.total);
    const customerType = !!properties?.is_first_purchase ? "NEW" : "EXISTING";
    const currency = properties?.currency;
    const attribution = properties?.referrerAttribution;
    const click = this.getAttributionParam(attribution?.landingPage);
    const channel = attribution?.utm_source || "organic";
    const parts = `${customerType}:${saleAmount}`;
    const test = this.isTestMode();

    const salePayload: OrderCompletedSalePayload = {
      click,
      saleAmount,
      channel,
      orderRef,
      parts,
      currency,
      test,
    };

    const pixelPayload: OrderCompletedPixelPayload = {
      amount: saleAmount,
      ch: channel,
      cr: "USD",
      merchant: 18973,
      parts: parts,
      ref: orderRef,
      testmode: test,
      tt: "ns",
      tv: 2,
    };

    window.AWIN.Tracking.Sale = salePayload;
    window.AWIN.Tracking.run();

    const src = addParamsToUrl(this.imgUrl, pixelPayload);

    await injectImg(this.imgId, src, this.name);
  }

  getAttributionParam(landingPage: string): string | undefined {
    let url: URL;

    if (landingPage?.startsWith("/")) {
      const origin = document.location.origin;
      url = new URL(origin + landingPage);
    } else {
      url = new URL(landingPage);
    }

    return url.searchParams.get("awc") || undefined;
  }

  isTestMode(): string {
    return pkg.isProduction ? "0" : "1";
  }
}
