import React from "react";

import { delay } from "./PromiseUtils";

/**
 * Async wait for a variable till it gets defined in window
 *
 * • Usage Example: Waiting for async window.FB var to be initialized
 * - eg: `waitForVar('FB').then(FB => FB.test())`
 */
async function waitForVar<T extends keyof Window>(
  name: T,
  {
    pollFrequency = 1000,
    retries = 100,
  }: {
    pollFrequency?: number;
    retries?: number;
  } = { pollFrequency: 1000, retries: 100 }
): Promise<Window[T]> {
  // eslint-disable-next-line no-prototype-builtins
  if (window && window.hasOwnProperty(name)) {
    return window[name];
  }
  if (retries <= 0) {
    throw new Error(
      `Variable did not load in after ${pollFrequency * retries} ms`
    );
  }
  await delay(pollFrequency);
  return waitForVar(name, { pollFrequency, retries: retries - 1 });
}

/**
 * useScript hook
 *
 * https://usehooks.com/useScript
 */
export default function useScript<T extends keyof Window>({
  src,
  variableName,
}: {
  src: string;
  variableName?: T;
}) {
  const [scriptVariable, setScriptVariable] = React.useState<Window[T]>();
  React.useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        return;
      }

      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelectorAll<HTMLScriptElement>(
        `script[src="${src}"]`
      )[0];

      if (!script) {
        // Create script
        script = document.createElement("script");
        script.src = src;
        script.async = true;
        // Add script to document body
        document.body.appendChild(script);
      }
    },
    [src] // Only re-run effect if script src changes
  );

  React.useEffect(() => {
    async function waitForVariable() {
      if (!variableName) {
        return;
      }
      try {
        setScriptVariable(await waitForVar(variableName));
      } catch (err) {
        OsPlatform.captureError(err, {
          tags: { package: "ui-utils" },
          extra: { variableName },
        });
      }
    }
    waitForVariable();
  }, [variableName]);

  return scriptVariable;
}
