/** @jsx jsxPragma */

import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import jsxPragma from "./jsxPragma";

export interface InnerBorderProps
  extends React.ComponentPropsWithoutRef<"div"> {
  sx?: SystemStyleObject;
}

function InnerBorder(
  { sx, ...props }: InnerBorderProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <div
      ref={ref}
      // @ts-ignore
      // eslint-disable-next-line
      sx={{
        position: "relative",
        overflow: "hidden",
        "&:after": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          border: "1px solid rgba(0,0,0,0.1)",
          pointerEvents: "none",
          borderRadius: sx && (sx as any).borderRadius,
        },
        ...sx,
      }}
      {...props}
    />
  );
}

export default React.forwardRef(InnerBorder);
