import React from "react";

export enum DeviceType {
  Desktop = "desktop",
  Mobile = "mobile",
  Tablet = "tablet",
}

type DeviceTypeContextState = {
  deviceType: DeviceType;
};

export const DeviceTypeContext = React.createContext<DeviceTypeContextState>({
  deviceType: DeviceType.Mobile,
});

export function useDeviceTypeContext() {
  return React.useContext(DeviceTypeContext);
}
