import { EsaSessionData } from "@outschool/auth-shared";
import { CookieKeys } from "@outschool/data-schemas";
import {
  FundingProgramAbbreviation,
  getFundingProgramEndDate,
  getFundingProgramWaitPeriod,
} from "@outschool/funding-programs-shared";
import Cookies from "js-cookie";

import { EsaSessionType } from "../providers/TokenProvider";

export const getEsaSession = (): EsaSessionData => {
  return JSON.parse(Cookies.get(CookieKeys.EsaSession) || "null");
};

export const isEsaSessionPresent = () => {
  return !!getSessionType();
};

export const esaCheckoutUrl = (
  orderSlugId: string,
  callbackUrl: string,
  vendorId: string
) => {
  const { cwUrl } = getEsaSession();
  return `${cwUrl}/payby-checkout/?callback=${callbackUrl}/api/cw/order/${orderSlugId}&vendorId=${vendorId}`;
};

export const destroyEsaSession = () => {
  Cookies.remove(CookieKeys.EsaSession);
  Cookies.remove(CookieKeys.EsaSessionType);
};

export const getEsaState = (): string | undefined => {
  const esaState =
    getSessionType() === EsaSessionType.MERIT
      ? FundingProgramAbbreviation.KS
      : getSessionType() === EsaSessionType.CW
      ? getEsaSession()?.shipping?.state
      : undefined;

  return esaState;
};

export const getEsaWaitPeriod = (): number => {
  const esaState = getEsaState();
  return getFundingProgramWaitPeriod(esaState);
};

export const getEsaEndDate = (ignoreFirstDeadline = false): Date | null => {
  const esaState = getEsaState();
  return getFundingProgramEndDate(esaState, ignoreFirstDeadline);
};

export const getSessionType = (): EsaSessionType | null => {
  if (Cookies.get("meritKeepSession")) {
    return EsaSessionType.MERIT;
  }
  if (Cookies.get(CookieKeys.EsaSession)) {
    return EsaSessionType.CW;
  }
  return null;
};
