import omit from "lodash/omit";
import React from "react";

import HelpLink from "../HelpLink";

const NavHelpLink = React.forwardRef(
  (props: React.ComponentProps<typeof HelpLink>, ref: React.Ref<any>) => {
    return (
      <HelpLink
        trackingName="nav_help_link"
        trackingUniqueId="nav_help_link"
        ref={ref}
        sx={{
          textDecoration: "none !important",
          "&:focus-visible": {
            outline: "-webkit-focus-ring-color auto 1px",
          },
          ...props.sx,
        }}
        // eslint-disable-next-line i18next/no-literal-string
        {...omit(props, "sx")}
      />
    );
  }
);

export default NavHelpLink;
