import { Box, Image } from "@outschool/backpack";
import React, { Ref, forwardRef } from "react";

import kakaoLoginButton from "../../images/login/Kakao_logo.svg";
import { TrackedButton, TrackedButtonProps } from "./TrackedButton";

export type KakaoButtonProps = TrackedButtonProps;

const BASE_COLOR = "#fee500";
const HOVER_COLOR = "#e3cc04";
const ACTIVE_COLOR = "#dcc404";

function KakaoButton(
  { sx, children, ...props }: KakaoButtonProps,
  ref: Ref<any>
) {
  return (
    <TrackedButton
      ref={ref}
      color="inherit"
      sx={[
        {
          color: "common.black",
          borderColor: BASE_COLOR,
          backgroundColor: BASE_COLOR,
          padding: 0,
          "&:hover:not(:disabled), &:focus:not(:disabled)": {
            color: "common.black",
            borderColor: HOVER_COLOR,
            backgroundColor: HOVER_COLOR,
            textDecoration: "none",
          },
          "&:active:not(:disabled)": {
            color: "common.black",
            borderColor: ACTIVE_COLOR,
            backgroundColor: ACTIVE_COLOR,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      <Box
        sx={{
          fontSize: "1.5em",
          padding: "10px",
          width: "44px",
        }}
      >
        <Image
          src={kakaoLoginButton}
          sx={{
            height: "20px",
            width: "25px",
            display: "block",
          }}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          padding: "12.5px",
          minWidth: "215px",
        }}
      >
        {children}
      </Box>
    </TrackedButton>
  );
}

export default forwardRef<any, KakaoButtonProps>(KakaoButton);
