import isbot from "isbot";
import { HeaderKeys } from "./enums";
import type { OutschoolBotInfo } from "./types";

/*
 * The express request headers object can have undefined, string, or an array
 * of strings (thanks to cookie headers) as values. This type accounts for all
 * cases so we can use `req.headers` as an argument without having to cast the
 * object as a Record<string, string>.
 *
 * Docs: https://nodejs.org/docs/latest-v18.x/api/http.html#messageheaders
 */
type ExpressRequestHeaders = {
  [key: string]: string | string[] | undefined;
  ["User-Agent"]?: string;
  ["user-agent"]?: string;
  [HeaderKeys.BotVerified]?: string;
  [HeaderKeys.BotScore]?: string;
  [HeaderKeys.ThreatScore]?: string;
};

export function parseExpressBotHeaders(
  headers: ExpressRequestHeaders
): OutschoolBotInfo {
  return createOsBotInfo(
    headers?.["user-agent"] || headers?.["User-Agent"] || "",
    headers?.[HeaderKeys.BotVerified] || "",
    headers?.[HeaderKeys.BotScore] || "",
    headers?.[HeaderKeys.ThreatScore] || ""
  );
}

export function parseRequestBotHeaders(headers: Headers): OutschoolBotInfo {
  return createOsBotInfo(
    headers.get("user-agent") || "",
    headers.get(HeaderKeys.BotVerified) || "",
    headers.get(HeaderKeys.BotScore) || "",
    headers.get(HeaderKeys.ThreatScore) || ""
  );
}

export function createOsBotInfo(
  userAgent: string | undefined = "",
  cfBotVerified: string | undefined = "",
  cfBotScore: string | undefined = "",
  cfThreatScore: string | undefined = ""
): OutschoolBotInfo {
  return {
    is_bot: isbot(userAgent),
    cloudflare_bot_verified: cfBotVerified === "true",
    cloudflare_bot_score: parseInt(cfBotScore, 10) || 0,
    cloudflare_threat_score: parseInt(cfThreatScore, 10) || 0,
  };
}

/*
 * This function takes an OutschoolBotInfo object, containing bot checks from
 * isbot (the open source package) and Cloudflare, and serializes it into a
 * compact string.
 *
 * This string format is used to set a cookie value in response to the client.
 * Cookie sizes are limited, so it's important to keep keys and values small.
 *
 * The format is four values delimited by a period: [0-1].[0-1].[0-99].[0-99]
 *
 * 1. A boolean (represented as 0 or 1) from isbot
 * 2. A boolean (0 or 1) from cloudflare's x-bot-verified header
 * 3. An int (0-99) from cloudflare's x-bot-score header
 * 4. An int (0-99) from cloudflare's x-threat-score header
 */
export function serializeOsBot(botInfo: OutschoolBotInfo): string {
  const isBot = botInfo.is_bot ? "1" : "0";
  const cfBotVerified = botInfo.cloudflare_bot_verified ? "1" : "0";
  const cfBotScore = botInfo.cloudflare_bot_score || "0";
  const cfThreatScore = botInfo.cloudflare_threat_score || "0";

  return `${isBot}.${cfBotVerified}.${cfBotScore}.${cfThreatScore}`;
}

/*
 * Parse a string serialized from a OutschoolBotInfo object and return an
 * OutschoolBotInfo object. See `serializeOsBot` for more details.
 */
export function deserializeOsBot(osBot: string): OutschoolBotInfo {
  const botInfo: OutschoolBotInfo = {
    is_bot: false,
    cloudflare_bot_verified: false,
    cloudflare_bot_score: 0,
    cloudflare_threat_score: 0,
  };

  if (!osBot) {
    return botInfo;
  }

  const botStringPattern = /^[0-1]\.[0-1]\.[0-9]{1,2}\.[0-9]{1,2}$/;

  if (!botStringPattern.test(osBot)) {
    return botInfo;
  }

  const [
    is_bot,
    cloudflare_bot_verified,
    cloudflare_bot_score,
    cloudflare_threat_score,
  ] = osBot.split(".");

  botInfo.is_bot = is_bot === "1";
  botInfo.cloudflare_bot_verified = cloudflare_bot_verified === "1";
  botInfo.cloudflare_bot_score = parseInt(cloudflare_bot_score, 10) || 0;
  botInfo.cloudflare_threat_score = parseInt(cloudflare_threat_score, 10) || 0;

  return botInfo;
}

/*
 * This is a convenience function to return a boolean (whether or not the
 * request is from a bot) given an OutschoolBotInfo object serialized as a
 * string.
 */
export function isBotFromString(osBot: string): boolean {
  const botInfo = deserializeOsBot(osBot);

  return isBotFromObject(botInfo);
}

/*
 * This convenience function leverages OutschooLBotInfo headers and returns
 * true if the request is from a bot.
 */
export function isBotFromExpressHeaders(
  headers: Record<string, string>
): boolean {
  const botInfo = parseExpressBotHeaders(headers);

  return isBotFromObject(botInfo);
}

/*
 * A convenience function to return a boolean given an OutschoolBotInfo
 * object.
 */
export function isBotFromObject(osBot: OutschoolBotInfo): boolean {
  const { cloudflare_bot_verified, is_bot } = osBot;

  return cloudflare_bot_verified || is_bot;
}
