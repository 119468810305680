import { Component, Priority } from "@outschool/ownership-areas";
import { errorMap, implementation } from "./lib/implementation";
import { expandAlarmSettings } from "./lib/expand-alarm-settings";

export type TagValue = string | number | boolean | null | undefined;

/**
 * This object mirrors the capture options that can be provided to sentry, with a few
 * modifications for ease of use.
 */
export interface OsCaptureContext extends OsSentryContext {
  /**
   * Sentry uses this priority to configure the type of alarm sent when the alarm
   * is activated. Technically it is recorded as a tag, but we have made it available
   * here on the object so that there can be additional type checking provided on the
   * property.
   */
  priority?: Priority;
  /**
   * Sentry uses this component to configure the location of where an alarm is sent
   * when the error is captured. Technically it is recorded as a tag, but we have made
   * it available here on the object so that there can be additional type checking
   * provided on the property.
   */
  component?: Component;
}

/**
 * This object mirrors the capture options that can be provided to sentry.
 */
export type OsSentryContext = {
  /**
   * Tags are used in Sentry as a means of searching and organizing errors.
   */
  tags?: Record<string, TagValue>;
  /**
   * Additional metadata from the application that you'd like to include in the error report.
   * Useful for debugging.
   */
  extra?: Record<string, unknown>;
  /**
   * Adds user-specific metadata regarding the user who is using the application. The 'id'
   * is a particularly useful property to include here.
   */
  user?: { id?: string } & Record<string, unknown>;
};

/**
 * Records the given object as an error in Sentry. It is preferable to capture error objects,
 * but sentry allows you to capture anything you want.
 *
 * The captured data to sentry will include any capture context inherited from
 * first {@link addErrorContext} then {@link runWithContextBaggage} and
 * then finally the provided capture context.
 */
export function captureError(thing: unknown, ctx?: OsCaptureContext): void {
  implementation.captureError(thing, expandAlarmSettings(ctx));
}

/**
 * Augments the given error with extra context details to pass to sentry if it is
 * eventually passed to captureError.
 *
 * The returned value is intended to be rethrown.
 *
 * If the given error 'thing' is not an object, it will be converted to an error object
 * so that the sentry context can be passed invisibly with it.
 *
 * To protect the integrity of errors, this function will not error in the case
 * of a missing platform implementation.
 */
// tested via platform-node
export function addErrorContext(
  thing: unknown,
  ctx: OsCaptureContext
): unknown {
  // ... does this count as objectifying the thing? Am I a part of the problem?
  let obj: {};
  if (typeof thing === "object" && thing !== null) {
    obj = thing;
  } else if (typeof thing === "string") {
    obj = new Error(thing);
  } else {
    obj = new Error(`${typeof thing} Error: ${thing}`);
  }

  ctx = expandAlarmSettings(ctx);
  let prevCtx = errorMap.get(obj);
  if (prevCtx) {
    errorMap.set(obj, {
      extra: { ...prevCtx.extra, ...ctx.extra },
      tags: { ...prevCtx.tags, ...ctx.tags },
      user: { ...prevCtx.user, ...ctx.user },
    });
  } else {
    errorMap.set(obj, ctx);
  }
  return obj;
}

/**
 * Records the given string as a message in Sentry. This is useful for tracking events
 * that may indicate errors but are not an actual error state.
 */
export function captureMessage(message: string, ctx?: OsCaptureContext): void {
  implementation.captureMessage(message, expandAlarmSettings(ctx));
}
