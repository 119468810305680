import { Components, Theme, buttonBaseClasses } from "@mui/material";

export const TAB_THEME: Components<Theme>["MuiTab"] = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      opacity: 1,
      "&&": {
        color: theme.palette.text.primary,
        // match body1
        fontSize: theme.typography.pxToRem(16),
      },
      // Target self if it's an anchor element, making it look like a default tab.
      "a&&": {
        textDecoration: "none",
        color: theme.palette.text.primary,
      },
      [`&.${buttonBaseClasses.focusVisible}`]: {
        // Add a background color to the focus visible state because we removed focus ripples.
        backgroundColor: theme.palette.action.focus,
      },
    }),
  },
};

// MuiTabPanel is from MUI Lab and not in the base theme
export const TAB_PANEL_THEME = {
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
};
