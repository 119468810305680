import React, { Dispatch, SetStateAction } from "react";

import useLocalStorageReference from "./useLocalStorageReference";

function useLocalStorageState<T extends any>(key: string, defaultValue: T) {
  const { canUseLocalStorage, localStorage } = useLocalStorageReference();
  const [value, _setValue] = React.useState<T>(() => {
    const storedValue = localStorage?.getItem(key);
    const parsedValue: T = storedValue ? JSON.parse(storedValue) : null;
    return parsedValue ?? defaultValue;
  });

  const saveToStorage = React.useCallback(
    (value: T) => {
      if (canUseLocalStorage && value !== undefined) {
        localStorage!.setItem(key, JSON.stringify(value));
      }
    },
    [canUseLocalStorage, localStorage, key]
  );

  const setValue = React.useCallback(
    (value: T | ((prevState: T) => T)) => {
      if (value instanceof Function) {
        // If value is a function we make it behave like the setState callback
        _setValue((currentValue: T) => {
          const newValue = value(currentValue);
          saveToStorage(newValue);
          return newValue;
        });
      } else {
        _setValue(value);
        saveToStorage(value);
      }
    },
    [saveToStorage]
  );

  React.useEffect(() => {
    saveToStorage(value);
  }, [value, saveToStorage]);

  return [value, setValue] as [T, Dispatch<SetStateAction<T>>];
}

export default useLocalStorageState;
