import { onError } from "@outschool/ui-apollo";

export function createErrorLoggerLink(clientName: string) {
  return onError(({ operation, graphQLErrors }) => {
    // Log validation errors to Sentry
    if (graphQLErrors) {
      for (let error of graphQLErrors) {
        if (error?.extensions?.code === "GRAPHQL_VALIDATION_FAILED") {
          OsPlatform.captureError(error, {
            tags: { package: "ui-gql" },
            extra: { clientName, ...operation },
          });
        }
      }
    }
  });
}
