/**
 * We want to load hls.js in a separate webpack chunk, but webpack does not
 * recognize React.lazy in dependencies. Thus, we need the app
 * code to do the dynamic import and pass in into this provider
 */

import { VideoPlayerProps } from "@outschool/ui-legacy-component-library";
import React, { useContext } from "react";

function MinimalVideoPlayer(props: VideoPlayerProps) {
  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <video {...props} />;
}

const VideoPlayerContext =
  React.createContext<React.FunctionComponent<VideoPlayerProps>>(
    MinimalVideoPlayer
  );

export function VideoPlayerProvider({
  children,
  videoPlayerComponent,
}: {
  children: React.ReactNode;
  videoPlayerComponent: React.FC<VideoPlayerProps>;
}) {
  return (
    <VideoPlayerContext.Provider value={videoPlayerComponent}>
      {children}
    </VideoPlayerContext.Provider>
  );
}

export const useVideoPlayerComponent = () => {
  return useContext(VideoPlayerContext);
};
