import { isLocalStorageSupported } from "@outschool/local-storage";

export function getAnonymousId(): undefined | string {
  const localStorage = isLocalStorageSupported();

  if (!localStorage) {
    return undefined;
  }

  return localStorage.getItem("ajs_anonymous_id")?.replace(/['"]/g, "");
}
