// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

import { Icon, Image, SxProps } from "@outschool/backpack";
import { fasPlayCircle } from "@outschool/icons";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import { LegacyBox } from "./LegacyBox";
import LegacyFlex from "./LegacyFlex";
import HlsVideo from "./Video/HlsVideo";
import VideoPlaySvg from "./VideoPlaySvg";
import { BoxButton, ErrorBoundary } from ".";

import type { HlsVideoProps, HlsType } from "./Video/HlsVideo";

type HlsImporter = () => Promise<{ default: HlsType }>;

type PlayButtonType = "white-bottom-left" | "white-center" | "blue-center";

export type VideoPlayerProps = Omit<HlsVideoProps, "Hls"> & {
  muted?: boolean;
  src: string;
  poster?: string;
  title?: React.ReactNode;
  autoPlay?: boolean;
  onlyPlayButton?: boolean;
  playButtonRef?: React.Dispatch<React.SetStateAction<HTMLElement | undefined>>;
  trackingRef?: React.Ref<any>;
  playsInline?: boolean;
  onClick?: () => void;
  onVideoCanPlay?: () => void;
  sx?: SystemStyleObject;
  posterSx?: SxProps;
  posterShadowSx?: SystemStyleObject;
  videoSx?: SystemStyleObject;
  disablePictureInPicture?: boolean;
  playButtonType?: PlayButtonType;
  loop?: boolean;
};

/**
 * This factory function wraps HLSVideo in a React.lazy and awaits
 * the provider hls.js loader.
 */
const HlsVideoLazyFactory = (loadHls: HlsImporter) =>
  React.lazy(() =>
    loadHls().then(({ default: Hls }) => ({
      default: (props: Omit<HlsVideoProps, "Hls">) =>
        HlsVideo({ ...props, Hls }),
    }))
  );

function VideoPlayer({
  muted,
  src,
  poster,
  title,
  autoPlay,
  onClick,
  onVideoCanPlay,
  onlyPlayButton = false,
  playButtonRef,
  trackingRef,
  playsInline = true,
  posterSx = {},
  posterShadowSx = {},
  videoSx = {},
  sx = {},
  playButtonType = "blue-center",
  loadHls,
  ...videoProps
}: VideoPlayerProps & { loadHls: HlsImporter }) {
  const [playButtonClicked, setPlayButtonClicked] = React.useState(!!autoPlay);
  const [controls, setControls] = React.useState(!!autoPlay);
  const [videoCanPlay, setVideoCanPlay] = React.useState(false);
  const videoPlayerRef = React.useRef<HTMLVideoElement | null>(null);
  const handlePlayClick = () => {
    videoPlayerRef?.current?.play();
    setPlayButtonClicked(true);
    setControls(true);
    onClick && onClick();
  };
  const handleOnVideoCanPlay = () => {
    onVideoCanPlay && onVideoCanPlay();
    setVideoCanPlay(true);
  };
  React.useEffect(() => {
    if (playButtonClicked && videoCanPlay && videoPlayerRef.current) {
      videoPlayerRef.current.play();
    }
  }, [playButtonClicked, videoCanPlay]);

  const HlsVideoLazy = React.useMemo(
    () => HlsVideoLazyFactory(loadHls),
    [loadHls]
  );

  const loadVideo = autoPlay || !poster || playButtonClicked;
  const maxHeight = sx && "maxHeight" in sx ? sx.maxHeight : "100%";
  return (
    <ErrorBoundary>
      <LegacyBox
        ref={trackingRef}
        sx={{ position: "relative", height: "100%", ...sx }}
      >
        {loadVideo && (
          <React.Suspense fallback={null}>
            <HlsVideoLazy
              sx={{
                maxHeight,
                zIndex: 2,
                width: "100%",
                ...(!!poster && {
                  position: "absolute",
                  top: 0,
                  left: 0,
                }),
                ...videoSx,
              }}
              muted={muted}
              videoRef={videoPlayerRef}
              src={src}
              controls={controls}
              onContextMenu={e => e.preventDefault()}
              controlsList="nodownload" // React scraps this but after React 16, this should be good.
              preload="auto"
              poster={poster}
              playsInline={playsInline}
              autoPlay={autoPlay}
              onVideoCanPlay={handleOnVideoCanPlay}
              {...videoProps}
            />
          </React.Suspense>
        )}

        {poster && (
          <Image<{ nextImage: { layout: "fill" } }>
            // TODO don't do this - we have no idea if this component is used in CSR or SSR
            nextImage={{ layout: "fill" }}
            src={poster}
            sx={[
              {
                visibility: playButtonClicked ? "hidden" : "visible",
                width: "100%",
                maxHeight: maxHeight as string,
                display: "block", // remove space under image
                objectFit: "contain",
              },
              ...(Array.isArray(posterSx) ? posterSx : [posterSx]),
            ]}
          />
        )}
        {!playButtonClicked && (
          <>
            <LegacyBox
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.6)",
                ...posterShadowSx,
              }}
            />
            <LegacyFlex
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {onlyPlayButton && (
                <LegacyBox sx={{ marginBottom: "large" }}>
                  <LegacyBox
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    {title}
                  </LegacyBox>
                </LegacyBox>
              )}
              <LegacyBox>
                <VideoPlayIcon
                  ref={playButtonRef}
                  playButtonType={playButtonType}
                  handlePlayClick={handlePlayClick}
                />
              </LegacyBox>
              {onlyPlayButton && (
                <LegacyBox sx={{ marginTop: "medium" }}>
                  <LegacyBox
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    Press Play
                  </LegacyBox>
                </LegacyBox>
              )}
            </LegacyFlex>
          </>
        )}
      </LegacyBox>
    </ErrorBoundary>
  );
}

interface VideoPlayIconProps {
  ref?: React.Dispatch<React.SetStateAction<HTMLElement | undefined>>;
  playButtonType?: PlayButtonType;
  handlePlayClick: () => void;
}

function VideoPlayIcon({
  ref,
  playButtonType,
  handlePlayClick,
}: VideoPlayIconProps) {
  if (playButtonType === "white-center") {
    return (
      <LegacyBox onClick={handlePlayClick} sx={{ cursor: "pointer" }}>
        <VideoPlaySvg />
      </LegacyBox>
    );
  }

  if (playButtonType === "white-bottom-left") {
    return (
      <BoxButton
        onClick={handlePlayClick}
        sx={{
          width: 0,
          height: 0,
          position: "absolute",
          left: 14,
          bottom: "14px",
          borderTop: "10px solid transparent",
          borderBottom: "10px solid transparent",
          borderLeft: "14px solid white",
          cursor: "pointer",
        }}
      />
    );
  }

  return (
    <Icon
      ref={ref as any}
      icon={fasPlayCircle}
      sx={{ cursor: "pointer", fontSize: "4em" }}
      onClick={handlePlayClick}
    />
  );
}

/**
 * We want to load hls.js in a separate webpack chunk, but webpack does not
 * recognize magic chunk comments in dependencies. Thus, we need the client
 * code to do the dynamic import and pass in into this factory to make `<VideoPlayer />`.
 *
 * To obtain the loader, use webpack's dynamic import function.  E.g:
 * ```
 * const HlsJs = () => import("hls.js")
 * const VideoPlayer = VideoPlayerFactory(HlsJs)
 * ```
 */
const VideoPlayerFactory = (loadHls: HlsImporter) => {
  const component = (props: VideoPlayerProps) =>
    VideoPlayer({ ...props, loadHls });
  component.displayName = "VideoPlayer";
  return component;
};

export default VideoPlayerFactory;
