import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

type FeatureFlags = Partial<Record<string, boolean>>;

export function useFeatureFlag(flagName: string): boolean {
  return useFeatureFlags().allFlags[flagName] ?? false;
}

export function useFeatureFlags(): FeatureFlagProviderValue {
  return useContext(FeatureFlagContext);
}

const FeatureFlagContext = React.createContext<FeatureFlagProviderValue>({
  allFlags: {},
  userFlagsLoaded: false,
});

interface FeatureFlagProviderProps {
  /**
   * The current user uid.  We don't directly use this in the FeatureFlag query,
   * but we use changes in its value to trigger a re-render and refetch
   */
  currentUserUid: string | undefined | null;
  /**
   * Feature flags enabled for all users and local overrides.
   */
  featureFlagOverrides: string[];
  /**
   * Fetch feature flags from backend
   */
  fetchFeatureFlags: () => Promise<string[] | null>;
}

interface FeatureFlagProviderValue {
  allFlags: FeatureFlags;
  userFlagsLoaded: boolean;
}

export const FeatureFlagProvider: React.FC<
  PropsWithChildren<FeatureFlagProviderProps>
> = ({ children, currentUserUid, featureFlagOverrides, fetchFeatureFlags }) => {
  const [userFlagsLoaded, setUserFlagsLoaded] = useState(false);
  const [featureFlags, setFeatureFlags] = useState<string[] | null>(null);
  const refetch = useCallback(async () => {
    const featureFlags = await fetchFeatureFlags();
    setFeatureFlags(featureFlags);
    if (currentUserUid) {
      setUserFlagsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFeatureFlags]);

  // Feature flag state varies based on user lists.
  // Whenever the current user changes, refetch feature flags.
  useEffect(() => {
    setTimeout(refetch, 10);
  }, [refetch, currentUserUid]);

  // Compile the list of enabled flags into an object mapping flag name to boolean.
  const allFlags = compileFlags(featureFlags, featureFlagOverrides);

  return (
    <FeatureFlagContext.Provider value={{ allFlags, userFlagsLoaded }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

function compileFlags(
  enabledFlags: string[] | null,
  enabledFlagsForAllUsers: string[]
): FeatureFlags {
  const result: FeatureFlags = {};
  // Fallback to the list baked into the HTML page, which has flags enabled for
  // all users and local overrides.
  for (const flag of enabledFlagsForAllUsers) {
    result[flag] = true;
  }
  // Use the provided feature flag list unless it's null
  if (enabledFlags) {
    for (const flag of enabledFlags) {
      result[flag] = true;
    }
  }
  return result;
}
