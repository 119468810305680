import { jsx as emotion } from "@emotion/react";
import css, { SystemStyleObject } from "@styled-system/css";
import lodashOmit from "lodash/omit";

const parseProps = (props: any) => {
  if (!props) {
    return null;
  }
  if (!props.sx) {
    return props;
  }

  return lodashOmit(
    {
      ...props,
      css: css(props.sx as SystemStyleObject),
    },
    "sx"
  );
};

/**
 * @deprecated Please use `styled()` from \@outschool/backpack for adding the sx prop.
 */
export default function jsxPragma(
  type: any,
  props: any,
  ...children: any[]
): React.ReactElement {
  return emotion(type, parseProps(props), ...children);
}
