export interface InitOptions {
  env?: string;
}

let gInitialized = false;

export function init(options: InitOptions = {}) {
  if (gInitialized && options.env !== "test") {
    throw new Error("@outschool/routes is already initialized");
  }

  gInitialized = true;
}
