import type { EventOptions, EventProperties, SendTrackEvent } from "../types";
import type { IntegrationCategory } from "../providers/AnalyticsContext";

import React from "react";

import { cloneEventProperties } from "../lib/util";
import {
  enqueueEvent,
  getMergedEventOptions,
  isOff,
  isReady,
  useAnalyticsContext,
} from "../providers/AnalyticsContext";

export default function useTrackEvent(
  category?: IntegrationCategory
): SendTrackEvent {
  const { status } = useAnalyticsContext();

  function track(
    name: string,
    properties?: EventProperties,
    options?: EventOptions,
    callback = () => {}
  ) {
    if (isOff(status)) {
      callback();
      return;
    }

    const clonedProperties = cloneEventProperties(properties);

    if (isReady(status)) {
      const eventOptions = getMergedEventOptions(options, category);
      window.analytics.track(name, clonedProperties, eventOptions, callback);
      return;
    }

    clonedProperties.enqueuedAt = new Date().toISOString();

    enqueueEvent(
      ["track", name, clonedProperties, options, callback],
      category
    );
  }

  return React.useCallback(track, [category, status]);
}
