import { gql } from "@outschool/ui-apollo";

import { AttendanceFragment } from "./Attendance";
import { LearnerFragment } from "./Learner";
import { RecordingRequestFragment } from "./RecordingRequest";

export const MeetingFragment = gql`
  fragment MeetingFragment on Meeting {
    uid
    start_time
    end_time
    deleted_at
    created_at
  }
`;

export const OneOnOneMeetingFragment = gql`
  fragment OneOnOneMeetingFragment on Meeting {
    refundableAmountCents
    paidAt
    recurring
  }
`;

export const MeetingWithAttendancesAndRecordings = gql`
  fragment MeetingWithAttendancesAndRecordings on Meeting {
    ...MeetingFragment
    attendances {
      ...AttendanceFragment
      learner {
        ...LearnerFragment
      }
    }
    recordingAvailability
    classRecordings {
      uid
      recordingId
      startTime
      endTime
    }
    recordingRequests {
      ...RecordingRequestFragment
    }
  }
  ${AttendanceFragment}
  ${MeetingFragment}
  ${LearnerFragment}
  ${RecordingRequestFragment}
`;
