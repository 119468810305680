/** @jsx jsxPragma */

import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import jsxPragma from "../jsxPragma";

export type VideoProps = React.VideoHTMLAttributes<HTMLVideoElement> & {
  sx?: SystemStyleObject;
  onVideoCanPlay?: () => void;
  videoRef?: React.MutableRefObject<HTMLVideoElement | null>;
};

/** A video element tag which accepts sx props */
export default function Video({
  sx,
  onVideoCanPlay,
  videoRef,
  ...props
}: VideoProps) {
  const [node, setNode] = React.useState<HTMLVideoElement | null>(null);
  React.useEffect(() => {
    if (!node) {
      return undefined;
    }

    const canPlayHandler = () => {
      if (onVideoCanPlay) {
        onVideoCanPlay();
      }
    };

    // See https://stackoverflow.com/a/46064096/11776827
    const errorHandler = (event: any) => {
      let error = event;

      // Chrome v60
      if (event.path && event.path[0]) {
        error = event.path[0].error;
      }

      // Firefox v55
      if (event.originalTarget) {
        error = error.originalTarget.error;
      }
      console.error(error);
    };

    node.addEventListener("canplay", canPlayHandler);
    node.addEventListener("error", errorHandler);

    return () => {
      node.removeEventListener("canplay", canPlayHandler);
      node.removeEventListener("error", errorHandler);
    };
  }, [node, onVideoCanPlay]);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      ref={node => {
        setNode(node);
        if (videoRef) {
          videoRef.current = node;
        }
      }}
      // @ts-ignore
      // eslint-disable-next-line
      sx={sx}
      data-testid="video"
      {...props}
    />
  );
}
