import { Components } from "@mui/material";
import { Theme } from "@mui/material/styles";

export const SWITCH_THEME: Components["MuiSwitch"] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    thumb: ({ theme }: { theme: Theme }) => ({
      boxShadow: `0px 0px 2px ${theme.palette.common.black}`,
    }),
  },
};
