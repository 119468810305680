import * as Auth from "@outschool/auth-shared";
import { HeaderKeys } from "@outschool/data-schemas";
import { TokenRefreshSource } from "@outschool/gql-frontend-generated";
import { isLocalStorageSupported } from "@outschool/local-storage";
import { Component } from "@outschool/ownership-areas";
import {
  ApolloClient,
  ApolloLink,
  NetworkError,
  createSetContextLink,
  onError,
  GraphQLRequest,
} from "@outschool/ui-apollo";
import { sessionRefresh, shouldAutomaticallyRefresh } from "@outschool/ui-auth";
import once from "lodash/once";
import { asyncOneAtATime } from "../lib/concurency-control";

export type TokenRefreshControl = {
  /** ƒn to retrieve authentication tokens */
  getTokens: () => {
    sessionToken: string | null;
    refreshToken: string | null;
  };

  /** ƒn to set authentication tokens */
  setTokens: (sessionToken: string, refreshToken?: string) => void;

  /** ƒn to set handle expired tokens. Will only be called once. */
  onTokensExpired: (context: { reason: string }) => void;

  shouldLogMissingRefreshToken: boolean;
};

export function createTokenRefreshLink(
  clientName: string,
  ctrl: TokenRefreshControl,
  getClient: () => ApolloClient<unknown>
): ApolloLink {
  // Debounce onTokensExpired to prevent repeated refreshes
  ctrl = { ...ctrl, onTokensExpired: once(ctrl.onTokensExpired) };
  const limitedRefresh = asyncOneAtATime(sessionRefresh);
  return ApolloLink.from([
    createSetContextLink(async (operation, ctx) => {
      const sessionToken = await getFreshToken(
        clientName,
        ctrl,
        getClient,
        operation,
        ctx,
        limitedRefresh
      );
      if (sessionToken) {
        return {
          headers: {
            authorization: `Bearer ${sessionToken}`,
            credentials: "same-origin",
          },
        };
      } else {
        return {};
      }
    }),
    onError(({ networkError }) => {
      // Trigger a logout if session token has an invalid signature
      // (This usually means we rotated our session secret)
      if (getAuthError(networkError) === "invalid_signature") {
        ctrl.onTokensExpired({
          reason: "ErrorLink: Invalid token signature sent to server",
        });
      }
    }),
  ]);
}

async function getFreshToken(
  clientName: string,
  ctrl: TokenRefreshControl,
  getClient: () => ApolloClient<unknown>,
  operation: GraphQLRequest,
  ctx: Record<string, any>,
  limitedRefresh: typeof sessionRefresh
): Promise<string | null> {
  const { sessionToken, refreshToken } = ctrl.getTokens();
  if (!sessionToken && !refreshToken) {
    return null;
  }

  if (ctrl.shouldLogMissingRefreshToken && sessionToken && !refreshToken) {
    logMissingRefreshToken(operation, clientName, sessionToken);
  }

  if (
    Auth.hasExpired(sessionToken) &&
    (!refreshToken || Auth.hasExpired(refreshToken))
  ) {
    ctrl.onTokensExpired({ reason: "RefreshLink: both tokens expired" });
    return null;
  }

  if (shouldAutomaticallyRefresh(sessionToken, refreshToken, ctx)) {
    try {
      return await doRefresh(refreshToken!, ctrl, getClient, limitedRefresh);
    } catch (e) {
      OsPlatform.captureError(e, {
        extra: { clientName, ...operation },
        tags: {
          package: "ui-gql",
          implementationLocation: "ApolloClient.RefreshLink",
          component: Component.UserAccountManagement,
        },
      });
      ctrl.onTokensExpired({ reason: "RefreshLink: refreshv2 failed " });
      return null;
    }
  }

  //both tokens are valid
  return sessionToken;
}

async function doRefresh(
  refreshToken: string,
  ctrl: TokenRefreshControl,
  getClient: () => ApolloClient<unknown>,
  limitedRefresh: typeof sessionRefresh
) {
  const result = await limitedRefresh(getClient(), {
    refreshToken,
    refreshSource: TokenRefreshSource.Apollo,
  });
  const data = result.data?.refreshV2;
  if (!data) {
    throw Error("refresh mutation didn't return data");
  }

  ctrl.setTokens(data.sessionToken, data.refreshToken);
  return data.sessionToken;
}

function logMissingRefreshToken(
  op: GraphQLRequest,
  clientName: string,
  sessionToken: string
) {
  const tags: any = {
    package: "ui-gql",
    missing_refresh_op: op.operationName,
    issuer_endpoint: Auth.getAuthTokenIssuerEndpoint(sessionToken),
    iat: Auth.getAuthTokenIat(sessionToken),
    isLearnerAuthTransfer: Auth.isLearnerAuthTransfer(sessionToken),
    isParentAuthTransfer: Auth.isParentAuthTransfer(sessionToken),
  };
  const localStorage = isLocalStorageSupported();
  if (localStorage) {
    tags.hasJwt = Boolean(localStorage.getItem("jwt"));
    tags.hasJwtRefreshToken = Boolean(localStorage.getItem("jwtRefreshToken"));
    tags.hasLearnerJwt = Boolean(localStorage.getItem("learnerJWT"));
    tags.hasLearnerRefreshJwt = Boolean(
      localStorage.getItem("learnerRefreshJWT")
    );
  }

  OsPlatform.captureMessage(`No refresh token during GraphQL operation`, {
    tags,
    extra: {
      clientName,
      ...op,
    },
  });
}

/**
 * Given a network error passed to Apollo, check if it is an
 * auth error and return the auth error code header if so. If
 * it is not an auth error or is missing the header, return null.
 */
function getAuthError(error: NetworkError | undefined): string | null {
  if (
    error &&
    "statusCode" in error &&
    "response" in error &&
    error.statusCode === 401
  ) {
    return error.response.headers.get(HeaderKeys.AuthError);
  }
  return null;
}
