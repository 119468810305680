import { SystemStyleObject } from "@styled-system/css";
import React from "react";
import { Manager, Popper, PopperProps, Reference } from "react-popper";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import LegacyBox, { LegacyBoxProps } from "./LegacyBox";

const ARROW_SIZE_PX = 19;

const PopoverPlacements: Record<string, SystemStyleObject> = {
  "top-right": {
    content: '""',
    position: "relative",
    top: 9.5,
    right: "calc(-100% + 1.5em + 32px)",
    height: ARROW_SIZE_PX,
    width: ARROW_SIZE_PX,
    background: "white",
    zIndex: 1001,
    transform: "rotate(-45deg)",
    borderRight: "1px solid rgba(37,93,173,0.25)",
    borderTop: "1px solid rgba(37,93,173,0.25)",
  },
  "bottom-left": {
    content: '""',
    position: "relative",
    top: 138,
    right: -27,
    height: ARROW_SIZE_PX,
    width: ARROW_SIZE_PX,
    background: "white",
    zIndex: 1001,
    transform: "rotate(-45deg)",
    borderLeft: "1px solid rgba(37,93,173,0.25)",
    borderBottom: "1px solid rgba(37,93,173,0.25)",
  },
  right: {
    content: '""',
    position: "relative",
    top: 28,
    right: -11,
    height: ARROW_SIZE_PX,
    width: ARROW_SIZE_PX,
    background: "white",
    zIndex: 1001,
    transform: "rotate(45deg)",
    borderLeft: "1px solid rgba(37,93,173,0.25)",
    borderBottom: "1px solid rgba(37,93,173,0.25)",
  },
  hide: {
    content: '""',
    position: "relative",
    top: 135,
    right: -27,
    height: 0,
    width: 0,
    background: "white",
    zIndex: 1001,
    transform: "rotate(-45deg)",
    borderLeft: "1px solid rgba(37,93,173,0.25)",
    borderBottom: "1px solid rgba(37,93,173,0.25)",
  },
};

type Placement = PopperProps["placement"];

type PopoverProps = {
  renderButton: (ref: React.Ref<HTMLElement>) => React.ReactElement;
  children: React.ReactNode;
  placement?: "top-right" | "bottom-left" | "right" | "hide";
  innerProps?: LegacyBoxProps;
  isHidden?: boolean;
  parentOverflow?: { enabled?: boolean; padding?: number };
  shouldSchedulePositionUpdate?: () => boolean;
  positionFixed?: boolean;
  mainPlacement?: Placement;
  modifiers?: PopperProps["modifiers"];
  animationDuration?: string;
  sx?: SystemStyleObject;
  fadeIn?: boolean;
  slideIn?: boolean;
  ref?: React.Ref<HTMLElement>;
};

const Popover = (
  {
    children,
    renderButton,
    placement = "top-right",
    innerProps = {},
    isHidden = false,
    sx = {},
    parentOverflow = {},
    shouldSchedulePositionUpdate = () => false,
    positionFixed = false,
    mainPlacement = "bottom",
    modifiers = {},
    animationDuration = "0.1s",
    fadeIn = true,
    slideIn = true,
    ...props
  }: PopoverProps,
  refToForward: any
) => {
  return (
    <Manager>
      <Reference>{({ ref }) => renderButton(ref)}</Reference>
      <Popper
        placement={mainPlacement}
        positionFixed={positionFixed}
        modifiers={{
          maxWidth: {
            enabled: true,
            order: 100,
            fn(data) {
              const maxWidth = document.documentElement.clientWidth - 32;
              if (data.offsets.popper.width >= maxWidth) {
                data.styles.width = `${maxWidth}px`;
              }
              return data;
            },
          },
          preventOverflow: {
            enabled: true,
            padding: 16,
            ...parentOverflow,
            boundariesElement: "scrollParent",
            priority: ["left", "right"],
          },
          flip: {
            enabled: false,
          },
          ...modifiers,
        }}
      >
        {({
          ref,
          style,
          placement: popperPlacement,
          arrowProps,
          scheduleUpdate,
        }) => {
          shouldSchedulePositionUpdate() && scheduleUpdate();
          return (
            <LegacyBox
              ref={ref}
              sx={{ zIndex: 1000, pointerEvents: isHidden ? "none" : "auto" }}
              style={style}
              data-placement={popperPlacement}
            >
              <LegacyBox
                sx={{
                  opacity: fadeIn && isHidden ? 0 : 1,
                  visibility: isHidden ? "hidden" : "visible",
                  marginTop: slideIn && isHidden ? -32 : 0,
                  transition: `opacity ${animationDuration} ease-out, margin-top ${animationDuration} ease-out`,
                  ...sx,
                }}
                {...props}
                ref={refToForward}
              >
                <LegacyBox
                  ref={arrowProps.ref}
                  style={arrowProps.style}
                  sx={PopoverPlacements[placement]}
                />
                <LegacyBox
                  {...innerProps}
                  sx={{
                    background: "white",
                    border: "1px solid rgba(37, 93, 173, 0.25)",
                    borderRadius: 5,
                    boxShadow: "rgb(0 0 0 / 16%) 0px 8px 20px",
                    overflow: "hidden",
                    ...innerProps.sx,
                  }}
                >
                  <LegacyBox
                    sx={{
                      position: "relative",
                      zIndex: 1002,
                      // Prevent menu from triggering page scrollbar when its height is large than page's
                      maxHeight: isHidden ? `calc(100vh - 100px)` : "auto",
                    }}
                  >
                    {children}
                  </LegacyBox>
                </LegacyBox>
              </LegacyBox>
            </LegacyBox>
          );
        }}
      </Popper>
    </Manager>
  );
};

// Enyzme explodes if we try to use popper.js
function PopoverStub(
  { renderButton, isHidden, children }: PopoverProps,
  refToForward: any
) {
  return (
    <>
      {renderButton(refToForward as React.Ref<HTMLElement>)}
      {!isHidden && children}
    </>
  );
}

const isTest = process.env.OUTSCHOOL_ENV === "test";
/**
 * @deprecated Use backpack Popper instead
 */
export const LegacyPopover: React.ForwardRefExoticComponent<PopoverProps> =
  React.forwardRef(isTest ? PopoverStub : Popover);
export default LegacyPopover;
