import { Components, buttonClasses, buttonGroupClasses } from "@mui/material";

// Add "link" variant to ButtonGroup variant options
declare module "@mui/material/ButtonGroup" {
  interface ButtonGroupClasses {
    link: string;
  }
}

const BUTTON_GROUP_THEME: Components["MuiButtonGroup"] = {
  defaultProps: {
    disableRipple: true,
    disableElevation: true,
    variant: "outlined",
  },
  styleOverrides: {
    root: {
      [`& > .${buttonGroupClasses.grouped}`]: {
        minWidth: "auto",
      },
    },
    fullWidth: {
      [`& > .${buttonClasses.root}`]: {
        // Remove horizontal padding from fullWidth buttons in group so that
        // they can fill their container without overflowing its bounds
        // (given the buttons themselves fit)
        paddingLeft: 0,
        paddingRight: 0,
        flexGrow: 1,
      },
    },
    text: {
      [`& > .${buttonClasses.root}`]: {
        border: "none",
      },
    },
    link: {
      [`& > .${buttonClasses.root} + .${buttonClasses.root}`]: {
        borderLeftStyle: "solid",
      },
      [`& .${buttonGroupClasses.groupedVertical}.${buttonClasses.root} + .${buttonGroupClasses.groupedVertical}.${buttonClasses.root} `]:
        {
          borderLeftStyle: "none",
          borderTopStyle: "solid",
        },
      [`& > .${buttonClasses.sizeSmall}`]: {
        padding: "0.3rem 0.9rem",
      },
      [`& > .${buttonClasses.sizeMedium}`]: {
        padding: "0.6rem 1.4rem",
      },
      [`& > .${buttonClasses.sizeLarge}`]: {
        padding: "0.8rem 1.8rem",
      },
    },
  },
};

export default BUTTON_GROUP_THEME;
