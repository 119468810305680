import type { Context, EventProperties } from "../types";

import { addParamsToUrl } from "@outschool/routes";

import { injectImg } from "../lib/util";
import {
  Integration,
  IntegrationCategory,
} from "../providers/AnalyticsContext";
import { AnalyticsPlugin, AnalyticsPluginType } from ".";

type PixelEventPayload = {
  /**
   * A unique ID identifying the affiliate.
   */
  a8: string;

  /**
   * A fixed program ID determined by a8.net.
   * Advertisers are notified of their Program IDs by A8.net support.
   * Example: “s00000000062001”
   */
  pid: string;

  /**
   * A unique order number generated by the merchant.
   */
  so: string;

  /**
   * The value of the si parameter is actually four separate parameters
   * separated by hyphens.
   *
   * 1. item price
   * 2. item count
   * 3. total price
   * 4. item code
   *
   * template: si={Item Price}-{Item Count}-{Total Price}-{Item Code}
   *
   */
  si: string;

  /**
   * The currency code must be set as a three letter code (Example: JPY, USD).
   *
   * When omitted, it defaults to JPY
   */
  currency: string;

  /**
   * The type parameter is optional. There are two legal values for the type parameter:
   * type=image
   * type=text
   * Defaults to: text/plain
   */
  type: string;
};

export default class A8Plugin implements AnalyticsPlugin {
  name = Integration.A8;
  category = IntegrationCategory.Advertising;
  type = AnalyticsPluginType.destination;
  version = "0.1.0";

  imgId = "os-a8";

  isLoadable() {
    return true;
  }

  async load(): Promise<void> {}

  async unload(): Promise<void> {
    const img = document.getElementById(this.imgId);
    img?.remove?.();
  }

  isLoaded() {
    return true;
  }

  async track(context: Context) {
    const { event, properties } = context.event;

    if (!properties || !properties.referrerAttribution?.a8) {
      return context;
    }

    /**
     *
     * This pixel integration contains hard coded values for the purchase
     * value becuase there's a deal on a per successful event.
     *
     * For every purchase, the advertiser gets a fixed amount, regardless
     * if the class is $1.
     *
     */
    switch (event) {
      case "user-created":
        await this.sendRegistration(properties);
        break;
      case "Order Completed":
        await this.sendConversion(properties);
        break;
      default:
        return context;
    }

    return context;
  }

  async sendRegistration(properties: EventProperties): Promise<void> {
    const payload = {
      a8: properties.referrerAttribution?.a8,
      pid: "s00000025441001",
      so: properties.loggedOutUserExperimentUid,
      si: "1500-1-1500-a8account",
      currency: "JPY",
      type: "text",
    };

    const src = this.buildUrl(payload);
    await injectImg(this.imgId, src, this.name);
  }

  async sendConversion(properties: EventProperties): Promise<void> {
    const payload = {
      a8: properties.referrerAttribution?.a8,
      pid: "s00000025441001",
      so: properties?.order_id,
      si: "3500-1-3500-a8experience",
      currency: "JPY",
      type: "text",
    };

    const src = this.buildUrl(payload);
    await injectImg(this.imgId, src, this.name);
  }

  buildUrl(payload: PixelEventPayload): string {
    return addParamsToUrl("https://px.a8.net/a8fly/earnings", payload);
  }
}
