/** @jsx jsxPragma */

import React from "react";
import { v4 } from "uuid";

import jsxPragma from "./jsxPragma";

import type { SystemStyleObject } from "@styled-system/css";

export interface CurvedTopperProps {
  curveWidth?: number;
  curveHeight?: number;
  numPoints?: number;
  color?: string;
  animate?: boolean;
  inverted?: boolean;
  sx?: SystemStyleObject;
}

export default function CurvedTopper({
  color = "white",
  curveWidth = 800,
  curveHeight = 40,
  numPoints = 2,
  animate = true,
  inverted = false,
  sx,
}: CurvedTopperProps) {
  const [uid, setUid] = React.useState("");
  const points = React.useMemo(
    () => getPoints(curveWidth, curveHeight, numPoints),
    [curveWidth, curveHeight, numPoints]
  );
  const path = inverted
    ? `M 0 0 L 0 ${curveHeight / 2} ${points} L ${curveWidth} 0 L 0 0`
    : `M 0 ${curveHeight} L 0 ${
        curveHeight / 2
      } ${points} L ${curveWidth} ${curveHeight}`;

  React.useEffect(() => {
    if (!uid) {
      setUid(v4());
    }
  }, [uid]);

  return (
    <svg
      // @ts-ignore
      // eslint-disable-next-line
      sx={{
        display: "block",
        width: "100%",
        // TODO(cp): Why is there sometimes a blank space at the bottom to make this necessary?
        marginBottom: -1,
        height: curveHeight,
        color,
        ...sx,
      }}
    >
      <pattern
        id={`curve-${uid}`}
        x="0"
        y="0"
        width={curveWidth}
        height="100%"
        patternUnits="userSpaceOnUse"
      >
        <path fill="currentColor" d={path} />
        {animate && (
          <animate
            attributeName="x"
            values={`0;${curveWidth}`}
            dur="10s"
            repeatCount="indefinite"
          />
        )}
      </pattern>

      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill={`url(#curve-${uid}`}
      ></rect>
    </svg>
  );
}

function getPoints(width: number, height: number, pointCount: number) {
  const distBetweenPoints = width / pointCount;
  let pointString = "";

  for (let i = 0; i < pointCount; i++) {
    const x = (i / pointCount) * width;
    const y = height / 2;

    pointString += `Q ${x + distBetweenPoints / 2} ${
      i % 2 == 0 ? 0 : height
    }, ${x + distBetweenPoints} ${y} `;
  }

  return pointString.trim();
}
