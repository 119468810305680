import React from "react";

import { setPerformanceMonitoringState } from "../../lib/performance";
import pkg from "../../lib/pkg";
import { useAnalyticsContext } from "../../providers/AnalyticsContext";
import { usePageContext } from "../../providers/PageContext";
import useTrackEvent from "../useTrackEvent";

export default function usePerformanceObservers(): void {
  const { initialized } = useAnalyticsContext();
  const page = usePageContext();
  const track = useTrackEvent();

  React.useEffect(() => {
    if (!pkg.performance || !initialized || !page.ready) {
      return;
    }

    setPerformanceMonitoringState(track, page);
  }, [initialized, page, track]);
}
