/**
 * WARNING: modifying any of these functions can negatively impact the SEO
 * of MANY activity URLs. For bug fixes and modified constraints on activity slugs,
 * be sure to update test files and to tag the owning team for review.
 */

import { Component } from "@outschool/ownership-areas";
import { pathToUrl } from "./config";
import { addParamsToUrl } from "./utils";
import type { UrlParams } from "./utils";

export interface PathFromActivityParams {
  uid: string;
  isClub: boolean;
  slug_id: string;
  title: string;
}

interface PathFromActivitiesRowParams {
  is_club: boolean;
  slug_id: string;
  title: string | null;
}

interface PathFromActivitySectionParams {
  uid: string;
}

const ACTIVITY_TITLE_MAX_LENGTH = 80;
const VALID_ACTIVITY_SLUG = /^([a-z0-9]+(-){1})+[a-zA-Z0-9]{8}$/;

export function createActivityPath(
  activityTitle: string,
  activitySlugId: string,
  activityIsClub: boolean,
  query?: UrlParams | null
): string {
  try {
    if (
      !activityTitle ||
      !activitySlugId ||
      typeof activityIsClub === "undefined"
    ) {
      throw new Error("title and slugId are required");
    }

    let path = activityIsClub ? "/groups" : "/classes";
    const slug = createActivitySlug(activityTitle, activitySlugId);

    path = `${path}/${slug}`;

    if (query) {
      path = addParamsToUrl(path, query);
    }

    return path;
  } catch (error) {
    OsPlatform.captureError(error, {
      component: Component.SEOTaxonomy,
      tags: { package: "routes" },
      extra: { activityTitle, activitySlugId, activityIsClub },
    });
    return "";
  }
}

export function createActivityUrl(
  activityTitle: string,
  activitySlugId: string,
  activityIsClub: boolean,
  query?: UrlParams,
  locale?: string
): string {
  return pathToUrl(
    createActivityPath(activityTitle, activitySlugId, activityIsClub, query),
    locale ? locale : ""
  );
}

export function createPathWithActivity(
  activity: PathFromActivityParams,
  section?: PathFromActivitySectionParams | null,
  query?: UrlParams | null
): string {
  const { isClub, is_club, slug_id: slugId, title } = activity as any;

  if (!!section) {
    const sectionUid = typeof section === "string" ? section : section.uid;

    if (!!query) {
      query.sectionUid = sectionUid;
    } else {
      query = { sectionUid };
    }
  }

  return createActivityPath(title, slugId, isClub ?? is_club, query);
}

export function createUrlWithActivity(
  activity: PathFromActivityParams,
  section?: PathFromActivitySectionParams,
  query?: UrlParams,
  locale?: string
): string;
export function createUrlWithActivity(
  activity: PathFromActivitiesRowParams,
  section?: PathFromActivitySectionParams,
  query?: UrlParams,
  locale?: string
): string;
export function createUrlWithActivity(
  activity: PathFromActivityParams & PathFromActivitiesRowParams,
  section?: PathFromActivitySectionParams,
  query?: UrlParams,
  locale?: string
): string {
  return pathToUrl(
    createPathWithActivity(
      { ...activity, isClub: activity.isClub ?? activity.is_club },
      section,
      query
    ),
    locale
  );
}

export function createActivitySlug(
  activityTitle: string,
  activitySlugId: string
): string {
  const segments = activityTitle.split("|");

  let trailingSegment: string | undefined;
  if (segments.length > 1) {
    trailingSegment = segments.pop();
  }

  let title = segments.join(" ");

  title = title
    .toLowerCase()
    .replace(/[^a-z0-9- ]/g, "")
    .replace(/\s{1,}/g, "-")
    .replace(/-{2,}/g, "-")
    .replace(/(^-|-$)/g, "");

  if (!title.length && !!trailingSegment) {
    return createActivitySlug(trailingSegment, activitySlugId);
  }

  if (title.length > ACTIVITY_TITLE_MAX_LENGTH) {
    title = title.substring(0, ACTIVITY_TITLE_MAX_LENGTH).replace(/-$/g, "");
  }

  const slug = `${title}-${activitySlugId}`;

  if (!isValidActivitySlug(slug)) {
    const message = `Failed to create slug from: ${activityTitle}, ${activitySlugId}`;
    throw new Error(message);
  }

  return slug;
}

export function getActivitySlugIdFromSlug(slug: string): string {
  return slug.substring(slug.length - 8, slug.length);
}

export function getActivityTitleFromSlug(slug: string): string {
  const slugId = getActivitySlugIdFromSlug(slug);
  return slug.replace(`-${slugId}`, "");
}

export function getActivityFromSlug(slug: string): {
  slugId: string;
  title: string;
} | null {
  if (!VALID_ACTIVITY_SLUG.test(slug)) {
    return null;
  }

  return {
    slugId: getActivitySlugIdFromSlug(slug),
    title: getActivityTitleFromSlug(slug),
  };
}

export function isValidActivitySlug(slug: string): boolean {
  if (!VALID_ACTIVITY_SLUG.test(slug)) {
    return false;
  }

  const title = getActivityTitleFromSlug(slug);

  if (title.length > ACTIVITY_TITLE_MAX_LENGTH) {
    return false;
  }

  return true;
}
