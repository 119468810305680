import React from "react";

import { TrackedButton, TrackedButtonProps } from "./TrackedButton";

const FB_COLOR = "#4267b2";
const FB_COLOR_HOVER = "#3b5c9f";
const FB_COLOR_ACTIVE = "#375694";

export type FacebookButtonProps = TrackedButtonProps;

const FacebookButton = (
  { sx, ...props }: TrackedButtonProps,
  ref: React.Ref<any>
) => (
  <TrackedButton
    ref={ref}
    color="inherit"
    sx={[
      {
        color: "common.white",
        borderColor: FB_COLOR,
        backgroundColor: FB_COLOR,
        "&:hover:not(:disabled), &:focus:not(:disabled)": {
          color: "common.white",
          borderColor: FB_COLOR_HOVER,
          backgroundColor: FB_COLOR_HOVER,
          textDecoration: "none",
        },
        "&:active:not(:disabled)": {
          color: "common.white",
          borderColor: FB_COLOR_ACTIVE,
          backgroundColor: FB_COLOR_ACTIVE,
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  />
);

export default React.forwardRef(FacebookButton);
