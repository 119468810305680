import {
  Components,
  Theme,
  accordionClasses,
  accordionSummaryClasses,
} from "@mui/material";

export const ACCORDION_THEME: Components<Theme>["MuiAccordion"] = {
  styleOverrides: {
    root: {
      [`&.${accordionClasses.expanded}`]: {
        margin: 0,
        "::before": {
          opacity: 1,
        },
        "& + &": {
          "&::before": {
            display: "unset",
            opacity: 1,
          },
        },
      },
    },
  },
};

export const ACCORDION_SUMMARY_THEME: Components<Theme>["MuiAccordionSummary"] =
  {
    styleOverrides: {
      root: {
        // fix default use of spacing() in MUI's base style
        padding: 0,

        [`&.${accordionSummaryClasses.expanded}`]: {
          minHeight: "auto", // has default of 64px
        },
      },
      content: ({ theme }: { theme: Theme }) => ({
        transition: "none", // removing the margin transition
        margin: theme.spacing(12, 0),

        [`&.${accordionSummaryClasses.expanded}`]: {
          margin: theme.spacing(12, 0), // keeping the margin the same for both states
        },
      }),
      expandIconWrapper: { color: "unset" },
    },
  };

export const ACCORDION_DETAILS_THEME: Components<Theme>["MuiAccordionDetails"] =
  {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        // fix default use of spacing() in MUI's base style
        padding: theme.spacing(8, 0, 16),
      }),
    },
  };
