// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box } from "@outschool/backpack";
import { ExternalLink } from "@outschool/ui-components-shared";
import { loginUrl } from "@outschool/routes";
import React from "react";

interface Props {
  provider?: string;
}

const LearnerSocialAccountLinkFailed: React.FC<Props> = ({ provider }) => {
  return (
    <Box>
      Sorry, we {"can't"} connect that {provider} account as the email is
      currently associated with an adult account. Please try a different{" "}
      {provider} account or log in as an adult at{" "}
      <ExternalLink url={loginUrl()}>{loginUrl()}</ExternalLink>
    </Box>
  );
};

export default LearnerSocialAccountLinkFailed;
