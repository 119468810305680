import { Box, Icon, IconProps, SxProps } from "@outschool/backpack";
import { faSpinner } from "@outschool/icons";
import React, { useEffect, useState } from "react";

export const LoadingIcon = (
  props: Omit<IconProps, "icon" | "fixedWidth" | "spin">
) => <Icon icon={faSpinner} fixedWidth spin {...props} />;

export interface LoadingProps {
  /** Delay in milliseconds before the loader will show. */
  delay?: number;
  /** Expands the container around the spinner to 100vh. */
  fullHeight?: boolean;
  sx?: SxProps;
  iconSx?: SxProps;
  [prop: string]: any;
}

/**
 * A basic loading spinner.
 */
const Loading: React.FC<LoadingProps> = ({
  delay,
  fullHeight = false,
  sx,
  iconSx,
  ...rest
}) => {
  const [show, setShow] = useState(delay ? false : true);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (delay) {
      timeoutId = setTimeout(() => setShow(true), delay);
    }
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      // @ts-ignore
      sx={[
        {
          color: "#AAA",
          textAlign: "center",
          marginY: "3em",
          ...(fullHeight && {
            height: "100vh",
          }),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {show && (
        <LoadingIcon
          sx={[
            { fontSize: "3em" },
            ...(Array.isArray(iconSx) ? iconSx : [iconSx]),
          ]}
        />
      )}
    </Box>
  );
};

export const LoadingModal = () => <Loading sx={{ flexGrow: 1 }} />;

export const LoadingInline = () => <Loading sx={{ marginY: "1em" }} />;

export default Loading;
