// prettier-ignore
export function dataIdFromObject(x: { __typename: string; uid: string; }): string;
export function dataIdFromObject(x: { __typename: string; id: string }): string;
export function dataIdFromObject(x: unknown): string | undefined;

export function dataIdFromObject(obj: Readonly<any>): string | undefined {
  if (obj.__typename) {
    if (obj.uid) {
      return obj.uid + obj.__typename;
    } else if (obj.id) {
      return obj.id + obj.__typename;
    }
  }
  return undefined;
}
