import { gql } from "@outschool/ui-apollo";

export default gql`
  query RandomCategoriesByFacet($facet: String, $limit: Int) {
    randomCategoriesByFacet(facet: $facet, limit: $limit) {
      uid
      basePath
      nameTitleCased
      slug
    }
  }
`;
