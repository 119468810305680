import type { Context } from "../types";

import { addParamsToUrl } from "@outschool/routes";

import { injectImg } from "../lib/util";
import {
  Integration,
  IntegrationCategory,
} from "../providers/AnalyticsContext";
import { AnalyticsPlugin, AnalyticsPluginType } from ".";

type PixelEventPayload = {
  Action: "SALE";
  // This is required and is any unique number for the order (used to prevent duplicate attribution).
  UDF1: string;
  // total_price: This is required and represents the total value of the order.
  udf2: string;
  // slshop_id: this is a unique identifier provided from the SocialLadder team.
  udf3: "OUTSCHOOL";
  // slquantity: this is required and is an integer value representing the number of units (if this information is not available please set it to 1).
  udf4: string;
  // slpromocode: This is optional - if discount codes or promo codes are used it can be set here.
  udf5?: string;
};

export default class SocialLadderPlugin implements AnalyticsPlugin {
  name = Integration.SocialLadder;
  category = IntegrationCategory.Advertising;
  type = AnalyticsPluginType.destination;
  version = "0.1.0";

  imgId = "os-socialladdder";

  isLoadable() {
    return true;
  }

  async load(): Promise<void> {}

  async unload(): Promise<void> {
    const img = document.getElementById(this.imgId);
    img?.parentNode?.removeChild(img);
  }

  isLoaded() {
    return true;
  }

  async track(context: Context) {
    const eventName = context.event.event;
    let payload: PixelEventPayload;

    switch (eventName) {
      case "Order Completed":
        const { properties } = context.event;

        payload = {
          Action: "SALE",
          UDF1: String(properties?.order_id),
          udf2: String(properties?.total),
          udf3: "OUTSCHOOL",
          udf4: String(properties?.products?.length),
        };
        if (!!properties?.coupon) {
          payload["udf5"] = String(properties?.coupon);
        }

        break;
      default:
        return context;
    }

    await this.sendPixelEvent(payload);

    return context;
  }

  async sendPixelEvent(payload: PixelEventPayload): Promise<void> {
    const src = this.buildUrl(payload);

    await injectImg(this.imgId, src, this.name);
  }
  //
  buildUrl(payload: PixelEventPayload): string {
    return addParamsToUrl(
      "https://socialladder.rkiapps.com/socialladderapi/api/v1/campaign/track",
      payload
    );
  }
}
