import { centsToNumericDollarString } from "@outschool/localization";
import {
  isValidEmail as isEmail,
  isValidEmailOrUsername as isEmailOrUsername,
  isTooLongPassword,
  isValidPassword,
  passwordStrengthFeedbackMessage,
  passwordTooLongFeedbackMessage,
} from "@outschool/text";
import * as Time from "@outschool/time";
import { dayjs } from "@outschool/time";
import compact from "lodash/compact";
import findKey from "lodash/findKey";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import mapValues from "lodash/mapValues";
import noop from "lodash/noop";
import some from "lodash/some";
import startCase from "lodash/startCase";
import React from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

const DATE_FORMAT = "YYYY-MM-DD";

/**
 * In this file, T means the type of the form, V means the type of a given member of that form.
 */

type FieldValue<V> = V;
export type FieldError = string | null;

type FieldValidator<V> = (
  value: FieldValue<V>,
  fieldLabel: string
) => FieldError;

export type FieldDefinition<V> = {
  label?: string;
  value?: FieldValue<V>;
  onChange?: (value: FieldValue<V>, event: any) => void;
  // Be careful when using onBlur, it can be buggy on Windows Firefox.
  // ref: https://outschool.atlassian.net/browse/EP-360
  onBlur?: (value: FieldValue<V>, event: any) => void;
  getValue?: (event: V | React.ChangeEvent<HTMLInputElement>) => FieldValue<V>;
  validators?: FieldValidator<V>[];
  validateOnChange?: boolean;
};

type FieldDefinitions<T> = {
  [K in keyof T]: FieldDefinition<T[K]>;
};

export type Field<V> = {
  id: string;
  key: string;
  value?: FieldValue<V>;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  disabled?: boolean;
  ref: React.MutableRefObject<any>;
};

type Fields<T> = { [K in keyof T]: Field<T[K]> };

type Setter<V> = (value: FieldValue<V>) => void;
type Setters<T> = { [K in keyof T]: Setter<T[K]> };

type FormValues<T> = { [K in keyof T]: FieldValue<T[K]> };
type FormErrors<T> = { [K in keyof T]?: FieldError[] | null };
type FormValuesKeys<T> = keyof FormValues<T>;

type State<T> = {
  values: FormValues<T>;
  errors: FormErrors<T>;
  touched: { [K in keyof T]?: boolean };
  refs: { [K in keyof T]: React.MutableRefObject<any> };
};
type RefKeys<T> = keyof T;

export type FormState<T> = State<T> & {
  fields: Fields<T>;
  validateAll: () => { errors: FormErrors<T>; hasErrors: boolean };
  focusFirstWithError: () => boolean;
  setters: Setters<T>;
};

type FieldChangedAction<V> = {
  type: "fieldChanged";
  fieldKey: string;
  fieldDefinition: FieldDefinition<V>;
  value: FieldValue<V>;
};

type FieldBlurredAction<V> = {
  type: "fieldBlurred";
  fieldKey: string;
  fieldDefinition: FieldDefinition<V>;
};

type UpdateValuesAction<T> = {
  type: "updateValues";
  values: FormValues<T>;
};

type UpdateErrorsAction<T> = {
  type: "updateErrors";
  errors: FormErrors<T>;
};

type Action<T> =
  | FieldChangedAction<T[keyof T]>
  | FieldBlurredAction<T[keyof T]>
  | UpdateValuesAction<T>
  | UpdateErrorsAction<T>;

function formStateReducer<T>(state: State<T>, action: Action<T>): State<T> {
  switch (action.type) {
    case "fieldChanged": {
      const values = {
        ...state.values,
        [action.fieldKey]: action.value,
      };
      return {
        ...state,
        values,
        errors: {
          ...state.errors,
          [action.fieldKey]: action.fieldDefinition.validateOnChange
            ? validate(action.fieldKey, action.fieldDefinition, action.value)
            : [],
        },
      };
    }
    case "fieldBlurred": {
      return {
        ...state,
        touched: {
          ...state.touched,
          [action.fieldKey]: true,
        },
        errors: {
          ...state.errors,
          [action.fieldKey]: validate(
            action.fieldKey,
            action.fieldDefinition,
            state.values[action.fieldKey as FormValuesKeys<T>]
          ),
        },
      };
    }
    case "updateValues": {
      return isEqual(state.values, action.values)
        ? state
        : { ...state, values: action.values };
    }
    case "updateErrors": {
      return isEqual(state.errors, action.errors)
        ? state
        : {
            ...state,
            errors: action.errors,
          };
    }
  }
}

type FormOptions<T> = {
  onFieldChange?: (p: { fieldKey: string; value: T[keyof T] }) => void;
  onFieldBlur?: (p: { fieldKey: string }) => void;
  validateInitialValues?: boolean;
  disabled?: boolean;
};

/**
 * @deprecated Please use react-hook-form instead
 */
export function useFormState<T>(
  fieldDefinitions: FieldDefinitions<T>,
  {
    onFieldChange = noop,
    onFieldBlur = noop,
    validateInitialValues = true,
    disabled,
  }: FormOptions<T> = {}
): FormState<T> {
  const fieldValues = mapValues(fieldDefinitions, "value") as FormValues<T>;
  const formId = React.useMemo(() => {
    const id = Math.floor(Math.random() * 10000);
    return `frm-${id}`;
  }, []);
  const [{ values, errors, touched, refs }, dispatch] = React.useReducer<
    React.Reducer<State<T>, Action<T>>
  >(formStateReducer, {
    values: fieldValues,
    errors: !validateInitialValues
      ? {}
      : (mapValues(fieldDefinitions, (fieldDefinition, fieldKey) =>
          fieldValues[fieldKey as FormValuesKeys<T>]
            ? validate(
                fieldKey,
                fieldDefinition,
                fieldValues[fieldKey as FormValuesKeys<T>]
              )
            : []
        ) as FormErrors<T>),
    touched: {},
    refs: mapValues(fieldDefinitions, () => React.createRef()),
  });

  useDeepCompareEffect(() => {
    dispatch({ type: "updateValues", values: fieldValues });
  }, [fieldValues]);

  const fields = mapValues(
    fieldDefinitions,
    <V extends T[keyof T]>(
      fieldDefinition: FieldDefinition<V>,
      fieldKey: string
    ) => {
      const { getValue = defaultGetValue, onChange, onBlur } = fieldDefinition;
      return {
        key: fieldKey,
        id: `${formId}-${fieldKey}`,
        value: values[fieldKey as FormValuesKeys<T>],
        ref: refs[fieldKey as RefKeys<T>],
        onChange(event: V | React.ChangeEvent<HTMLInputElement>) {
          const value = getValue(event);
          onChange && onChange(value, event);
          dispatch({ type: "fieldChanged", fieldDefinition, fieldKey, value });
          onFieldChange({ fieldKey, value });
        },

        onBlur(event: V | React.ChangeEvent<HTMLInputElement>) {
          const value = getValue(event);
          onBlur && onBlur(value, event);
          dispatch({ type: "fieldBlurred", fieldDefinition, fieldKey });
          onFieldBlur({ fieldKey });
        },
        disabled,
      } as unknown as Field<T>;
    }
  ) as unknown as Fields<T>;

  const setters = mapValues(
    fieldDefinitions,
    <V extends T[keyof T]>(
      fieldDefinition: FieldDefinition<V>,
      fieldKey: string
    ) => {
      return (value: V) =>
        dispatch({ type: "fieldChanged", fieldDefinition, fieldKey, value });
    }
  ) as unknown as Setters<T>;

  return {
    fields,
    values,
    errors,
    touched,
    refs,
    setters,
    validateAll() {
      const errors = mapValues(fieldDefinitions, (fieldDefinition, fieldKey) =>
        validate(
          fieldKey,
          fieldDefinition,
          values[fieldKey as FormValuesKeys<T>]
        )
      );

      dispatch({ type: "updateErrors", errors });

      return {
        errors,
        hasErrors: some(errors, fieldErrors => !isEmpty(fieldErrors)),
      };
    },
    focusFirstWithError(): boolean {
      const fieldKey = findKey(errors, fieldErrors => !isEmpty(fieldErrors));
      if (fieldKey && refs[fieldKey as RefKeys<T>]?.current?.focus) {
        refs[fieldKey as RefKeys<T>].current.focus();
        return true;
      }
      return false;
    },
  };
}

function isChangeEvent<V>(
  event: V | React.ChangeEvent<HTMLInputElement>
): event is React.ChangeEvent<HTMLInputElement> {
  return (event as any)?.currentTarget;
}

function defaultGetValue<V>(
  event: V | React.ChangeEvent<HTMLInputElement>
): FieldValue<V> {
  /* obviously, the unmodified onChange can only work with properties of type string.  No way to check that
     at compiled time */
  return isChangeEvent(event)
    ? (event.currentTarget.value as unknown as V)
    : event;
}

function validate<V>(
  fieldKey: string,
  fieldDefinition: FieldDefinition<V>,
  value: FieldValue<V>
) {
  const { validators } = fieldDefinition;
  const label = fieldDefinition.label ?? startCase(fieldKey);
  return !validators || validators.length === 0
    ? null
    : compact(validators.map(validate => validate(value, label)));
}

function isEmptyField<V>(value: FieldValue<V>) {
  if (isArray(value) || isObject(value)) {
    return isEmpty(value);
  }

  return !value;
}

export function required<V>(messageFn = () => "Required") {
  return (value: FieldValue<V>) => (isEmptyField(value) ? messageFn() : null);
}

export function noUrl<V extends string>(
  messageFn = () => "URLs are not allowed"
) {
  return (value: V) => (/:\/\//i.test(value) ? messageFn() : null);
}

export function minLength(
  min: number,
  messageFn = (label: string, min: number) =>
    `${label} must be at least ${min} characters`
) {
  return (value: string, label: string) =>
    (value || "").length < min ? messageFn(label, min) : null;
}

export function maxLength(
  max: number,
  messageFn = (label: string, max: number) =>
    `${label} must be at most ${max} characters`
) {
  return (value: string, label: string) =>
    (value || "").length > max ? messageFn(label, max) : null;
}

export function maxValue(
  max: number,
  messageFn = (label: string, max: number) =>
    `${label} must be no more than ${max}`
) {
  return (value: number, label: string) =>
    value > max ? messageFn(label, max) : null;
}

export function isNotNaN(
  messageFn = (label: string) => `${label} must be a valid number.`
) {
  return (value: number, label: string) => {
    return isNaN(value) ? messageFn(label) : null;
  };
}

export function minValue(
  min: number,
  messageFn = (label: string, min: number) => `${label} must be at least ${min}`
) {
  return (value: number, label: string) =>
    value < min ? messageFn(label, min) : null;
}

export function validatePassword(
  messageFn = (_label: string) => passwordStrengthFeedbackMessage,
  messageFnTooLong = (_label: string) => passwordTooLongFeedbackMessage
) {
  return (value: string, label: string) => {
    if (typeof value !== "string") {
      return null;
    }
    if (isTooLongPassword(value)) {
      return messageFnTooLong(label);
    }
    if (!isValidPassword(value)) {
      return messageFn(label);
    }
    return null;
  };
}

export function mustMatch(
  getValueToMatch: () => string,
  messageFn = (_label: string) => "Passwords must match"
) {
  return (value: string, label: string) => {
    const valueToMatch = getValueToMatch();
    return value === valueToMatch ? null : messageFn(label);
  };
}

export function isStrictlyValidEmail(email: string): boolean {
  if (!email) {
    false;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function isValidEmail<V>(
  messageFn = (label: string) => `${label} must be an email`
) {
  return (value: FieldValue<V>, label: string) =>
    typeof value === "string" && !isEmail(value) ? messageFn(label) : null;
}

export function isValidEmailOrUsername<V>(
  messageFn = (label: string) => `${label} must be an email or username`
) {
  return (value: FieldValue<V>, label: string) =>
    typeof value === "string" && !isEmailOrUsername(value)
      ? messageFn(label)
      : null;
}

export function isOneOf<V>(options: V[]) {
  const messageFn = (label: string) => `${label} must be one of ${options}`;
  return (value: FieldValue<V>, label: string) =>
    typeof !value && !options.includes(value) ? messageFn(label) : null;
}

export function doesNotContain<V>(
  forbidden: string,
  messageFn = (description: string) => `Cannot contain ${description}`
) {
  return (value: FieldValue<V>, description: string) =>
    typeof value === "string" && value.includes(forbidden)
      ? messageFn(description)
      : null;
}

export function isValidDate(
  dateFmt = "MM/DD/YYYY",
  messageFn = (label: string) => {
    return `${label} must be valid date with format ${dateFmt}`;
  }
) {
  return (value: string, label: string) => {
    return value && !Time.createDayjs(value, dateFmt, true).isValid()
      ? messageFn(label)
      : null;
  };
}

export function isDateWithinRange(
  min: dayjs.Dayjs,
  max: dayjs.Dayjs,
  dateFmt = "MM/DD/YYYY",
  inclusive: boolean,
  messageFn = (label: string) => {
    return `${label} must be after ${min
      .subtract(inclusive ? 1 : 0, "d")
      .format(dateFmt)} and before ${max
      .add(inclusive ? 1 : 0, "d")
      .format(dateFmt)}`;
  }
) {
  return (value: string, label: string) =>
    value &&
    !Time.createDayjs(value, dateFmt).isBetween(
      min,
      max,
      "day",
      inclusive ? "[]" : "()"
    )
      ? messageFn(label)
      : null;
}

export function validateUSD(errorMessage: string) {
  const localeUnsupported = localeWarning();
  return (value: string) => {
    if (localeUnsupported) {
      return localeUnsupported;
    }
    if (isEmptyField(value)) {
      return null;
    }
    if (
      !/^\$?\d+(\.(\d{1,2}))?$/.test(value) &&
      !/^\$?(\d{1,3})(,\d{3})*(\.(\d{1,2}))?$/.test(value)
    ) {
      return errorMessage;
    }
    if (!value) {
      return errorMessage;
    }
    const usd = usdToCents(value);
    if (!Number.isFinite(usd)) {
      return errorMessage;
    } else {
      return null;
    }
  };
}

export function centsToUSD(v: number): string {
  const localeUnsupported = localeWarning();
  if (localeUnsupported) {
    return `$${Number.NaN}`;
  }
  return `$${centsToNumericDollarString(v)}`;
}

export function usdToCents(value: string): number {
  const localeUnsupported = localeWarning();
  if (localeUnsupported) {
    return Number.NaN;
  }
  const withoutDollarSign = value?.startsWith("$") ? value?.slice(1) : value;
  const withoutThousandsSeperators = withoutDollarSign.split(",").join("");
  return Math.floor(Number.parseFloat(withoutThousandsSeperators) * 100);
}

export function dateToString(value: Date): string {
  return Time.createDayjsTz(value, Time.OUTSCHOOL_TIMEZONE).format(DATE_FORMAT);
}

export function stringToDate(value: string): Date {
  return Time.createDayjsTz(
    value,
    Time.OUTSCHOOL_TIMEZONE,
    DATE_FORMAT
  ).toDate();
}

export function validateDate(value: string) {
  return Time.createDayjs(value, DATE_FORMAT).isValid()
    ? null
    : `Invalid date. It must be in the format of ${DATE_FORMAT}`;
}

export function localeWarning(): string | null {
  const expectedLocalizedString = "1,000.01";
  const actualLocalizedString = (1000.01).toLocaleString();
  return expectedLocalizedString !== actualLocalizedString
    ? `Locale is not supported.  Expected "${actualLocalizedString}" to be the same as "${expectedLocalizedString}"`
    : null;
}

export function nullishSet<In, Out>(
  object: In | undefined | null,
  setter: (v: In) => Out
): Out | undefined | null {
  return object === undefined
    ? undefined
    : object === null
    ? null
    : setter(object);
}

export function hasFormErrors<T>(errors: FormErrors<T>) {
  return Object.values(errors).some(
    (fieldErrors: FieldError[] | null) => !isEmpty(fieldErrors)
  );
}
