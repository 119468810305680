/**
 * Client code relies on these exact string values for conditional logic.
 */
export const SESSION_HAS_EXPIRED = "Session has expired";
export const USER_ACCOUNT_NOT_FOUND = "User not found.";
export const CLASS_NO_LONGER_AVAILABLE = "This class is no longer available";
export const USER_ACCOUNT_SUSPENDED = "This account has been suspended.";
export const USER_ACCOUNT_LOCKED =
  "Your account has been locked due to too many failed password attempts.";
export const USER_ACCOUNT_LOCKED_INACTIVE =
  "Your account has been locked due to inactivity.";
export const LEARNER_CLASSROOM_NO_ACCESS =
  "Learner has no access to this classroom";
export const USER_ENROLLMENT_WITHDRAWN = "Enrollment is withdrawn";
export const USER_NOT_ENROLLED_TO_CLASS = "User is not enrolled to class";
export const CANT_REFRESH_TOKEN = "Cannot refresh token.";
export const INCORRECT_INFO =
  "Your account information was entered incorrectly.";
