import {
  Modal,
  ModalContent,
  ModalContentProps,
  ModalProps,
  Theme,
} from "@outschool/backpack";
import React from "react";

type NudgeModalProps = Omit<ModalProps, "children"> &
  Pick<ModalContentProps, "children">;

export function NudgeModal({ children, sx = {}, ...props }: NudgeModalProps) {
  // Position the modal fixed on the bottom. The parent Modal already sets
  // the bottom to 0, so removing the top positions it on the bottom.
  // Square the bottom border corners.
  // Set a max width for the modal above small.
  return (
    <Modal
      {...props}
      sx={[
        (theme: Theme) => ({
          [theme.breakpoints.down("sm")]: { top: "unset" },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <ModalContent
        sx={(theme: Theme) => ({
          position: "relative",
          borderRadius: "1.6rem",
          [theme.breakpoints.down("sm")]: {
            borderRadius: "1.6rem 1.6rem 0 0",
          },
          [theme.breakpoints.up("sm")]: { maxWidth: "480px" },
        })}
      >
        {children}
      </ModalContent>
    </Modal>
  );
}
