import { capitalize, isEmpty } from "lodash";

export interface CategoriesRow {
  name: string;
  name_sentence_cased_override: string | null;
  name_title_cased_override: string | null;
}

/**
 * use this for buttons
 * e.g. "{category}" --> "Art", "iPad", "NFL", "Self-confidence"
 */
export function nameTitleCased(category: CategoriesRow) {
  return (
    category.name_title_cased_override ??
    (category.name === category.name.toLocaleUpperCase()
      ? category.name
      : toTitleCase(category.name))
  );
}

/**
 * use this in the middle of a sentence
 * e.g. "More {category} classes" --> "More art classes", "More iPad classes", "More NFL classes"
 */
export function nameSentenceCased(category: CategoriesRow) {
  return (
    category.name_sentence_cased_override ??
    (category.name === category.name.toLocaleUpperCase()
      ? category.name
      : category.name.toLocaleLowerCase())
  );
}

/**
 * use this at the start of a sentence
 * e.g. "{category} classes" --> "Art classes", "iPad classes", "NFL classes"
 */
export function nameStartSentenceCased(category: CategoriesRow) {
  const titleCase = nameTitleCased(category);
  const sentenceCase = nameSentenceCased(category);
  return titleCase[0] + sentenceCase.slice(1);
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toTitleCase(str: string) {
  // using this instead of _.startCase because lodash removes special characters
  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
  });
}

export function getCategoryPillTitle(term: string = "") {
  return term.replace("For", "for");
}

export function getOnlineClassesPageDescription(inclusion_strategy?: string) {
  if (inclusion_strategy === "taught-in-spanish") {
    return "Discover immersive and engaging Spanish-language classes on Outschool, where your child can learn and improve their language skills while exploring a diverse range of educational topics taught by Spanish-speaking educators.";
  }
  return "Live online classes that make kids excited to learn. Try academic courses, virtual tutoring, fun clubs, and more. As low as $10 per class.";
}

export function reviewCategoryTitle(
  categoryName: string,
  isTop: boolean = false
): string {
  // TODO: refactor to take into account class/age related terms like getOnlineClassesPageTitle
  // to avoid things like "Reviews for classes under $10 for ten year olds classes"
  if (isTop) {
    return "Reviews for top " + categoryName + " classes";
  }
  return "Reviews for " + categoryName + " classes";
}

export function getOnlineClassesPageTitle(
  // TODO: refactor this to take category row as input so we can use category.nameSentenceCased and category.nameTitleCased for more accurate word casing
  term: string = "",
  titleCaseInput: boolean = true,
  inclusionStrategy: string = ""
): string {
  if (titleCaseInput) {
    term = toTitleCase(term).replace("For", "for");
  }
  if (inclusionStrategy === "facets-age") {
    if (!/^Classes/.test(term)) {
      return capitalize(term.replace(" for ", " classes for "));
    } else {
      return term;
    }
  } else if (inclusionStrategy === "facets-age-groups") {
    const ageRange = term.split(" ")[0];
    const category = term.replace(ageRange, "").trim();
    return `${category} classes for ${ageRange}`;
  } else if (containsClassRelatedTerm(term)) {
    return `${capitalizeFirstLetter(term)} for kids`;
  } else if (containsAgeRelatedTerm(term)) {
    return `${capitalizeFirstLetter(term)} classes`;
  } else if (containsPluralTerm(term)) {
    return `Classes about ${term}`;
  } else if (!isEmpty(term) || inclusionStrategy === "facets-popular") {
    return `${capitalizeFirstLetter(term)} classes for kids`;
  } else {
    return "Affordable online classes for kids: academic, tutoring, and more";
  }
}

const synonymsForClasses = [
  "camp",
  "club",
  "lesson",
  "tutor",
  "workshop",
  "tutoring",
  "course",
  "courses",
  "taught",
  "curriculum",
];

const ageRelatedKeywords = [
  "grade",
  "high school",
  "kindergarten",
  "middle school",
];

export function containsAgeRelatedTerm(term: string) {
  return containsTerm(term, ageRelatedKeywords);
}

export function containsClassRelatedTerm(term: string) {
  return containsTerm(term, synonymsForClasses);
}

function containsTerm(term: string, termList: string[]) {
  return termList.some(listTerm => term.toLowerCase().includes(listTerm));
}

export function containsPluralTerm(term: string) {
  // check if term ends with an s
  if (term && term.match(/s$/gi)) {
    term = term.trim().toLowerCase();
    if (["is", "ss"].includes(term.substring(term.length - 2))) {
      return false;
    }
    return true;
  }
  return false;
}
