import { SearchFilters } from "@outschool/gql-backend-generated";
import { simpleRandomId } from "@outschool/ui-utils";
import lodashIsEqual from "lodash/isEqual";
import lodashPickBy from "lodash/pickBy";
import React, { useMemo } from "react";

import { useImpressionTracking } from "./tracking";
import {
  Id,
  TrackingContext,
  TrackingContextValue,
  useTrackingContext,
} from "./TrackingContext";
import { TrackingProperties } from "./TrackingProperties";

interface TrackableParameterProp<T> {
  uid: Id;
  __typename?: T;
}

export type TrackingProps = {
  name: string;
  impressionEventName?: string;
  pageImpressionIdDefined?: string;
  uniqueId: string;
  useLinkTracking?: boolean;
  sharedProperties?: { [key: string]: number | string };
  children: (
    setImpressionNode: React.Dispatch<HTMLElement>,
    trackTouch?: (properties?: TrackingProperties) => void
  ) => React.ReactElement;
  uniqueProperties?: { [key: string]: any };
} & OptionalTrackingProps;

type OptionalTrackingProps = {
  page?: string;
  order?: string;
  filters?: SearchFilters;
  activity?: TrackableParameterProp<"Activity">;
  section?: TrackableParameterProp<"Section"> | null;
  leader?: TrackableParameterProp<"Leader" | "User"> | null;
  parent?: TrackableParameterProp<"Parent" | "User"> & {
    firstPaidEnrollment?: { uid: string; confirmed_at?: Date } | null;
  };
  enrollment?: TrackableParameterProp<"Enrollment">;
  feedback?: TrackableParameterProp<"Feedback">;
};

const MemoizedTrackingContextProvider = React.memo(
  ({
    value,
    children,
  }: {
    value: TrackingContextValue;
    children: React.ReactElement;
  }) => {
    return (
      <TrackingContext.Provider value={value}>
        {children}
      </TrackingContext.Provider>
    );
  },
  (prevProps, nextProps) => {
    return (
      lodashIsEqual(prevProps.value, nextProps.value) &&
      prevProps.children === nextProps.children
    );
  }
);

export function TrackingPage({
  name,
  impressionEventName = undefined,
  pageImpressionIdDefined = simpleRandomId(),
  uniqueId,
  children,
  useLinkTracking = false,
  uniqueProperties = {},
  ...otherProps
}: TrackingProps) {
  const currentContext = useTrackingContext();

  if (Object.keys(currentContext).length !== 0) {
    throw new Error(
      "TrackingPage should only be at the top level of the tracking hierarchy"
    );
  }
  const [impressionNode, setImpressionNode] =
    React.useState<HTMLElement | null>();
  const newTrackingValues = useTrackingValuesFromProps({ ...otherProps });
  const [pageImpressionId] = React.useState<string>(pageImpressionIdDefined);
  const pageTrackingContext: TrackingContextValue = React.useMemo(() => {
    const linkTracking = useLinkTracking || {};
    return {
      ...newTrackingValues,
      pageImpressionId,
      page_name: name,
      ...linkTracking,
    };
  }, [newTrackingValues, pageImpressionId, name, useLinkTracking]);

  useImpressionTracking({
    node: impressionNode,
    trackingLabel: name,
    uniqueId,
    sharedProperties: { ...uniqueProperties, ...pageTrackingContext },
    impressionEventName,
    trackViews: true,
  });

  return (
    <MemoizedTrackingContextProvider value={pageTrackingContext}>
      {children(setImpressionNode)}
    </MemoizedTrackingContextProvider>
  );
}

export function TrackingPageSection({
  name,
  impressionEventName = undefined,
  uniqueId,
  uniqueProperties = {},
  children,
  sharedProperties = {},
  ...otherProps
}: TrackingProps) {
  const currentContext = useTrackingContext();
  if (!currentContext.page_name || currentContext.page_section_name) {
    throw new Error(
      "TrackingPageSection should only be nested below a TrackingPage"
    );
  }
  const [impressionNode, setImpressionNode] = React.useState<HTMLElement>();
  const newTrackingValues = useTrackingValuesFromProps(otherProps);
  const pageSectionTrackingContext = useMemo(
    () => ({
      ...currentContext,
      ...newTrackingValues,
      ...sharedProperties,
      page_section_name: name,
    }),
    [currentContext, newTrackingValues, name, sharedProperties]
  );
  const { trackTouch } = useImpressionTracking({
    node: impressionNode,
    trackingLabel: name,
    uniqueId,
    impressionEventName,
    sharedProperties: { ...pageSectionTrackingContext, ...uniqueProperties },
    trackViews: true,
  });

  return (
    <MemoizedTrackingContextProvider value={pageSectionTrackingContext}>
      {children(setImpressionNode, trackTouch)}
    </MemoizedTrackingContextProvider>
  );
}

export function ConditionalTrackingPageSection({
  children,
  ...props
}: TrackingProps) {
  const trackingContext = useTrackingContext();
  const isPageTracked =
    Boolean(trackingContext) && Boolean(trackingContext.page_name);
  const isPageSectionTracked =
    Boolean(trackingContext) && Boolean(trackingContext.page_section_name);
  return isPageTracked && !isPageSectionTracked ? (
    <TrackingPageSection {...props}>{children}</TrackingPageSection>
  ) : (
    children(() => {})
  );
}

export function TrackingPageSubsection({
  name,
  impressionEventName = undefined,
  uniqueId,
  children,
  ...otherProps
}: TrackingProps) {
  const currentContext = useTrackingContext();
  if (
    !currentContext.page_name ||
    !currentContext.page_section_name ||
    currentContext.page_subsection_name
  ) {
    throw new Error(
      "TrackingPageSubsection should only be nested below a TrackingPageSection"
    );
  }
  const [impressionNode, setImpressionNode] = React.useState<HTMLElement>();
  const newTrackingValues = useTrackingValuesFromProps(otherProps);
  const pageSubSectionTrackingContext = {
    ...currentContext,
    ...newTrackingValues,
    page_subsection_name: name,
  };
  useImpressionTracking({
    node: impressionNode,
    trackingLabel: name,
    uniqueId,
    impressionEventName,
    sharedProperties: pageSubSectionTrackingContext,
    trackViews: true,
  });

  return (
    <MemoizedTrackingContextProvider value={pageSubSectionTrackingContext}>
      {children(setImpressionNode)}
    </MemoizedTrackingContextProvider>
  );
}

export function ConditionalTrackingPageSectionOrSubSection({
  children,
  ...props
}: TrackingProps) {
  const trackingContext = useTrackingContext();
  const isPageTracked =
    Boolean(trackingContext) && Boolean(trackingContext.page_name);
  const isPageSectionTracked =
    Boolean(trackingContext) && Boolean(trackingContext.page_section_name);
  const isPageSubSectionTracked =
    Boolean(trackingContext) && Boolean(trackingContext.page_subsection_name);
  if (!isPageTracked || isPageSubSectionTracked) {
    return children(() => {});
  } else if (!isPageSectionTracked) {
    return <TrackingPageSection {...props}>{children}</TrackingPageSection>;
  } else {
    return (
      <TrackingPageSubsection {...props}>{children}</TrackingPageSubsection>
    );
  }
}

function useTrackingValuesFromProps(
  props: OptionalTrackingProps
): TrackingContextValue {
  const {
    page,
    order,
    filters,
    activity,
    section,
    leader,
    parent,
    enrollment,
    feedback,
  } = props;
  return useMemo(
    () =>
      lodashPickBy({
        page,
        order,
        filters,
        activity_uid: activity?.uid,
        section_uid: section?.uid,
        leader_uid: leader?.uid,
        parent_uid: parent?.uid,
        enrollment_uid: enrollment?.uid,
        feedback_uid: feedback?.uid,
        first_enrollment_purchased_at:
          parent?.firstPaidEnrollment?.confirmed_at,
      }),
    [
      page,
      order,
      filters,
      activity?.uid,
      section?.uid,
      leader?.uid,
      parent?.uid,
      enrollment?.uid,
      feedback?.uid,
      parent?.firstPaidEnrollment?.confirmed_at,
    ]
  );
}
