import { makeLocalePrefix } from "./utils";

type PathFunction = (...args: any[]) => string;

export class BaseRoutes {
  constructor(
    public readonly baseUrl: string,
    public readonly locale?: string
  ) {}

  /**
   * Adds the appropriate host for this this environment to a path (and query
   * string) route.
   * @param path Ex: /homepage?user=asdf
   * @returns Ex: outschool.com/homepage?user=asdf
   */
  url(path: string) {
    return `${this.baseUrl}${makeLocalePrefix(this.locale)}${path}`;
  }
}

/**
 * Helper function that transforms a path-generating function into a URL-
 * generating function. It assumes that the output function will be a member
 * of a `BaseRoutes` subclass.
 * @param pathFunc A function that returns a path string
 * @returns A function that returns a complete URL string
 */
export const makeUrlFunction = <T extends PathFunction>(
  pathFunc: T
): ((...args: Parameters<T>) => string) => {
  return function (this: BaseRoutes, ...args: Parameters<T>) {
    return this.url(pathFunc(...args));
  };
};
