import { CookieKeys } from "@outschool/data-schemas";
import Cookies from "js-cookie";
import React from "react";

import {
  AnalyticsMode,
  AnalyticsModeSource,
} from "../../providers/AnalyticsContext";
import useIpLocation from "../util/useIpLocation";
import useAdSettings from "./useAdSettings";
import useCookieSettings from "./useCookieSettings";
import useFallbackSettings from "./useFallbackSettings";

export type AnalyticsSettings = {
  analyticsMode: AnalyticsMode;
  analyticsModeSource: AnalyticsModeSource;
  analyticsModeSourceOverride: AnalyticsModeSource | null;
  analyticsSettingsLoaded: boolean;
  updateAnalyticsSettings: (peferences: any) => void;
};

const AJS_USER_ID_COOKIE_NAME = "ajs_user_id";
const LOCALE_COOKIE_KEY = "selectedLocale";
const STRIPE_MID_COOKIE_NAME = "__stripe_mid";
const STRIPE_SID_COOKIE_NAME = "__stripe_sid";
const CF_APP_SESSION_COOKIE_NAME = "CF_AppSession";
const CF_AUTHORIZATION_COOKIE_NAME = "CF_Authorization";

const ESSENTIAL_COOKIES = [
  AJS_USER_ID_COOKIE_NAME,
  CookieKeys.AnonymousId,
  CookieKeys.CookiePreferences,
  CookieKeys.EsaSession,
  CookieKeys.LoggedInUserExperimentUid,
  CookieKeys.LoggedOutUserExperimentUid,
  CookieKeys.OsBot,
  CookieKeys.OsRef,
  LOCALE_COOKIE_KEY,
  STRIPE_MID_COOKIE_NAME,
  STRIPE_SID_COOKIE_NAME,
  CF_APP_SESSION_COOKIE_NAME,
  CF_AUTHORIZATION_COOKIE_NAME,
];

/*
 * Check a user's DNSMPI preferences and cookie consent preferences if
 * applicable to the user's location. If a user has preferences set for DNSMPI
 * AND cookies, use the settings mode with lesser permission.
 *
 * In other words, if the user allows all cookies, but has DNSMPI set to true,
 * then honor DNSMPI and clear non-essential cookies.
 *
 * If ui-analytics fails to load after a fixed time limit
 * (see: `./useFallbackSettings`), then the analytics mode source will
 * be set to `Fallback` and the mode set to `Limited`. This allows for the
 * collection of functional events and avoids the risk sending advertising
 * events in GDPR regions if location data fails to load.
 */
export default function useAnalyticsSettings(): AnalyticsSettings {
  const { ipInfoLoaded, isInGDPR } = useIpLocation();
  const { adSettingsLoaded, adSettingsMode } = useAdSettings();
  const { cookieSettingsLoaded, cookieSettingsMode, updateCookieSettings } =
    useCookieSettings();
  const { fallbackSettingsLoaded, fallbackSettingsMode, cancelFallbackTimer } =
    useFallbackSettings();
  const [analyticsMode, setAnalyticsMode] = React.useState<AnalyticsMode>(
    AnalyticsMode.Unknown
  );
  const [analyticsModeSourceOverride, setAnalyticsModeSourceOverride] =
    React.useState<AnalyticsModeSource | null>(null);
  const [analyticsModeSource, setAnalyticsModeSource] =
    React.useState<AnalyticsModeSource>(AnalyticsModeSource.Unknown);

  React.useEffect(() => {
    if (!ipInfoLoaded || analyticsModeSource !== AnalyticsModeSource.Unknown) {
      return;
    }

    if (isInGDPR) {
      setAnalyticsModeSource(AnalyticsModeSource.CookieSettings);
      cancelFallbackTimer();
    } else {
      setAnalyticsModeSource(AnalyticsModeSource.AdSettings);
    }
  }, [analyticsModeSource, cancelFallbackTimer, ipInfoLoaded, isInGDPR]);

  React.useEffect(() => {
    if (
      fallbackSettingsLoaded &&
      analyticsModeSource === AnalyticsModeSource.Unknown
    ) {
      setAnalyticsModeSource(AnalyticsModeSource.FallbackSettings);
    }
  }, [analyticsModeSource, fallbackSettingsLoaded]);

  React.useEffect(() => {
    if (
      analyticsModeSource === AnalyticsModeSource.Unknown ||
      analyticsMode !== AnalyticsMode.Unknown
    ) {
      return;
    }

    if (
      analyticsModeSource === AnalyticsModeSource.CookieSettings &&
      cookieSettingsLoaded
    ) {
      if (
        AnalyticsMode[cookieSettingsMode] === AnalyticsMode.Enabled &&
        adSettingsLoaded &&
        AnalyticsMode[adSettingsMode] === AnalyticsMode.Limited
      ) {
        setAnalyticsMode(AnalyticsMode[adSettingsMode]);
        setAnalyticsModeSourceOverride(AnalyticsModeSource.AdSettings);
      } else {
        setAnalyticsMode(AnalyticsMode[cookieSettingsMode]);
      }
    } else if (
      analyticsModeSource === AnalyticsModeSource.AdSettings &&
      adSettingsLoaded
    ) {
      if (
        AnalyticsMode[adSettingsMode] === AnalyticsMode.Enabled &&
        cookieSettingsLoaded &&
        AnalyticsMode[cookieSettingsMode] === AnalyticsMode.Limited
      ) {
        setAnalyticsMode(AnalyticsMode[cookieSettingsMode]);
        setAnalyticsModeSourceOverride(AnalyticsModeSource.CookieSettings);
      } else {
        setAnalyticsMode(AnalyticsMode[adSettingsMode]);
      }

      cancelFallbackTimer();
    } else if (
      analyticsModeSource === AnalyticsModeSource.FallbackSettings &&
      fallbackSettingsLoaded
    ) {
      cancelFallbackTimer();
      setAnalyticsMode(AnalyticsMode[fallbackSettingsMode]);
    }
  }, [
    adSettingsLoaded,
    adSettingsMode,
    analyticsMode,
    analyticsModeSource,
    cancelFallbackTimer,
    cookieSettingsMode,
    cookieSettingsLoaded,
    fallbackSettingsMode,
    fallbackSettingsLoaded,
  ]);

  const analyticsSettingsLoaded: boolean = React.useMemo(
    () => analyticsMode !== AnalyticsMode.Unknown,
    [analyticsMode]
  );

  const updateAnalyticsSettings = React.useCallback(
    (preferences: boolean) => {
      if (analyticsModeSource === AnalyticsModeSource.CookieSettings) {
        updateCookieSettings(preferences);
      }
    },
    [analyticsModeSource, updateCookieSettings]
  );

  React.useEffect(() => {
    if (!analyticsSettingsLoaded) {
      return;
    }

    if (
      analyticsMode === AnalyticsMode.Limited &&
      analyticsModeSource !== AnalyticsModeSource.FallbackSettings
    ) {
      removeNonEssentialCookies();
    }
  }, [analyticsMode, analyticsModeSource, analyticsSettingsLoaded]);

  return {
    analyticsMode,
    analyticsModeSource,
    analyticsModeSourceOverride,
    analyticsSettingsLoaded,
    updateAnalyticsSettings,
  };
}

function removeNonEssentialCookies() {
  for (const cookieName of Object.keys(Cookies.get())) {
    if (!ESSENTIAL_COOKIES.includes(cookieName)) {
      Cookies.remove(cookieName);
    }
  }
}
