/** @jsx jsxPragma */

import { forwardRef } from "react";

import jsxPragma from "./jsxPragma";
import LegacyBox, { LegacyBoxProps } from "./LegacyBox";

export interface CardProps extends LegacyBoxProps {}

/**
 * Basic Card primitive.
 */
const Card: React.ForwardRefExoticComponent<CardProps> = forwardRef(
  ({ as: Component = LegacyBox, sx, ...rest }, ref) => {
    return (
      <Component
        ref={ref}
        // @ts-ignore
        sx={{
          border: "1px solid",
          borderColor: "cardBorder",
          p: "medium",
          m: "medium",
          borderRadius: "large",
          ...sx,
        }}
        {...rest}
      />
    );
  }
);

export default Card;
