import { isLocalStorageSupported } from "@outschool/local-storage";
import React from "react";
import type { AdSettings } from "../../types";

export enum AdSettingsMode {
  Enabled = "Enabled",
  Limited = "Limited",
  Off = "Off",
  Unknown = "Unknown",
}

const LOCAL_STORAGE_KEY = "DoNotSellMyPersonalInfo";

export default function useAdSettings(): AdSettings {
  const [adSettingsMode, setAdSettingsMode] = React.useState<AdSettingsMode>(
    AdSettingsMode.Unknown
  );
  const [adSettingsLoaded, setAdSettingsLoaded] =
    React.useState<boolean>(false);
  const [adTrackingUsageUidsArray, setAdTrackingUsageUidsArray] =
    React.useState<string[]>([]);
  const [shouldNotSellPersonalInfo, setShouldNotSellPersonalInfo] =
    React.useState<boolean>(isDnsmpiEnabled());

  React.useEffect(() => {
    const mode = resolveAdSettingsMode(shouldNotSellPersonalInfo);
    setAdSettingsMode(mode);
    setAdSettingsLoaded(true);
  }, [shouldNotSellPersonalInfo]);

  const updateDoNotSellMyPersonalInfo = React.useCallback(
    (shouldNotSell: boolean) => {
      setShouldNotSellPersonalInfo(shouldNotSell);
      saveAdSettings(shouldNotSell);
    },
    [setShouldNotSellPersonalInfo]
  );

  const addAdConversionTrackingUsage = React.useCallback(
    (trackingUsageUid: string) => {
      setAdTrackingUsageUidsArray([
        ...adTrackingUsageUidsArray,
        trackingUsageUid,
      ]);
    },
    [setAdTrackingUsageUidsArray, adTrackingUsageUidsArray]
  );

  const removeAdConversionTrackingUsage = React.useCallback(
    (trackingUsageUid: string) => {
      setAdTrackingUsageUidsArray(
        adTrackingUsageUidsArray.filter(uid => uid !== trackingUsageUid)
      );
    },
    [setAdTrackingUsageUidsArray, adTrackingUsageUidsArray]
  );

  return {
    adSettingsLoaded,
    adSettingsMode,
    updateDoNotSellMyPersonalInfo,
    shouldNotSellPersonalInfo,
    addAdConversionTrackingUsage,
    removeAdConversionTrackingUsage,
    isUsingAdConversionTracking: adTrackingUsageUidsArray.length > 0,
  };
}

export function resolveAdSettingsMode(shouldNotSellPersonalInfo: boolean) {
  return shouldNotSellPersonalInfo
    ? AdSettingsMode.Limited
    : AdSettingsMode.Enabled;
}

function saveAdSettings(doNotSellMyPersonalInfo: boolean) {
  const localStorage = isLocalStorageSupported();

  if (!localStorage) {
    return;
  }

  localStorage.setItem(LOCAL_STORAGE_KEY, String(doNotSellMyPersonalInfo));
}

function isDnsmpiEnabled() {
  const localStorage = isLocalStorageSupported();

  if (!localStorage) {
    return false;
  }

  return localStorage.getItem(LOCAL_STORAGE_KEY) === "true";
}
