/** @jsx jsxPragma */

import { responsive } from "@outschool/ui-utils";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import jsxPragma from "./jsxPragma";

const CONTAINER_PADDING = "medium";

type ContainerProps = React.PropsWithChildren<
  {
    size?: string;
    fullBleed?: boolean;
    sx?: SystemStyleObject;
    backgroundColor?: string;
    background?: string;
    backgroundImg?: string;
    overflowHidden?: boolean;
  } & Record<string, any>
>;

const Container = React.forwardRef<unknown, ContainerProps>(
  (
    {
      size = "medium",
      fullBleed,
      sx,
      backgroundImg,
      backgroundColor,
      background,
      overflowHidden,
      ...props
    },
    ref
  ) => {
    const BGImage = backgroundImg
      ? {
          backgroundImage: `url(${backgroundImg})`,
          backgroundPosition: "center 4%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }
      : {};
    return (
      <div
        // @ts-ignore
        // eslint-disable-next-line
        sx={{
          maxWidth: "100%",
          paddingX: fullBleed
            ? responsive({ default: 0, large: CONTAINER_PADDING })
            : CONTAINER_PADDING,
          backgroundColor,
          background,
          overflow: overflowHidden ? "hidden" : "inherit",
          ...BGImage,
        }}
      >
        <div
          // @ts-ignore
          ref={ref}
          // @ts-ignore
          // eslint-disable-next-line
          sx={{
            maxWidth: `container.${size}`,
            width: "100%",
            marginX: "auto",
            ...sx,
          }}
          {...props}
        />
      </div>
    );
  }
);

export default Container;

export const PageContainer = (props: ContainerProps) => (
  // @ts-ignore
  <Container {...props} sx={{ paddingY: "large", ...(props.sx || {}) }} />
);
