import { gql } from "@outschool/ui-apollo";

const CurrentUserAttachmentFragment = gql`
  fragment CurrentUserAttachmentFragment on Attachment {
    uid
    file {
      filename
      mimetype
      size
      url
      thumbUrl
    }
    conversionStatus
    created_at
  }
`;

export const CurrentUserTeacherApplicationFragment = gql`
  fragment CurrentUserTeacherApplicationFragment on TeacherApplication {
    uid
    legal_name
    status
    submitted_at
    reviewed_at
    teaching_experience
    teaching_credentials
    class_types
    online_profiles
    sample_classes
    class_subjects
    age_ranges_taught
    years_of_experience
    how_did_you_hear_about_us
    experience_types
    video {
      ...CurrentUserAttachmentFragment
    }
  }
  ${CurrentUserAttachmentFragment}
`;

export const ChildFragment = gql`
  fragment ChildFragment on Child {
    uid
    name
    age
    birthMonth
    date_of_birth
    birthYear
    email
    avatar
    unlockedAvatars

    pronoun
    intro
    deleted_at
    email_confirmed_at
    optedOutOfPersonalizedRecommendationsAt
    isLoginEnabled
    username
    hasPassword
    attributes {
      uid
      key
      value
    }
  }
`;

export const CurrentUserChildFragment = gql`
  fragment CurrentUserChildFragment on Child {
    ...ChildFragment
  }
  ${ChildFragment}
`;

export const FullCurrentUserFragment = gql`
  fragment PricingOfferFragment on PricingOffer {
    uid
    name
    userLimitCents
    purchaseLimitCents
    totalCapCents
    startAfter
    endBy
    buyerOrgName
    isTotalCapReached
    remainingCapCents
    requirePaymentMethod
    isOfferLimitReached
    userOfferEmail
    discount
    isFinancialAid
  }

  fragment DefaultScheduleFiltersFragment on UserScheduleFilters {
    startAfter
    endBy
    startAfterTime
    endByTime
    dow
  }

  fragment InterfacePreferencesFragment on InterfacePreferences {
    activityFormatDescriptionFlexHidden
    activityFormatDescriptionOngoingHidden
    activityFormatDescriptionOneTimeHidden
    activityFormatDescriptionMultiDayHidden
    defaultScheduleFilters {
      ...DefaultScheduleFiltersFragment
    }
  }

  fragment FullCurrentUserFragment on User {
    uid
    slug_id
    name
    email
    otherEmailDetails {
      uid
      email
      confirmed_at
    }
    isLearnerModeEnabled
    firstPaidEnrollment {
      uid
      confirmed_at
    }
    confirmed_at
    subscribed_at
    email_bounced_at
    default_currency
    phone
    photo
    leader_subscribed_at
    calendar_uid
    intercomUserHash
    payout_email
    owner_of_seller_org_uid
    admin_for_organization_uid
    do_not_sell_my_personal_info
    background_checked_at
    background_check_started_at
    interview_scheduled_at
    interviewed_at
    classroom_training_scheduled_at
    classroom_trained_at
    latestLegalName
    hasCompletedClassContentPoliciesTraining
    hasCompletedSafetyAndPrivacyTraining
    hasCompletedClassContentPoliciesOrgAdminTraining
    hasCompletedSafetyAndPrivacyOrgAdminTraining
    teacher_approved_at
    is_crosslister
    introduction
    created_at
    updated_at
    deleted_at
    suspended_at
    is_admin
    learner_uses_parent_mode
    leader_link
    unreadMessageThreadsCount
    unreadPrivateClassMessageThreadsCount
    roles
    details {
      about
      headline
      countryOfResidence
      introduction
      browserTimeZone
      timeRangeAfterSchool
      timeRangeSchool
      timeRangeWeekend
      socialOptOut
      howFoundOutschool
      howFoundOutschoolOther
      educationApproach
      adminDashboardUrl
      experiments
    }
    locale
    location {
      address
      city
      lat
      lng
      timeZone
      state
    }
    organizationAdmin {
      organization {
        uid
        name
        type
      }
    }
    children {
      ...CurrentUserChildFragment
    }
    video {
      ...CurrentUserAttachmentFragment
    }
    interfacePreferences {
      ...InterfacePreferencesFragment
    }
    isRecordingAutopostEnabled
    schoolClosure {
      application_status
      needs_assistance
    }
    pricingOffer {
      ...PricingOfferFragment
    }
    sellerOrg {
      uid
      name
      leader_link
      currentUserIsOwner
      currentUserIsTeacher
      hasApprovedTeacherAccountForOwner
    }
    latestSellerOrgApplication {
      uid
      status
      approvedAt
      rejectedAt
      contractSignedAt
      adminResponse
    }
    latestTeacherApplication {
      ...CurrentUserTeacherApplicationFragment
    }
    leader {
      uid
      showCalendar
      mainToolbarPinnedTabs
    }
    learnerModeSwitchAuthRequirement
    anonymousId
    wasReferred
    isEducatorBetaTester
    consented_to_coppa_notice_at
    hasVerifiedIdentity
    consent_to_share_class_history
    consent_to_share_favourites
    privacy_state
    profile_link_uid
    hasSubscription
  }
  ${CurrentUserTeacherApplicationFragment}
  ${CurrentUserChildFragment}
  ${CurrentUserAttachmentFragment}
`;

export const currentUserQuery = gql`
  query CurrentUserQuery {
    currentUser {
      ...FullCurrentUserFragment
    }
  }
  ${FullCurrentUserFragment}
`;
