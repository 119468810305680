import { EAST_ASIA_LOCALES, useLocale } from "@outschool/localization";
import { shoppingCartCheckoutPath } from "@outschool/routes";
import { getEsaState, useSession } from "@outschool/ui-auth";
import { useExperiment } from "@outschool/ui-experiments";
import { Screen } from "@outschool/ui-utils";

export function useShoppingCartExperiment() {
  const { currentUser, currentUserHasLoaded } = useSession();
  const esaState = getEsaState();
  const isMobile = Screen.useIsMobile();
  const locale = useLocale();
  const isEastAsia = EAST_ASIA_LOCALES.includes(locale);

  const { variant, trigger } = useExperiment({
    currentUserHasLoaded,
    experimentName: "ShoppingCart3",
  });

  const isEligibleForExperiment =
    !esaState &&
    !isMobile &&
    currentUser &&
    !isEastAsia &&
    !currentUser.organizationAdmin;
  return {
    isEligibleForExperiment,
    shouldShowCart: isEligibleForExperiment && variant === "treatment",
    trigger,
    cartPath: shoppingCartCheckoutPath(),
    variant,
  };
}
