import { Component } from "@outschool/ownership-areas";
import fetch from "cross-fetch";
import jsonStringify from "json-stringify-safe";
import { v4 as uuid } from "uuid";

import { RestProxyPayload } from "./events";

const { doNotTrackEnabled } = require("analytics-plugin-do-not-track");

type RestProxyConfig = {
  endpoint: string;
  errorComponent?: Component;
};

type AnalyticsPluginRestProxyConfig = RestProxyConfig;
type AttributesRestClientConfig = RestProxyConfig;

export type SendEventOptions = {
  skipCredentials?: boolean;
  token?: string;
};

export type SendEventResult = {
  error: Error | null;
  response: Response | null;
  responseText: string | null;
};

class RestProxyClient {
  protected endpoint: string | null;
  protected errorComponent: Component | undefined;
  protected contextLibraryName: string;

  constructor({ endpoint, errorComponent }: RestProxyConfig) {
    this.endpoint = endpoint;
    this.errorComponent = errorComponent;
    this.contextLibraryName = "kafka-server";
  }

  /**
   * Adds to event the common set of properties all events have
   * @param properties
   */
  _applyCommonEventProperties(properties: any) {
    properties.event = properties.event || {};
    properties.event.uuid = uuid();
    properties.event.git_commit_id = OsPlatform.osEnvironment.gitCommit;
    properties.event.context_library_name = this.contextLibraryName;
  }

  _getPropertiesFromPayload(payload: any): RestProxyPayload {
    // TODO fix, this should be 1 level in
    // somehow data looks like this from experiments service
    if (payload.payload != undefined) {
      return payload.payload.properties;
    }

    return payload;
  }

  _getTopicSchemaIdFromPayload(payload: any) {
    const properties = this._getPropertiesFromPayload(payload);

    return properties.topic_schema_id;
  }

  _getTopicFromPayload(payload: any | any[]) {
    let properties: RestProxyPayload;

    if (Array.isArray(payload)) {
      properties = this._getPropertiesFromPayload(payload[0]);
    } else {
      properties = this._getPropertiesFromPayload(payload);
    }

    return properties.topic;
  }

  _createRequestBodyFromPayload(payload: any | any[]) {
    let payloadArray: Array<any>;

    if (Array.isArray(payload)) {
      payloadArray = payload;
    } else {
      payloadArray = [payload];
    }

    const topicSchemaId = this._getTopicSchemaIdFromPayload(payloadArray[0]);

    const records = payloadArray.map(item => {
      const properties = this._getPropertiesFromPayload(item);

      this._applyCommonEventProperties(properties);

      return {
        value: properties.event,
      };
    });

    return {
      value_schema_id: topicSchemaId,
      records,
    };
  }

  /**
   * Client can choose to batch a few events together, if so the input
   * to this method is an array of events
   * @param payload
   */
  async sendEvent(
    payload: any | any[],
    options: SendEventOptions = {}
  ): Promise<SendEventResult> {
    const result: SendEventResult = {
      error: null,
      response: null,
      responseText: null,
    };

    if (doNotTrackEnabled()) {
      // The user has Do Not Track enabled so we return early to prevent sending tracking events
      return result;
    }

    const topic = this._getTopicFromPayload(payload);
    const body = this._createRequestBodyFromPayload(payload);

    const config: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/vnd.kafka.jsonschema.v2+json",
        Accept: "application/vnd.kafka.v2+json",
      },
      body: jsonStringify(body),
    };

    if (options.token) {
      const basicAuth = Buffer.from(options.token).toString("base64");
      config.headers = config.headers || {};
      (config.headers as Record<string, string>)[
        "Authorization"
      ] = `Basic ${basicAuth}`;
    }

    if (!options.skipCredentials) {
      config.credentials = "include";
    }

    try {
      const response = await fetch(`${this.endpoint}/topics/${topic}`, config);

      const { status, statusText } = response;

      if (status < 400) {
        result.response = response;

        return result;
      }

      const text = await response.text();
      result.responseText = text;

      if (status >= 400 && status < 500) {
        console.error("Kafka Rest Proxy Error:", statusText, text);

        return result;
      }

      console.error("Unexpected Kafka Rest Proxy Error:", statusText, text);
    } catch (error) {
      this.reportError(error);
      result.error = error;
    }

    return result;
  }

  reportError(error: Error) {
    OsPlatform.captureError(error, {
      tags: { package: "kafka-rest-proxy-client" },
      component: this.errorComponent,
    });
  }
}
/** analytics.js plugging to send data via Kafka Rest Proxy
 * see https://getanalytics.io/plugins/writing-plugins/
 * an example in github: https://github.com/DavidWells/analytics/blob/master/packages/analytics-plugin-intercom/src/node.js
 * @param payload
 */
function RestProxyAnalyticsPlugin(config: AnalyticsPluginRestProxyConfig) {
  const client = new RestProxyClient(config);
  // return object for analytics to use
  return {
    name: "kafka-rest_proxy",
    config: {},
    // is this signature correct?
    track: (payload: { payload: any }) => {
      // call provider specific event tracking, parameters not typed but they are typed on calls to track method
      client.sendEvent(payload);
    },
  };
}

export {
  RestProxyAnalyticsPlugin,
  AnalyticsPluginRestProxyConfig,
  AttributesRestClientConfig,
  RestProxyConfig,
  RestProxyClient,
  RestProxyPayload,
};
