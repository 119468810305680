import { Image } from "@outschool/backpack";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import InnerBorder, { InnerBorderProps } from "./InnerBorder";

export interface AvatarProps extends InnerBorderProps {
  /** Fully qualified URL to a profile image. */
  src: string;
  /** Alternate text for avatar image; usually a blank string. */
  alt?: string;
  /** Single number that applies as width and height. */
  size?: number;
  /** Apply style props to the wrapper around the avatar image. */
  sx?: SystemStyleObject;
}

/**
 * The Avatar component is used for profile images. Pay attention to the `alt`
 * attribute here. Avatar defaults to an alt tag of blank string, which means it
 * is treated as decorative by screen readers. If the avatar serves a particular
 * purpose make sure the `alt` attribute describes it well.
 */
export default function Avatar({
  src,
  size,
  alt = "",
  sx,
  ...props
}: AvatarProps) {
  return (
    <InnerBorder
      sx={{
        borderRadius: "50%",
        position: "relative",
        overflow: "hidden",
        ...(size ? { width: size, height: size } : {}),
        ...sx,
      }}
      {...props}
    >
      <Image
        src={src}
        width={size}
        height={size}
        sx={{
          objectFit: "cover",
          ...(size ? { width: size, height: size } : {}),
        }}
        alt={alt}
      />
    </InnerBorder>
  );
}
