import {
  DegreeSubject,
  DegreeType,
  TeacherCertificateDiscipline,
  TeacherCertificateState,
} from "@outschool/gql-frontend-generated";
import { TFunction, useTranslation } from "@outschool/localization";

export default function useCredentialString() {
  const { t } = useTranslation(
    "ui-components-website\\src\\translations\\useCredentialString"
  );
  return {
    translateDegreeType(degreeType: DegreeType) {
      return translateDegreeType(t, degreeType);
    },
    translateShortDegreeType(degreeType: DegreeType) {
      return translateShortDegreeType(t, degreeType);
    },
    translateCredentialDiscipline(
      certificateDiscipline: TeacherCertificateDiscipline
    ) {
      return translateCredentialDiscipline(t, certificateDiscipline);
    },
    translateDegreeSubject(degreeSubject: DegreeSubject) {
      return translateDegreeSubject(t, degreeSubject);
    },
    translateCertificateState(certificateState: TeacherCertificateState) {
      return translateCertificateState(t, certificateState);
    },
  };
}

function translateDegreeType(t: TFunction, degreeType: DegreeType): string {
  switch (degreeType) {
    case null:
    case undefined:
      return "";
    case DegreeType.AssociatesDegree:
      return t("Associate's Degree");
    case DegreeType.BachelorsDegree:
      return t("Bachelor's Degree");
    case DegreeType.MastersDegree:
      return t("Master's Degree");
    case DegreeType.DoctoralDegree:
      return t("Doctoral Degree");
    case DegreeType.DoctorOfMedicine:
      return t("Doctor of Medicine");
    case DegreeType.JurisDoctor:
      return t("Juris Doctor");
    default:
      return degreeType;
  }
}

function translateShortDegreeType(
  t: TFunction,
  degreeType: DegreeType
): string {
  switch (degreeType) {
    case null:
    case undefined:
      return "";
    case DegreeType.AssociatesDegree:
      return t("Associates");
    case DegreeType.BachelorsDegree:
      return t("Bachelors");
    case DegreeType.MastersDegree:
      return t("Masters");
    case DegreeType.DoctoralDegree:
      return t("PhD");
    case DegreeType.DoctorOfMedicine:
      return t("MD");
    case DegreeType.JurisDoctor:
      return t("JD");
    default:
      return degreeType;
  }
}

function translateDegreeSubject(
  t: TFunction,
  degreeSubject: DegreeSubject
): string {
  switch (degreeSubject) {
    case DegreeSubject.Accounting:
      return t("Accounting");
    case DegreeSubject.ActuarialScience:
      return t("Actuarial Science");
    case DegreeSubject.Advertising:
      return t("Advertising");
    case DegreeSubject.AerospaceEngineering:
      return t("Aerospace Engineering");
    case DegreeSubject.Agriculture:
      return t("Agriculture");
    case DegreeSubject.AmericanStudies:
      return t("American Studies");
    case DegreeSubject.AnimalScience:
      return t("Animal Science");
    case DegreeSubject.Anthropology:
      return t("Anthropology");
    case DegreeSubject.Architecture:
      return t("Architecture");
    case DegreeSubject.ArtAndDesign:
      return t("Art and Design");
    case DegreeSubject.ArtHistoryCriticismAndConservation:
      return t("Art History, Criticism and Conservation");
    case DegreeSubject.AsianStudiesCivilization:
      return t("Asian Studies/Civilization");
    case DegreeSubject.BehavioralScience:
      return t("Behavioral Science");
    case DegreeSubject.Biochemistry:
      return t("Biochemistry");
    case DegreeSubject.BiologyBiologicalSciences:
      return t("Biology/Biological Sciences");
    case DegreeSubject.BiomedicalSciences:
      return t("Biomedical Sciences");
    case DegreeSubject.BotanyPlantBiology:
      return t("Botany/Plant Biology");
    case DegreeSubject.BusinessAdministrationAndManagement:
      return t("Business Administration and Management");
    case DegreeSubject.BusinessCommerce:
      return t("Business/Commerce");
    case DegreeSubject.ChemicalEngineering:
      return t("Chemical Engineering");
    case DegreeSubject.Chemistry:
      return t("Chemistry");
    case DegreeSubject.CivilEngineering:
      return t("Civil Engineering");
    case DegreeSubject.Communications:
      return t("Communications");
    case DegreeSubject.ComputerEngineering:
      return t("Computer Engineering");
    case DegreeSubject.ComputerScience:
      return t("Computer Science");
    case DegreeSubject.ConstructionManagement:
      return t("Construction Management");
    case DegreeSubject.CriminalJustice:
      return t("Criminal Justice");
    case DegreeSubject.Dance:
      return t("Dance");
    case DegreeSubject.EarlyChildhoodEducation:
      return t("Early Childhood Education");
    case DegreeSubject.Economics:
      return t("Economics");
    case DegreeSubject.Education:
      return t("Education");
    case DegreeSubject.ElectricalEngineering:
      return t("Electrical Engineering");
    case DegreeSubject.ElementaryEducation:
      return t("Elementary Education");
    case DegreeSubject.EngineeringGeneral:
      return t("Engineering (General)");
    case DegreeSubject.EnglishLanguageAndLiterature:
      return t("English Language and Literature");
    case DegreeSubject.EnvironmentalScience:
      return t("Environmental Science");
    case DegreeSubject.EthnicStudies:
      return t("Ethnic Studies");
    case DegreeSubject.ExerciseScience:
      return t("Exercise Science");
    case DegreeSubject.FamilyAndConsumerSciencesHumanSciences:
      return t("Family and Consumer Sciences/Human Sciences");
    case DegreeSubject.FashionMerchandising:
      return t("Fashion Merchandising");
    case DegreeSubject.FilmCinemaVideoStudies:
      return t("Film/Cinema/Video Studies");
    case DegreeSubject.Finance:
      return t("Finance");
    case DegreeSubject.FineStudioArts:
      return t("Fine/Studio Arts");
    case DegreeSubject.ForensicScienceAndTechnology:
      return t("Forensic Science and Technology");
    case DegreeSubject.Forestry:
      return t("Forestry");
    case DegreeSubject.Genetics:
      return t("Genetics");
    case DegreeSubject.GeologyEarthScience:
      return t("Geology/Earth Science");
    case DegreeSubject.GraphicDesign:
      return t("Graphic Design");
    case DegreeSubject.HealthAndPhysicalEducationFitness:
      return t("Health and Physical Education/Fitness");
    case DegreeSubject.HealthProfessionsAndRelatedPrograms:
      return t("Health Professions and Related Programs");
    case DegreeSubject.HealthServicesAlliedHealthHealthSciences:
      return t("Health Services/Allied Health/Health Sciences");
    case DegreeSubject.History:
      return t("History");
    case DegreeSubject.Horticulture:
      return t("Horticulture");
    case DegreeSubject.HospitalAndHealthCareFacilitiesAdministrationManagement:
      return t("Hospital and Health Care Facilities Administration/Management");
    case DegreeSubject.HospitalityManagement:
      return t("Hospitality Management");
    case DegreeSubject.HumanDevelopmentFamilyStudies:
      return t("Human Development Family Studies");
    case DegreeSubject.HumanResourcesManagement:
      return t("Human Resources Management");
    case DegreeSubject.InformationTechnology:
      return t("Information Technology");
    case DegreeSubject.InteriorDesign:
      return t("Interior Design");
    case DegreeSubject.InternationalBusiness:
      return t("International Business");
    case DegreeSubject.InternationalRelations:
      return t("International Relations");
    case DegreeSubject.Journalism:
      return t("Journalism");
    case DegreeSubject.Kinesiology:
      return t("Kinesiology");
    case DegreeSubject.LiberalArtsAndSciences:
      return t("Liberal Arts and Sciences");
    case DegreeSubject.Linguistics:
      return t("Linguistics");
    case DegreeSubject.ManagementInformationSystems:
      return t("Management Information Systems");
    case DegreeSubject.MarineBiology:
      return t("Marine Biology");
    case DegreeSubject.Marketing:
      return t("Marketing");
    case DegreeSubject.MaterialsScience:
      return t("Materials Science");
    case DegreeSubject.Math:
      return t("Mathematics");
    case DegreeSubject.MechanicalEngineering:
      return t("Mechanical Engineering");
    case DegreeSubject.MolecularBiology:
      return t("Molecular Biology");
    case DegreeSubject.Music:
      return t("Music");
    case DegreeSubject.Nursing:
      return t("Nursing");
    case DegreeSubject.NutritionSciences:
      return t("Nutrition Sciences");
    case DegreeSubject.OccupationalTherapy:
      return t("Occupational Therapy");
    case DegreeSubject.OrganizationalLeadership:
      return t("Organizational Leadership");
    case DegreeSubject.Philosophy:
      return t("Philosophy");
    case DegreeSubject.Physics:
      return t("Physics");
    case DegreeSubject.PoliticalScienceAndGovernment:
      return t("Political Science and Government");
    case DegreeSubject.PreMedicinePreMedicalStudies:
      return t("Pre-Medicine/Pre-Medical Studies");
    case DegreeSubject.Psychology:
      return t("Psychology");
    case DegreeSubject.PublicAdministration:
      return t("Public Administration");
    case DegreeSubject.PublicHealth:
      return t("Public Health");
    case DegreeSubject.PublicRelations:
      return t("Public Relations");
    case DegreeSubject.RealEstate:
      return t("Real Estate");
    case DegreeSubject.ReligiousStudies:
      return t("Religious Studies");
    case DegreeSubject.SecondaryEducation:
      return t("Secondary Education");
    case DegreeSubject.SecondarySchoolAdministrationPrincipalship:
      return t("Secondary School Administration/Principalship");
    case DegreeSubject.SocialSciences:
      return t("Social Sciences");
    case DegreeSubject.SocialWork:
      return t("Social Work");
    case DegreeSubject.Sociology:
      return t("Sociology");
    case DegreeSubject.SpanishLanguageAndLiterature:
      return t("Spanish Language and Literature");
    case DegreeSubject.SpecialEducation:
      return t("Special Education");
    case DegreeSubject.SpeechCommunicationAndRhetoric:
      return t("Speech Communication and Rhetoric");
    case DegreeSubject.SpeechLanguagePathologyPathologist:
      return t("Speech-Language Pathology/Pathologist");
    case DegreeSubject.SportsManagement:
      return t("Sports Management");
    case DegreeSubject.SupplyChainManagementLogistics:
      return t("Supply Chain Management/Logistics");
    case DegreeSubject.TheatreArts:
      return t("Theatre Arts");
    case DegreeSubject.UrbanAndRegionalPlanning:
      return t("Urban and Regional Planning");
    case DegreeSubject.UrbanStudies:
      return t("Urban Studies");
    case DegreeSubject.VeterinaryTechnology:
      return t("Veterinary Technology");
    case DegreeSubject.ZoologyAnimalBiology:
      return t("Zoology/Animal Biology");
    case DegreeSubject.Other:
      return t("Other");
    case DegreeSubject.English:
      return t("English");
    case DegreeSubject.ForeignLanguage:
      return t("Foreign Language");
    case DegreeSubject.HealthOrPe:
      return t("Health or PE");
    case DegreeSubject.MusicOrTheaterOrArts:
      return t("Music or Theatre or Arts");
    case DegreeSubject.Science:
      return t("Science");
    default:
      return degreeSubject;
  }
}

function translateCredentialDiscipline(
  t: TFunction,
  certificateDiscipline: TeacherCertificateDiscipline
): string {
  switch (certificateDiscipline) {
    case TeacherCertificateDiscipline.EnglishOrLanguageArts:
      return t("English/Language Arts");
    case TeacherCertificateDiscipline.Mathematics:
      return t("Mathematics");
    case TeacherCertificateDiscipline.ForeignLanguage:
      return t("Foreign Language");
    case TeacherCertificateDiscipline.Science:
      return t("Science");
    case TeacherCertificateDiscipline.SocialStudiesOrHistory:
      return t("Social Studies/History");
    case TeacherCertificateDiscipline.HealthOrPe:
      return t("Health/PE");
    case TeacherCertificateDiscipline.ElementaryEducation:
      return t("Elementary Education");
    case TeacherCertificateDiscipline.SecondaryEducation:
      return t("Secondary Education");
    case TeacherCertificateDiscipline.SpecialEducation:
      return t("Special Education");
    case TeacherCertificateDiscipline.MusicOrTheaterOrArts:
      return t("Music/Theater/Arts");
    case TeacherCertificateDiscipline.EnglishToSpeakersOfOtherLanguages:
      return t("English to Speakers of Other Languages");
    case TeacherCertificateDiscipline.Other:
      return t("Other");
    default:
      return certificateDiscipline;
  }
}

function translateCertificateState(
  t: TFunction,
  state: TeacherCertificateState
): string {
  switch (state) {
    case null:
    case undefined:
      return "";
    case TeacherCertificateState.NotInUs:
      return t("Non-US");
    case TeacherCertificateState.Alabama:
      return t("Alabama");
    case TeacherCertificateState.Alaska:
      return t("Alaska");
    case TeacherCertificateState.Arizona:
      return t("Arizona");
    case TeacherCertificateState.Arkansas:
      return t("Arkansas");
    case TeacherCertificateState.California:
      return t("California");
    case TeacherCertificateState.Colorado:
      return t("Colorado");
    case TeacherCertificateState.Connecticut:
      return t("Connecticut");
    case TeacherCertificateState.Delaware:
      return t("Delaware");
    case TeacherCertificateState.DistrictOfColumbia:
      return t("District of Columbia");
    case TeacherCertificateState.Florida:
      return t("Florida");
    case TeacherCertificateState.Georgia:
      return t("Georgia");
    case TeacherCertificateState.Hawaii:
      return t("Hawaii");
    case TeacherCertificateState.Idaho:
      return t("Idaho");
    case TeacherCertificateState.Illinois:
      return t("Illinois");
    case TeacherCertificateState.Indiana:
      return t("Indiana");
    case TeacherCertificateState.Iowa:
      return t("Iowa");
    case TeacherCertificateState.Kansas:
      return t("Kansas");
    case TeacherCertificateState.Kentucky:
      return t("Kentucky");
    case TeacherCertificateState.Louisiana:
      return t("Louisiana");
    case TeacherCertificateState.Maine:
      return t("Maine");
    case TeacherCertificateState.Maryland:
      return t("Maryland");
    case TeacherCertificateState.Massachusetts:
      return t("Massachusetts");
    case TeacherCertificateState.Michigan:
      return t("Michigan");
    case TeacherCertificateState.Minnesota:
      return t("Minnesota");
    case TeacherCertificateState.Mississippi:
      return t("Mississippi");
    case TeacherCertificateState.Missouri:
      return t("Missouri");
    case TeacherCertificateState.Montana:
      return t("Montana");
    case TeacherCertificateState.Nebraska:
      return t("Nebraska");
    case TeacherCertificateState.Nevada:
      return t("Nevada");
    case TeacherCertificateState.NewHampshire:
      return t("New Hampshire");
    case TeacherCertificateState.NewJersey:
      return t("New Jersey");
    case TeacherCertificateState.NewMexico:
      return t("New Mexico");
    case TeacherCertificateState.NewYork:
      return t("New York");
    case TeacherCertificateState.NorthCarolina:
      return t("North Carolina");
    case TeacherCertificateState.NorthDakota:
      return t("North Dakota");
    case TeacherCertificateState.Ohio:
      return t("Ohio");
    case TeacherCertificateState.Oklahoma:
      return t("Oklahoma");
    case TeacherCertificateState.Oregon:
      return t("Oregon");
    case TeacherCertificateState.Pennsylvania:
      return t("Pennsylvania");
    case TeacherCertificateState.RhodeIsland:
      return t("Rhode Island");
    case TeacherCertificateState.SouthCarolina:
      return t("South Carolina");
    case TeacherCertificateState.SouthDakota:
      return t("South Dakota");
    case TeacherCertificateState.Tennessee:
      return t("Tennessee");
    case TeacherCertificateState.Texas:
      return t("Texas");
    case TeacherCertificateState.Utah:
      return t("Utah");
    case TeacherCertificateState.Vermont:
      return t("Vermont");
    case TeacherCertificateState.Virginia:
      return t("Virginia");
    case TeacherCertificateState.Washington:
      return t("Washington");
    case TeacherCertificateState.WestVirginia:
      return t("West Virginia");
    case TeacherCertificateState.Wisconsin:
      return t("Wisconsin");
    case TeacherCertificateState.Wyoming:
      return t("Wyoming");
    default:
      return state;
  }
}
