import { Icon, IconProps, visuallyHidden } from "@outschool/backpack";
import { IconDefinition } from "@outschool/icons";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import LegacyButton from "./LegacyButton/LegacyButton";

/**
 * @deprecated Use `IconButtonProps` from `@outschool/backpack` instead.
 */
export type IconButtonProps = {
  icon: IconDefinition;
  iconProps?: Omit<IconProps, "icon">;
  screenReaderLabel?: string;
  sx: SystemStyleObject;
} & Record<string, any>;

function IconButton(
  {
    icon,
    iconProps = {},
    screenReaderLabel,
    sx,
    disabled,
    onClick,
    ...props
  }: IconButtonProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  const { sx: iconSx = {}, ...iconRestProps } = iconProps;

  return (
    <LegacyButton
      variant="link"
      sx={{
        width: 46,
        height: 46,
        color: "grayText",
        "&:hover:not(:disabled), &:active:not(:disabled)": { color: "link" },
        "&:disabled": { cursor: "default" },
        ...sx,
      }}
      disabled={disabled}
      onClick={e => {
        if (disabled) {
          e.preventDefault();
        } else {
          onClick?.(e);
        }
      }}
      {...props}
      ref={ref}
    >
      <Icon
        icon={icon}
        sx={[
          { color: disabled ? "disabledText" : iconSx?.color },
          ...(Array.isArray(iconSx) ? iconSx : [iconSx]),
        ]}
        {...iconRestProps}
      />
      {screenReaderLabel && (
        <span style={visuallyHidden}>{screenReaderLabel}</span>
      )}
    </LegacyButton>
  );
}

/**
 * @deprecated Use `IconButton` from `@outschool/backpack` instead.
 */
export default React.forwardRef<HTMLElement, IconButtonProps>(IconButton);
