import React from "react";

import { TrackedButton, TrackedButtonProps } from "./TrackedButton";

const GOOGLE_COLOR = "#4285f4";
const GOOGLE_COLOR_HOVER = "#2a75f3";
const GOOGLE_COLOR_ACTIVE = "#1b6cf2";

export type GoogleButtonProps = TrackedButtonProps;

const GoogleButton = (
  { sx, ...props }: TrackedButtonProps,
  ref: React.Ref<any>
) => (
  <TrackedButton
    ref={ref}
    color="inherit"
    sx={[
      {
        color: "common.white",
        borderColor: GOOGLE_COLOR,
        backgroundColor: GOOGLE_COLOR,
        "&:hover:not(:disabled), &:focus:not(:disabled)": {
          color: "common.white",
          borderColor: GOOGLE_COLOR_HOVER,
          backgroundColor: GOOGLE_COLOR_HOVER,
          textDecoration: "none",
        },
        "&:active:not(:disabled)": {
          color: "common.white",
          borderColor: GOOGLE_COLOR_ACTIVE,
          backgroundColor: GOOGLE_COLOR_ACTIVE,
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  />
);

export default React.forwardRef(GoogleButton);
