import { gql } from "@outschool/ui-apollo";

export const LearnerFragment = gql`
  fragment LearnerFragment on Learner {
    uid
    name
    age
    avatar
    pronoun
    intro
    deleted_at
    isVerified
    isBlocked
    blockedByUserUid
    blockedAt
  }
`;
