import { Components, Theme } from "@mui/material";

export const TEXT_FIELD_THEME: Components<Theme>["MuiTextField"] = {
  defaultProps: {
    // outlined is the only variant we support, so be sure it's set
    variant: "outlined",
    InputLabelProps: {
      // keep the input label on top of the input, as if it's focused
      shrink: true,
    },
  },
};

export const OUTLINED_INPUT_THEME: Components<Theme>["MuiOutlinedInput"] = {
  // These styles apply to all outlined inputs. Currently this only affects
  // TimePicker and DateTimePicker but we should be aware of the scope of this
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(8),
    }),
  },
};
