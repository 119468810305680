/**
 * Events sent via kafka rest proxy client
 * need to be valid against a schema  defined in schema registry
 * to be ingested by Kafka,
 * otherwise the event will be rejected.
 *
 * Many of these schemas are managed in code on the data-schemas package
 * but not all, the migration is in progress
 *
 * See confluent schema registry notebook
 * https://www.notion.so/outschool/Confluent-Schema-Registry-Runbook-9ddeec7582204dba844c0a5cf276676d
 *
 * Constants per schema match the confluent schema identifiers
 **/

interface AnalyticsPluginRestProxyTrackArgs {
  event: object;
  topic: string;
  topic_schema_id: string;
}

// Payload sent to sentEvent of RestProxy
// this is a better name as not all usages of this service are through the analytics plugin
type RestProxyPayload = AnalyticsPluginRestProxyTrackArgs;

interface BaseEvent {
  anonymous_id?: string;
  context_library_name: string;
  git_commit_id: string;
  event_text: string;
  user_uid?: string;
  uuid: string;
}

const EXPERIMENT_TRIGGERS_INTAKE_TOPIC = "experiment_triggers_raw";
const EXPERIMENT_TRIGGERS_INTAKE_TOPIC_SCHEMA_ID = "100298";
const EXPERIMENT_TRIGGERS_REFINED_TOPIC = "experiment_triggers";
const EXPERIMENT_TRIGGERS_REFINED_TOPIC_SCHEMA = "experiment_triggers-value";

const EXPERIMENT_ASSIGNMENTS_INTAKE_TOPIC = "experiment_assignments_raw";
const EXPERIMENT_ASSIGNMENTS_INTAKE_TOPIC_SCHEMA_ID = "100187";
const EXPERIMENT_ASSIGNMENTS_REFINED_TOPIC = "experiment_assignments";
const EXPERIMENT_ASSIGNMENTS_REFINED_TOPIC_SCHEMA =
  "experiment_assignments-value";

const LEARNER_TRACKING_INTAKE_TOPIC = "tracking_events_raw";
const LEARNER_TRACKING_INTAKE_TOPIC_SCHEMA_ID = "100586";
const LEARNER_TRACKING_REFINED_TOPIC = "tracking_events";
const LEARNER_TRACKING_REFINED_TOPIC_SCHEMA = "tracking_events-value";

const SEARCH_IMPRESSIONS_INTAKE_TOPIC = "search_impressions_raw";
const SEARCH_IMPRESSIONS_INTAKE_TOPIC_SCHEMA_ID = "100201";
const SEARCH_IMPRESSIONS_REFINED_TOPIC = "search_impressions";
const SEARCH_IMPRESSIONS_REFINED_TOPIC_SCHEMA = "search_impressions-value";

// for generic events and data
const ANALYTICS_EVENTS_TOPIC = "analytics_events";
const ANALYTICS_EVENTS_SCHEMA_ID = "100558";

//For Read Only Login tracking
const FACEBOOK_USERS_TOPIC = "facebook_users";
const FACEBOOK_USERS_SCHEMA_ID = "100590";

const GOOGLE_USERS_TOPIC = "google_users";
const GOOGLE_USERS_SCHEMA_ID = "100589";

const APPLE_USERS_TOPIC = "apple_users";
const APPLE_USERS_SCHEMA_ID = "100671";

const OIDC_USERS_TOPIC = "oidc_users";
const OIDC_USERS_SCHEMA_ID = "100689";

const TRUSTOS_EVENTS_TOPIC = "trustos_events";
const TRUSTOS_EVENTS_SCHEMA_ID = "100612";

export {
  BaseEvent,
  AnalyticsPluginRestProxyTrackArgs,
  RestProxyPayload,
  EXPERIMENT_TRIGGERS_INTAKE_TOPIC,
  EXPERIMENT_TRIGGERS_INTAKE_TOPIC_SCHEMA_ID,
  EXPERIMENT_TRIGGERS_REFINED_TOPIC,
  EXPERIMENT_TRIGGERS_REFINED_TOPIC_SCHEMA,
  EXPERIMENT_ASSIGNMENTS_INTAKE_TOPIC,
  EXPERIMENT_ASSIGNMENTS_INTAKE_TOPIC_SCHEMA_ID,
  EXPERIMENT_ASSIGNMENTS_REFINED_TOPIC,
  EXPERIMENT_ASSIGNMENTS_REFINED_TOPIC_SCHEMA,
  ANALYTICS_EVENTS_TOPIC,
  ANALYTICS_EVENTS_SCHEMA_ID,
  LEARNER_TRACKING_INTAKE_TOPIC,
  LEARNER_TRACKING_REFINED_TOPIC,
  LEARNER_TRACKING_INTAKE_TOPIC_SCHEMA_ID,
  LEARNER_TRACKING_REFINED_TOPIC_SCHEMA,
  SEARCH_IMPRESSIONS_INTAKE_TOPIC,
  SEARCH_IMPRESSIONS_INTAKE_TOPIC_SCHEMA_ID,
  SEARCH_IMPRESSIONS_REFINED_TOPIC,
  SEARCH_IMPRESSIONS_REFINED_TOPIC_SCHEMA,
  FACEBOOK_USERS_TOPIC,
  FACEBOOK_USERS_SCHEMA_ID,
  GOOGLE_USERS_TOPIC,
  GOOGLE_USERS_SCHEMA_ID,
  APPLE_USERS_TOPIC,
  APPLE_USERS_SCHEMA_ID,
  OIDC_USERS_TOPIC,
  OIDC_USERS_SCHEMA_ID,
  TRUSTOS_EVENTS_TOPIC,
  TRUSTOS_EVENTS_SCHEMA_ID,
};
