function sanitizeBranchName(branch: string) {
  return (
    branch
      // Regex treats '_' as a word character, so we convert it to
      // hyphens first to get nicer sanitized names.
      .replace(/_/g, "-")
      // Convert to pascal case
      .toLowerCase()
      .replace(/\b(\w)/g, s => s.toUpperCase())
      // Remove non-alpha characters
      .replace(/\W/g, "")
      // Prevent the stack prefix from being too long
      .slice(0, 30)
  );
}

// Replace all consecutive non-domain name safe characters with '-'
//
// Note that here in the CI environment you can use CI_COMMIT_REF_SLUG, but that
// doesn't exist locally and so, we've mostly replicated it here with this
// function that we can replicate / run in multiple places.
// keep in sync with lang-js/build-scripts/util/deploys.js
// Replace all consecutive non-domain name safe characters with '-'
// and remove leading/trailing '-'.
export function getEnvironmentSlug(environment: string, branch: string) {
  return environment === "Prod"
    ? "production"
    : branch.replace(/[^A-Za-z0-9]+/g, "-").replace(/^-|-$/g, "");
}

export function getEnvName(isProduction: boolean, gitBranch: string) {
  return isProduction
    ? "Prod"
    : gitBranch === "master"
    ? "Stage"
    : "Br" + sanitizeBranchName(gitBranch);
}

export function readNonZeroNaturalNumber(
  envVar: string | undefined,
  defaultValue: number | undefined = undefined
) {
  if (!envVar) {
    return undefined;
  }
  const n = Number.parseInt(envVar, 10);
  return Number.isFinite(n) && n > 0 ? n : defaultValue;
}

export default {
  getEnvName,
  readNonZeroNaturalNumber,
};

// https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#dns-label-names
export function nameToRFC1035(name: string, max = 63) {
  return (
    name
      // Strip non-alphanumeric.
      .replaceAll(/[^A-Za-z0-9\-]+/g, "")
      // Convert pascal case to snake case.
      .replaceAll(/([^A-Z])(?=[A-Z][^A-Z])/g, "$1-")
      .toLowerCase()
      .substring(0, max)
      // Ensure name does not end with a dash.
      .replace(/-+$/, "")
  );
}

export function getK8sNamespace(app: string, env: string, mr?: string) {
  let ns;
  if (env.startsWith("Br")) {
    if (!mr) {
      throw new Error(
        "MR number must be specified in review to run in kubernetes!"
      );
    }

    const maxLength = 63;
    const a = `${mr}-`;
    const c = `-${app}`;
    // Skip the Br prefix and use as much as possible.
    const b = env.slice(2, 2 + maxLength - a.length - c.length);
    ns = a + b + c;
  } else {
    ns = app;
  }
  return ns.toLowerCase().substring(0, 63);
}
