// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

import { getReferrerAttribution } from "@outschool/attribution";
import {
  CreateAccountMutation as CreateAccountMutationType,
  CreateAccountMutationVariables,
} from "@outschool/gql-frontend-generated";
import {
  ExecutionResult,
  MutationFunctionOptions,
  MutationResult,
  gql,
  useMutation,
} from "@outschool/ui-apollo";
import { FullCurrentUserFragment } from "./CurrentUserQuery";
import React from "react";

export const createAccountMutation = gql`
  mutation CreateAccount(
    $name: String
    $email: String!
    $password: String!
    $attribution: Attribution
    $browserTimeZone: String
    $locale: UserLocale
    $isLeader: Boolean
    $subscribe: Boolean
    $isGiftCardSignup: Boolean
    $shouldNotSellPersonalInfo: Boolean
    $sellerOrgPrimaryContactName: String
    $osRef: OsRefInput
    $isOnboardingSignup: Boolean
    $shouldCreateDefaultLearner: Boolean
    $defaultLearnerAge: Int
  ) {
    createAccount(
      name: $name
      email: $email
      password: $password
      attribution: $attribution
      browserTimeZone: $browserTimeZone
      locale: $locale
      isLeader: $isLeader
      subscribe: $subscribe
      isGiftCardSignup: $isGiftCardSignup
      shouldNotSellPersonalInfo: $shouldNotSellPersonalInfo
      sellerOrgPrimaryContactName: $sellerOrgPrimaryContactName
      osRef: $osRef
      isOnboardingSignup: $isOnboardingSignup
      shouldCreateDefaultLearner: $shouldCreateDefaultLearner
      defaultLearnerAge: $defaultLearnerAge
    ) {
      uid
      sessionToken
      refreshToken
      currentUser {
        ...FullCurrentUserFragment
      }
    }
  }
  ${FullCurrentUserFragment}
`;

type CreateAccountMutationOptions = MutationFunctionOptions<
  CreateAccountMutationType,
  Omit<CreateAccountMutationVariables, "osRef">
>;

type UseCreateAccountMutationReturnType = [
  (
    options: CreateAccountMutationOptions
  ) => Promise<ExecutionResult<CreateAccountMutationType>>,
  MutationResult<CreateAccountMutationType>
];

export function useCreateAccountMutation(): UseCreateAccountMutationReturnType {
  const [createAccount, executionStatusVars] = useMutation<
    CreateAccountMutationType,
    CreateAccountMutationVariables
  >(createAccountMutation);
  // Wrap the createAccount mutation to add attribution when it's called
  const createAccountFn = React.useCallback(
    async (options: CreateAccountMutationOptions, anonymousId?: string) => {
      return await createAccount({
        ...options,
        // @ts-ignore TS(2322): Type '{ osRef: any; email?: string | undefined; at... Remove this comment to see the full error message
        variables: {
          ...options.variables,
          osRef: {
            ...getReferrerAttribution(),
            anonymousId,
          },
        },
      });
    },
    [createAccount]
  );

  return [createAccountFn, executionStatusVars];
}
